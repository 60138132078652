// // PDFGenerator.js
// import React from 'react';
// import html2pdf from 'html2pdf.js';

// import {MdDownloadForOffline} from "react-icons/md"

// const PDFGenerator = ({ text, title }) => {
//   const generatePDF = () => {
//     // Create a hidden element with the text content
//     const element = document.createElement('div');
//     element.innerHTML = text;
//     // element.style.display = 'none';
//     // document.body.appendChild(element);

//     // Convert the HTML element to PDF
//     html2pdf(element, {
//       margin: 0.5,
//       filename: title,
//       html2canvas: { scale: 2 },
//       jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' },
//       onComplete: () => {
//         // Remove the temporary element
//         document.body.removeChild(element);
//       },
//     });

//     // // Simulate click event on a temporary anchor element
//     // const downloadLink = document.createElement('a');
//     // downloadLink.href = '#';
//     // downloadLink.setAttribute('download', title);
//     // document.body.appendChild(downloadLink);
//     // downloadLink.click();
//     // document.body.removeChild(downloadLink);
//   };

//   return (
//     <div className='mt-5 '>
//       <button className='flex items-center gap-3' onClick={generatePDF}><MdDownloadForOffline/> Download PDF</button>
//     </div>
//   );
// };

// export default PDFGenerator;
// PDFGenerator.js
import React from 'react';
import html2pdf from 'html2pdf.js';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';

const PDFGenerator = ({ text, assetUrls, title }) => {
  const generatePDFAndDownloadZip = async () => {

    // Create a hidden element with the text content
    // const element = document.createElement('div');
    // element.innerHTML = text;

     // Add the PDF to the zip file
    // Convert the HTML element to PDF

    const zip = new JSZip();



    const assetPromises = assetUrls.map(async (url, index) => {
      console.log('rl', url)
      const response = await fetch(`https://sbpbackend.sharjahaviation.com/uploads/${url.newfilename}`);
      const assetBlob = await response.blob();
      const filename = url.newfilename
      zip.file(filename, assetBlob, { binary: true });
    });

    await Promise.all(assetPromises);

    // Generate zip and download
    zip.generateAsync({ type: 'blob' }).then((zipBlob) => {
      saveAs(zipBlob, 'assets_and_pdf.zip');
    });
  };

  return (
    <div>
      <button onClick={generatePDFAndDownloadZip}>Download Assets and PDF as Zip</button>
    </div>
  );
};

export default PDFGenerator;