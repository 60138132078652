import React, { useState, useEffect } from 'react'
import { Link, useParams, useNavigate } from "react-router-dom";

import { toast } from "react-toastify";
import LoadingBar from "../Global/LoadingBar";
import Navbar from "../Global/Navbar";
import SelectDesignation from "../Global/SelectDesignation";

import { BsDot } from "react-icons/bs";
import Utils from '../../utils.json';
import Credits from '../Global/Credits';

const Login = () => {

  const [adminName, setAdminName] = useState('')

  const navigate = useNavigate();

  useEffect(() => {
    let ls = localStorage.getItem('admin');
    if (ls) {
      navigate('/view')
    }
  })

  const [staffid, setStaffId] = useState('');
  const [staffpassword, setStaffPassword] = useState('');

  const handleCheckForAccess = () => {
    if (staffid === "") {
      toast.error('Staffid is required!', { autoClose: 2000 });
      return false;
    }

    if (staffpassword === "") {
      toast.error('Password is required For the briefing!', { autoClose: 2000 });
      return false;
    }

    const data = {
      staffid,
      staffpassword
    }


    fetch(`${Utils['domains']['development']}/staff/adminallowaccess`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json", // Specify that you're sending JSON data
      },
      body: JSON.stringify(data), // Set the JSON data as the request body
    }).then((e) => {
      return e.json()
    }).then((data) => {
      if (!data.length > 0) {
        toast.error('Invalid username and password!', { autoClose: 2000 });
        return false;
      }

      localStorage.setItem('admin', JSON.stringify(data[0]));
      setAdminName(data[0]['staffname'])
      setTimeout(() => {
        navigate('/view')

      }, 1000);
      toast.success('welcome, ' + data[0]['staffname'] + '!')

      // return false;

      // setLoadingBar(true)
      // setAllowAccess(true)
      // updateStaffCredentialsContext(staffid, staffpassword)
      // const staff = {staffid, staffpassword, designation};



      // toast('New Briefing Created Successfully');
      // setTitle("")
      // setDesignation("all")
      // setCategory("SOP");
      // setTimeout(() => {
      //   navigate('/view')
      // }, 2000);
    })
  }

  return (
    <div>
      <PasswordModel staffid={staffid} setStaffId={setStaffId} staffpassword={staffpassword} setStaffPassword={setStaffPassword} handleCheckForAccess={handleCheckForAccess} />
    </div>
  )
}

const PasswordModel = ({ handleCheckForAccess, staffid, staffpassword, setStaffId, setStaffPassword }) => {
  return (
    // <div style={{"background": "#0005", "backdropFilter": "blur(15px)"}} className="shadow-sm border border-black w-[90%] lg:w-[400px] py-20 rounded-md flex flex-col gap-6 p-10 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
    <div className="shadow-sm border bg-a-gray w-[90%] lg:w-[400px] py-20 rounded-md flex flex-col gap-6 p-10 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">

      <div className="flex flex-col gap-1">

        <p className="text-white">Select your designation</p>

        <p className="text-white text-xs">Selected: Admin</p>
      </div>

      <SelectDesignation />



      <input
        type="text"
        className="h-10 rounded-lg px-3"
        placeholder="Staff ID"
        value={staffid}
        onChange={(e) => {
          setStaffId(e.target.value);
        }}
      />
      <input
        type="password"
        className="h-10 rounded-lg px-3"
        placeholder="Password"
        value={staffpassword}
        onChange={(e) => {
          setStaffPassword(e.target.value);
        }}
      />
      <button
        onClick={handleCheckForAccess}
        className="bg-gradient-to-r from-a-pink to-a-blue hover:to-a-pink hover:from-a-blue px-12 py-4 rounded-lg text-white uppercase text-xs
                
                "
      >
        Login
      </button>
      <Credits />
    </div>
  );
};

export default Login