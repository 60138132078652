import React, { useEffect, useState } from "react";
import Navbar from "../../Global/Navbar";

import { toast } from "react-toastify";
import { Link } from "react-router-dom";

import { useNavigate } from "react-router-dom";
import LoadingBar from "../../Global/LoadingBar";
import { PiMicrosoftExcelLogoFill } from "react-icons/pi";
import Utils from '../../../utils.json';

import {TiTick} from "react-icons/ti"
import {FaCheckCircle} from "react-icons/fa";

import {IoMdCloseCircle} from "react-icons/io"

const SignNotSIgnStaff = () => {
  const navigate = useNavigate();

  useEffect(() => {
    let ls = localStorage.getItem('admin');
    if (!ls) {
      navigate('/login')
    }
    return;
  })
  const [staffList, setStaffList] = useState([]);
  const [allstafflist, setallStafflist] = useState([])
  const [modifyrecords1sttime, setmodifyrecords1sttime] = useState(true);
  const [loadingbar, setLoadingBar] = useState(true);
  

 

  // create new staff
  const [staffname, setStaffName] = useState("");
  const [staffid, setStaffId] = useState("");
  const [staffcategory, setStaffCategory] = useState("");
  const [signstatus, setSignStatus] = useState("no-sign");
  const [briefingcategory, setbriefingCategory] = useState("");
  const [staffbriefing, setStaffbriefing] = useState(0);
  //   const [staffpassword, setStaffPassword] = useState("");
  const [newCreateNewStaff, setNewCreateNewStaff] = useState(false);
  const [briefingforoptions, setBriefingForOptions] = useState([])
  const [briefingforoptionsSubtitle, setBriefingForOptionsSubtitle] = useState([])
  const [briefingid, setBriefingId] = useState('');
  const [shiftlist, setshiftlist] = useState([]);
  const [shiftwise, setshiftwise] = useState('')
  const [selectBriefingTitleUnique, setselectBriefingTitleUnique] = useState("")

  // after frontend filter count sign or not sign
  const [afterfeSign, setAfterfesign] = useState(0);


  // filter
  const [applyFilter, setApplyFilter] = useState(false);
  const [stafffetchlimit, setstafffetchlimit] = useState(20);

  useEffect(() => {
    fetch(`${Utils['domains']['development']}/shift/getlist`, {
      method: "get",
      headers: {
        "Content-Type": "application/json", // Specify that you're sending JSON data
      },
      // body: JSON.stringify({}), // Set the JSON data as the request body
    })
      .then((e) => {
        return e.json();
      })
      .then((data) => {
        setTimeout(() => {
          // setLoadingBar(false);
          setshiftlist(data);
        }, 0);
      });
  }, []);

  // 
  useEffect(() => {
    fetch(`${Utils['domains']['development']}/staff/viewbyfilter`, {
      method: "post",
      headers: {
        "Content-Type": "application/json", // Specify that you're sending JSON data
      },
      body: JSON.stringify({ stafffetchlimit: '' }), // Set the JSON data as the request body
    })
      .then((e) => {
        return e.json();
      })
      .then((data) => {
        setTimeout(() => {
          // setLoadingBar(false);
          setallStafflist(data);
        }, 0);
      });
  }, []);

  useEffect(() => {
    fetch(`${Utils['domains']['development']}/staff/signnotsignstaff`, {
      method: "get",
      headers: {
        "Content-Type": "application/json", // Specify that you're sending JSON data
      },
      // body: JSON.stringify({}), // Set the JSON data as the request body
    })
      .then((e) => {
        return e.json();
      })
      .then((data) => {
        setTimeout(() => {
          setLoadingBar(false);
          setStaffList(data);
          setmodifyrecords1sttime(true)

        }, 0);
      });
  }, []);

  // 
  useEffect(() => {
    // console.clear()
    // console.log(staffList.length)
    // console.log(allstafflist.length)

    if (modifyrecords1sttime) {

      if (staffList.length >= 1 && allstafflist.length >= 1) {
        // console.clear()
        // console.log("0-=---=0-04032940329403249320=324-32-4=32-4=32-432=4-32=4-32=4-=")
        for (let i = 0; i < staffList.length; i++) {
          const staffid = staffList[i].staffid;


          const matcObj = allstafflist.find(obj => obj.staffid === staffid);

          if (matcObj) {
            staffList[i].shift = matcObj.shift;

          }
        }

        // console.log('updated staff list h', staffList)
        setStaffList(staffList);
        setmodifyrecords1sttime(false);

      }
    }


 


  }, [staffList, allstafflist])

  useEffect(()=>{
    let tablesrow = document.querySelectorAll("tr");
    setAfterfesign(tablesrow.length);

    Array.from(tablesrow).map((tr, index)=>{
      tr.querySelector("td").innerText = index;
      console.log(index, index, "and index will be there")
    })
  })

  useEffect(() => {
    fetch(`${Utils['domains']['development']}/staff/allbriefing`, {
      method: "get",
      headers: {
        "Content-Type": "application/json", // Specify that you're sending JSON data
      },
      // body: JSON.stringify({}), // Set the JSON data as the request body
    })
      .then((e) => {
        return e.json();
      })
      .then((data) => {
        setTimeout(() => {

          setBriefingForOptions(data)
          console.clear()
          console.log(data)
          console.log(typeof data)

          let seenTitles = {};
          let uniqueObjects = []

          for (let obj of data) {
            let title = obj.title;
            if (!seenTitles[title]) {
              seenTitles[title] = true;
              uniqueObjects.push(obj)
            }
          }
          console.log(uniqueObjects)


          setBriefingForOptionsSubtitle(uniqueObjects)
          // setLoadingBar(false);
          // setStaffList(data);
        }, 0);
      });
  }, []);

  const updateStaff = (key, value, id) => {
    const data = {
      key, value, id
    }
    fetch(`${Utils['domains']['development']}/staff/updatestaffsign`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json", // Specify that you're sending JSON data
      },
      body: JSON.stringify(data), // Set the JSON data as the request body
    }).then((e) => {
      toast('Details updated successfully!', { autoClose: 2000 })
    });
  }

  useEffect(() => {
    if (applyFilter) {

      const params = new URLSearchParams({
        staffid,
        staffcategory,
        stafffetchlimit,
        briefingcategory,
        signstatus,
        briefingid,
        selectBriefingTitleUnique
      });
      console.log(params);
      

      let data = { staffid, staffcategory, stafffetchlimit, briefingcategory };
      fetch(
        `${Utils['domains']['development']}/staff/signnotsignstaffbyfilter?${params.toString()}`,
        {
          method: "get",
          headers: {
            "Content-Type": "application/json", // Specify that you're sending JSON data
          },
          //   body: JSON.stringify(data), // Set the JSON data as the request body
        }
      )
        .then((e) => {
          return e.json();
        })
        .then((data) => {
          setTimeout(() => {
            setLoadingBar(false);
            setStaffList(data);

            // console.clear()
            // console.log("-----------------------------------------")
            // console.log(data)
            // console.clear()
            // console.log(data)
            setApplyFilter(false);
            setmodifyrecords1sttime(true)

          }, 0);
        });
    }
    let tablesrow = document.querySelectorAll("tr");
    setAfterfesign(tablesrow.length)

    Array.from(tablesrow).map((tr, index)=>{
      tr.querySelector("td").innerText = index;
      console.log(index, index);
      
    })
  }, [applyFilter]);

  const HandleExportAsExcel = () => {
    const newStaffList = staffList.map((item) => {
      const updatedItem = { ...item };
      delete updatedItem.questions;
      delete updatedItem._id;
      delete updatedItem.briefingid;
      delete updatedItem.__v;
      return updatedItem;
    });

    const keyMapping = {
      staffid: 'Staff ID',
      briefingtitle: 'Briefing Title',
      staffname: 'Staff Name',
      designation: 'Designation',
      category: 'Category',
      createdTime: 'Created Time',
      status: 'Status',
      createdDate: 'Created Date'
    };

    const separator = ",";
    const keys = Object.keys(newStaffList[0]);

    const formattedKeys = keys.map(key => keyMapping[key]);


    const csvContent = [
      formattedKeys.join(separator),
      ...newStaffList.map((item) =>
        keys.map((key) => item[key]).join(separator)
      ),
    ].join("\n");

    const blob = new Blob([csvContent], { type: "text/csv" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "data.csv";
    a.click();
    URL.revokeObjectURL(url);
    setTimeout(() => {
      a.remove()
    }, 1000);
  };

  return (
    <div>
      {/* {alert("Redirecting to the main page!")} */}
      {/* {alert("Development is going on with this page")} */}
      <Navbar title={"Staff List"} showarrow={false} />
      {loadingbar && <LoadingBar />}
      <FilterBar
      afterfeSign={afterfeSign}
        briefingforoptions={briefingforoptions}
        setBriefingForOptions={setBriefingForOptions}
        briefingforoptionsSubtitle={briefingforoptionsSubtitle} setBriefingForOptionsSubtitle={setBriefingForOptionsSubtitle}
        staffid={staffid}
        shiftlist={shiftlist}
        staffname={staffname}
        shiftwise={shiftwise} setshiftwise={setshiftwise}
        staffcategory={staffcategory}
        setStaffId={setStaffId}
        setStaffName={setStaffName}
        setStaffCategory={setStaffCategory}
        HandleExportAsExcel={HandleExportAsExcel}
        briefingcategory={briefingcategory}
        setbriefingCategory={setbriefingCategory}
        setselectBriefingTitleUnique={setselectBriefingTitleUnique}
        selectBriefingTitleUnique={selectBriefingTitleUnique}
        briefingid={briefingid} setBriefingId={setBriefingId}
        setApplyFilter={setApplyFilter}
        signstatus={signstatus}
        setSignStatus={setSignStatus}
        setLoadingBar={setLoadingBar}
        stafffetchlimit={stafffetchlimit}
        setstafffetchlimit={setstafffetchlimit}
      />
      <div class="relative rounded-xl overflow-auto">
        <div class="shadow-sm overflow-x-scroll  lg:overflow-hidden my-8">
          {!staffList.length <= 0 && (
            <StaffTable loadingbar={loadingbar} staffList={staffList} updateStaff={updateStaff} shiftwise={shiftwise} />
          )} </div> </div>
    </div>
  );
};

const FilterBar = ({
  afterfeSign,
  staffid,
  staffcategory,
  setStaffId,
  setStaffCategory,
  shiftlist,
  shiftwise, setshiftwise,
  setApplyFilter,
  setLoadingBar,
  HandleExportAsExcel,
  briefingforoptionsSubtitle, setBriefingForOptionsSubtitle,
  selectBriefingTitleUnique, setselectBriefingTitleUnique,

  stafffetchlimit,
  setstafffetchlimit,
  signstatus,
  setSignStatus,
  briefingforoptions,
  setBriefingForOptions,

  setbriefingCategory,
  briefingcategory,

  briefingid,
  setBriefingId,

}) => {
  const handleApplyFilter = () => {
    setApplyFilter(true);
    setLoadingBar(true);
  };
  return (
    <div className="bg-a-gray py-4 mt-5 rounded-lg flex flex-wrap gap-3 items-center px-5 w-full   h-auto">
      <div>
        <p className="text-xs mb-1 text-white" > Select Staff </p>

        <input
          type="text"
          className="h-10 rounded-lg px-3 w-24"
          placeholder="Staff Id"
          value={staffid}
          onChange={(e) => {
            setStaffId(e.target.value);
          }}
        />

      </div>

      <div>
        <p className="text-xs mb-1 text-white" > Designation </p>

        <select
          onChange={(e) => {
            // console.log(briefingforoptions)

            // let prbfo = briefingforoptions.filter((pre)=>{
            //   if (pre.designation === e.target.value) {
            //     // console.log(pre)
            //     return pre;
            //   }
            // })

            // setBriefingForOptions(prbfo)
            setStaffCategory(e.target.value);
          }}
          value={staffcategory}
          className="h-10 rounded-lg px-3"
          placeholder="Designation"
        >
          <option value="">All</option>
          <option value="aa">AA</option>
          <option value="pa">PA</option>
          <option value="psa">PSA</option>
          <option key="PSA-HALA" value="PSA-HALA">PSA-HALA</option>
        <option key="PSA-VCT" value="PSA-VCT">PSA-VCT</option>
          <option value="tl">TL</option>
          <option value="css">CSS</option>
          <option value="csm">CSM</option>
          <option value="CSRA">CSRA</option>
        </select>
      </div>

      <div>
        <p className="text-xs mb-1 text-white" > Briefing Category </p>

        <select
          onChange={(e) => {
            setbriefingCategory(e.target.value);
          }}
          type="text"
          value={briefingcategory}
          className="h-10 rounded-lg px-3"
          placeholder="Category"
        >
          <option value="">All</option>
          <option value="SOP">SOP</option>
          <option value="General Briefing">General Briefing</option>
          <option value="Safety Briefing">Safety Briefing</option>
          <option value="Immigration Guideline">Immigration Guideline </option>
          <option value="Product Knowledge">Product Knowledge</option>
          <option value="Work Instruction">Work Instruction</option>

          <option value="Ops Requirments">Ops Requirments</option>
          <option value="Other">Other</option>
        </select>

      </div>

      <div>
        <p className="text-xs mb-1 text-white" > Briefing Subjects </p>


        <select
          onChange={(e) => {
            setBriefingId(e.target.value);
          }}
          type="text"
          value={briefingid}
          className="h-10 rounded-lg px-3"
          placeholder="Category"
        >
          {briefingforoptions.length <= 0 && <option value="">Loading...</option>}
          {briefingforoptions.length >= 0 && <option value="">All</option>}
          {briefingforoptions.length >= 0 && briefingforoptions.map((briefing, index) => {
            if (staffcategory === "") {
              return <option value={briefing._id}> {briefing.designation} - {briefing.title.substring(0, 20)}...</option>

            } else {
              if (briefing.designation === staffcategory) {

                return <option value={briefing._id}> {briefing.designation} - {briefing.title.substring(0, 20)}...</option>
              }
            }
          })}

        </select>

      </div>

      <div>
        <p className="text-xs mb-1 text-white" > Select Shift by ADM </p>


        <select
          // onChange={(e) => {
          //   setStaffDesignation(e.target.value);
          // }}
          // onChange={(e) => {
          //   setshift(e.target.value);
          // }}
          className="h-10 rounded-lg px-3"
          placeholder="Designation"
          // defaultValue={staff.shift}
          onChange={(e) => {
            // updateStaff('shift', e.target.value, staff._id )
            setshiftwise(e.target.value)
          }}
        >

          <option value="">All</option>
          {shiftlist.map((shift, index) => {
            return <option key={shift._id} value={shift._id}>{shift.shiftadm}</option>
          })}


        </select>

      </div>

<div>
<p className="text-xs mb-1 text-white" > Sub Title </p>
          {/* {selectBriefingTitleUnique} */}
      <select
          onChange={(e) => {
            console.log(e.target.value)
            setselectBriefingTitleUnique(e.target.value);
          }}
          type="text"
          value={selectBriefingTitleUnique}
          className="h-10 rounded-lg px-3"
          placeholder="Category"
        >
          {briefingforoptionsSubtitle.length <= 0 && <option value="">Loading...</option>}
          {briefingforoptionsSubtitle.length >= 0 && <option value="">All</option>}
          {briefingforoptionsSubtitle.length >= 0 && briefingforoptionsSubtitle.map((briefing, index) => {
            // if (staffcategory === "") {
            //   return <option value={briefing._id}> {briefing.designation} - {briefing.title.substring(0, 20)}...</option>

            // } else {
            //   if (briefing.designation === staffcategory) {

            //     return <option value={briefing._id}> {briefing.designation} - {briefing.title.substring(0, 20)}...</option>
            //   }
            // }
              return <option value={briefing.title}> {briefing.title.substring(0, 30)}...</option>
          })}

        </select>
</div>


      <div>
        <p className="text-xs mb-1 text-white" > Sign Status </p>



        <select
          onChange={(e) => {
            setSignStatus(e.target.value);
          }}
          type="text"
          value={signstatus}
          className="h-10 rounded-lg px-3"
          placeholder="Category"
        >
          <option value="">All</option>
          <option value="sign">Sign</option>
          <option value="no-sign">Not Sign Yet!</option>
        </select>

      </div>

      <div>
        <p className="text-xs mb-1 text-white" > Limit </p>

        <select
          onChange={(e) => {
            setstafffetchlimit(e.target.value);
          }}
          value={stafffetchlimit}
          className="h-10 rounded-lg px-3"
          placeholder="limit"
        >
          <option value="5">5</option>
          <option selected value="10">
            10
          </option>
          <option value="20">20</option>
          <option value="30">30</option>
          <option value="">All</option>

        </select>
      </div>
      <button
        onClick={handleApplyFilter}
        className="  bg-gradient-to-r from-a-pink to-a-blue hover:to-a-pink hover:from-a-blue px-12 h-10 rounded-lg text-white uppercase text-xs"
      >
        Apply
      </button>
      <div className="bg-gray-800 p-2 rounded text-white">
      Query Result {afterfeSign - 1}
      </div>
      {/* <button
        onClick={HandleExportAsExcel}
        className="flex items-center gap-2  bg-green-600 px-12 h-10 rounded-lg text-white uppercase text-xs"
      >
        <PiMicrosoftExcelLogoFill size="22px" />
        Export Excel
      </button> */}
    </div>
  );
};

const StaffTable = ({ loadingbar, staffList, updateStaff, shiftwise }) => {



  return (
    <table class="border-collapse  w-full  text-sm rounded-lg overflow-x-scroll lg:overflow-hidden mt-5">
      <thead className="bg-gradient-to-r from-a-pink to-a-blue text-white">
        <tr>
        <td class="border-b border-r border-slate-100 dark:border-slate-700 p-4 pl-8  text-white">
          SNO
          </td>
          <td class="border-b border-r border-slate-100 dark:border-slate-700 p-4 pl-8  text-white">
            Assign
          </td>
          <td class="border-b border-r border-slate-100 dark:border-slate-700 p-4  text-white">
            Staff Id
          </td>
          <td class="border-b border-r border-slate-100 dark:border-slate-700 p-4  text-white">
            Staff Name
          </td>
          <td class="border-b border-r border-slate-100 dark:border-slate-700 p-4  text-white">
            Designation
          </td>
          <td class="border-b border-r border-slate-100 dark:border-slate-700 p-4 pr-8  text-white">
            Status
          </td>
          <td class="border-b border-r border-slate-100 dark:border-slate-700 p-4 pr-8  text-white">
            Signed Date/Time
          </td>
          <td class="border-b border-r border-slate-100 dark:border-slate-700 p-4 pr-8  text-white">
            Briefing Title
          </td>
          <td class="border-b border-r border-slate-100 dark:border-slate-700 p-4 pr-8  text-white">
            Briefing Category
          </td>
        </tr>
      </thead>
      <tbody class="bg-a-gray text-white">
        
        {staffList.map((staff, index) => {
          // { console.clear() }

          // { console.log(staff) }
          if (!['802711', '000111'].includes(staff.staffid)) {
         
            if (!staff.staffname.includes("-T")) {
            if (shiftwise != "") {
              if (shiftwise === staff.shift) {
                return <TableRow staff={staff} updateStaff={updateStaff} uid={`${index}${staff.staffid}${staff.briefingid}`}  />;
              }
            } else {
              return <TableRow staff={staff} updateStaff={updateStaff} uid={`${index}${staff.staffid}${staff.briefingid}`}   />
            }}
          }

        })}
      </tbody>
      
    </table>
  );
};

const TableRow = ({ staff, updateStaff, uid }) => {
  return (
    <tr id={uid} key={uid} className={staff.staffname.includes("-T") && "bg-[brown]"}>
     {}
      <td class="border-b border-r border-slate-100 dark:border-slate-700 p-4 pl-8  text-white font-bold">
        
</td>
      <td class="border-b border-r border-slate-100 dark:border-slate-700 p-4 pl-8  text-white font-bold">
        {/* {staff.assign} */}
        {/* <select
          className="h-10 rounded-lg px-3"
          placeholder="Designation"
          defaultValue={staff.assign}
          onChange={(e) => {

            updateStaff('assign', e.target.value, staff._id)
          }}
        >
          <option key="assign" value="assign">assign</option>
          <option key="not-assign" value="not-assign">not-assign</option>
        </select> */}
        {staff.assign}
      </td>
      <td class="border-b border-r border-slate-100 dark:border-slate-700 p-4  text-white">
        {staff.staffid}
        {/* {staff.shift} */}
      </td>
      <td class="border-b border-r border-slate-100 dark:border-slate-700 p-4 pr-8  text-white">
        {staff.staffname}
      </td>
      <td class="border-b border-r border-slate-100 dark:border-slate-700 p-4 pr-8 uppercase font-bold text-white">
       
    
        {staff.designation}
    
      </td>
      <td class={"border-b border-r border-slate-100 dark:border-slate-700 p-4 pr-8  text-white "}>
      <div className="flex gap-5 items-center">
        {staff.status === "sign" && <FaCheckCircle className="text-green-500" size={25}/>}
        {staff.status === "no-sign" && <IoMdCloseCircle className="text-red-500" size={30}/>}
        
        {/* {staff.status} */}

        {!staff.staffname.includes("-T") && staff.status} 
        {staff.staffname.includes("-T") && <p>Testing User</p>} 


          </div>
      </td>
      <td class="border-b border-r border-slate-100 dark:border-slate-700 p-4 pr-8  text-white">
        {staff.status === 'sign' ? 'Date: ' + staff.createdDate.split("T")[0] : 'N/A'} <br />
        {staff.status === 'sign' &&  'Time: ' + staff.createdDate.split("T")[1].split(".")[0]}
        {}
      </td>
      <td class="border-b border-r border-slate-100 dark:border-slate-700 p-4 pr-8  text-white underline">
        
        <Link to={'/viewexpand/' + staff.briefingid}>
          {staff.briefingtitle}
        </Link>
      </td>
      <td class="border-b border-r border-slate-100 dark:border-slate-700 p-4 pr-8  text-white">
        {staff.category}
      </td>
    </tr>
  );
};

export default SignNotSIgnStaff;
