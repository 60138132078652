import React from 'react'

// 
import {BsArrowLeftShort} from 'react-icons/bs'

const Navbar = ({title, gobacklink, showarrow=true}) => {
  return (
    <div className='flex items-center gap-3'>
        {showarrow && <BsArrowLeftShort size={40} className='text-a-gray'/>}
        <p className='text-white'>{title}</p>
    </div>
  )
}

export default Navbar