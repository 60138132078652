import React from 'react'
import { Tree, TreeNode } from 'react-organizational-chart'


const ViewCSMReport = ({ rptDtaFields }) => {
    return (
        <div className="grid gap-5">


            <TreeStructure rptDtaFields={rptDtaFields} />
            <GeneralFields rptDtaFields={rptDtaFields} />
        </div>
    )
}

// Tree structure for role 
const TreeStructure = ({ rptDtaFields }) => {

    return (
        <div className='bg-gray-100  rounded-md p-5 border border-gray-300 w-full'>
            <Tree lineWidth={'5px'} lineColor={'#444'} label={<div className="bg-a-blue font-bold text-white border border-[5px] border-a-blue p-2 rounded-md inline-block">Structure</div>}>

                {/* Landside Structure */}
                <TreeNode label={<div className="border border-[5px] border-a-blue p-2 rounded-md inline-block">Landside</div>}>
                    <TreeNode label={<div className="">
                        <p className="text-sm mb-1 mt-1" > ADM </p>

                        <input
                            value={rptDtaFields['shiftadm'] && rptDtaFields['shiftadm'].value.toString()}
                            disabled
                            list="persons"
                            type="text"
                            className="h-10 rounded-lg px-3 w-full bg-gray-200 mt-1 "
                        />
                    </div>} >

                        <TreeNode label={<div className="border border-[5px] border-a-blue p-2 rounded-md inline-block">CSM</div>}>
                            <TreeNode label={<div className="">
                                <p className="text-sm mb-1 mt-1" > Airside CSM </p>

                                <input
                                    value={rptDtaFields['shiftairsideacsm'] && rptDtaFields['shiftairsideacsm'].value.toString()}
                                    disabled
                                    list="persons"
                                    type="text"
                                    className="h-10 rounded-lg px-3 w-full bg-gray-200 mt-1 "

                                />
                            </div>} />
                            <TreeNode label={<div className="">
                                <p className="text-sm mb-1 mt-1" > Landside CSM </p>

                                <input
                                    value={rptDtaFields['shiftairsidelcsm'] && rptDtaFields['shiftairsidelcsm'].value.toString()}
                                    disabled

                                    list="persons"
                                    type="text"
                                    className="h-10 rounded-lg px-3 w-full bg-gray-200 mt-1 "

                                />
                            </div>} />
                            <TreeNode label={<div className="">
                                <p className="text-sm mb-1 mt-1" > Floater CSM </p>
                                <input
                                    value={rptDtaFields['shiftairsidefcsm'] && rptDtaFields['shiftairsidefcsm'].value.toString()}
                                    disabled
                                    list="persons"
                                    type="text"
                                    className="h-10 rounded-lg px-3 w-full bg-gray-200 mt-1 "

                                />
                            </div>} />
                        </TreeNode>

                    </TreeNode>
                </TreeNode>

                {/* Airside Structure */}
                <TreeNode label={<div className="border border-[5px] border-a-blue p-2 rounded-md inline-block">Airside</div>}>

                    <TreeNode label={<div className="">
                        <p className="text-sm mb-1 mt-1" > AOM </p>

                        <input
                            value={rptDtaFields['shiftaom'] && rptDtaFields['shiftaom'].value.toString()}
                            disabled
                            list="persons"
                            type="text"
                            className="h-10 rounded-lg px-3 w-full bg-gray-200 mt-1 "
                        />
                    </div>} />
                    <TreeNode label={<div className="">
                        {/* Cargo Structure */}
                        <TreeNode label={<div className="border border-[5px] border-a-blue p-2 rounded-md inline-block">BOC</div>}>
                            <TreeNode label={<div className="">
                                <p className="text-sm mb-1 mt-1" > BOC Joining </p>

                                <input
                                    value={rptDtaFields['shiftbocjoin'] && rptDtaFields['shiftbocjoin'].value.toString()}
                                    disabled
                                    list="persons"
                                    type="text"
                                    className="h-10 rounded-lg px-3 w-full bg-gray-200 mt-1 "
                                />
                            </div>} />
                            <TreeNode label={<div className="">
                                <p className="text-sm mb-1 mt-1" > BOC Transfer </p>

                                <input
                                    value={rptDtaFields['shiftboctran'] && rptDtaFields['shiftboctran'].value.toString()}
                                    disabled
                                    list="persons"
                                    type="text"
                                    className="h-10 rounded-lg px-3 w-full bg-gray-200 mt-1 "
                                />
                            </div>} />
                        </TreeNode>




                    </div>} />
                </TreeNode>

                {/* Cargo Structure */}
                <TreeNode label={<div className="border border-[5px] border-a-blue p-2 rounded-md inline-block">Cargo</div>}>
                    <TreeNode label={<div className="">
                        <p className="text-sm mb-1 mt-1" > COM </p>

                        <input
                            value={rptDtaFields['shiftcom'] && rptDtaFields['shiftcom'].value.toString()}
                            disabled
                            list="persons"
                            type="text"
                            className="h-10 rounded-lg px-3 w-full bg-gray-200 mt-1 "
                        />
                    </div>} />
                </TreeNode>


            </Tree>
        </div>
    )
}

// all general fields but 
const GeneralFields = ({ rptDtaFields }) => {

    return (
        <div className=' bg-gray-100  rounded-md p-5  border border-gray-300'>
            <div className=' grid gap-5'>

                {/* OTP Related Fields */}
                <p className="font-bold">OTP</p>
                <div className="grid grid-cols-3 gap-5 mt-1">

                    {/*  OTP within 0 Min  */}
                    <div className="">
                        <p className="text-sm mb-1" > OTP within 0 Min		 </p>
                        <input
                            type="text"
                            className="h-10 rounded-lg px-3 w-full bg-gray-200  "
                            placeholder="OTP within 0 Min	"
                            value={rptDtaFields['otpw0m'] && (rptDtaFields['otpw0m'].value.toString() + "%")}
                            disabled
                        />
                        {/* <span>{rptDtaFields['otpw0m'] && rptDtaFields['otpw0m']?.user}</span> */}
                        <span className='text-xs mt-1 text-green-700'>Updated BY: {rptDtaFields['otpw0m'] && rptDtaFields['otpw0m']?.user}</span>
                    </div>

                    {/* OTP within 15 Min	  */}
                    <div className="">
                        <p className="text-sm mb-1" > OTP within 15 Min			 </p>
                        <input
                            type="text"
                            className="h-10 rounded-lg px-3 w-full bg-gray-200  "
                            placeholder="OTP within 15 Min	"
                            value={rptDtaFields['otpw15m'] && (rptDtaFields['otpw15m'].value.toString() + "%")}
                            disabled
                        />
                        <span className='text-xs mt-1 text-green-700'>Updated BY: {rptDtaFields['otpw15m'] && rptDtaFields['otpw15m']?.user}</span>
                    </div>

                    {/* OTP Ground Service  */}
                    <div className="">
                        <p className="text-sm mb-1" > OTP Ground Service			 </p>
                        <input
                            type="text"
                            className="h-10 rounded-lg px-3 w-full bg-gray-200  "
                            placeholder="OTP Ground Service	"
                            value={rptDtaFields['otpgs'] && (rptDtaFields['otpgs'].value.toString() + "%")}
                            disabled
                        />
                        <span className='text-xs mt-1 text-green-700'>Updated BY: {rptDtaFields['otpgs'] && rptDtaFields['otpgs']?.user}</span>
                    </div>
                </div>

                <hr />

                {/* Flights Details */}
                <p className="font-bold">Flights Details</p>
                <div className="grid grid-cols-3 gap-5">

                    {/* select  */}
                    <div className="">
                        <p className="text-sm mb-1" > Total G9 Flights	 </p>
                        <input
                            type="number"
                            className="h-10 rounded-lg px-3 w-full bg-gray-200 "
                            placeholder="Total G9 Flights"
                            value={rptDtaFields['totalg9f'] && rptDtaFields['totalg9f'].value.toString()}
                            disabled
                        />
                        <span className='text-xs mt-1 text-green-700'>Updated BY: {rptDtaFields['totalg9f'] && rptDtaFields['totalg9f']?.user}</span>
                    </div>



                    {/* select  */}
                    <div className="">
                        <p className="text-sm mb-1" > Total on Time			 </p>
                        <input
                            type="number"
                            className="h-10 rounded-lg px-3 w-full bg-gray-200 "
                            placeholder="Total on Time	"
                            value={rptDtaFields['totalot'] && rptDtaFields['totalot'].value.toString()}
                            disabled
                        />
                        <span className='text-xs mt-1 text-green-700'>Updated BY: {rptDtaFields['totalot'] && rptDtaFields['totalot']?.user}</span>
                    </div>

                    {/* select  */}
                    <div className="">
                        <p className="text-sm mb-1" > Delays Over 15 Min			 </p>
                        <input
                            type="number"
                            className="h-10 rounded-lg px-3 w-full bg-gray-200 "
                            placeholder="Delays Over 15 Min"
                            value={rptDtaFields['delyo15m'] && rptDtaFields['delyo15m'].value.toString()}
                            disabled
                        />
                        <span className='text-xs mt-1 text-green-700'>Updated BY: {rptDtaFields['delyo15m'] && rptDtaFields['delyo15m']?.user}</span>
                    </div>

                    {/* select  */}
                    <div className="">
                        <p className="text-sm mb-1" > Total Delayed			 </p>
                        <input
                            type="number"
                            className="h-10 rounded-lg px-3 w-full bg-gray-200 "
                            placeholder="Total Delayed"
                            value={rptDtaFields['totald'] && rptDtaFields['totald'].value.toString()}
                            disabled
                        />
                        <span className='text-xs mt-1 text-green-700'>Updated BY: {rptDtaFields['totald'] && rptDtaFields['totald']?.user}</span>
                    </div>

                    {/* select  */}
                    <div className="">
                        <p className="text-sm mb-1" > Total accountable delays				 </p>
                        <input
                            type="number"
                            className="h-10 rounded-lg px-3 w-full bg-gray-200 "
                            placeholder="Total accountable delays	"
                            value={rptDtaFields['totalaccd'] && rptDtaFields['totalaccd'].value.toString()}
                            disabled
                        />
                        <span className='text-xs mt-1 text-green-700'>Updated BY: {rptDtaFields['totalaccd'] && rptDtaFields['totalaccd']?.user}</span>
                    </div>

                </div>

                <hr />

                {/* Pax Details related fields */}
                <p className="font-bold">Pax Details</p>
                <div className="grid grid-cols-3 gap-5">

                    {/* select  */}
                    <div className="">
                        <p className="text-sm mb-1" > Total ABY Pax	 </p>
                        <input
                            type="number"
                            className="h-10 rounded-lg px-3 w-full bg-gray-200 "
                            placeholder="Total ABY Pax"
                            value={rptDtaFields['totalabyp'] && rptDtaFields['totalabyp'].value.toString()}
                            disabled
                        />
                        <span className='text-xs mt-1 text-green-700'>Updated BY: {rptDtaFields['totalabyp'] && rptDtaFields['totalabyp']?.user}</span>
                    </div>

                    {/* select  */}
                    <div className="">
                        <p className="text-sm mb-1" > Total ABY joining Pax		 </p>
                        <input
                            type="number"
                            className="h-10 rounded-lg px-3 w-full bg-gray-200 "
                            placeholder="Total ABY joining Pax"
                            value={rptDtaFields['totalabyjp'] && rptDtaFields['totalabyjp'].value.toString()}
                            disabled
                        />
                        <span className='text-xs mt-1 text-green-700'>Updated BY: {rptDtaFields['totalabyjp'] && rptDtaFields['totalabyjp']?.user}</span>
                    </div>

                    {/* select  */}
                    <div className="">
                        <p className="text-sm mb-1" > Total ABY inbound Pax			 </p>
                        <input
                            type="number"
                            className="h-10 rounded-lg px-3 w-full bg-gray-200 "
                            placeholder="Total ABY inbound Pax	"
                            value={rptDtaFields['totalabyip'] && rptDtaFields['totalabyip'].value.toString()}
                            disabled
                        />
                        <span className='text-xs mt-1 text-green-700'>Updated BY: {rptDtaFields['totalabyip'] && rptDtaFields['totalabyip']?.user}</span>
                    </div>







                </div>

                <div className="grid grid-cols-3 gap-5">

                    {/* select  */}
                    <div className="">
                        <p className="text-sm mb-1" > Total ABY Pax	 </p>
                        <input
                            type="number"
                            className="h-10 rounded-lg px-3 w-full bg-gray-200 "
                            placeholder="Total ABY Pax"
                            value={rptDtaFields['totalabyp'] && rptDtaFields['totalabyp'].value.toString()}
                            disabled
                        />
                        <span className='text-xs mt-1 text-green-700'>Updated BY: {rptDtaFields['totalabyp'] && rptDtaFields['totalabyp']?.user}</span>
                    </div>

                    {/* select  */}
                    <div className="">
                        <p className="text-sm mb-1" > Total ABY joining Pax		 </p>
                        <input
                            type="number"
                            className="h-10 rounded-lg px-3 w-full bg-gray-200 "
                            placeholder="Total ABY joining Pax"
                            value={rptDtaFields['totalabyjp'] && rptDtaFields['totalabyjp'].value.toString()}
                            disabled
                        />
                        <span className='text-xs mt-1 text-green-700'>Updated BY: {rptDtaFields['totalabyjp'] && rptDtaFields['totalabyjp']?.user}</span>
                    </div>

                    {/* select  */}
                    <div className="">
                        <p className="text-sm mb-1" > Total ABY inbound Pax			 </p>
                        <input
                            type="number"
                            className="h-10 rounded-lg px-3 w-full bg-gray-200 "
                            placeholder="Total ABY inbound Pax	"
                            value={rptDtaFields['totalabyip'] && rptDtaFields['totalabyip'].value.toString()}
                            disabled
                        />
                        <span className='text-xs mt-1 text-green-700'>Updated BY: {rptDtaFields['totalabyip'] && rptDtaFields['totalabyip']?.user}</span>
                    </div>







                </div>

                <hr />

                {/* Hala/Sales Details */}
                <p className="font-bold">Hala/Sales Details</p>
                <div className="grid grid-cols-3 gap-5">

                    {/* select  */}
                    <div className="">
                        <p className="text-sm mb-1" > LCD Fees collection	 </p>
                        <input
                            type="text"
                            className="h-10 rounded-lg px-3 w-full bg-gray-200 "
                            placeholder="LCD Fees collection"
                            value={rptDtaFields['lcdfc'] && (rptDtaFields['lcdfc'].value.toString() + "AED")}
                            disabled
                        />
                        <span className='text-xs mt-1 text-green-700'>Updated BY: {rptDtaFields['lcdfc'] && rptDtaFields['lcdfc']?.user}</span>
                    </div>

                    {/* select  */}
                    <div className="">
                        <p className="text-sm mb-1" > Hala Service Collection	 </p>
                        <input
                            type="text"
                            className="h-10 rounded-lg px-3 w-full bg-gray-200 "
                            placeholder="Hala Service Collection"
                            value={rptDtaFields['halasc'] && (rptDtaFields['halasc'].value.toString() + "AED")}
                            disabled
                        />
                        <span className='text-xs mt-1 text-green-700'>Updated BY: {rptDtaFields['halasc'] && rptDtaFields['halasc']?.user}</span>
                    </div>

                    {/* select  */}
                    <div className="">
                        <p className="text-sm mb-1" > EBT			 </p>
                        <input
                            type="text"
                            className="h-10 rounded-lg px-3 w-full bg-gray-200 "
                            placeholder="EBT"
                            value={rptDtaFields['ebt'] && (rptDtaFields['ebt'].value.toString() + "AED")}
                            disabled
                        />
                        <span className='text-xs mt-1 text-green-700'>Updated BY: {rptDtaFields['ebt'] && rptDtaFields['ebt']?.user}</span>
                    </div>







                </div>

                <hr />

                {/* Other Fields */}
                <p className="font-bold">Other</p>

                <div className="grid grid-cols-3 gap-5">

                    {/* select  */}
                    <div className="">
                        <p className="text-sm mb-1" > Online check in		 </p>
                        <input
                            type="number"
                            className="h-10 rounded-lg px-3 w-full bg-gray-200 "
                            placeholder="Online check in"
                            value={rptDtaFields['onlineci'] && rptDtaFields['onlineci'].value.toString()}
                            disabled
                        />
                        <span className='text-xs mt-1 text-green-700'>Updated BY: {rptDtaFields['onlineci'] && rptDtaFields['onlineci']?.user}</span>
                    </div>
                </div>

                <hr />

                {/* Other Airline Details */}
                <p className="font-bold">Other Airline Details</p>
                <div className="grid grid-cols-3 gap-5">

                    {/* Otp within 0 Min  */}
                    <div className="">
                        <p className="text-sm mb-1" > Otp within 0 Min	 </p>
                        <input
                            type="number"
                            className="h-10 rounded-lg px-3 w-full bg-gray-200 "
                            placeholder="Otp within 0 Min"
                            value={rptDtaFields['otpw0m_oal']?.value?.toString() || 0}
                            disabled
                        />
                        <span className='text-xs mt-1 text-green-700'>Updated BY: {rptDtaFields['otpw0m_oal'] && rptDtaFields['otpw0m_oal']?.user}</span>
                    </div>

                    {/* Otp within 15 Min  */}
                    <div className="">
                        <p className="text-sm mb-1" > Otp within 15 Min	 </p>
                        <input
                            type="number"
                            className="h-10 rounded-lg px-3 w-full bg-gray-200 "
                            placeholder="Otp within 15 Min"
                            value={rptDtaFields['otpw15m_oal']?.value?.toString() || 0}
                            disabled
                        />
                        <span className='text-xs mt-1 text-green-700'>Updated BY: {rptDtaFields['otpw15m_oal'] && rptDtaFields['otpw15m_oal']?.user}</span>
                    </div>

                    {/* Otp Ground Services  */}
                    <div className="">
                        <p className="text-sm mb-1" > Otp Ground Services	 </p>
                        <input
                            type="number"
                            className="h-10 rounded-lg px-3 w-full bg-gray-200 "
                            placeholder="Otp Ground Services"
                            value={rptDtaFields['otpgs_oal']?.value?.toString() || 0}
                            disabled
                        />
                        <span className='text-xs mt-1 text-green-700'>Updated BY: {rptDtaFields['otpgs_oal'] && rptDtaFields['otpgs_oal']?.user}</span>
                    </div>

                    {/* Total OAL Flights  */}
                    <div className="">
                        <p className="text-sm mb-1" > Total OAL Flights	 </p>
                        <input
                            type="number"
                            className="h-10 rounded-lg px-3 w-full bg-gray-200 "
                            placeholder="Total OAL Flights"
                            value={rptDtaFields['totaloalf__oal'] && rptDtaFields['totaloalf__oal'].value.toString()}
                            disabled
                        />
                        <span className='text-xs mt-1 text-green-700'>Updated BY: {rptDtaFields['totaloalf__oal'] && rptDtaFields['totaloalf__oal']?.user}</span>
                    </div>

                    {/* Total on Time  */}
                    <div className="">
                        <p className="text-sm mb-1" > Total on Time	 </p>
                        <input
                            type="number"
                            className="h-10 rounded-lg px-3 w-full bg-gray-200 "
                            placeholder="Total on Time"
                            value={rptDtaFields['totalot_oal'] && rptDtaFields['totalot_oal'].value.toString()}
                            disabled
                        />
                        <span className='text-xs mt-1 text-green-700'>Updated BY: {rptDtaFields['totalot_oal'] && rptDtaFields['totalot_oal']?.user}</span>
                    </div>

                    {/* Delays Over 15 min  */}
                    <div className="">
                        <p className="text-sm mb-1" > Delays Over 15 min	 </p>
                        <input
                            type="number"
                            className="h-10 rounded-lg px-3 w-full bg-gray-200 "
                            placeholder="Delays Over 15 min"
                            value={rptDtaFields['delyo15m_oal'] && rptDtaFields['delyo15m_oal'].value.toString()}
                            disabled
                        />
                        <span className='text-xs mt-1 text-green-700'>Updated BY: {rptDtaFields['delyo15m_oal'] && rptDtaFields['delyo15m_oal']?.user}</span>
                    </div>

                    {/* Total Delayed  */}
                    <div className="">
                        <p className="text-sm mb-1" > Total Delayed	 </p>
                        <input
                            type="number"
                            className="h-10 rounded-lg px-3 w-full bg-gray-200 "
                            placeholder="Total Delayed"
                            value={rptDtaFields['totald_oal'] && rptDtaFields['totald_oal'].value.toString()}
                            disabled
                        />
                        <span className='text-xs mt-1 text-green-700'>Updated BY: {rptDtaFields['totald_oal'] && rptDtaFields['totald_oal']?.user}</span>
                    </div>

                    {/* Total Accountable Delays  */}
                    <div className="">
                        <p className="text-sm mb-1" > Total Accountable Delays	 </p>
                        <input
                            type="number"
                            className="h-10 rounded-lg px-3 w-full bg-gray-200 "
                            placeholder="Total Accountable Delays"
                            value={rptDtaFields['totalaccd_oal'] && rptDtaFields['totalaccd_oal'].value.toString()}
                            disabled
                        />
                        <span className='text-xs mt-1 text-green-700'>Updated BY: {rptDtaFields['totalaccd_oal'] && rptDtaFields['totalaccd_oal']?.user}</span>
                    </div>

                    {/* Total OAL pax  */}
                    <div className="">
                        <p className="text-sm mb-1" > Total OAL pax	 </p>
                        <input
                            type="number"
                            className="h-10 rounded-lg px-3 w-full bg-gray-200 "
                            placeholder="Total OAL pax"
                            value={rptDtaFields['totaloalp__oal'] && rptDtaFields['totaloalp__oal'].value.toString()}
                            disabled
                        />
                        <span className='text-xs mt-1 text-green-700'>Updated BY: {rptDtaFields['totaloalp__oal'] && rptDtaFields['totaloalp__oal']?.user}</span>
                    </div>







                </div>

                <hr />


            </div>
        </div>
    )
}

export default ViewCSMReport