import React, { useRef } from 'react';

const HalaReport = () => {
    return (
        <div className="p-6 bg-gray-100 min-h-screen">
            <GeneralFields />
        </div>
    );
};

const GeneralFields = () => {
    const services = [
        { title: "URGENT SERVICES", code: "100005" },
        { title: "UAE CITIZENS & SR FEE", code: "100011" },
        { title: "PHOTOCOPY", code: "1010" },
        { title: "MAAS DEPARTURE", code: "1012" },
        { title: "QUICK CHECK-IN", code: "1015" },
        { title: "DEP PORTER SERVICE", code: "1040" },
        { title: "ARV PORTER SERVICE", code: "1041" },
        { title: "WRAPPING (SMALL)", code: "1042" },
        { title: "CARTON BOX", code: "1044" },
        { title: "WCHR ABY DEPARTURE", code: "1073" },
        { title: "WCHR OAL DEPARTURE", code: "1075" },
        { title: "MAAS ARRIVAL SINGLE PAX", code: "300106" },
        { title: "PRINT SERVICE", code: "300116" },
        { title: "FAMILY PACKAGE (2 ADULTS + 2 CHILD)", code: "300136" },
        { title: "G9 TICKET FEE (50)", code: "50002" },
        { title: "G9 1ST ROW SEATS", code: "50005" },
        { title: "G9 2ND ROW SEATS", code: "50006" },
        { title: "G9 4TH ROW SEATS", code: "50008" },
    ];

    const sectionRefs = services.map(() => useRef(null));

    const scrollToSection = (index) => {
        sectionRefs[index].current.scrollIntoView({ behavior: 'smooth' });
    };

    return (
        <div>
            <div className="flex space-x-2 mb-6">
                {services.map((service, index) => (
                    <Tab key={service.code} title={service.title} onClick={() => scrollToSection(index)} />
                ))}
            </div>
            <div className="space-y-6">
                {services.map((service, index) => (
                    <ServiceSection key={service.code} title={service.title} code={service.code} ref={sectionRefs[index]} />
                ))}
            </div>
        </div>
    );
};

const Tab = ({ title, onClick }) => {
    return (
        <button
            className="flex-1 text-center text-gray-700 text-sm font-medium py-2 border-b-2 border-transparent hover:border-blue-600 hover:text-blue-600 focus:outline-none"
            onClick={onClick}
        >
            {title}
        </button>
    );
};

const ServiceSection = React.forwardRef(({ title, code }, ref) => {
    return (
        <div ref={ref} className="bg-white rounded-lg shadow-lg p-5">
            <h2 className="font-semibold text-lg text-gray-800">{title}</h2>
            <p className="text-xs text-gray-500 mb-3">Service CODE: {code}</p>
            <div className="grid grid-cols-1 md:grid-cols-5 gap-4 mt-2">
                {['QUANTITY', 'Amount', 'NET', 'VAT', 'TOTAL'].map((label, index) => (
                    <InputField key={index} label={label} />
                ))}
            </div>
        </div>
    );
});

const InputField = ({ label }) => {
    return (
        <div className="flex flex-col">
            <label className="text-xs font-medium text-gray-700 mb-1">{label}</label>
            <input
                type="number"
                className="h-10 rounded-lg px-3 border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-600 transition duration-200"
                placeholder={`Enter ${label}`}
            />
        </div>
    );
};

export default HalaReport;
