import React, { useCallback, useEffect, useState } from 'react'

import { Tree, TreeNode } from 'react-organizational-chart'
import { Chart } from "react-google-charts";

// import { FunnelChart } from 'react-funnel-pipeline'
// import 'react-funnel-pipeline/dist/index.css'
// import { React }

const CSMShiftReport = ({ rptDtaFields, setRptDtaFields, handleUpdateReport, activeReport, personsList, loginUser }) => {
    useEffect(() => {

        console.log('loginUser is:', loginUser)
    }, [loginUser])
    return (
        <div className='flex gap-8 flex-col'>

            {/* Tree Structure */}
            <TreeStructure personsList={personsList} rptDtaFields={rptDtaFields} setRptDtaFields={setRptDtaFields} handleUpdateReport={handleUpdateReport} activeReport={activeReport} loginUser={loginUser} />

            <hr />

            <div className='grid grid-cols-3 gap-10'>

                <div className='border-r'>
                    {/* Title bar for report */}
                    <div className='flex items-center bg-gradient-to-r from-a-pink to-a-blue justify-center rounded-md font-bold text-xl h-[60px] text-white mb-5'>
                        G9 OTP Chart
                    </div>

                    <OTPChart rptDtaFields={rptDtaFields} setRptDtaField={setRptDtaFields} />
                </div>

                <div className='border-r'>
                    {/* Title bar for report */}
                    <div className='flex items-center bg-gradient-to-r from-a-pink to-a-blue justify-center rounded-md font-bold text-xl h-[60px] text-white mb-5'>
                    G9 & OAL Flights
                    </div>

                    <FlightDetailsChart rptDtaFields={rptDtaFields} setRptDtaField={setRptDtaFields} />
                </div>

                <div>
                    {/* Title bar for report */}
                    <div className='flex items-center bg-gradient-to-r from-a-pink to-a-blue justify-center rounded-md font-bold text-xl h-[60px] text-white mb-5'>
                    G9 Delay

                    </div>

                    <FlightDelayChart rptDtaFields={rptDtaFields} setRptDtaField={setRptDtaFields} />
                </div>

            </div>

            <hr />
            <div className='grid gap-10 grid-cols-3'>

                {/* General Fields */}
                <GeneralFields rptDtaFields={rptDtaFields} setRptDtaFields={setRptDtaFields} handleUpdateReport={handleUpdateReport} activeReport={activeReport} loginUser={loginUser} />

                <div className='grid gap-102'>
                    <hr />
                    <div className='flex items-center bg-gradient-to-r from-a-pink to-a-blue justify-center rounded-md font-bold text-xl h-[60px] text-white'>
                        Pax Chart
                    </div>

                    <PaxChart rptDtaFields={rptDtaFields} setRptDtaField={setRptDtaFields} />

                    <hr />

                    <div className='flex items-center bg-gradient-to-r from-a-pink to-a-blue justify-center rounded-md font-bold text-xl h-[60px] text-white'>
                        Hala Chart
                    </div>

                    <HalaChart rptDtaFields={rptDtaFields} setRptDtaField={setRptDtaFields} />
                </div>

            </div>

            <hr />

            {/* Title bar for report */}
            {/* <div className='flex items-center bg-gradient-to-r from-a-pink to-a-blue justify-center rounded-md text-xl font-bold h-[60px] text-white'>
                Combine Flights With Delay
            </div> */}

            {/* <CombieFlightADelayChart rptDtaFields={rptDtaFields} setRptDtaField={setRptDtaFields} /> */}

        </div>
    )
}

// Tree Structure
const TreeStructure = ({ personsList, rptDtaFields, setRptDtaFields, handleUpdateReport, activeReport, loginUser }) => {
    // handle data
    const handleRptDta = (field, value) => {
        setRptDtaFields((previousData) => {
            return {
                ...previousData, [field]:
                {
                    ...rptDtaFields[field],
                    value: value == '' ? '' : value,
                    user: `${loginUser?.staffid}~${loginUser?.staffname}`
                }
            }
        })
    }

    useEffect(() => {
        // alert("I am running twice")
        if (activeReport) {

            handleUpdateReport('reportFields', rptDtaFields)
        }
    }, [rptDtaFields])

    return (
        <div className='p-5 bg-gray-50 border-a-blue border rounded-md'>
            <datalist id="persons">
                {personsList.map((person, index) => (
                    <option key={index} value={person} />
                ))}
            </datalist>
            <Tree lineWidth={'5px'} lineColor={'#FF512F'} label={<div className="bg-a-blue font-bold text-white border border-[5px] border-a-blue p-2 rounded-md inline-block">Structure</div>}>

                {/* Landside Structure */}
                <TreeNode label={<div className="border border-[5px] border-a-blue p-2 rounded-md inline-block">Landside</div>}>
                    <TreeNode label={<div className="">
                        <p className="text-sm mb-1 mt-1" > Select ADM </p>

                        <input
                            value={rptDtaFields['shiftadm'] && rptDtaFields['shiftadm'].value.toString()}
                            onChange={(e) => {
                                handleRptDta('shiftadm', e.target.value)
                            }}
                            list="persons"
                            type="text"
                            className="h-10 rounded-lg px-3 w-full bg-gray-200 mt-1 border border-a-blue"
                        />
                    </div>} >

                        <TreeNode label={<div className="border border-[5px] border-a-blue p-2 rounded-md inline-block">CSM</div>}>
                            <TreeNode label={<div className="">
                                <p className="text-sm mb-1 mt-1" > Airside CSM </p>

                                <input
                                    value={rptDtaFields['shiftairsideacsm'] && rptDtaFields['shiftairsideacsm'].value.toString()}
                                    onChange={(e) => {
                                        handleRptDta('shiftairsideacsm', e.target.value)
                                    }}
                                    list="persons"
                                    type="text"
                                    className="h-10 rounded-lg px-3 w-full bg-gray-200 mt-1 border border-a-blue"

                                />
                            </div>} />
                            <TreeNode label={<div className="">
                                <p className="text-sm mb-1 mt-1" > Landside CSM </p>

                                <input
                                    value={rptDtaFields['shiftairsidelcsm'] && rptDtaFields['shiftairsidelcsm'].value.toString()}
                                    onChange={(e) => {
                                        handleRptDta('shiftairsidelcsm', e.target.value)
                                    }}
                                    list="persons"
                                    type="text"
                                    className="h-10 rounded-lg px-3 w-full bg-gray-200 mt-1 border border-a-blue"

                                />
                            </div>} />
                            <TreeNode label={<div className="">
                                <p className="text-sm mb-1 mt-1" > Floater CSM </p>
                                <input
                                    value={rptDtaFields['shiftairsidefcsm'] && rptDtaFields['shiftairsidefcsm'].value.toString()}
                                    onChange={(e) => {
                                        handleRptDta('shiftairsidefcsm', e.target.value)
                                    }}
                                    list="persons"
                                    type="text"
                                    className="h-10 rounded-lg px-3 w-full bg-gray-200 mt-1 border border-a-blue"

                                />
                            </div>} />
                        </TreeNode>

                    </TreeNode>
                </TreeNode>

                {/* Airside Structure */}
                <TreeNode label={<div className="border border-[5px] border-a-blue p-2 rounded-md inline-block">Airside</div>}>

                    <TreeNode label={<div className="">
                        <p className="text-sm mb-1 mt-1" > AOM </p>

                        <input
                            value={rptDtaFields['shiftaom'] && rptDtaFields['shiftaom'].value.toString()}
                            onChange={(e) => {
                                handleRptDta('shiftaom', e.target.value)
                            }}
                            list="persons"
                            type="text"
                            className="h-10 rounded-lg px-3 w-full bg-gray-200 mt-1 border border-a-blue"
                        />
                    </div>} />
                    <TreeNode label={<div className="">
                        {/* Cargo Structure */}
                        <TreeNode label={<div className="border border-[5px] border-a-blue p-2 rounded-md inline-block">BOC</div>}>
                            <TreeNode label={<div className="">
                                <p className="text-sm mb-1 mt-1" > BOC Joining </p>

                                <input
                                    value={rptDtaFields['shiftbocjoin'] && rptDtaFields['shiftbocjoin'].value.toString()}
                                    onChange={(e) => {
                                        handleRptDta('shiftbocjoin', e.target.value)
                                    }}
                                    list="persons"
                                    type="text"
                                    className="h-10 rounded-lg px-3 w-full bg-gray-200 mt-1 border border-a-blue"
                                />
                            </div>} />
                            <TreeNode label={<div className="">
                                <p className="text-sm mb-1 mt-1" > BOC Transfer </p>

                                <input
                                    value={rptDtaFields['shiftboctran'] && rptDtaFields['shiftboctran'].value.toString()}
                                    onChange={(e) => {
                                        handleRptDta('shiftboctran', e.target.value)
                                    }}
                                    list="persons"
                                    type="text"
                                    className="h-10 rounded-lg px-3 w-full bg-gray-200 mt-1 border border-a-blue"
                                />
                            </div>} />
                        </TreeNode>




                    </div>} />
                </TreeNode>

                {/* Cargo Structure */}
                <TreeNode label={<div className="border border-[5px] border-a-blue p-2 rounded-md inline-block">Cargo</div>}>
                    <TreeNode label={<div className="">
                        <p className="text-sm mb-1 mt-1" > COM </p>

                        <input
                            value={rptDtaFields['shiftcom'] && rptDtaFields['shiftcom'].value.toString()}
                            onChange={(e) => {
                                handleRptDta('shiftcom', e.target.value)
                            }}
                            list="persons"
                            type="text"
                            className="h-10 rounded-lg px-3 w-full bg-gray-200 mt-1 border border-a-blue"
                        />
                    </div>} />
                </TreeNode>


            </Tree>
        </div>
    )
}

// 
const GeneralFields = ({ rptDtaFields, setRptDtaFields, handleUpdateReport, activeReport, loginUser }) => {

    const [rptDtaFieldsChange, setrptDtaFieldsChange] = useState(false);

    // handle data
    const handleRptDta = (field, value) => {
        setRptDtaFields((previousData) => {
            return {
                ...previousData, [field]:
                {
                    ...rptDtaFields[field],
                    value: value == '' ? 0 : parseInt(value),
                    user: `${loginUser?.staffid}~${loginUser?.staffname}`
                }
            }
        })

        if (['totalg9f', 'totald', 'delyo15m', 'totalaccd', 'totaloalf__oal', 'totald_oal', 'delyo15m_oal', 'totalaccd_oal', 'totalot', 'totalot_oal'].includes(field)) {
            setrptDtaFieldsChange(true);
            // alert('System in mking prob here')
        }

    }

    useEffect(() => {
        // alert("I am running twice")
        if (activeReport) {

            handleUpdateReport('reportFields', rptDtaFields)
        }
    }, [rptDtaFields])

    const autoCalculation = (val1, val2, val3) => {

        let value = (rptDtaFields[val1].value - rptDtaFields[val2].value) / rptDtaFields[val3].value * 100;


        console.log('Auto Cal: ', value)
        console.log(rptDtaFields[val1].value)
        console.log(rptDtaFields[val2].value)
        console.log(rptDtaFields[val3].value)
    }


    const manualCalc = () => {
        const calculatePercentage = (numerator, denominator) => {
            if (denominator === 0) return 0; // Avoid division by zero
            return Math.round((numerator / denominator) * 100);
        };

        const totalg9f = rptDtaFields["totalg9f"].value || 0;
        const totald = rptDtaFields["totald"].value || 0;
        const delyo15m = rptDtaFields["delyo15m"].value || 0;
        const totalaccd = rptDtaFields["totalaccd"].value || 0;

        const totalot = rptDtaFields['totalot'].value || 0;
        let caltotalDel = totalg9f - totalot;

        
        const totaloalf_oal = rptDtaFields["totaloalf__oal"].value || 0;
        const totald_oal = rptDtaFields["totald_oal"].value || 0;
        const delyo15m_oal = rptDtaFields["delyo15m_oal"].value || 0;
        const totalaccd_oal = rptDtaFields["totalaccd_oal"].value || 0;
        
        const totalot_oal = rptDtaFields['totalot_oal'].value || 0;
        let caltotalot_oal = totaloalf_oal - totalot_oal;


        const otpw0m = calculatePercentage(totalg9f - totald, totalg9f);
        const otpw15m = calculatePercentage(totalg9f - delyo15m, totalg9f);
        const otpgs = calculatePercentage(totalg9f - totalaccd, totalg9f);

        const otpw0m_oal = calculatePercentage(totaloalf_oal - totald_oal, totaloalf_oal);
        const otpw15m_oal = calculatePercentage(totaloalf_oal - delyo15m_oal, totaloalf_oal);
        const otpgs_oal = calculatePercentage(totaloalf_oal - totalaccd_oal, totaloalf_oal);

        setRptDtaFields((previousData) => ({
            ...previousData,
            otpw0m: { ...previousData['otpw0m'], value: otpw0m },
            otpw15m: { ...previousData['otpw15m'], value: otpw15m },
            otpgs: { ...previousData['otpgs'], value: otpgs },
            otpw0m_oal: { ...previousData['otpw0m_oal'], value: otpw0m_oal },
            otpw15m_oal: { ...previousData['otpw15m_oal'], value: otpw15m_oal },
            otpgs_oal: { ...previousData['otpgs_oal'], value: otpgs_oal },
            totald: {...previousData['totald'], value: caltotalDel},
            totald_oal: {...previousData['totald_oal'], value: caltotalot_oal}
        }));

    }

    useEffect(() => {
        if (rptDtaFieldsChange) {
            manualCalc()
            setrptDtaFieldsChange(false);
        }
    }, [rptDtaFieldsChange]);



    return (
        <div className='col-span-2'>
            <button
    onClick={manualCalc}
    className="bg-blue-500 text-white font-bold py-2 px-4 rounded hover:bg-blue-700 transition duration-200"
>
    Auto Calculate
</button>
            <div className=' grid gap-5'>

                {/* OTP Related Fields */}
                <p className="font-bold">OTP</p>
                <div className="grid grid-cols-3 gap-5 mt-1">

                    {/*  OTP within 0 Min  */}
                    <div className="">
                        <p className="text-sm mb-1" > OTP within 0 Min		 </p>
                        <input
                            type="number"
                            className="h-10 rounded-lg px-3 w-full bg-gray-200 border border-a-blue "
                            placeholder="OTP within 0 Min	"
                            value={rptDtaFields['otpw0m'] && rptDtaFields['otpw0m'].value.toString()}
                            onChange={(e) => {
                                handleRptDta('otpw0m', e.target.value)
                            }}
                        />
                    </div>

                    {/* OTP within 15 Min	  */}
                    <div className="">
                        <p className="text-sm mb-1" > OTP within 15 Min			 </p>
                        <input
                            type="number"
                            className="h-10 rounded-lg px-3 w-full bg-gray-200 border border-a-blue "
                            placeholder="OTP within 15 Min	"
                            value={rptDtaFields['otpw15m'] && rptDtaFields['otpw15m'].value.toString()}
                            onChange={(e) => {
                                handleRptDta('otpw15m', e.target.value)
                            }}
                        />
                    </div>

                    {/* OTP Ground Service  */}
                    <div className="">
                        <p className="text-sm mb-1" > OTP Ground Service			 </p>
                        <input
                            type="number"
                            className="h-10 rounded-lg px-3 w-full bg-gray-200 border border-a-blue "
                            placeholder="OTP Ground Service	"
                            value={rptDtaFields['otpgs'] && rptDtaFields['otpgs'].value.toString()}
                            onChange={(e) => {
                                handleRptDta('otpgs', e.target.value)
                            }}
                        />
                    </div>
                </div>

                <hr />

                {/* Flights Details */}
                <p className="font-bold">Flights Details</p>
                <div className="grid grid-cols-3 gap-5">

                    {/* select  */}
                    <div className="">
                        <p className="text-sm mb-1" > Total G9 Flights	 </p>
                        <input
                            type="number"
                            className="h-10 rounded-lg px-3 w-full bg-gray-200 border border-a-blue"
                            placeholder="Total G9 Flights"
                            value={rptDtaFields['totalg9f'] && rptDtaFields['totalg9f'].value.toString()}
                            onChange={(e) => {
                                handleRptDta('totalg9f', e.target.value)
                            }}
                        />
                    </div>

                    {/* select  */}
                    <div className="">
                        <p className="text-sm mb-1" > Total other airlines flights (N/A)		 </p>
                        <input
                            type="number"
                            disabled
                            className="h-10 rounded-lg px-3 w-full bg-gray-200 border border-a-blue"
                            placeholder="Total other airlines flights"
                            value={rptDtaFields['totaloafli'] && rptDtaFields['totaloafli'].value.toString()}
                            onChange={(e) => {
                                handleRptDta('totaloafli', e.target.value)
                            }}
                        />
                    </div>

                    {/* select  */}
                    <div className="">
                        <p className="text-sm mb-1" > Total on Time			 </p>
                        <input
                            type="number"
                            className="h-10 rounded-lg px-3 w-full bg-gray-200 border border-a-blue"
                            placeholder="Total on Time	"
                            value={rptDtaFields['totalot'] && rptDtaFields['totalot'].value.toString()}
                            onChange={(e) => {
                                handleRptDta('totalot', e.target.value)
                            }}
                        />
                    </div>

                    {/* select  */}
                    <div className="">
                        <p className="text-sm mb-1" > Delays Over 15 Min			 </p>
                        <input
                            type="number"
                            className="h-10 rounded-lg px-3 w-full bg-gray-200 border border-a-blue"
                            placeholder="Delays Over 15 Min"
                            value={rptDtaFields['delyo15m'] && rptDtaFields['delyo15m'].value.toString()}
                            onChange={(e) => {
                                handleRptDta('delyo15m', e.target.value);
                                // autoCalculation('totalg9f', 'delyo15m', 'totalg9f')
                            }}
                        />
                    </div>

                    {/* select  */}
                    <div className="">
                        <p className="text-sm mb-1" > Total Delayed			 </p>
                        <input
                            type="number"
                            className="h-10 rounded-lg px-3 w-full bg-gray-200 border border-a-blue"
                            placeholder="Total Delayed"
                            value={rptDtaFields['totald'] && rptDtaFields['totald'].value.toString()}
                            onChange={(e) => {
                                handleRptDta('totald', e.target.value)
                            }}
                        />
                    </div>

                    {/* select  */}
                    <div className="">
                        <p className="text-sm mb-1" > Total accountable delays				 </p>
                        <input
                            type="number"
                            className="h-10 rounded-lg px-3 w-full bg-gray-200 border border-a-blue"
                            placeholder="Total accountable delays	"
                            value={rptDtaFields['totalaccd'] && rptDtaFields['totalaccd'].value.toString()}
                            onChange={(e) => {
                                handleRptDta('totalaccd', e.target.value)
                            }}
                        />
                    </div>

                </div>

                <hr />

                {/* Pax Details related fields */}
                <p className="font-bold">Pax Details</p>
                <div className="grid grid-cols-3 gap-5">

                    {/* select  */}
                    <div className="">
                        <p className="text-sm mb-1" > Total ABY Pax	 </p>
                        <input
                            type="number"
                            className="h-10 rounded-lg px-3 w-full bg-gray-200 border border-a-blue"
                            placeholder="Total ABY Pax"
                            value={rptDtaFields['totalabyp'] && rptDtaFields['totalabyp'].value.toString()}
                            onChange={(e) => {
                                handleRptDta('totalabyp', e.target.value)
                            }}
                        />
                    </div>

                    {/* select  */}
                    <div className="">
                        <p className="text-sm mb-1" > Total ABY joining Pax		 </p>
                        <input
                            type="number"
                            className="h-10 rounded-lg px-3 w-full bg-gray-200 border border-a-blue"
                            placeholder="Total ABY joining Pax"
                            value={rptDtaFields['totalabyjp'] && rptDtaFields['totalabyjp'].value.toString()}
                            onChange={(e) => {
                                handleRptDta('totalabyjp', e.target.value)
                            }}
                        />
                    </div>

                    {/* select  */}
                    <div className="">
                        <p className="text-sm mb-1" > Total ABY inbound Pax			 </p>
                        <input
                            type="number"
                            className="h-10 rounded-lg px-3 w-full bg-gray-200 border border-a-blue"
                            placeholder="Total ABY inbound Pax	"
                            value={rptDtaFields['totalabyip'] && rptDtaFields['totalabyip'].value.toString()}
                            onChange={(e) => {
                                handleRptDta('totalabyip', e.target.value)
                            }}
                        />
                    </div>







                </div>


                <hr />


                {/* Pax Details related fields */}
                {/* Pax Details related fields */}
                <p className="font-bold">Pax Details</p>
                <div className="grid grid-cols-3 gap-5">

                    {/* Total ABY Pax Section */}
                    <div className="border p-4 rounded-lg bg-gray-100">
                        <p className="text-sm mb-1">Total ABY Pax</p>
                        <input
                            type="number"
                            className="h-10 rounded-lg px-3 w-full bg-gray-200 border border-a-blue"
                            placeholder="Total ABY Pax"
                            value={rptDtaFields['totalabyp']?.value.toString() || ""}
                            onChange={(e) => handleRptDta('totalabyp', e.target.value)}
                        />
                        <p className="text-sm mb-1 mt-3">(Infants)</p>
                        <input
                            type="number"
                            className="h-10 rounded-lg px-3 w-full bg-gray-200 border border-a-blue"
                            placeholder="Total ABY Pax (Infants)"
                            value={rptDtaFields['totalabypinf']?.value.toString() || ""}
                            onChange={(e) => handleRptDta('totalabypinf', e.target.value)}
                        />
                    </div>

                    {/* Total ABY Joining Pax Section */}
                    <div className="border p-4 rounded-lg bg-gray-100">
                        <p className="text-sm mb-1">Total ABY Joining Pax</p>
                        <input
                            type="number"
                            className="h-10 rounded-lg px-3 w-full bg-gray-200 border border-a-blue"
                            placeholder="Total ABY Joining Pax"
                            value={rptDtaFields['totalabyjp']?.value.toString() || ""}
                            onChange={(e) => handleRptDta('totalabyjp', e.target.value)}
                        />
                        <p className="text-sm mb-1 mt-3">(Infants)</p>
                        <input
                        disabled
                            type="number"
                            className="h-10 rounded-lg px-3 w-full bg-gray-400 border border-a-blue"
                            placeholder="Total ABY Joining Pax (Infants)"
                            value={rptDtaFields['totalabyjpinf']?.value.toString() || ""}
                            onChange={(e) => handleRptDta('totalabyjpinf', e.target.value)}
                        />
                    </div>

                    {/* Total ABY Inbound Pax Section */}
                    <div className="border p-4 rounded-lg bg-gray-100">
                        <p className="text-sm mb-1">Total ABY Inbound Pax</p>
                        <input
                            type="number"
                            className="h-10 rounded-lg px-3 w-full bg-gray-200 border border-a-blue"
                            placeholder="Total ABY Inbound Pax"
                            value={rptDtaFields['totalabyip']?.value.toString() || ""}
                            onChange={(e) => handleRptDta('totalabyip', e.target.value)}
                        />
                        <p className="text-sm mb-1 mt-3">(Infants)</p>
                        <input
                        disabled
                            type="number"
                            className="h-10 rounded-lg px-3 w-full bg-gray-400 border border-a-blue"
                            placeholder="Total ABY Inbound Pax (Infants)"
                            value={rptDtaFields['totalabyipinf']?.value.toString() || ""}
                            onChange={(e) => handleRptDta('totalabyipinf', e.target.value)}
                        />
                    </div>
                </div>


                <hr />

                {/* Hala/Sales Details */}
                <p className="font-bold">Hala/Sales Details</p>
                <div className="grid grid-cols-3 gap-5">

                    {/* select  */}
                    <div className="">
                        <p className="text-sm mb-1" > LCD Fees collection	 </p>
                        <input
                            type="number"
                            className="h-10 rounded-lg px-3 w-full bg-gray-200 border border-a-blue"
                            placeholder="LCD Fees collection"
                            value={rptDtaFields['lcdfc'] && rptDtaFields['lcdfc'].value.toString()}
                            onChange={(e) => {
                                handleRptDta('lcdfc', e.target.value)
                            }}
                        />
                    </div>

                    {/* select  */}
                    <div className="">
                        <p className="text-sm mb-1" > Hala Service Collection	 </p>
                        <input
                            type="number"
                            className="h-10 rounded-lg px-3 w-full bg-gray-200 border border-a-blue"
                            placeholder="Hala Service Collection"
                            value={rptDtaFields['halasc'] && rptDtaFields['halasc'].value.toString()}
                            onChange={(e) => {
                                handleRptDta('halasc', e.target.value)
                            }}
                        />
                    </div>

                    {/* select  */}
                    <div className="">
                        <p className="text-sm mb-1" > EBT			 </p>
                        <input
                            type="number"
                            className="h-10 rounded-lg px-3 w-full bg-gray-200 border border-a-blue"
                            placeholder="EBT"
                            value={rptDtaFields['ebt'] && rptDtaFields['ebt'].value.toString()}
                            onChange={(e) => {
                                handleRptDta('ebt', e.target.value)
                            }}
                        />
                    </div>







                </div>

                <hr />

                {/* Other Fields */}
                <p className="font-bold">Other</p>

                <div className="grid grid-cols-3 gap-5">

                    {/* select  */}
                    <div className="">
                        <p className="text-sm mb-1" > Online check in		 </p>
                        <input
                            type="number"
                            className="h-10 rounded-lg px-3 w-full bg-gray-200 border border-a-blue"
                            placeholder="Online check in"
                            value={rptDtaFields['onlineci'] && rptDtaFields['onlineci'].value.toString()}
                            onChange={(e) => {
                                handleRptDta('onlineci', e.target.value)
                            }}
                        />
                    </div>
                </div>

                <hr />

                {/* Other Airline Details */}
                <p className="font-bold">Other Airline Details</p>
                <div className="grid grid-cols-3 gap-5">

                    {/* Otp within 0 Min  */}
                    <div className="">
                        <p className="text-sm mb-1" > Otp within 0 Min	 </p>
                        <input
                            type="number"
                            className="h-10 rounded-lg px-3 w-full bg-gray-200 border border-a-blue"
                            placeholder="Otp within 0 Min"
                            value={rptDtaFields['otpw0m_oal']?.value?.toString() || 0}
                            onChange={(e) => {
                                handleRptDta('otpw0m_oal', e.target.value)
                            }}
                        />
                    </div>

                    {/* Otp within 15 Min  */}
                    <div className="">
                        <p className="text-sm mb-1" > Otp within 15 Min	 </p>
                        <input
                            type="number"
                            className="h-10 rounded-lg px-3 w-full bg-gray-200 border border-a-blue"
                            placeholder="Otp within 15 Min"
                            value={rptDtaFields['otpw15m_oal']?.value?.toString() || 0}
                            onChange={(e) => {
                                handleRptDta('otpw15m_oal', e.target.value)
                            }}
                        />
                    </div>

                    {/* Otp Ground Services  */}
                    <div className="">
                        <p className="text-sm mb-1" > Otp Ground Services	 </p>
                        <input
                            type="number"
                            className="h-10 rounded-lg px-3 w-full bg-gray-200 border border-a-blue"
                            placeholder="Otp Ground Services"
                            value={rptDtaFields['otpgs_oal']?.value?.toString() || 0}
                            onChange={(e) => {
                                handleRptDta('otpgs_oal', e.target.value)
                            }}
                        />
                    </div>

                    {/* Total OAL Flights  */}
                    <div className="">
                        <p className="text-sm mb-1" > Total OAL Flights	 </p>
                        <input
                            type="number"
                            className="h-10 rounded-lg px-3 w-full bg-gray-200 border border-a-blue"
                            placeholder="Total OAL Flights"
                            value={rptDtaFields['totaloalf__oal'] && rptDtaFields['totaloalf__oal'].value.toString()}
                            onChange={(e) => {
                                handleRptDta('totaloalf__oal', e.target.value)
                            }}
                        />
                    </div>

                    {/* Total on Time  */}
                    <div className="">
                        <p className="text-sm mb-1" > Total on Time	 </p>
                        <input
                            type="number"
                            className="h-10 rounded-lg px-3 w-full bg-gray-200 border border-a-blue"
                            placeholder="Total on Time"
                            value={rptDtaFields['totalot_oal'] && rptDtaFields['totalot_oal'].value.toString()}
                            onChange={(e) => {
                                handleRptDta('totalot_oal', e.target.value)
                            }}
                        />
                    </div>

                    {/* Delays Over 15 min  */}
                    <div className="">
                        <p className="text-sm mb-1" > Delays Over 15 min	 </p>
                        <input
                            type="number"
                            className="h-10 rounded-lg px-3 w-full bg-gray-200 border border-a-blue"
                            placeholder="Delays Over 15 min"
                            value={rptDtaFields['delyo15m_oal'] && rptDtaFields['delyo15m_oal'].value.toString()}
                            onChange={(e) => {
                                handleRptDta('delyo15m_oal', e.target.value)
                            }}
                        />
                    </div>

                    {/* Total Delayed  */}
                    <div className="">
                        <p className="text-sm mb-1" > Total Delayed	 </p>
                        <input
                            type="number"
                            className="h-10 rounded-lg px-3 w-full bg-gray-200 border border-a-blue"
                            placeholder="Total Delayed"
                            value={rptDtaFields['totald_oal'] && rptDtaFields['totald_oal'].value.toString()}
                            onChange={(e) => {
                                handleRptDta('totald_oal', e.target.value)
                            }}
                        />
                    </div>

                    {/* Total Accountable Delays  */}
                    <div className="">
                        <p className="text-sm mb-1" > Total Accountable Delays	 </p>
                        <input
                            type="number"
                            className="h-10 rounded-lg px-3 w-full bg-gray-200 border border-a-blue"
                            placeholder="Total Accountable Delays"
                            value={rptDtaFields['totalaccd_oal'] && rptDtaFields['totalaccd_oal'].value.toString()}
                            onChange={(e) => {
                                handleRptDta('totalaccd_oal', e.target.value)
                            }}
                        />
                    </div>

                    {/* Total OAL pax  */}
                    <div className="">
                        <p className="text-sm mb-1" > Total OAL pax	 </p>
                        <input
                            type="number"
                            className="h-10 rounded-lg px-3 w-full bg-gray-200 border border-a-blue"
                            placeholder="Total OAL pax"
                            value={rptDtaFields['totaloalp__oal'] && rptDtaFields['totaloalp__oal'].value.toString()}
                            onChange={(e) => {
                                handleRptDta('totaloalp__oal', e.target.value)
                            }}
                        />
                    </div>



                    {/* Total OAL pax  inf*/}
                    <div className="">
                        <p className="text-sm mb-1" > Total OAL INFANTs	 </p>
                        <input
                            type="number"
                            className="h-10 rounded-lg px-3 w-full bg-gray-200 border border-a-blue"
                            placeholder="Total OAL pax"
                            value={rptDtaFields['totaloalp__oal__INF'] && rptDtaFields['totaloalp__oal__INF'].value.toString()}
                            onChange={(e) => {
                                handleRptDta('totaloalp__oal__INF', e.target.value)
                            }}
                        />
                    </div>







                </div>

                <hr />


            </div>
        </div>
    )
}

// // 
const OTPChart = ({ rptDtaFields, setRptDtaField }) => {
    return (
        <div className='col-span-1 '>
            <Chart
                chartType="PieChart"
                width="100%"
                height="400px"
                data={[
                    ["Field", "Total"],
                    ["OTP within 0 Min", rptDtaFields['otpw0m']?.value || 0],
                    ["OTP within 15 Min", rptDtaFields['otpw15m']?.value || 0],
                    ["OTP Ground Service", rptDtaFields['otpgs']?.value || 0]
                ]}
                options={{
                    title: "OTP",
                    pieHole: 0.4,
                    is3D: false,
                }}
            />
        </div>
    )
}

// // 
const FlightDetailsChart = ({ rptDtaFields, setRptDtaField }) => {
    return (
        <div className='col-span-1 '>
            <Chart
                chartType="PieChart"
                width="100%"
                height="400px"
                data={[
                    ["Field", "Total"],
                    ["Air Arabia Flights", rptDtaFields['totalg9f']?.value || 0],
                    ["Other Airline", rptDtaFields['totaloalf__oal']?.value || 0],
                    // ["Total Flights", ((rptDtaFields['totalg9f']?.value || 0) + (rptDtaFields['totaloafli']?.value || 0)) || 0]
                ]}
                options={{
                    title: "G9 & OAL Flights",
                    pieHole: 0.4,
                    is3D: false,
                }}
            />
        </div>
    )
}

// 
const FlightDelayChart = ({ rptDtaFields, setRptDtaField }) => {
    return (
        <div className='col-span-1 '>
            <Chart
                chartType="PieChart"
                width="100%"
                height="400px"
                data={[
                    ["Field", "Total"],
                    ["Delays Over 15 Min", rptDtaFields['delyo15m']?.value || 0],
                    ["Total Delayed", rptDtaFields['totald']?.value || 0],
                    ["Total accountable delays", rptDtaFields['totalaccd']?.value || 0]
                ]}
                options={{
                    title: "G9 Delay",
                    pieHole: 0.4,
                    is3D: false,
                }}
            />
        </div>
    )
}

// 
const PaxChart = ({ rptDtaFields, setRptDtaField }) => {
    return (
        <div className='col-span-1 '>
            <Chart
                chartType="PieChart"
                width="100%"
                height="400px"
                data={[
                    ["Field", "Total"],
                    // ["Total ABY Pax", rptDtaFields['totalabyp']?.value || 0],
                    ["Total ABY joining Pax", rptDtaFields['totalabyjp']?.value || 0],
                    ["Total ABY inbound Pax", rptDtaFields['totalabyip']?.value || 0],
                    // ["Total other airlines Pax", rptDtaFields['totaloapax']?.value || 0]
                ]}
                options={{
                    title: "Pax",
                    pieHole: 0.4,
                    is3D: false,
                }}
            />
        </div>
    )
}

// 
const HalaChart = ({ rptDtaFields, setRptDtaField }) => {
    return (
        <div className='col-span-1 '>
            <Chart
                chartType="PieChart"
                width="100%"
                height="400px"
                data={[
                    ["Field", "Total"],
                    ["LCD Fees collection", rptDtaFields['lcdfc']?.value || 0],
                    ["Hala Service Collection", rptDtaFields['halasc']?.value || 0],
                    ["EBT", rptDtaFields['ebt']?.value || 0]
                ]}
                options={{
                    title: "Hala",
                    pieHole: 0.4,
                    is3D: false,
                }}
            />
        </div>
    )
}

// 
// const CombieFlightADelayChart = ({ rptDtaFields, setRptDtaField }) => {
//     return (
//         <Chart height={"500px"} chartType="ColumnChart" data={[
//             ["Element", "Density"],
//             ["Total G9 Flights", rptDtaFields['totalg9f']?.value || 0], // RGB value
//             ["Total other airlines flights", rptDtaFields['totaloafli']?.value || 0], // English color name
//             ["Total on Time", rptDtaFields['totalot']?.value || 0],
//             ["Delays Over 15 Min", rptDtaFields['delyo15m']?.value || 0], // CSS-style declaration
//             ["Total Delayed", rptDtaFields['totald']?.value || 0], // CSS-style declaration
//             ["Total accountable delays", rptDtaFields['totalaccd']?.value || 0], // CSS-style declaration
//         ]} />
//     );
// }


export default CSMShiftReport