// StaffContext.js
import { createContext, useContext, useState } from 'react';

const StaffContext = createContext();

export const StaffProvider = ({ children }) => {
  const [staffIdContext, setStaffIdContext] = useState('');
  const [staffPasswordContext, setStaffPasswordContext] = useState('');

  const updateStaffCredentialsContext = (newId, newPassword) => {
    setStaffIdContext(newId);
    setStaffPasswordContext(newPassword);
  };

  return (
    <StaffContext.Provider value={{ staffIdContext, staffPasswordContext, updateStaffCredentialsContext }}>
      {children}
    </StaffContext.Provider>
  );
};

export const useStaff = () => {
  return useContext(StaffContext);
};
