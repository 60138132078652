import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";

import { MiniLoadingBar } from "../Global/LoadingBar";
import Utils from '../../utils.json';

import { GrPowerReset } from "react-icons/gr";

const ViewReport = () => {
  const navigate = useNavigate();
  const [adminLevel, setadminLevel] = useState('');



  // reports related
  const ReportsList = ['CSM Shift Report', 'AOM Shift Report', 'BOC Shift Report', 'COM Shift Report'];
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // schema copying from add_report
  const CSMSHIFTREPORT_Schema = {
    "otpw0m": { value: 0, demoValue: 0, defaultValue: 0, title: "OTP within 0 Min", user: "" },
    "otpw15m": { value: 0, demoValue: 0, defaultValue: 0, title: "OTP within 15 Min", user: "" },
    "otpgs": { value: 0, demoValue: 0, defaultValue: 0, title: "OTP Ground Service", user: "" },
    "totalg9f": { value: 0, demoValue: 0, defaultValue: 0, title: "Total G9 Flights", user: "" },
    "totalot": { value: 0, demoValue: 0, defaultValue: 0, title: "Total on Time", user: "" },
    "delyo15m": { value: 0, demoValue: 0, defaultValue: 0, title: "Delays Over 15 Min", user: "" },
    "totald": { value: 0, demoValue: 0, defaultValue: 0, title: "Total Delayed", user: "" },
    "totalaccd": { value: 0, demoValue: 0, defaultValue: 0, title: "Total accountable delays", user: "" },
    "totalabyp": { value: 0, demoValue: 0, defaultValue: 0, title: "Total ABY Pax", user: "" },
    "totalabypinf": { value: 0, demoValue: 0, defaultValue: 0, title: "Total ABY Pax INF", user: "" },
    "totalabyjp": { value: 0, demoValue: 0, defaultValue: 0, title: "Total ABY joining Pax", user: "" },
    "totalabyjpinf": { value: 0, demoValue: 0, defaultValue: 0, title: "Total ABY joining Pax INF", user: "" },
    "totalabyip": { value: 0, demoValue: 0, defaultValue: 0, title: "Total ABY inbound Pax", user: "" },
    "totalabyipinf": { value: 0, demoValue: 0, defaultValue: 0, title: "Total ABY inbound Pax INF", user: "" },


    "onlineci": { value: 0, demoValue: 0, defaultValue: 0, title: "Online check in", user: "" },
    // "totaloafli": { value: 0, demoValue: 0, defaultValue: 0, title: "Total other airlines flights", user: "" },
    // "totaloapax": { value: 0, demoValue: 0, defaultValue: 0, title: "Total other airlines Pax", user: "" },
    "ebt": { value: 0, demoValue: 0, defaultValue: 0, title: "EBT", user: "" },
    "lcdfc": { value: 0, demoValue: 0, defaultValue: 0, title: "LCD Fees collection", user: "" },
    "halasc": { value: 0, demoValue: 0, defaultValue: 0, title: "Hala Service Collection", user: "" },

    "otpw0m_oal": { value: 0, demoValue: 0, defaultValue: 0, title: "OTP within 0 Min OAL", user: "" },
    "otpw15m_oal": { value: 0, demoValue: 0, defaultValue: 0, title: "OTP within 15 Min OAL", user: "" },
    "otpgs_oal": { value: 0, demoValue: 0, defaultValue: 0, title: "OTP Ground Service OAL", user: "" },
    "totaloalf__oal": { value: 0, demoValue: 0, defaultValue: 0, title: "Total OAL Flights", user: "" },
    "totalot_oal": { value: 0, demoValue: 0, defaultValue: 0, title: "Total on Time OAL", user: "" },
    "delyo15m_oal": { value: 0, demoValue: 0, defaultValue: 0, title: "Delays Over 15 Min OAL", user: "" },
    "totald_oal": { value: 0, demoValue: 0, defaultValue: 0, title: "Total Delayed OAL", user: "" },
    "totalaccd_oal": { value: 0, demoValue: 0, defaultValue: 0, title: "Total accountable delays OAL", user: "" },
    "totaloalp__oal": { value: 0, demoValue: 0, defaultValue: 0, title: "Total OAL Pax", user: "" },
    "totaloalp__oal__INF": { value: 0, demoValue: 0, defaultValue: 0, title: "Total OAL Pax INF", user: "" },

    "shiftadm": { value: "", demoValue: "", defaultValue: "", title: "ADM", user: "" },
    "shiftaom": { value: "", demoValue: "", defaultValue: "", title: "AOM", user: "" },
    "shiftbocjoin": { value: "", demoValue: "", defaultValue: "", title: "BOC Joining", user: "" },
    "shiftboctran": { value: "", demoValue: "", defaultValue: "", title: "BOC Transfer", user: "" },
    "shiftcom": { value: "", demoValue: "", defaultValue: "", title: "COM", user: "" },
    "shiftairsideacsm": { value: "", demoValue: "", defaultValue: "", title: "Airside CSM", user: "" },
    "shiftairsidelcsm": { value: "", demoValue: "", defaultValue: "", title: "Landside CSM", user: "" },
    "shiftairsidefcsm": { value: "", demoValue: "", defaultValue: "", title: "Floater CSM", user: "" },
  }

  const fieldGroups = {
    'G9 Ops': {
      OTP: ['otpw0m', 'otpw15m', 'otpgs'],
      FLIGHTS: ['totalg9f', 'totalot'],
      PAX: ['totalabyp', 'totalabyjp', 'totalabyip', 'totalabypinf', 'totalabyjpinf', 'totalabyipinf'],
      DELAY: ['delyo15m', 'totald', 'totalaccd'],
      OTHERS: ['onlineci']
    },
    'Other Airlines': {
      OTP: ['otpw0m_oal', 'otpw15m_oal', 'otpgs_oal'],
      FLIGHTS: ['totaloalf__oal', 'totalot_oal'],
      PAX: ['totaloalp__oal', 'totaloalp__oal__INF'],
      DELAY: ['delyo15m_oal', 'totald_oal', 'totalaccd_oal'],
    },
    Hala: {
      HALA: ['halasc'],
      OTHERS: ['ebt', 'lcdfc'],
    },
    // Others: {

    // },
    // Role: {
    //   ADM: ['shiftadm'],
    //   Airside: ['shiftairsideacsm', 'shiftairsidelcsm', 'shiftairsidefcsm'],
    //   Landside: ['shiftaom', 'shiftbocjoin', 'shiftboctran'],
    //   Cargo: ['shiftcom'],
    // },
  };

  // parsing admin from ls
  useEffect(() => {
    let ls = localStorage.getItem('admin');
    if (!ls) {
      navigate('/login')
    }

    if (ls) {

      let admin = JSON.parse(ls)
      setadminLevel(admin.staffcategory)
    }

  })

  // checking if user have access
  useEffect(() => {
    if (adminLevel !== "") {
      { !['SUPER-ADMIN', 'csm', 'ADM', 'HOD', 'HALA-MANAGER'].includes(adminLevel) && navigate("/view") }
    }
  }, [adminLevel])

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://sbpbackend.sharjahaviation.com/sbpapis/highestFields', {
          method: "POST",
          headers: {
            "Content-Type": "application/json", // Specify that you're sending JSON data
          }
        }); // replace with your API endpoint
        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }
        const result = await response.json();
        setData(result.data); // assuming the API returns { data: ... }
        console.log(result.data)
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const shiftsList = [
    'Mr. Mourad',
    'Mr. Othman',
    'Mr. Humaid',
    'Ms. Rachita'
  ]
  const [selectedShift, setSelectedShift] = useState(null);

  // Function to handle shift selection
  const handleShiftSelect = (shift) => {
    setSelectedShift(shift);
  };


  if (loading) return <LoadingIndicator />;
  if (error) return <div className="text-red-500">{error}</div>;


  const organizeDataByGroups = (data, fieldGroups) => {
    let organizedData = {};


    // Iterate over the fieldGroups structure
    Object.entries(fieldGroups).forEach(([groupName, subGroups]) => {
      organizedData[groupName] = {};

      // Iterate over each sub-group (e.g., OTP, FLIGHTS, PAX)
      Object.entries(subGroups).forEach(([subGroupName, fields]) => {
        // Filter data based on field names in this sub-group
        const matchedFields = data.filter((field) => fields.includes(field.fieldName));

        // Store matched fields in the organizedData object
        if (matchedFields.length > 0) {
          organizedData[groupName][subGroupName] = matchedFields;
        }
      });
    });

    return organizedData;
  };

  const organizedData = organizeDataByGroups(data, fieldGroups);

  const shiftCounts = countShiftOccurrences(data);
  const totalFields = Object.values(shiftCounts).reduce((total, count) => total + count, 0);

  // Calculate each shift's percentage of the total
  const progressData = Object.entries(shiftCounts).map(([shift, count]) => ({
    shift,
    count,
    percentage: (count / totalFields) * 100
  }));




  return (
    <div className="bg-[#181C14] p-6 rounded-lg">

      <div className="mb-5">
      <div className="flex flex-col space-y-4 mb-10 relative">
  {/* Reset Icon Positioned at the Top Right */}
  <button
    onClick={() => handleShiftSelect(null)} // Reset selection
    className="absolute top-0 right-0 p-3 bg-[#4F8EF7] text-white rounded-full hover:bg-[#4F8EF7] cursor-pointer transition-all duration-300 transform hover:rotate-180"
    aria-label="Reset All"
  >
    {/* Reset Icon (Circular Arrow) */}
    <GrPowerReset size={25}/>
  </button>

  {/* Detailed Breakdown as Beautiful Cards */}
  <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 mt-8">
    {progressData.map(({ shift, count, percentage }) => (
      <div
        onClick={() => handleShiftSelect(shift)} // Handle shift selection
        key={shift}
        style={{
          backgroundColor:
            shift === selectedShift
              ? // Highlight selected shift
                (shift === "Mr. Mourad"
                  ? "#4F8EF7" // Lighter version for selected shift
                  : shift === "Ms. Rachita"
                    ? "#FF9A8B"
                    : shift === "Mr. Humaid"
                      ? "#FFD700"
                      : "#8FBC8F")
              : // Default color for non-selected shifts
              (shift === "Mr. Mourad"
                ? "#4F8EF760"
                : shift === "Ms. Rachita"
                  ? "#FF9A8B60"
                  : shift === "Mr. Humaid"
                    ? "#FFD70060"
                    : "#8FBC8F60"),
          border: shift === selectedShift ? "2px solid #fff" : "none", // Add border for selected shift
        }}
        className="p-4 rounded-lg shadow-lg hover:shadow-xl transition-all duration-300 cursor-pointer"
      >
        <div className="text-xl font-semibold mb-2 text-white">{shift}</div>
        <div className="text-lg text-white">{count} fields</div>
        <div className="text-sm text-white">({percentage.toFixed(2)}%)</div>
      </div>
    ))}
  </div>
</div>






        {/* Single Combined Progress Bar */}
        <div className="flex items-center space-x-4 mb-10">
          <span className="text-lg font-bold text-white">Shift Performance</span>
          <div className="flex-1 flex rounded-full h-10 relative overflow-hidden">
            {/* Single unified progress bar where shifts are combined */}
            {progressData.map(({ shift, count, percentage }, index) => (
              <div
                key={shift}
                style={{
                  width: `${percentage}%`,
                  backgroundColor: shift === "Mr. Mourad" ? "#4F8EF760" :
                    shift === "Ms. Rachita" ? "#FF9A8B60" :
                      shift === "Mr. Humaid" ? "#FFD70060" : "#8FBC8F60",
                }}
                className={`h-10 ${index !== progressData.length - 1 ? '' : ''} transition-all duration-500 text-xs text-white pl-3 flex items-center`}
              > {shift} - {percentage.toFixed(2)} % </div>
            ))}
          </div>
          <span className="text-lg font-semibold text-white">{totalFields} Total Fields</span>
        </div>

        <div className="mb-10">

          {/* <h1 className="bg-gradient-to-r from-purple-500 to-indigo-600 text-white text-3xl font-semibold p-6 rounded-lg shadow-lg text-center transform"> */}
          <h1 className="bg-[#ECDFCC] text-[#181C14] text-3xl font-semibold p-6 rounded-lg shadow-lg text-center transform">
            🔥 Highest Recorded Values
          </h1>
        </div>
      </div>

      <div className="">
        {Object.entries(organizedData).map(([groupName, subGroups]) => (
          <div key={groupName} className="mb-12 bg-[#3C3D37] p-6 rounded-lg shadow-lg">
            {/* Group Heading */}
            {/* <h2 className="text-4xl font-extrabold text-blue-800 mb-6 p-4 bg-gradient-to-r from-blue-600 to-blue-800 text-white rounded-xl shadow-lg"> */}
            <h2 className="text-4xl font-extrabold  mb-6 p-4 bg-[#697565] text-[#ECDFCC] rounded-xl shadow-lg">
              {groupName}
            </h2>

            {/* Subgroup Loop */}
            {Object.entries(subGroups).map(([subGroupName, fields]) => (
              <div key={subGroupName} className="mb-8">
                {/* Subgroup Heading */}
                <h3 className="text-3xl font-semibold text-[#ECDFCC] mb-4 p-3 bg-[#42433d] rounded-md shadow-md">
                  {subGroupName}
                </h3>

                {/* Fields Grid */}
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4 gap-8 py-6 px-4 bg-[#4f5049] rounded-lg shadow-sm">
                  {fields.map((field) => (
                    (!selectedShift || field.shift === selectedShift) && (
                      <FieldCard key={field.fieldName} field={field} />)
                  ))}
                </div>
              </div>
            ))}
          </div>
        ))}
      </div>



    </div>


  );
};

const getRandomGradient = () => {
  const gradients = [
    "from-blue-300 via-blue-400 to-blue-500", // Soft blue
    "from-green-300 via-green-400 to-green-500", // Fresh green
    "from-purple-300 via-purple-400 to-purple-500", // Elegant purple
    "from-teal-300 via-teal-400 to-teal-500", // Calm teal
    "from-pink-300 via-pink-400 to-pink-500", // Bright pink
    "from-yellow-300 via-yellow-400 to-yellow-500", // Sunny yellow
    "from-indigo-300 via-indigo-400 to-indigo-500", // Deep indigo
    "from-red-300 via-red-400 to-red-500", // Bold red
    "from-orange-300 via-orange-400 to-orange-500", // Warm orange
    "from-cyan-300 via-cyan-400 to-cyan-500", // Cool cyan
    "from-lime-300 via-lime-400 to-lime-500", // Vibrant lime
    // "from-gray-300 via-gray-400 to-gray-500", // Neutral gray
    "from-rose-300 via-rose-400 to-rose-500", // Soft rose
    "from-amber-300 via-amber-400 to-amber-500", // Golden amber
    "from-fuchsia-300 via-fuchsia-400 to-fuchsia-500", // Bold fuchsia
    "from-violet-300 via-violet-400 to-violet-500", // Soft violet
    "from-sky-300 via-sky-400 to-sky-500", // Sky blue
    "from-emerald-300 via-emerald-400 to-emerald-500", // Rich emerald
    "from-teal-300 via-teal-300 to-teal-400", // Subtle teal gradient
    "from-blue-300 via-blue-300 to-blue-400", // Soft ocean blue
    "from-pink-300 via-pink-300 to-pink-400", // Soft pink tones
  ];

  return gradients[Math.floor(Math.random() * gradients.length)];
};

const FieldCard = ({ field }) => {
  const [gradient, setGradient] = useState('');

  useEffect(() => {
    setGradient(getRandomGradient());
  }, []); // Empty dependency array ensures it runs only once when the component mounts
  return (
    <div
      // className={`bg-gradient-to-br ${gradient} p-6 rounded-xl shadow-lg hover:shadow-2xl transition-transform transform hover:scale-105 duration-300 cursor-pointer`}
      className={`bg-[#696b61] p-6 rounded-xl shadow-lg hover:shadow-2xl transition-transform transform hover:scale-105 duration-300 cursor-pointer`}
    >
      <div className="flex items-center space-x-3 mb-4">
        <div className="text-4xl font-bold text-blue-600">🔥</div>
        <div className="text-2xl font-semibold text-[#ECDFCC]">{field.title}</div>
      </div>

      <div className="mb-3">
        <div className="text-[#ECDFCC] text-lg">Highest Value</div>
        <div className="text-4xl font-bold text-[#ECDFCC] bg-[#4f5049] inline-block p-2 rounded-md mt-1">{field.maxValue}</div>
      </div>

      <div className="text-sm text-[#ECDFCC] mb-2">
        <span className="font-semibold">Shift:</span> {field.shift}
      </div>
      <div className="text-sm text-[#ECDFCC] mb-2">
        <span className="font-semibold">Date:</span> {new Date(field.reportDate).toLocaleDateString()}
      </div>
      <div className="text-sm text-[#ECDFCC]">
        <span className="font-semibold">Time:</span> {field.reportTime}
      </div>

      <div className="mt-3 flex justify-center">
        <span className="bg-[#ECDFCC] text-[#181C14] text-xs font-medium py-1 px-3 rounded-full">
          Highest Reported Value
        </span>
      </div>
    </div>
  );
};

// Function to count the occurrences of each shift
const countShiftOccurrences = (data) => {
  const shiftCounts = {
    'Mr. Mourad': 0,
    'Mr. Othman': 0,
    'Mr. Humaid': 0,
    'Ms. Rachita': 0
  };


  data.forEach((item) => {
    const shiftName = item.shift;
    if (shiftCounts[shiftName] !== undefined) {
      shiftCounts[shiftName]++;
    }
  });

  return shiftCounts;
};






const LoadingIndicator = () => (
  <div className="flex justify-center items-center min-h-screen">
    <div className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full border-t-transparent border-gray-600" role="status">
      <span className="sr-only">Loading...</span>
    </div>
  </div>
);

export default ViewReport;
