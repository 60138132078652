import React from 'react'

import { Chart } from "react-google-charts";

const BOCShiftReport = () => {
  return (
    <div>

      <div className='grid gap-10 grid-cols-3'>

        {/* General Fields */}
        <GeneralFields />

        <div className='grid gap-102'>
          <BagsHandleChart/>
          <hr />
          <BaggageDeliveryPerf/>
        </div>


      </div>

    </div>
  )
}

// General Fields
const GeneralFields = () => {
  return (
    <div className='col-span-2'>
      <div className='grid gap-5'>

        {/* Title bar for report */}
        <div className='flex items-center bg-gradient-to-r from-a-pink to-a-blue justify-center rounded-md text-xl font-bold h-[60px] text-white'>
          Bags Handled
        </div>


        {/*Joining bags ex SHJ      Related Fields */}
        <p className="font-bold">Joining bags ex SHJ   </p>
        <div className="grid grid-cols-2 gap-5 mt-1">

          {/*  Air Arabia  */}
          <div className="">
            <p className="text-sm mb-1" > Air Arabia		 </p>
            <input
              type="search"
              className="h-10 rounded-lg px-3 w-full bg-gray-200 border border-a-blue "
              placeholder=" Air Arabia		"
              // value={tempselectedboc}
              onChange={(e) => {


              }}
            />
          </div>

          {/* Oal	  */}
          <div className="">
            <p className="text-sm mb-1" > Oal			 </p>
            <input
              type="search"
              className="h-10 rounded-lg px-3 w-full bg-gray-200 border border-a-blue "
              placeholder="Oal	"
              // value={tempselectedboc}
              onChange={(e) => {


              }}
            />
          </div>

        </div>

        {/* Transfer bags    Related Fields */}
        <p className="font-bold"> Transfer Bags </p>
        <div className="grid grid-cols-2 gap-5 mt-1">

          {/*  Air Arabia  */}
          <div className="">
            <p className="text-sm mb-1" > Air Arabia		 </p>
            <input
              type="search"
              className="h-10 rounded-lg px-3 w-full bg-gray-200 border border-a-blue "
              placeholder=" Air Arabia		"
              // value={tempselectedboc}
              onChange={(e) => {


              }}
            />
          </div>

          {/* Oal	  */}
          <div className="">
            <p className="text-sm mb-1" > Oal			 </p>
            <input
              type="search"
              className="h-10 rounded-lg px-3 w-full bg-gray-200 border border-a-blue "
              placeholder="Oal	"
              // value={tempselectedboc}
              onChange={(e) => {


              }}
            />
          </div>

        </div>

        {/* Arrival bags    Related Fields */}
        <p className="font-bold">Arrival bags   </p>
        <div className="grid grid-cols-2 gap-5 mt-1">

          {/*  Air Arabia  */}
          <div className="">
            <p className="text-sm mb-1" > Air Arabia		 </p>
            <input
              type="search"
              className="h-10 rounded-lg px-3 w-full bg-gray-200 border border-a-blue "
              placeholder=" Air Arabia		"
              // value={tempselectedboc}
              onChange={(e) => {


              }}
            />
          </div>

          {/* Oal	  */}
          <div className="">
            <p className="text-sm mb-1" > Oal			 </p>
            <input
              type="search"
              className="h-10 rounded-lg px-3 w-full bg-gray-200 border border-a-blue "
              placeholder="Oal	"
              // value={tempselectedboc}
              onChange={(e) => {


              }}
            />
          </div>

        </div>

        {/* Title bar for report */}
        <div className='flex items-center bg-gradient-to-r from-a-pink to-a-blue justify-center rounded-md text-xl font-bold h-[60px] text-white'>
          Arrival Baggage Delivery Performance
        </div>

        {/* First Bag Delivery    Related Fields */}
        <p className="font-bold">First Bag Delivery   </p>
        <div className="grid grid-cols-2 gap-5 mt-1">

          {/*  Number of delays     */}
          <div className="">
            <p className="text-sm mb-1" > Number of delays   </p>
            <input
              type="search"
              className="h-10 rounded-lg px-3 w-full bg-gray-200 border border-a-blue "
              placeholder="Imports	"
              // value={tempselectedboc}
              onChange={(e) => {


              }}
            />
          </div>

          {/* % of delays   	  */}
          <div className="">
            <p className="text-sm mb-1" > % of delays   </p>
            <input
              type="search"
              className="h-10 rounded-lg px-3 w-full bg-gray-200 border border-a-blue "
              placeholder="Exports	"
              // value={tempselectedboc}
              onChange={(e) => {


              }}
            />
          </div>

        </div>

        {/* First Bag Delivery    Related Fields */}
        <p className="font-bold">Last Bag Delivery   </p>
        <div className="grid grid-cols-2 gap-5 mt-1">

          {/*  Number of delays     */}
          <div className="">
            <p className="text-sm mb-1" > Number of delays   </p>
            <input
              type="search"
              className="h-10 rounded-lg px-3 w-full bg-gray-200 border border-a-blue "
              placeholder="Imports	"
              // value={tempselectedboc}
              onChange={(e) => {


              }}
            />
          </div>

          {/* % of delays   	  */}
          <div className="">
            <p className="text-sm mb-1" > % of delays   </p>
            <input
              type="search"
              className="h-10 rounded-lg px-3 w-full bg-gray-200 border border-a-blue "
              placeholder="Exports	"
              // value={tempselectedboc}
              onChange={(e) => {


              }}
            />
          </div>

        </div>


      </div>
    </div>
  )
}

// 
const BagsHandleChart = () => {
  return (
    <div className='col-span-1 '>
      <Chart
        chartType="PieChart"
        width="100%"
        height="400px"
        data={[
          ["Field", "Total"],
          ["Air arabia", 11],
          ["Other Airline", 2],
        ]}
        options={{
          title: "Total",
          pieHole: 0.4,
          is3D: false,
        }}
      />
    </div>
  )
}

// 
const BaggageDeliveryPerf = () => {
  return (
    <div className='col-span-1 '>
      <Chart
        chartType="PieChart"
        width="100%"
        height="400px"
        data={[
          ["Field", "Total"],
          ["First Bag Delivery", 3],
          ["Last Bag Delivery", 5],
        ]}
        options={{
          title: "Performance",
          pieHole: 0.4,
          is3D: false,
        }}
      />
    </div>
  )
}

export default BOCShiftReport