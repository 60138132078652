import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

// Icons
import { BiMessageSquareAdd, BiSelectMultiple } from "react-icons/bi";
import { BsGrid, BsViewStacked } from "react-icons/bs";
import { PiUserSwitchLight } from "react-icons/pi";
import { CgArrowTopRightO } from "react-icons/cg";


import { LuCloudCog } from "react-icons/lu";
import { ImLoop2 } from "react-icons/im";
import { FaRegUser } from "react-icons/fa";
import { BiCodeCurly, BiUser } from "react-icons/bi";
import { IoIosInformationCircleOutline } from "react-icons/io"
// import {}
import { AiOutlineBarChart, AiOutlineUnorderedList } from "react-icons/ai";
import { FiLogOut } from "react-icons/fi";
import { HiOutlineDocumentAdd, HiOutlineClipboardList } from "react-icons/hi";
import { MdOutlineVerifiedUser } from "react-icons/md"
import { GrRefresh } from "react-icons/gr"
import { MdOutlineRefresh } from "react-icons/md"
import { HiOutlineDocumentReport } from "react-icons/hi";


import Logo from "../../Assets/logo.png";

const Sidebar = ({ setShowSidebar, showSidebar }) => {
  const navigate = useNavigate();
  const [adminLogin, setAdminLogin] = useState(true);
  const [adminLevel, setadminLevel] = useState('');



  useEffect(() => {
    let ls = localStorage.getItem("admin");
    if (!ls) {
      setAdminLogin(false);
    } else {
      setAdminLogin(true);
      let admin = JSON.parse(ls)

      setadminLevel(admin.staffcategory)
    }
  }, [showSidebar]);
  return (
    // <div className={'p-5 bg-a-gray h-screen w-52 fixed lg:block hidden'}>
    <div
      className={`sidebar p-2 pr-0 z-50 bg-gradient-to-r from-a-pink to-a-blue overflow-scroll fixed w-52 h-screen transition-transform duration-300 transform ${showSidebar ? "translate-x-0" : "-translate-x-full"
        }`}
    >
      {/* Header */}
      <Header />

      {/* Menus */}
      <Menus adminLevel={adminLevel} adminLogin={adminLogin} setShowSidebar={setShowSidebar} />
    </div>
  );
};

// Header
const Header = () => {
  return (
    <div className="flex items-center gap-3 bg-white p-3 rounded-md">
      <img src={Logo} alt="" />
    </div>
  );
};

// Menus
const Menus = ({ adminLogin, setShowSidebar, adminLevel }) => {
  return (
    <div className="mt-10   grid grid-cols-2 gap-2 overflow-y-scroll">

      <div onClick={() => {
        alert("We are working on it. Soon you will able to switch between your admin and normal account!")
      }} className=" cursor-pointer col-span-2 flex items-center  bg-[#777] justify-center  h-[80px] rounded-md gap-2 hover:bg-a-blue flex-col">
        <PiUserSwitchLight className="text-[#fff]" size="24" />
        {/* {!showicon && <div className="ml-3"></div>} */}
        <p className="text-[#fff] text-xs text-center font-bold">Switch To Personal</p>
      </div>

      {(
        <MenusItem
          setShowSidebar={setShowSidebar}
          showicon={true}
          name="About"
          link="/about"
          icon={<IoIosInformationCircleOutline className="text-[#fff]" size="23" />}
        />
      )}


      {/* {adminLogin && ['SUPER-ADMIN', 'csm', 'ADM', 'HOD', 'HALA-MANAGER'].includes(adminLevel) && (
        <MenusItem
          setShowSidebar={setShowSidebar}
          showicon={true}
          name="Create"
          link="/"
          icon={<BiMessageSquareAdd className="text-[#fff]" size="23" />}
        />
      )}
      {adminLogin && (
        <MenusItem
          setShowSidebar={setShowSidebar}
          showicon={true}
          name="Briefing"
          link="/view"
          icon={<BsGrid className="text-[#fff]" size="23" />}
        />
      )} */}

      {/* admin loin access is required  */}
      {adminLogin && (
        <MenusItem
          setShowSidebar={setShowSidebar}
          showicon={true}
          name="App"
          link="/apps"
          icon={<BsGrid className="text-[#fff]" size="23" />}
        />
      )}

      {/* <p className="text-a-pink text-xs font-bold">BMS</p> */}

      <div className=" cursor-pointer col-span-2 flex items-center bg-[#777] justify-center  h-[30px] rounded-md gap-2  flex-col text-[#fff]  h-[50px]">
        <p className=" text-xs text-center font-bold">SAS Reports</p>
      </div>
      {adminLogin && ['SUPER-ADMIN', 'ADM', 'HOD', 'HALA-MANAGER'].includes(adminLevel) && (
        <MenusItem
          setShowSidebar={setShowSidebar}
          showicon={true}
          name="Add Report"
          link="/report/addreports"
          icon={<BiMessageSquareAdd className="text-[#fff]" size="23" />}
        />
      )}
      {adminLogin && ['SUPER-ADMIN', 'ADM', 'HOD', 'HALA-MANAGER'].includes(adminLevel) && (
        <MenusItem
          setShowSidebar={setShowSidebar}
          showicon={true}
          name="View Report"
          link="/report/viewreports"
          icon={<HiOutlineDocumentReport className="text-[#fff]" size="23" />}
        />
      )}
      {adminLogin && ['SUPER-ADMIN', 'ADM', 'HOD', 'HALA-MANAGER'].includes(adminLevel) && (
        <MenusItem
          setShowSidebar={setShowSidebar}
          showicon={true}
          name="Highest Fields"
          link="/report/highestfields"
          icon={<CgArrowTopRightO  className="text-[#fff]" size="23" />}
        />
      )}
      <div className=" cursor-pointer col-span-2 flex items-center bg-[#777] justify-center  h-[30px] rounded-md gap-2  flex-col text-[#fff]  h-[50px]">
        <p className=" text-xs text-center font-bold">Briefing Management System</p>
      </div>

      {adminLogin && ['SUPER-ADMIN', 'csm', 'ADM', 'HOD', 'HALA-MANAGER'].includes(adminLevel) && (
        <MenusItem
          setShowSidebar={setShowSidebar}
          showicon={true}
          name="Create"
          //       bg="#555"
          // text="white"
          link="/"
          icon={<BiMessageSquareAdd className="text-[#fff]" size="23" />}
        />
      )}
      {adminLogin && (
        <MenusItem
          setShowSidebar={setShowSidebar}
          showicon={true}
          name="Briefing"
          link="/view"
          icon={<BsGrid className="text-[#fff]" size="23" />}
        />
      )}
      {adminLogin && (
        <MenusItem
          setShowSidebar={setShowSidebar}
          showicon={true}
          name="Allocate Briefings"
          link="/manageassign"
          icon={<BiSelectMultiple className="text-[#fff]" size="23" />}
        />
      )}
      {adminLogin && (
        <MenusItem
          setShowSidebar={setShowSidebar}
          showicon={true}
          name="Analytics"
          //      bg="#555"
          // text="white"
          link="/summary"
          icon={<AiOutlineBarChart className="text-[#fff]" size="23" />}
        />
      )}
      {adminLogin && (
        <MenusItem
          setShowSidebar={setShowSidebar}
          showicon={true}
          name="Browse"
          // bg="#555"
          // text="white"
          link="/briefingstaff/signnotsIgnStaff"
          icon={<AiOutlineUnorderedList className="text-[#fff]" size="23" />}
        />
      )}
      {adminLogin && (
        <MenusItem
          setShowSidebar={setShowSidebar}
          showicon={true}
          name="Staff"
          link="/stafflist"
          icon={<FaRegUser className="text-[#fff]" size="23" />}
        />
      )}
      {adminLogin && (
        <MenusItem
          setShowSidebar={setShowSidebar}
          showicon={true}
          name="Logs"
          link="/logs"
          icon={<HiOutlineClipboardList className="text-[#fff]" size="23" />}
        />
      )}

      {adminLogin && (
        <MenusItem
          setShowSidebar={setShowSidebar}
          showicon={true}
          name="Urls"
          link="/urls"
          icon={<BiCodeCurly className="text-[#fff]" size="23" />}
        />
      )}


      <div className=" cursor-pointer col-span-2 flex items-center bg-[#777] justify-center  h-[30px] rounded-md gap-2  flex-col text-[#fff] h-[50px]">
        <p className=" text-xs text-center font-bold">Staff Performance Management System</p>
      </div>




      {!adminLogin && (
        <MenusItem
          setShowSidebar={setShowSidebar}
          showicon={true}
          name="Admin Login"
          link="/login"
          icon={<BiUser className="text-[#fff]" size="23" />}
        />
      )}
      {/* {adminLogin && <MenusItem setShowSidebar={setShowSidebar} showicon={true} name="Logout" link="/logout" icon={<FiLogOut className='text-white'/>}/>} */}

      {/* {!adminLogin && <hr />} */}

      {/* {adminLogin && <hr />} */}

      {adminLogin && (
        <MenusItem
          setShowSidebar={setShowSidebar}
          showicon={true}
          name="Add Record"
          link="/spms/addrecord"
          //      bg="#555"
          // text="white"
          icon={<HiOutlineDocumentAdd className="text-[#fff]" size="23" />}
        />
      )}
      {adminLogin && ['SUPER-ADMIN', 'csm', 'css', 'ADM', 'HOD', 'HALA-MANAGER'].includes(adminLevel) && (
        <MenusItem
          setShowSidebar={setShowSidebar}
          showicon={true}
          name="Records"
          link="/spms/viewrecord"
          //      bg="#555"
          text="white"
          icon={<BsViewStacked className="text-[#fff]" size="23" />}
        />
      )}
      {adminLogin && ['SUPER-ADMIN', 'ADM'].includes(adminLevel) && (
        <MenusItem
          setShowSidebar={setShowSidebar}
          showicon={true}
          name="Records Approvals"
          link="/spms/approvedrecords"
          icon={<MdOutlineVerifiedUser className="text-[#fff]" size="23" />}
        />
      )}
      {adminLogin && (
        <MenusItem
          setShowSidebar={setShowSidebar}
          showicon={true}
          name="Records Analysis"
          link="/spms/recordanalyzer"
          icon={<LuCloudCog className="text-[#fff]" size="23" />}
        />
      )}
      {adminLogin && (
        <MenusItem
          setShowSidebar={setShowSidebar}
          showicon={true}
          name="Actions"
          link="/spms/actions"
          icon={<ImLoop2 className="text-[#fff]" size="23" />}
        />
      )}
      {adminLogin && (
        <MenusItem
          setShowSidebar={setShowSidebar}
          showicon={true}
          name="Logout"
          link="/logout"
          icon={<FiLogOut className="text-[#fff]" size="23" />}
        />
      )}

      {!adminLogin && (
        <MenusItem
          setShowSidebar={setShowSidebar}
          showicon={true}
          name="Staff Login"
          link="#"
          icon={<FaRegUser className="text-[#fff]" size="23" />}
        />
      )}
      {!adminLogin && (
        <MenusItem
          setShowSidebar={setShowSidebar}
          name="AA"
          link="/briefingstaff/aa"
          icon={<FaRegUser className="text-[#fff]" size="23" />}
        />
      )}
      {!adminLogin && (
        <MenusItem
          setShowSidebar={setShowSidebar}
          name="PA"
          link="/briefingstaff/pa"
          icon={<FaRegUser className="text-[#fff]" size="23" />}
        />
      )}
      {!adminLogin && (
        <MenusItem
          setShowSidebar={setShowSidebar}
          name="PSA"
          link="/briefingstaff/psa"
          icon={<FaRegUser className="text-[#fff]" size="23" />}
        />
      )}
      {!adminLogin && (
        <MenusItem
          setShowSidebar={setShowSidebar}
          name="TL"
          link="/briefingstaff/tl"
          icon={<FaRegUser className="text-[#fff]" size="23" />}
        />
      )}
      {!adminLogin && (
        <MenusItem
          setShowSidebar={setShowSidebar}
          name="CSS"
          link="/briefingstaff/css"
          icon={<FaRegUser className="text-[#fff]" size="23" />}
        />
      )}
      {!adminLogin && (
        <MenusItem
          setShowSidebar={setShowSidebar}
          name="CSM"
          link="/briefingstaff/csm"
          icon={<FaRegUser className="text-[#fff]" size="23" />}
        />
      )}
      {!adminLogin && (
        <MenusItem
          setShowSidebar={setShowSidebar}
          name="VCT"
          link="/briefingstaff/vct"
          icon={<FaRegUser className="text-[#fff]" size="23" />}
        />
      )}

      {/* HALA N/A  */}
      {!adminLogin && (
        <MenusItem
          setShowSidebar={setShowSidebar}
          name="HALA"
          link="/briefingstaff/hala"
          icon={<FaRegUser className="text-[#fff]" size="23" />}
        />
      )}


    </div>
  );
};

// Menus Item
const MenusItem = ({ name, link, icon, showicon, setShowSidebar, bg = "#f1eded", text = "#333" }) => {

  return (

    <Link className={`flex items-center justify-center bg-[#fff7] text="#fff"  hover:bg-gradient-to-r from-a-pink to-a-blue h-[80px] rounded-md gap-2  flex-col`}

      to={link}
      onClick={() => {
        // if (name === "Records Approvals") {
        //   alert("The feature is under development! Coming soon")
        // }
        if (name === "Logout") {
          setShowSidebar(false)
        }
        // setShowSidebar(false);
      }}
    >
      {showicon && icon}
      {/* {!showicon && <div className="ml-3"></div>} */}
      <p className=" text-xs text-center">{name}</p>
    </Link>

  );
};

const RefreshBtn = ({ history }) => {
  const handleRefresh = () => {
    window.location.reload()
  }

  return (
    <MdOutlineRefresh onClick={handleRefresh} className="text-white cursor-pointer" size={20} />
  )
}

export default Sidebar;
