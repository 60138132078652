import React, { useState, useEffect } from 'react'
import Navbar from '../Global/Navbar'

import { useNavigate } from 'react-router-dom';

const RTCActiveUsers = ({ activeUsers }) => {
  const navigate = useNavigate();

  useEffect(() => {
    let ls = localStorage.getItem('admin');
    if (!ls) {
      navigate('/login')
    }
    return;
  })


  return (
    <div>
      <Navbar title="Real Time Users (RTC)" showarrow={false} />

      {/*  */}
      <div className='bg-white p-5'>

        <ul>
          {Object.keys(activeUsers).map((userId)=>{
            const user = activeUsers[userId];
            const activeTime = Math.floor((new Date() - new Date(user.activeSince)) / 1000);
            console.log(activeTime)

            return (
              <li key={userId}>
                {user.username} (Active for: {activeTime} seconds)
              </li>
            )
          })}
        </ul>

      </div>


    </div>
  )
}

export default RTCActiveUsers