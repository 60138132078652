import React from "react";

// question
const Questions = ({
  qid,
  entirequestion,
  handleQuestionChange,
  handleOptionChange,
  handleCorrectOptionChange,
}) => {
  return (
    <div className="w-full" id={"question-" + qid}>
      <textarea
        type="text"
        className="h-24 rounded-lg p-3 w-full bg-a-gray text-white"
        // placeholder={"Question "+qid}
        placeholder={entirequestion.question}
        // value={entirequestion.question}
        onChange={(e) =>
          handleQuestionChange(entirequestion.qid, e.target.value)
        }
      ></textarea>
      <div className="mt-5 grid grid-cols-2 gap-5 ">
        
        {entirequestion["options"].map((option, index) => {
          return (
            <label
              className="inline-flex gap-3 h-24 items-start rounded-lg p-3 w-full bg-a-gray text-white"
              htmlFor={"option-cb-" + index + "-" + qid}
            >
              <div className="flex items-center gap-3">
                <input
                  type="checkbox"
                  name=""
                  id={"option-cb-" + index + "-" + qid}
                  checked={option.correct}
                  onChange={() =>
                    handleCorrectOptionChange(entirequestion.qid, index)
                  }
                />
                <p className="bg-white h-8 w-8 text-black flex items-center justify-center rounded-full ">
                  A
                </p>
              </div>
              <textarea
                // value={option.opt}
                onChange={(e) =>
                  handleOptionChange(entirequestion.qid, index, e.target.value)
                }
                className="bg-transparent border-none outline-none w-full h-full"
                placeholder={"Option " + (parseInt(index) + 1)}
              >
                
              </textarea>
            </label>
          );
        })}
      </div>
    </div>
  );
};

export default Questions;
