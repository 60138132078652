import React, { useEffect, useState } from "react";

import { Link, Navigate, useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import { toast } from "react-toastify";
import LoadingBar from "../../Global/LoadingBar";
import Navbar from "../../Global/Navbar";

import { BsDot } from "react-icons/bs";
import { AiOutlineClose } from "react-icons/ai";

import { useStaff as useStaffContext } from "../../../Context/StaffContext";
import Quotes from "./Quotes";
import Utils from "../../../utils.json";
import Credits from "../../Global/Credits";
import SelectDesignation from "../../Global/SelectDesignation";
import { IoIosCloseCircleOutline, IoIosCheckmarkCircleOutline } from "react-icons/io";


const BriefingStaff = () => {
  const {
    updateStaffCredentialsContext,
    staffIdContext,
    staffPasswordContext,
  } = useStaffContext();

  const [briefingList, setBriefingList] = useState([]);
  const [loadingbar, setLoadingBar] = useState(false);

  const [staffDesignation, setStaffDesignation] = useState("PSA");
  const [category, setCategory] = useState("");
  const [briefinglimit, setBriefingLimit] = useState(5);

  const [staffid, setStaffId] = useState("");
  const [staffname, setStaffName] = useState("");
  const [staffpassword, setStaffPassword] = useState("");
  const [staffnewpassword, setStaffNewPassword] = useState("");
  const [applyNewPassword, setApplyNewPassword] = useState("");
  const [showNewPasswordModel, setShowNewPasswordModel] = useState(false);
  const [showQuoteModel, setShowQuoteModel] = useState(false);

  useEffect(() => {
    const data = {
      staffid,
      staffpassword,
      staffnewpassword,
    };
    if (applyNewPassword) {
      if (staffnewpassword === "") {
        toast.error("Please enter your new password", { autoClose: 2000 });
        // return false;
        setApplyNewPassword(false);
      } else {
        fetch(`${Utils["domains"]["development"]}/staff/updatestaffpassword`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json", // Specify that you're sending JSON data
          },
          body: JSON.stringify(data), // Set the JSON data as the request body
        }).then((e) => {
          toast("Password updated successfully", { autoClose: 2000 });
          setApplyNewPassword(false);
          setShowNewPasswordModel(false);

          setTimeout(() => {
            handleLogoutUser();
          }, 6000);
        });
      }
    }
  }, [applyNewPassword]);

  const [allowaccess, setAllowAccess] = useState(false);

  useEffect(() => {
    let ls = localStorage.getItem("staff")

    if (ls) {
      if (staffpassword != "") {

        if (['sup@123sbp', 'tlsbp@234#', 'csra@alphasbp', 'csm@sbp1234', '123'].includes(staffpassword)) {
          alert("It's required to change your password")
          setShowNewPasswordModel(true)
        }
      }
    }
  }, [staffpassword])

  useEffect(() => {
    let ls = localStorage.getItem("staff");
    if (ls) {
      ls = JSON.parse(ls);
      setStaffId(ls.staffid);
      setStaffPassword(ls.staffpassword);

      console.log(ls);
      console.log(designation);
      console.log(ls.designation);
      
      
      


      if (ls.designation === designation) {
        setAllowAccess(true);
        updateStaffCredentialsContext(ls.staffid, ls.staffpassword);
        setStaffId(ls.staffid);
        setStaffName(ls.staffname);
        // setShowQuoteModel(true)
        setStaffPassword(ls.staffpassword);
        setLoadingBar(true);
      } else {
        localStorage.clear();
        setAllowAccess(false);
      }
    } else {
      setAllowAccess(false);
    }
  }, []);

  const [applyFilter, setApplyFilter] = useState(false);

  let { designation } = useParams();

  const handleCheckForAccess = () => {
    if (staffid === "") {
      toast.error("Staffid is required!", { autoClose: 2000 });
      return false;
    }

    if (staffpassword === "") {
      toast.error("Password is required For the briefing!", {
        autoClose: 2000,
      });
      return false;
    }

    const data = {
      staffid,
      staffpassword,
    };

    fetch(`${Utils["domains"]["development"]}/staff/staffallowaccess`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json", // Specify that you're sending JSON data
      },
      body: JSON.stringify(data), // Set the JSON data as the request body
    })
      .then((e) => {
        return e.json();
      })
      .then((data) => {
        if (!data.length > 0) {
          toast.error("Invalid username and password!", { autoClose: 2000 });
          return false;
        }

        // return false;

        if (designation != "all") {
          if (data[0].staffcategory !== designation) {
            toast.error("Please select the correct Designation!", { autoClose: 2000 });
            return false;
          }
        }

        setLoadingBar(true);
        setAllowAccess(true);
        setShowQuoteModel(true);
        updateStaffCredentialsContext(staffid, staffpassword);
        const staff = {
          staffid,
          staffpassword,
          designation,
          staffname: data[0].staffname,
          _id: data[0]._id,
        };
        setStaffName(staff.staffname);
        localStorage.setItem("staff", JSON.stringify(staff));

        // toast('New Briefing Created Successfully');
        // setTitle("")
        // setDesignation("all")
        // setCategory("SOP");
        // setTimeout(() => {
        //   navigate('/view')
        // }, 2000);
      });
  };

  // alert(designation)

  useEffect(() => {
    setStaffDesignation(designation);
  }, []);

  useEffect(() => {
    if (allowaccess) {
      // console.log(staffid)
      fetch(
        `${Utils["domains"]["development"]}/briefing/briefingstaffview?designation=${designation}&staffid=${staffid}`,
        {
          method: "get",
          headers: {
            "Content-Type": "application/json", // Specify that you're sending JSON data
          },
          // body: JSON.stringify(data), // Set the JSON data as the request body
        }
      )
        .then((e) => {
          return e.json();
        })
        .then((data) => {
          setTimeout(() => {
            setLoadingBar(false);
            console.log(data, 'data is empty')
            setBriefingList(data);
          }, 0);
        });
    }
  }, [allowaccess]);

  useEffect(() => {
    if (applyFilter) {
      fetch(
        `${Utils["domains"]["development"]}/briefing/briefingstaffviewbyfilter?designation=${designation}&briefinglimit=${briefinglimit}&category=${category}&staffid=${staffid}`,
        {
          method: "get",
          headers: {
            "Content-Type": "application/json", // Specify that you're sending JSON data
          },
          // body: JSON.stringify(data), // Set the JSON data as the request body
        }
      )
        .then((e) => {
          return e.json();
        })
        .then((data) => {
          setTimeout(() => {
            setLoadingBar(false);
            setBriefingList(data);
            setApplyFilter(false);
          }, 0);
        });
    }
  }, [applyFilter]);

  let lst = localStorage.getItem("staff");
  if (lst) {


    lst = JSON.parse(lst);

    if (lst['_id']) {
      lst = lst
    } else {
      lst = "dont fetch data"
    }
  }

  const [actionwith, setactionwith] = useState(lst);
  const [logslist, setloglist] = useState([]);


  useEffect(() => {

    let data = { staff: staffid, actionwith, status: 'assigner-release' };
    fetch(`${Utils['domains']['development']}/actions/viewbyfilter`, {
      method: "post",
      headers: {
        "Content-Type": "application/json", // Specify that you're sending JSON data
      },
      body: JSON.stringify(data), // Set the JSON data as the request body
    })
      .then((e) => {
        return e.json();
      })
      .then((data) => {
        setTimeout(() => {
          setLoadingBar(false);
          setloglist(data);
          console.log(data)
          setApplyFilter(false);
        }, 0);
      });

  }, []);


  const handleLogoutUser = () => {
    localStorage.clear();
    // setLoadingBar(true)
    setAllowAccess(false);
    updateStaffCredentialsContext("", "");
    setStaffId("");
    setStaffPassword("");
  };

  return (
    <div>
      {allowaccess &&
        !showQuoteModel &&
        briefingList.length > 0 &&
        !loadingbar &&
        <NewApp StaffName={staffname} briefingList={briefingList} />}
      {/* {staffIdContext}
      {staffPasswordContext} */}
      {/* {allowaccess && !showQuoteModel && (
        <Navbar
          title={"Briefing List for " + staffDesignation.toUpperCase()}
          showarrow={false}
        />
      )} */}
      {loadingbar && <LoadingBar />}

      {!allowaccess && (
        <PasswordModel
          handleCheckForAccess={handleCheckForAccess}
          staffid={staffid}
          designation={designation}
          staffpassword={staffpassword}
          setStaffId={setStaffId}
          setStaffPassword={setStaffPassword}
        />
      )}

      {allowaccess && !showQuoteModel && (
        <BriefingFilterPanel
          logslist={logslist}
          category={category}
          setCategory={setCategory}
          setBriefingLimit={setBriefingLimit}
          briefinglimit={briefinglimit}
          setApplyFilter={setApplyFilter}
          showNewPasswordModel={showNewPasswordModel}
          setShowNewPasswordModel={setShowNewPasswordModel}
          setLoadingBar={setLoadingBar}
          staffid={staffid}
          staffpassword={staffpassword}
          setStaffId={setStaffId}
          setStaffPassword={setStaffPassword}
          handleLogoutUser={handleLogoutUser}
        />
      )}

        {
          console.log({allowaccess, showQuoteModel, briefingList, loadingbar, staffid, staffname, designation})
          
        }

      {allowaccess &&
        !showQuoteModel &&
        briefingList.length === 0 &&
        !loadingbar && <p className="mt-5 text-xs">No briefing created yet!</p>}

      {allowaccess &&
        !showQuoteModel &&
        briefingList.length > 0 &&
        !loadingbar && (
          <p className="text-white mt-10 bg-gradient-to-r from-a-pink to-a-blue w-20 h-8 rounded-full flex items-center justify-center">
            {staffDesignation.toUpperCase()}
          </p>
        )}
      {/* {allowaccess &&
        !showQuoteModel &&
        briefingList.length > 0 &&
        !loadingbar && <TreeView briefingList={briefingList} />} */}
      {showNewPasswordModel && (
        <ChangePasswordModel
          handleCheckForAccess={handleCheckForAccess}
          staffid={staffid}
          staffpassword={staffpassword}
          setStaffId={setStaffId}
          setStaffPassword={setStaffPassword}
          staffnewpassword={staffnewpassword}
          setStaffNewPassword={setStaffNewPassword}
          setApplyNewPassword={setApplyNewPassword}
          showNewPasswordModel={showNewPasswordModel}
          setShowNewPasswordModel={setShowNewPasswordModel}
        />
      )}

      {showQuoteModel && (
        <Quotes setShowQuoteModel={setShowQuoteModel} staffname={staffname} />
      )}
      {showQuoteModel && console.log("Do proper debuging")}
    </div>
  );
};

const NewApp = ({ StaffName, briefingList }) => {
  return (
    <div className=" w-full grid gap-5 text-gray-700">
      <HeaderSection StaffName={StaffName} />
      <StatisticSection briefingList={briefingList}/>
      <BriSection briefingList={briefingList} />
    </div>
  )
}

const HeaderSection = ({ StaffName }) => {
  return (
    <div className="rounded-md p-3 bg-white">
      <p className="text-lg ">
        <p className="text-xs">Welcome Back,</p>
        <p className="font-bold"> {StaffName}</p>
      </p>
    </div>
  )
}

const StatisticSection = ({briefingList}) => {
  return (
    <div className="grid grid-cols-2 gap-2 rounded bg-white p-2 text-xs">
      <div className="  bg-green-100 rounded-md p-2">
        <p>Signed</p>
        <p className="font-bold text-sm">{briefingList.filter(item => item.status === "sign").length}</p>
      </div>
      <div className="  bg-red-100 rounded-md p-2">
        <p>Not Signed</p>
        <p className="font-bold text-sm">{briefingList.filter(item => item.status !== "sign").length}</p>
      </div>
    </div>
  )
}

const BriSection = ({ briefingList }) => {
  return (
    <div className="p-3 rounded-md bg-white">

      {/* header */}
      <div className="flex items-center justify-between rounded">
        <p className="text-xs">Recent Updates</p>
        <button className="text-blue-500 font-bold text-xs">
          View All
        </button>
      </div>

      {/* Briefing list */}
      <div className="grid lg:grid-cols-3 gap-3 mt-5">
        {briefingList.map((briefing, index) => {
          return (
            briefing.assign === "assign" && <BriSectionCard briefing={briefing} key={index} />
          );
        })}

      </div>

    </div>
  )
}

const BriSectionCard = ({ briefing }) => {
  const { title, category, status, createdDate, _id } = briefing;
  return (
    <Link to={"/briefingstaff/sign/" + _id + "/" + status} className="text-xs border-b lg:border rounded lg:p-3 pb-3 flex items-center justify-between">
      <div>
        <p className="">
          <span className="font-bold mr-2">{category}</span>
          <span className="mr-2">•</span>
          <span className="">{createdDate.split("T")[0]}</span>
        </p>
        <p className="mt-1">{title.slice(0, 32)}...</p>
      </div>
      <div>
        {status === "sign" && (
          <IoIosCheckmarkCircleOutline size={25} className="text-lg text-green-500" />
        )}
        {status === "no-sign" && (

          <IoIosCloseCircleOutline size={25} className="text-lg text-red-500" />

        )}
      </div>
    </Link>
  )
}

const TreeView = ({ briefingList }) => {
  return (
    <div className="mt-5 border-l-4 bg-a-gray  w-[90%] lg:w-1/2 border-white pb-5 pt-5 pl-10 ml-10">
      {briefingList.map((brif, index) => {
        return (
          brif.assign === "assign" && <TreeViewCard brif={brif} key={index} />
        );
      })}
    </div>
  );
};

const TreeViewCard = ({ brif }) => {
  return (
    <div
      className={
        "mb-10 hover:text-white " +
        [brif.status === "sign" ? "text-[#fff6]" : "text-white"]
      }
    >
      <p className="text-base briefing-date-bullet font-bold flex gap-2">
        {brif.createdDate.split("T")[0]} -{" "}
        {brif.status === "sign" && (
          <span className="bg-green-600 text-white py-1 rounded w-20  items-center justify-center inline-flex text-xs font-normal uppercase">
            Signed
          </span>
        )}
        {brif.status === "no-sign" && (
          <span className="bg-red-600 py-1 rounded w-20 inline-flex justify-center text-xs font-normal uppercase">
            Not Sign
          </span>
        )}
        {/* {brif.assign === 'assign' && <span className="bg-indigo-400 py-1 rounded w-20 inline-flex justify-center text-white text-xs font-normal uppercase">{brif.assign}</span>} */}
      </p>
      <div className="flex gap-3 items-center text-base mt-2">
        <BsDot /> <span className="italic text-a-blue">{brif.category}</span> -{" "}
        <Link to={"/briefingstaff/sign/" + brif._id + "/" + brif.status} className="font-bold">
          {brif.title}
        </Link>{" "}
      </div>
    </div>
  );
};

const BriefingFilterPanel = ({
  category,
  setCategory,
  setBriefingLimit,
  briefinglimit,
  setLoadingBar,
  setApplyFilter,
  staffid,
  staffpassword,
  setStaffId,
  logslist,
  setStaffPassword,
  handleLogoutUser,
  showNewPasswordModel,
  setShowNewPasswordModel,
}) => {
  const handleApplyFilter = () => {
    setApplyFilter(true);
    setLoadingBar(true);
  };
  const handleChangePassword = () => {
    setShowNewPasswordModel(!showNewPasswordModel);
  };
  return (
    <div className="bg-a-gray py-4 mt-5 rounded-lg flex flex-wrap lg:flex-nowrap  gap-3 items-center px-5 w-full   h-auto">
      <select
        onChange={(e) => {
          setCategory(e.target.value);
        }}
        type="text"
        value={category}
        className="h-10 rounded-lg px-3"
        placeholder="Category"
      >
        <option value="">All</option>
        <option value="SOP">SOP</option>
        <option value="General Briefing">General Briefing</option>
        <option value="Safety Briefing">Safety Briefing</option>
        <option value="Immigration Guideline">Immigration Guideline </option>
        <option value="Product Knowledge">Product Knowledge</option>
        <option value="Work Instruction">Work Instruction</option>

        <option value="Ops Requirments">Ops Requirments</option>
        <option value="Other">Other</option>
      </select>

      <select
        onChange={(e) => {
          setBriefingLimit(e.target.value);
        }}
        value={briefinglimit}
        className="h-10 rounded-lg px-3"
        placeholder="limit"
      >
        <option value="5">5</option>
        <option selected value="10">
          10
        </option>
        <option value="20">20</option>
        <option value="30">30</option>
        <option value="">All</option>
      </select>

      <Link
        to="/profile"
        className="  bg-gradient-to-r from-a-pink to-a-blue hover:to-a-pink flex items-center justify-center hover:from-a-blue px-12 h-10 rounded-lg text-white uppercase text-xs"
      >
        My Records
      </Link>

      <button
        onClick={handleApplyFilter}
        className="  bg-gradient-to-r from-a-pink to-a-blue hover:to-a-pink hover:from-a-blue px-12 h-10 rounded-lg text-white uppercase text-xs"
      >
        Apply
      </button>
      <button
        onClick={handleChangePassword}
        className="  bg-gradient-to-r from-a-pink to-a-blue hover:to-a-pink hover:from-a-blue px-12 h-10 rounded-lg text-white uppercase text-xs"
      >
        Change Password
      </button>
      <Link
        to="/staffactions"
        className="  gap-2 bg-gradient-to-r from-a-pink to-a-blue hover:to-a-pink flex items-center justify-center hover:from-a-blue px-12 h-10 rounded-lg text-white uppercase text-xs"
      >
        Actions <span className="bg-white text-black p-1 font-bold rounded-full">{logslist.length}</span>
      </Link>
      <button
        onClick={handleLogoutUser}
        className="  bg-gradient-to-r from-a-pink to-a-blue hover:to-a-pink hover:from-a-blue px-12 h-10 rounded-lg text-white uppercase text-xs"
      >
        Logout
      </button>
    </div>
  );
};

const PasswordModel = ({
  handleCheckForAccess,
  staffid,
  staffpassword,
  setStaffId,
  setStaffPassword,
  designation
}) => {
  return (
    <div className="shadow-sm border bg-a-gray w-[90%] lg:w-[400px] py-20 rounded-md flex flex-col gap-6 p-10 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
      <div className="flex flex-col gap-1">

        <p className="text-white">Select your designation</p>

        <p className="text-white text-xs">Selected: {designation.toUpperCase()}</p>
      </div>
      <SelectDesignation />
      <input
        type="text"
        className="h-10 rounded-lg px-3"
        placeholder="Staff ID"
        value={staffid}
        onChange={(e) => {
          setStaffId(e.target.value);
        }}
        onKeyDown={(e)=>{
          if (e.key === 'Enter') {
            handleCheckForAccess()
          }
        }}
      />
      <input
        type="password"
        className="h-10 rounded-lg px-3"
        placeholder="Password"
        value={staffpassword}
        onKeyDown={(e)=>{
          if (e.key === 'Enter') {
            handleCheckForAccess()
          }
        }}
        onChange={(e) => {
          setStaffPassword(e.target.value);
        }}
      />
      <button
        onClick={handleCheckForAccess}
        className="bg-gradient-to-r from-a-pink to-a-blue hover:to-a-pink hover:from-a-blue px-12 py-4 rounded-lg text-white uppercase text-xs
              
              "
      >
        Access
      </button>

      {/* ©️ credits to staff */}
      <Credits />
    </div>
  );
};

const ChangePasswordModel = ({
  handleCheckForAccess,
  staffid,
  staffpassword,
  setStaffId,
  setStaffPassword,
  staffnewpassword,
  setStaffNewPassword,
  setApplyNewPassword,
  setShowNewPasswordModel,
}) => {
  const handleApplyNewPassword = () => {
    setApplyNewPassword(true);
  };
  return (
    <div className="shadow-sm border bg-a-gray w-[90%] lg:w-[400px] pb-20 rounded-md flex flex-col gap-6 p-10 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
      <div className="w-full flex justify-end">
        <button
          className="h-9 w-9 rounded flex items-center justify-center bg-red-500"
          onClick={() => setShowNewPasswordModel(false)}
        >
          <AiOutlineClose className="text-white" size="20px" />
        </button>
      </div>

      <input
        type="text"
        className="h-10 rounded-lg px-3"
        placeholder="Staff ID"
        value={staffid}
        disabled
        onChange={(e) => {
          setStaffId(e.target.value);
        }}
      />
      <input
        type="text"
        className="h-10 rounded-lg px-3"
        placeholder="Password"
        disabled
        value={staffpassword}
        onChange={(e) => {
          setStaffPassword(e.target.value);
        }}
      />
      <input
        type="text"
        className="h-10 rounded-lg px-3"
        placeholder="Password"
        value={staffnewpassword}
        onChange={(e) => {
          setStaffNewPassword(e.target.value);
        }}
      />
      <button
        onClick={handleApplyNewPassword}
        className="bg-gradient-to-r from-a-pink to-a-blue hover:to-a-pink hover:from-a-blue px-12 py-4 rounded-lg text-white uppercase text-xs
              
              "
      >
        Change Password
      </button>
    </div>
  );
};

export default BriefingStaff;
