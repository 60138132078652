import React, { useEffect, useState } from 'react'

import { Link, useParams, useNavigate } from "react-router-dom";


const SelectDesignation = () => {
  const [activeEndPoint, setActiveEndPoint] = useState("");

  const handleDesChange = (endpoint) => {
    setActiveEndPoint(endpoint)
  }

  useEffect(()=>{
    const endpointFROMURL = window.location.pathname.split('/').pop();
    setActiveEndPoint(endpointFROMURL)
  }, [])

  return (
    <div className='grid gap-2 grid-cols-3 '>
      {/* {activeEndPoint} */}
      <div ><Link style={{backgroundColor: activeEndPoint.toUpperCase() == 'AA' ? '#b3b300' : ''}} onClick={()=>handleDesChange("AA")} to="/briefingstaff/aa" className="py-1 bg-a-lgray flex items-center justify-center rounded-md text-white">AA</Link></div>
      <div ><Link style={{backgroundColor: activeEndPoint.toUpperCase() == 'PA' ? '#b3b300' : ''}} onClick={()=>handleDesChange("PA")} to="/briefingstaff/pa" className="py-1 bg-a-lgray flex items-center justify-center rounded-md text-white">PA</Link></div>
      <div ><Link style={{backgroundColor: activeEndPoint.toUpperCase() == 'PSA' ? '#b3b300' : ''}} onClick={()=>handleDesChange("PSA")} to="/briefingstaff/psa" className="py-1 bg-a-lgray flex items-center justify-center rounded-md text-white">PSA</Link></div>
      <div ><Link style={{backgroundColor: activeEndPoint.toUpperCase() == 'PSA-HALA' ? '#b3b300' : ''}} onClick={()=>handleDesChange("PSA-HALA")} to="/briefingstaff/PSA-HALA" className="py-1 bg-a-lgray flex items-center justify-center rounded-md text-white">PSA-HALA</Link></div>
      <div ><Link style={{backgroundColor: activeEndPoint.toUpperCase() == 'PSA-VCT' ? '#b3b300' : ''}} onClick={()=>handleDesChange("PSA-VCT")} to="/briefingstaff/PSA-VCT" className="py-1 bg-a-lgray flex items-center justify-center rounded-md text-white">PSA-VCT</Link></div>
      <div ><Link style={{backgroundColor: activeEndPoint.toUpperCase() == 'TL' ? '#b3b300' : ''}} onClick={()=>handleDesChange("TL")} to="/briefingstaff/tl" className="py-1 bg-a-lgray flex items-center justify-center rounded-md text-white">TL</Link></div>
      <div ><Link style={{backgroundColor: activeEndPoint.toUpperCase() == 'CSS' ? '#b3b300' : ''}} onClick={()=>handleDesChange("CSS")} to="/briefingstaff/css" className="py-1 bg-a-lgray flex items-center justify-center rounded-md text-white">CSS</Link></div>
      <div ><Link style={{backgroundColor: activeEndPoint.toUpperCase() == 'CSM' ? '#b3b300' : ''}} onClick={()=>handleDesChange("CSM")} to="/briefingstaff/csm" className="py-1 bg-a-lgray flex items-center justify-center rounded-md text-white">CSM</Link></div>
      <div ><Link style={{backgroundColor: activeEndPoint.toUpperCase() == 'CSRA' ? '#b3b300' : ''}} onClick={()=>handleDesChange("CSRA")} to="/briefingstaff/CSRA" className="py-1 bg-a-lgray flex items-center justify-center rounded-md text-white">CSRA</Link></div>
      <div className='col-span-3'><Link style={{backgroundColor: activeEndPoint == 'login' ? '#b3b300' : ''}} onClick={()=>handleDesChange("CSM")}  to="/login" className="py-1 bg-a-lgray flex items-center justify-center rounded-md text-white ">Admin Login</Link></div>
    </div>
  )
}

export default SelectDesignation