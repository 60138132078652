import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Editor } from "@tinymce/tinymce-react";
import { toast } from "react-toastify";
import { useDropzone } from "react-dropzone";
import { Tree, TreeNode } from 'react-organizational-chart';
import { Chart } from "react-google-charts";

import { MiniLoadingBar } from "../Global/LoadingBar";

import Utils from '../../utils.json';

// Reports
import CSMShiftReport from "./CSMShiftReport.jsx"
import AOMShiftReport from "./AOMShiftReport.jsx"
import COMShiftReport from "./COMShiftReport.jsx"
import BOCShiftReport from "./BOCShiftReport.jsx"
import HalaReport from "./HalaReport.jsx";

// Report Template
import ReportTemplate from "./ReportTemplate.jsx"


const AddReport = () => {
  const navigate = useNavigate();
  const [adminLevel, setadminLevel] = useState('');
  const [admin, SetAdmin] = useState(null);

  const [shiftlist, setshiftlist] = useState([]);
  const [stafflist, setStaffList] = useState([]);

  const ReportsList = ['CSM Shift Report', 'AOM Shift Report', 'BOC Shift Report', 'COM Shift Report', 'HALA Report'];
  const [selectedReport, setSelectedReport] = useState(ReportsList[0]); // temp selected normally not-selected as an default value
  // const [reportStruData, setReportStruData] = useState({
  //   ReportTitle: 'CSM Shift Report',
  //   fields: {},


  // });


  // schema copying from add_report
  const CSMSHIFTREPORT_Schema = {
    "otpw0m": { value: 0, demoValue: 0, defaultValue: 0, title: "OTP within 0 Min", user: "" },
    "otpw15m": { value: 0, demoValue: 0, defaultValue: 0, title: "OTP within 15 Min", user: "" },
    "otpgs": { value: 0, demoValue: 0, defaultValue: 0, title: "OTP Ground Service", user: "" },
    "totalg9f": { value: 0, demoValue: 0, defaultValue: 0, title: "Total G9 Flights", user: "" },
    "totalot": { value: 0, demoValue: 0, defaultValue: 0, title: "Total on Time", user: "" },
    "delyo15m": { value: 0, demoValue: 0, defaultValue: 0, title: "Delays Over 15 Min", user: "" },
    "totald": { value: 0, demoValue: 0, defaultValue: 0, title: "Total Delayed", user: "" },
    "totalaccd": { value: 0, demoValue: 0, defaultValue: 0, title: "Total accountable delays", user: "" },
    "totalabyp": { value: 0, demoValue: 0, defaultValue: 0, title: "Total ABY Pax", user: "" },
    "totalabypinf": { value: 0, demoValue: 0, defaultValue: 0, title: "Total ABY Pax INF", user: "" },
    "totalabyjp": { value: 0, demoValue: 0, defaultValue: 0, title: "Total ABY joining Pax", user: "" },
    "totalabyjpinf": { value: 0, demoValue: 0, defaultValue: 0, title: "Total ABY joining Pax INF", user: "" },
    "totalabyip": { value: 0, demoValue: 0, defaultValue: 0, title: "Total ABY inbound Pax", user: "" },
    "totalabyipinf": { value: 0, demoValue: 0, defaultValue: 0, title: "Total ABY inbound Pax INF", user: "" },


    "onlineci": { value: 0, demoValue: 0, defaultValue: 0, title: "Online check in", user: "" },
    // "totaloafli": { value: 0, demoValue: 0, defaultValue: 0, title: "Total other airlines flights", user: "" },
    // "totaloapax": { value: 0, demoValue: 0, defaultValue: 0, title: "Total other airlines Pax", user: "" },
    "ebt": { value: 0, demoValue: 0, defaultValue: 0, title: "EBT", user: "" },
    "lcdfc": { value: 0, demoValue: 0, defaultValue: 0, title: "LCD Fees collection", user: "" },
    "halasc": { value: 0, demoValue: 0, defaultValue: 0, title: "Hala Service Collection", user: "" },

    "otpw0m_oal": { value: 0, demoValue: 0, defaultValue: 0, title: "OTP within 0 Min OAL", user: "" },
    "otpw15m_oal": { value: 0, demoValue: 0, defaultValue: 0, title: "OTP within 15 Min OAL", user: "" },
    "otpgs_oal": { value: 0, demoValue: 0, defaultValue: 0, title: "OTP Ground Service OAL", user: "" },
    "totaloalf__oal": { value: 0, demoValue: 0, defaultValue: 0, title: "Total OAL Flights", user: "" },
    "totalot_oal": { value: 0, demoValue: 0, defaultValue: 0, title: "Total on Time OAL", user: "" },
    "delyo15m_oal": { value: 0, demoValue: 0, defaultValue: 0, title: "Delays Over 15 Min OAL", user: "" },
    "totald_oal": { value: 0, demoValue: 0, defaultValue: 0, title: "Total Delayed OAL", user: "" },
    "totalaccd_oal": { value: 0, demoValue: 0, defaultValue: 0, title: "Total accountable delays OAL", user: "" },
    "totaloalp__oal": { value: 0, demoValue: 0, defaultValue: 0, title: "Total OAL Pax", user: "" },
    "totaloalp__oal__INF": { value: 0, demoValue: 0, defaultValue: 0, title: "Total OAL Pax INF", user: "" },

    "shiftadm": { value: "", demoValue: "", defaultValue: "", title: "ADM", user: "" },
    "shiftaom": { value: "", demoValue: "", defaultValue: "", title: "AOM", user: "" },
    "shiftbocjoin": { value: "", demoValue: "", defaultValue: "", title: "BOC Joining", user: "" },
    "shiftboctran": { value: "", demoValue: "", defaultValue: "", title: "BOC Transfer", user: "" },
    "shiftcom": { value: "", demoValue: "", defaultValue: "", title: "COM", user: "" },
    "shiftairsideacsm": { value: "", demoValue: "", defaultValue: "", title: "Airside CSM", user: "" },
    "shiftairsidelcsm": { value: "", demoValue: "", defaultValue: "", title: "Landside CSM", user: "" },
    "shiftairsidefcsm": { value: "", demoValue: "", defaultValue: "", title: "Floater CSM", user: "" },
  }

  const personsList = ['Mourad Rizqi', 'Mohamed Othman Mohamed', 'Rachita Srivastava', 'Humaid Mohammed', 'Dridi Karim', 'Issam Shamlan', 'Fares Mustapha', 'Abdessamad Aoussar', 'Lucie Zborovska', 'Adnan Ahmed', 'Yudha Singh Subedi', 'Yamn Al Swidan', 'Sumesh Surendran', 'Nour El Houda Mamouni', 'Souad Abbasi', 'Saadeidin', 'Mohammad Sadiq', 'Chadhry Baber Iqbal Muhammed', 'Saqib Ghayas', 'Youssef', 'Puneet', 'Siby', 'Joshua', 'Ebrahim', 'Morris', 'Muthapa', 'Shehbaz', 'Joji', 'Wajahat', 'Jobim', 'Shoukath', 'Fazal'];

  const [activeReport, setActiveReport] = useState(null);
  const [activeReportFull, setActiveReportFull] = useState(null);

  const [rptDtaTitle, setRptDtaTitle] = useState(selectedReport);
  const [rptDtaFields, setRptDtaFields] = useState({});
  const [rptDtaDate, setRptDtaDate] = useState(null);
  const [rptDtaTime, setRptDtaTime] = useState("Morning");
  const [rptSelectedShift, setRptSelectedShift] = useState('');

  // parsing admin from ls
  useEffect(() => {
    let ls = localStorage.getItem('admin');
    if (!ls) {
      navigate('/login')
    }

    if (ls) {

      let admin = JSON.parse(ls)
      SetAdmin(admin);
      setadminLevel(admin.staffcategory)
    }

  }, [navigate])

  // checking if user have access
  useEffect(() => {
    if (adminLevel !== "") {
      { !['SUPER-ADMIN', 'csm', 'ADM', 'HOD', 'HALA-MANAGER'].includes(adminLevel) && navigate("/view") }
    }
  }, [adminLevel])

  // ---------------------------------------------------------------------------------
  // get all staff list
  useEffect(() => {
    fetch(`${Utils['domains']['development']}/spms/getstafflist`, {
      method: "get",
      headers: {
        "Content-Type": "application/json", // Specify that you're sending JSON data
      },
      // body: JSON.stringify({}), // Set the JSON data as the request body
    })
      .then((e) => {
        return e.json();
      })
      .then((data) => {
        setTimeout(() => {
          setStaffList(data);
        }, 500);
      });
  }, []);

  // get shift list
  useEffect(() => {
    fetch(`${Utils['domains']['development']}/shift/getlist`, {
      method: "get",
      headers: {
        "Content-Type": "application/json", // Specify that you're sending JSON data
      },
      // body: JSON.stringify({}), // Set the JSON data as the request body
    })
      .then((e) => {
        return e.json();
      })
      .then((data) => {
        setTimeout(() => {
          setshiftlist(data);
        }, 0);
      });
  }, []);

  // handle report change
  useEffect(() => {

    switch (selectedReport) {
      case 'CSM Shift Report':
        setRptDtaFields(CSMSHIFTREPORT_Schema);
        break;
      case 'AOM Shift Report':
        setRptDtaFields({})
        break;
      case 'BOC Shift Report':
        setRptDtaFields({})
        break;
      case 'COM Shift Report':
        setRptDtaFields({})
        break;
      case 'HALA Report':
        setRptDtaFields({})
        break;
      default:
        setRptDtaFields({})
        break;
    }

    setActiveReport(null)
    setActiveReportFull(null)
    setRptSelectedShift('')

  }, [selectedReport, rptDtaDate, rptDtaTime])

  // 
  // useEffect(() => {
  //   console.log(rptDtaFields)
  // }, [rptDtaFields])

  // add report
  const handleAddReport = () => {
    const data = {
      reportTitle: rptDtaTitle,
      reportFields: rptDtaFields,
      reportDate: rptDtaDate,
      reportTime: rptDtaTime,
      reportShift: rptSelectedShift
    }

    // if (rptSelectedShift!=="N/S") {
    //   toast.info("Please select the date");
    //   setActiveReport(null);
    //   setRptDtaFields(CSMSHIFTREPORT_Schema)
    //   return;
    // }

    if (rptDtaDate === null || rptDtaDate === "") {
      toast.info("Please select the date");
      setActiveReport(null);
      setActiveReportFull(null)
      setRptDtaFields(CSMSHIFTREPORT_Schema)
      return;
    }

    fetch(`${Utils['domains']['development']}/sbpapis/add`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json", // Specify that you're sending JSON data
      },
      body: JSON.stringify(data), // Set the JSON data as the request body
    }).then((e) => {
      return e.json()
    }).then((data) => {
      if (data.status !== "error") {
        toast.info(data.message)
        console.log(data)

        // set fetched report id
        setActiveReport(data.data._id)
        setActiveReportFull(data.data)
        setRptDtaFields(data.data.reportFields)
        setRptSelectedShift(data.data?.reportShift)

      } else {
        toast.error(data.message)

        // reset 
        setActiveReport(null);
        setActiveReportFull(null)
        setRptDtaFields(CSMSHIFTREPORT_Schema)
        setRptSelectedShift('')

      }
    })
  }

  const handleUpdateReport = (field, value) => {
    if (!activeReport) {
      return false;
    }
    if (activeReportFull.isPublishedInit) {
      toast.error('The report cannot be update since it was published.')

      return false;
    }
    console.log(field, value, activeReport)
    const data = {
      field: field,
      value: value,
      report_id: activeReport
    }

    fetch(`${Utils['domains']['development']}/sbpapis/update`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json", // Specify that you're sending JSON data
      },
      body: JSON.stringify(data), // Set the JSON data as the request body
    }).then((e) => {
      return e.json()
    }).then((data) => {
      if (data.status !== "failed") {
        // toast.info(data.message)
        // setActiveReport(data.data._id)
        setActiveReportFull(data.data)
        // setRptDtaFields(data.data.reportFields)
        // setRptSelectedShift(data.data?.reportShift)
        console.log('i am data while updateing', data)
      } else {
        toast.error(data.message)
      }
    })
  }

  return (
    <div className="bg-white p-10 flex gap-8 flex-col rounded-md">

      {/* Main Title */}
      <MainTitle />



      <hr />

      {/* Toolbar */}
      <Toolbar report={{
        ReportsList: ReportsList, setSelectedReport: setSelectedReport, selectedReport: selectedReport, rptDtaDate: rptDtaDate, setRptDtaDate: setRptDtaDate,
        rptDtaTime: rptDtaTime, setRptDtaTime: setRptDtaTime,
        rptSelectedShift: rptSelectedShift, setRptSelectedShift: setRptSelectedShift, handleUpdateReport, activeReportFull, activeReport
      }} />

      <hr />

      {/* Action Bar */}
      <ActionBar report={{ selectedReport: selectedReport, handleAddReport: handleAddReport, activeReport, activeReportFull, handleUpdateReport }} />

      <hr />

      <span > Selected Report ID: <span className="font-bold bg-yellow-400">{activeReport ? activeReport : "No Report Selected"}</span>
      </span>
      <hr />

      {/* Title bar for report */}
      <div className='flex items-center bg-gradient-to-r from-a-pink to-a-blue justify-center rounded-md font-bold text-xl h-[60px] text-white'>
        {selectedReport == 'not-selected' ? 'No Report Selected' : selectedReport}
      </div>

      <hr />

      {/* Display Report based on selection */}
      {selectedReport == "CSM Shift Report" && <CSMShiftReport handleUpdateReport={handleUpdateReport} rptDtaFields={rptDtaFields} setRptDtaFields={setRptDtaFields} activeReport={activeReport} personsList={personsList} loginUser={admin} />}
      {selectedReport == "AOM Shift Report" && <AOMShiftReport />}
      {selectedReport == "BOC Shift Report" && <BOCShiftReport />}
      {selectedReport == "COM Shift Report" && <COMShiftReport />}
      {selectedReport == "HALA Report" && <HalaReport />}
      {["not-selected"].includes(selectedReport) && <div>No Report Selected</div>}

    </div>
  );
};


// Main Title
const MainTitle = () => {
  return (
    <div>
      <p className="font-bold text-2xl">Create New Report (BETA)</p>
      <p>Under development</p>
    </div>
  )
}

// Toolbar
const Toolbar = ({ report }) => {

  const { selectedReport, ReportsList, setSelectedReport, rptDtaDate, setRptDtaDate,
    rptDtaTime, setRptDtaTime, rptSelectedShift, setRptSelectedShift, handleUpdateReport, activeReportFull, activeReport } = report;

  return (
    <div className="flex gap-5 items-center ">

      {/* Choose Report */}
      <div>
        <p className="text-sm mb-1" > Select Report </p>
        <select
          className="h-10 rounded-lg px-3 bg-gray-200 border border-a-blue mt-1"
          onChange={(e) => {
            // if (e.target.value != "not-selected") {
            setSelectedReport(e.target.value)
            // }
          }}
          value={selectedReport}
        >

          <option value="not-selected">Select Report</option>
          {ReportsList.map((report) => {
            return <option value={report}>{report}</option>
          })}

        </select>
      </div>

      {/* choose Date */}
      <div>
        <p className="text-sm mb-1" > Choose Date </p>
        <input value={rptDtaDate} onChange={(e) => {
          setRptDtaDate(e.target.value)
        }} type="date" className="h-10 rounded-lg px-3 bg-gray-200 border border-a-blue mt-1" />
      </div>

      {/* choose your time */}
      <div>
        <p className="text-sm mb-1" > Choose Your Time </p>
        <select
          value={rptDtaTime}
          onChange={(e) => {
            setRptDtaTime(e.target.value)
          }}
          className="h-10 rounded-lg px-3 bg-gray-200 border border-a-blue mt-1"
        >
          <option value="Morning">Morning</option>
          <option value="Night">Night</option>
        </select>
      </div>

      <div>
        <p className="text-sm mb-1" > Choose Shift </p>
        <select
          value={rptSelectedShift}
          onChange={(e) => {
            setRptSelectedShift(e.target.value)
            handleUpdateReport("reportShift", e.target.value)
          }}
          className="h-10 rounded-lg px-3 bg-gray-200 border border-a-blue mt-1"
        >
          <option value="N/S">Select Shift</option>
          <option value="Mr. Mourad">Mr. Mourad</option>
          <option value="Mr. Humaid">Mr. Humaid</option>
          <option value="Mr. Othman">Mr. Othman</option>
          <option value="Ms. Rachita">Ms. Rachita</option>
        </select>
      </div>



    </div>
  )
}

// Action Bar
const ActionBar = ({ report }) => {
  const { selectedReport, handleAddReport, activeReport, activeReportFull, handleUpdateReport } = report;
  return (
    <div className="flex gap-5 items-center flex-wrap ">
      <button
        disabled={selectedReport !== 'CSM Shift Report'}
        className="bg-blue-500 hover:bg-blue-600 px-8  text-white uppercase text-md h-10 rounded-lg 
              "
        onClick={handleAddReport}
      >
        Select Report
      </button>
      {activeReport && (<button
        disabled={(selectedReport !== 'CSM Shift Report') || !activeReport}
        className={` px-8  text-white uppercase text-md h-10 rounded-lg 
             ${activeReportFull.isPublishedInit === true ? ' bg-gray-500 ' : ' bg-green-500  '} `}
        onClick={() => {
          if (activeReportFull.isPublishedInit !== true) {
            let con = confirm("Do you want to publish this report once you publish than you will not able to edit this report!")
            if (con) {
              handleUpdateReport('isPublishedInit', true)
            }
          }
          // handleAddReport()
          // handleAddReport()
        }}
      >
        {activeReportFull.isPublishedInit === true ? 'Published' : 'PUBLISH'}
      </button>)}



    </div>
  )
}


export default AddReport;
