import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom"

// left from here 
const Logout = () => {
  const navigate = useNavigate();

  useEffect(() => {
    localStorage.removeItem("admin");
    navigate("/login");
  });

  return <div></div>;
};

export default Logout;
