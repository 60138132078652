import React, { useEffect, useState } from "react";
import Navbar from "../Global/Navbar";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import LoadingBar from "../Global/LoadingBar";
import { PiMicrosoftExcelLogoFill } from "react-icons/pi";
import { GrDocumentDownload } from "react-icons/gr";
import { BsThreeDotsVertical } from "react-icons/bs";
import Utils from "../../utils.json";
import { Chart } from "react-google-charts";
import { Tooltip } from "react-tooltip";
import { MdInfoOutline } from "react-icons/md";
import { BiCloudUpload } from "react-icons/bi";
import { MiniLoadingBar } from "../Global/LoadingBar";
import { AiOutlineFile, AiOutlineDelete } from "react-icons/ai";
import { FaCheckCircle } from "react-icons/fa";
import { MdCancel } from "react-icons/md";


import { useDropzone } from "react-dropzone";

const CommentModel = ({ selectedComment, showCommentModel, setshowCommentModel }) => {
  return (<div>
    <div className="fixed top-0 left-0 w-screen h-screen bg-[#0009]">
      <div className="p-5 rounded-lg bg-a-gray border fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-[800px] h-[500px] text-white text-sm">

        {selectedComment}

          {/* Footer */}
          <div className="flex justify-end gap-2 mt-10">
          <button
            onClick={() => { setshowCommentModel(false) }}
            className="px-4 py-2 rounded-lg text-white uppercase text-md
               border-2 border-a-gray
               bg-a-lgray
              shadow-md
              "
          >
            Cancel
          </button>
          
        </div>

      </div>

    </div>

  </div>)
}

const EditModel = ({ attachments, setApplyFilter, setAttachments, selectedRecord, showEditModel, setshowEditModel, updateRECORDSPMS }) => {

  useEffect(() => {
    setAttachments([])
  }, [])


  const handleEditRecord = () => {
    let mergedattacheted = [];
    mergedattacheted = [...attachments, ...selectedRecord.attachments]

    // console.log("----------------------------------------------------------------------")
    // console.log("new-attach", attachments);
    // console.log("old", selectedRecord.attachments);
    // console.log("merged", mergedattacheted);

    let update = updateRECORDSPMS("attachments", mergedattacheted, selectedRecord._id);

    setshowEditModel(false)
    setTimeout(() => {
      setApplyFilter(true)
    }, 1000);
  }

  return (
    <div className="fixed top-0 left-0 w-screen h-screen bg-[#0009]">
      <div className="p-5 rounded-lg bg-a-gray border fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-[800px] h-[500px]">

        {/* Header */}
        <div>
          <p className="text-white text-xl font-bold">Edit Record For {selectedRecord.staffname}</p>
          <p className="text-white mt-2 mb-2">{selectedRecord._id}</p>
        </div>

        {/* Content */}
        <AddAttachment
          attachments={attachments}
          setAttachments={setAttachments}
        />

        {/* Footer */}
        <div className="flex justify-end gap-2 mt-10">
          <button
            onClick={() => { setshowEditModel(false) }}
            className="px-4 py-2 rounded-lg text-white uppercase text-md
               border-2 border-a-gray
               bg-a-lgray
              shadow-md
              "
          >
            Cancel
          </button>
          <button
            // onClick={() => { setshowEditModel(false) }}
            onClick={handleEditRecord}
            className="px-4 py-2 rounded-lg text-white uppercase text-md
               border-2 border-a-gray
               bg-green-500
              shadow-md
              "
          >
            Save
          </button>
        </div>

      </div>
    </div>
  )
}

const AddAttachment = ({ attachments, setAttachments }) => {
  const [files, setFiles] = useState([]); // Use an array for multiple files
  const [uploadFile, setUploadFile] = useState(false); // Use an array for multiple

  const onDrop = (acceptedFiles) => {
    const selectedFile = acceptedFiles[0];
    const uniquekey = `${Date.now()}`;
    if (selectedFile) {
      selectedFile.status = "pending";
      selectedFile.newfilename = "";
      selectedFile.key = uniquekey;

      setFiles([selectedFile, ...files]); // Append new files to the existing list
      setAttachments([
        { key: uniquekey, filename: selectedFile.name, newfilename: "" },
        ...attachments,
      ]);

      setUploadFile(true);
    }
    // alert(); // This is just for debugging purposes, you can remove it
  };

  useEffect(() => {
    if (uploadFile) {
      const formData = new FormData();
      formData.append("file", files[0]);
      formData.append("key", files[0].key);

      const response = fetch(`${Utils['domains']['development']}/files/upload`, {
        method: "POST",
        body: formData,
      })
        .then((e) => {
          return e.json();
        })
        .then((data) => {
          setUploadFile(false);
          const updatedFiles = files.map((file) => {
            if (file.key === data.key) {
              file.status = "uploaded";
              file.newfilename = data.newfilename;
            }

            return file;
            // const updatedFile = {
            //   ...JSON.parse(JSON.stringify(file)),
            //   status: "uploaded",
            // };
            // return updatedFile;
          });

          const modifyattachments = attachments.map((file) => {
            if (file.key === data.key) {
              file.newfilename = data.newfilename;
            }
            return file;
          });

          setAttachments(modifyattachments);

          // console.table(files)
          // console.table(updatedFiles)
          // // setFiles(updatedFiles)
        });
    }
  }, [uploadFile]);

  const handleDeleteFile = (uid) => {
    // Create a new array that excludes the item with the specified index (uid)
    const updatedFiles = files.filter((_, index) => index !== uid);
    const updatedattachment = attachments.filter((_, index) => index !== uid);

    // Update the state with the new array
    setFiles(updatedFiles);
    setAttachments(updatedattachment);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: false,
    // accept: 'image/*', // Define the accepted file types
  });

  return (
    <div className="w-full">
      <div
        {...getRootProps()}
        className="dropzone w-full cursor-pointer  h-[150px] border bg-white flex-col rounded-lg flex items-center justify-center gap-2"
      >
        <input {...getInputProps()} name="file" />
        <BiCloudUpload size="140px" className="text-a-pink" />
        <p> Drag & drop file or browse! </p>
      </div>
      <div className="bg-white p-5 mt-5 rounded-lg grid grid-cols-2 gap-3">
        {files.map((file, index) => {
          return (
            <FileDisplay
              file={file}
              uid={index}
              handleDeleteFile={handleDeleteFile}
            />
          );
        })}
      </div>
      {/* <button onClick={uploadFiles}>Upload Testing</button> */}

      {/* {file && (
      <div>
        <p>Selected File: {file.name}</p>
        <button>Upload</button>
      </div>
    )} */}
    </div>
  );
};

const FileDisplay = ({ file, uid, handleDeleteFile }) => {
  return (
    <div className="w-full cursor-pointer  h-12 bg-gray-200 border-l-4 border-l-a-pink border border-gray-300  rounded-lg flex items-center gap-2 px-5 justify-between">
      <div
        className="flex items-center gap-2 relative"
        title={file.name + " " + file.newfilename}
      >
        {file.status === "pending" && <MiniLoadingBar />}
        {file.status === "uploaded" && <AiOutlineFile />}
        {/* <MiniLoadingBar/> */}
        <p>
          {file.name.substring(0, 5)} <span className="text-a-pink">···</span>{" "}
          {file.name.substring(file.name.length - 5)}
        </p>
      </div>
      <div
        onClick={() => handleDeleteFile(uid)}
        className="bg-red-500 w-8 h-8 rounded-full grid place-items-center"
      >
        <AiOutlineDelete className="text-white" size="17px" />
      </div>
    </div>
  );
};

const ViewRecord = () => {
  const [showEditModel, setshowEditModel] = useState(false);
  const [showCommentModel, setshowCommentModel] = useState(false);
  const [attachments, setAttachments] = useState([]);
  const [selectedRecord, setSelectedRecord] = useState({
    "_id": "",
    "staffname": "",
    "attachments": ""
  });
  const [selectedComment, setSelectedComment] = useState("");

  const navigate = useNavigate();
  const [adminLevel, setadminLevel] = useState('');
  const [showCharts, setShowCharts] = useState(true);

  useEffect(() => {
    let ls = localStorage.getItem("admin");
    if (!ls) {
      navigate("/login");
    }
    let admin = JSON.parse(ls)

    setadminLevel(admin.staffcategory)
    return;
  });

  useEffect(() => {
    if (adminLevel !== "") {

      { !['SUPER-ADMIN', 'csm', 'css', 'ADM', 'HOD', 'HALA-MANAGER'].includes(adminLevel) && navigate("/spms/addrecord") }
    }

  }, [adminLevel])

  const [spmsRecord, setSpmsRecord] = useState([]);
  const [spmsRecordBreakdown, setSpmsRecordBreakdown] = useState([]);
  const [loadingbar, setLoadingBar] = useState(true);
  const [stafflist, setStaffList] = useState([]);
  const [isPositive, setisPositive] = useState('both');
  const [isApproved, setisApproved] = useState('both');


  const [staffid, setstaffid] = useState("");
  const [tempstaffid, settempstaffid] = useState("");
  const [area, setarea] = useState("");
  const [destination, setdestination] = useState("");
  const [flightno, setflightno] = useState("");
  const [recordtype, setrecordtype] = useState("");
  const [reportedby, setreportedby] = useState("");
  const [tempreportedby, settempreportedby] = useState("");
  const [comments, setcomments] = useState("");

  const [expendrecordtypemultiplesel, setexpendrecordtypemultiplesel] =
    useState(false);

  const [startdate, setStartDate] = useState("");
  const [dateofincidentstart, setdateofincidentstart] = useState("");
  const [dateofincidentend, setdateofincidentend] = useState("");
  const [enddate, setEndDate] = useState("");



  useEffect(() => {
    if (spmsRecord.length >= 1) {
      const data = spmsRecord;

      const staffDetails = {};

      data.forEach((item) => {
        const staffId = item.staffid._id;
        const staffIDAirport = item.staffid.staffid;
        const staffName = item.staffid.staffname;
        const recordType = item.recordtype;
        const isPositive = item.isPositive;
        console.log(item.isPositive)

        if (staffId) {
          if (!staffDetails[staffId]) {
            staffDetails[staffId] = {
              _id: staffId,
              staffName: staffName,
              staffIDAirport: staffIDAirport,
              totalRecords: 0,
              recordTypeCounts: {},
              totalPositiveRecords: 0,
              totalNegativeRecords: 0,
            };
          }

          staffDetails[staffId].totalRecords++;
          staffDetails[staffId].recordTypeCounts[recordType] =
            (staffDetails[staffId].recordTypeCounts[recordType] || 0) + 1;

          if (isPositive) {
            staffDetails[staffId].totalPositiveRecords += 1;
          } else {
            staffDetails[staffId].totalNegativeRecords += 1;
          }

        }
      });

      const resultList = Object.keys(staffDetails).map(
        (key) => staffDetails[key]
      );

      setSpmsRecordBreakdown(resultList);
    } else {
      // console.log(spmsRecord)
      setSpmsRecordBreakdown([])
    }
  }, [spmsRecord]);

  // filter
  const [applyFilter, setApplyFilter] = useState(false);
  const [stafffetchlimit, setstafffetchlimit] = useState(20);

  useEffect(() => {
    fetch(`${Utils["domains"]["development"]}/spms/getstafflist`, {
      method: "get",
      headers: {
        "Content-Type": "application/json", // Specify that you're sending JSON data
      },
      // body: JSON.stringify({}), // Set the JSON data as the request body
    })
      .then((e) => {
        return e.json();
      })
      .then((data) => {
        setTimeout(() => {
          //   setLoadingBar(false);
          setStaffList(data);
        }, 500);
      });
  }, []);

  useEffect(() => {
    fetch(`${Utils["domains"]["development"]}/spms/viewrecord?staffcategory=css`, {
      method: "get",
      headers: {
        "Content-Type": "application/json", // Specify that you're sending JSON data
      },
      // body: JSON.stringify({}), // Set the JSON data as the request body
    })
      .then((e) => {
        return e.json();
      })
      .then((data) => {
        setTimeout(() => {
          setLoadingBar(false);
          setSpmsRecord(data);
        }, 0);
      });
  }, []);

  useEffect(() => {
    if (applyFilter) {
      // let data = { staffid, staffcategory, stafffetchlimit, briefingcategory };
      fetch(
        `${Utils["domains"]["development"]}/spms/viewrecordbyfilter?staffid=${staffid}&area=${area}&destination=${destination}&flightno=${flightno}&recordtype=${recordtype}&reportedby=${reportedby}&limit=${stafffetchlimit}&startdate=${startdate}&enddate=${enddate}&dateofincidentstart=${dateofincidentstart}&dateofincidentend=${dateofincidentend}&staffcategory=${adminLevel}&isPositive=${isPositive}&isApproved=${isApproved}`,
        {
          method: "get",
          headers: {
            "Content-Type": "application/json", // Specify that you're sending JSON data
          },
          //   body: JSON.stringify(data), // Set the JSON data as the request body
        }
      )
        .then((e) => {
          return e.json();
        })
        .then((data) => {
          setTimeout(() => {
            setLoadingBar(false);

            setSpmsRecord(data);
            setApplyFilter(false);
          }, 0);
        });
    }
  }, [applyFilter]);

  const HandleExportAsExcel = () => {
    const newspmsRecord = spmsRecord.map((item) => {
      const updatedItem = { ...item };
      // delete updatedItem.questions;
      delete updatedItem._id;
      // delete updatedItem.briefingid;
      delete updatedItem.__v;
      delete updatedItem.attachments;

      let staffid = `${updatedItem.staffid.staffid}`;
      let staffname = `${updatedItem.staffid.staffname}`;

      let reportedbystaffid = `${updatedItem.reportedby.staffid}`;
      let reportedbystaffname = `${updatedItem.reportedby.staffname}`;

      updatedItem.staffid = staffid;
      updatedItem.staffname = staffname;

      updatedItem.reportedbystaffid = reportedbystaffid;
      updatedItem.reportedbystaffname = reportedbystaffname;
      updatedItem.comments = updatedItem.comments.replace(/<[^>]*>/g, '')

      delete updatedItem.reportedby;
      // delete updatedItem.comments;
      return updatedItem;
    });

    // console.log(updatedItem)
    // return false;

    const keyMapping = {
      area: "Area",
      // comments: 'Comment',
      createdTime: "Created Time",
      destination: "Destination",
      flightno: "Flight No",
      recordtype: "Record Type",
      reportedbystaffid: "Reported by staff ID",
      reportedbystaffname: "Reported by staff Name",
      staffid: "Staff Id",
      staffname: "Staff Name",
      comments: "Comments",
      createdDate: "Created Date",
    };

    const separator = ",";
    const keys = Object.keys(newspmsRecord[0]);

    const formattedKeys = keys.map((key) => keyMapping[key]);

    const csvContent = [
      formattedKeys.join(separator),
      ...newspmsRecord.map((item) =>
        keys.map((key) => item[key]).join(separator)
      ),
    ].join("\n");

    const blob = new Blob([csvContent], { type: "text/csv" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "data.csv";
    a.click();
    URL.revokeObjectURL(url);
    setTimeout(() => {
      a.remove();
    }, 1000);
  };

  const updateRECORDSPMS = (key, value, id) => {

    // updateRECORDSPMS("area", e.target.value, staff._id);
    // 
    console.clear()
    console.log(spmsRecord)
    console.log(key, value, id)

    setSpmsRecord(prevRecords => {
      const updatedRecords = prevRecords.map(record => {
        console.log(record)
        if (record._id === id) {
          console.log("yes .,,,,,")
          return { ...record, [key]: value };
        }
        return record;
      });
      return updatedRecords;
    });

    return false;


    // Feature [23/05/2024] --->?/

    const data = {
      key,
      value,
      id,
    };
    fetch(`${Utils["domains"]["development"]}/spms/updaterecordspms`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json", // Specify that you're sending JSON data
      },
      body: JSON.stringify(data), // Set the JSON data as the request body
    }).then((e) => {
      toast("Details updated successfully!", { autoClose: 2000 });
      return true
    });
  };

  return (
    <div className="w-full">
      <Navbar title={"Staff List"} showarrow={false} />
      {loadingbar && <LoadingBar />}
      <InputGroup

        showCharts={showCharts}
        setShowCharts={setShowCharts}
        staffid={staffid}
        area={area}
        destination={destination}
        flightno={flightno}
        recordtype={recordtype}
        reportedby={reportedby}
        comments={comments}
        HandleExportAsExcel={HandleExportAsExcel}
        expendrecordtypemultiplesel={expendrecordtypemultiplesel}
        setexpendrecordtypemultiplesel={setexpendrecordtypemultiplesel}
        enddate={enddate}
        setEndDate={setEndDate}
        startdate={startdate}
        setStartDate={setStartDate}
        tempreportedby={tempreportedby}
        setisPositive={setisPositive}
        isPositive={isPositive}
        isApproved={isApproved} setisApproved={setisApproved}
        settempreportedby={settempreportedby}
        tempstaffid={tempstaffid}
        settempstaffid={settempstaffid}
        stafffetchlimit={stafffetchlimit}
        setstafffetchlimit={setstafffetchlimit}
        setstaffid={setstaffid}
        setarea={setarea}
        setdestination={setdestination}
        setflightno={setflightno}
        setrecordtype={setrecordtype}
        setreportedby={setreportedby}
        setcomments={setcomments}
        setApplyFilter={setApplyFilter}
        setLoadingBar={setLoadingBar}
        stafflist={stafflist}
      />
      <InputGroup2
        dateofincidentstart={dateofincidentstart} setdateofincidentstart={setdateofincidentstart}
        dateofincidentend={dateofincidentend} setdateofincidentend={setdateofincidentend}

        setEndDate={setEndDate} setStartDate={setStartDate} enddate={enddate} startdate={startdate} setApplyFilter={setApplyFilter} setLoadingBar={setLoadingBar} />
      <div class="relative rounded-xl overflow-auto w-full">
        <div className="bg-white p-2 text-lg mt-5">
          Total Filtered Records: {spmsRecord.length} - 3292
        </div>
        <div class="shadow-sm overflow-x-scroll w-full  lg:overflow-x-scroll my-8">
          {spmsRecordBreakdown.length < 1 && <p className="bg-white p-2 rounded">No Record Found!</p>}
          {showCharts && !spmsRecordBreakdown.length <= 0 && (
            <StaffBreakDownTable
              setLoadingBar={setLoadingBar}
              setApplyFilter={setApplyFilter}
              loadingbar={loadingbar}
              spmsRecordBreakdown={spmsRecordBreakdown}
              setstaffid={setstaffid}
              settempstaffid={settempstaffid}
            />
          )}{" "}
        </div>{" "}
      </div>

      <div class="relative rounded-xl overflow-auto w-full">
        <div class="shadow-sm overflow-x-scroll w-full  lg:overflow-x-scroll my-8">
          {!spmsRecord.length <= 0 && (
            <StaffTable
              adminLevel={adminLevel}
              loadingbar={loadingbar}
              spmsRecord={spmsRecord}
              updateRECORDSPMS={updateRECORDSPMS}
              selectedRecord={selectedRecord} setSelectedRecord={setSelectedRecord}
              showEditModel={showEditModel} setshowEditModel={setshowEditModel}
              showCommentModel={showCommentModel} setshowCommentModel={setshowCommentModel}
              selectedComment={selectedComment} setSelectedComment={setSelectedComment}
            />
          )}{" "}
        </div>{" "}
      </div>
      {/* ['SUPER-ADMIN', 'ADM'].includes(adminLevel) */}
      {['SUPER-ADMIN', 'ADM'].includes(adminLevel) && showEditModel && <EditModel setApplyFilter={setApplyFilter} updateRECORDSPMS={updateRECORDSPMS} showEditModel={showEditModel} setshowEditModel={setshowEditModel} attachments={attachments} setAttachments={setAttachments} selectedRecord={selectedRecord} />}
      {['SUPER-ADMIN', 'ADM'].includes(adminLevel) && showCommentModel && <CommentModel showCommentModel={showCommentModel} setshowCommentModel={setshowCommentModel} selectedComment={selectedComment} setSelectedComment={setSelectedComment}/>}
    </div>
  );
};

const StaffBreakDownTable = ({
  setLoadingBar,
  setApplyFilter,
  loadingbar,
  spmsRecordBreakdown,
  settempstaffid,
  setstaffid,
}) => {
  return (
    <div className="grid gap-5 grid-cols-3">
      {/* {spmsRecordBreakdown.length < 1 && <p>No Record Found!</p>} */}
      {/* {console.log(spmsRecordBreakdown)} */}
      {spmsRecordBreakdown.map((staff, index) => {
        return (
          <TableBreakDownRow
            setstaffid={setstaffid}
            staff={staff}
            key={index}
            setLoadingBar={setLoadingBar}
            setApplyFilter={setApplyFilter}
            settempstaffid={settempstaffid}
          />
        );
      })}
    </div>
  );
};


const STAFFBDCHARTMeter = ({ convertedobject }) => {
  return (
    <div className="mt-5 overflow-hidden rounded">
      <Chart
        chartType="Gauge"
        width="100%"
        // height="400px"
        data={convertedobject}
        options={{
          // width: 400,
          // height: 400,
          redFrom: 0,
          redTo: 100,
        }}
      />
    </div>
  );
};

const STAFFBDCHARTMeter2 = ({ convertedobject }) => {
  return (
    <div className="mt-5 overflow-hidden rounded">
      <Chart
        chartType="Gauge"
        width="100%"
        // height="400px"
        data={convertedobject}
        options={{
          // width: 400,
          // height: 400,
          greenFrom: 0,
          greenTo: 100,
        }}
      />
    </div>
  );
};

const TableBreakDownRow = ({
  staff,
  setLoadingBar,
  setApplyFilter,
  setstaffid,
  settempstaffid,
}) => {
  let positiveRecords = staff.totalPositiveRecords;
  let negativeRecords = staff.totalNegativeRecords;
  let totalRecords = staff.totalRecords;

  let positivePer = (positiveRecords / totalRecords) * 100;
  let negativePer = (negativeRecords / totalRecords) * 100;


  const recordTypeCountsHTML = Object.keys(staff.recordTypeCounts)
    .map(
      (recordType) =>
        `<p>${recordType.toUpperCase()}: ${staff.recordTypeCounts[recordType]
        }</p>`
    )
    .join("");

  const convertedobject = Object.entries(staff.recordTypeCounts).map(
    ([key, value]) => [`${value} - ` + key, value]
  );

  return (
    <div className="rounded-lg border bg-white border-stone-300 p-5 overflow-hidden">
      <Badge percentage={Math.round(positivePer)} />
      <div className="flex justify-between items-center">
        <div>
          <div>
            {staff.staffName} - {staff.staffIDAirport}
          </div>
          <div className="text-a-blue font-bold text-sm">
            Total Records: {staff.totalRecords}
          </div>
          <p className="text-green-700 text-sm mt-2">
            Positive: {positiveRecords} - {Math.round(positivePer)}%  </p>
          <p className="text-red-700 text-sm">Negative: {negativeRecords} - {Math.round(negativePer)}%</p>

        </div>
        <div>
          <button
            onClick={() => {
              setstaffid(staff._id);
              settempstaffid(staff.staffName);
              setApplyFilter(true);

              setLoadingBar(true);
            }}
            className="  bg-gradient-to-r from-a-pink to-a-blue hover:to-a-pink hover:from-a-blue px-12 h-10 rounded-lg text-white uppercase text-xs"
          >
            View
          </button>
        </div>
      </div>

      <STAFFBDCHART convertedobject={convertedobject} />
      <div className="bg-red-700 h-4 w-full">
        <div className={`bg-green-700 h-4`} style={{ width: positivePer.toString() + "%" }}></div>
      </div>
      <div className="flex items-center gap-5">
        <div className="w-1/2">
          <STAFFBDCHARTMeter2 convertedobject={[
            ["Label", "Value"],
            ["😇", Math.round(positivePer)],
            // ["CPU", getRandomNumber()],
            // ["Network", getRandomNumber()],
          ]} />
        </div>
        <div className="w-1/2">
          <STAFFBDCHARTMeter convertedobject={[
            ["Label", "Value"],
            ["😒", Math.round(negativePer)],
            // ["CPU", getRandomNumber()],
            // ["Network", getRandomNumber()],
          ]} />
        </div>

      </div>
    </div>
  );
};

const StaffTable = ({ adminLevel, loadingbar, spmsRecord, updateRECORDSPMS, showEditModel, setshowEditModel, selectedRecord, setSelectedRecord, showCommentModel, setshowCommentModel, selectedComment, setSelectedComment }) => {
  return (
    <table class="border-collapse  w-full  text-xs rounded-lg overflow-x-scroll lg:overflow-x-scroll mt-5">
      <thead className="bg-gradient-to-r from-a-pink to-a-blue text-white">
        <tr>
          <td class="border-b border-r border-slate-100 dark:border-slate-700 p-4 pl-8  text-white">
            Actions
          </td>
          <td class="border-b border-r border-slate-100 dark:border-slate-700 p-4 pl-8  text-white">
            Sno
          </td>
          <td class="border-b border-r border-slate-100 dark:border-slate-700 p-4 pl-8  text-white">
            Staff Id
          </td>
          <td class="border-b border-r border-slate-100 dark:border-slate-700 p-4  text-white">
            Staff Name
          </td>
          <td class="border-b border-r border-slate-100 dark:border-slate-700 p-4 pr-8  text-white">
            Reported By
          </td>

          <td class="border-b border-r border-slate-100 dark:border-slate-700 p-4 pr-8  text-white">
            DOI/R
          </td>
          <td class="border-b border-r border-slate-100 dark:border-slate-700 p-4 pr-8  text-white">
            Report Type
          </td>
          <td class="border-b border-r border-slate-100 dark:border-slate-700 p-4 pr-8  text-white">
            Positivity Status
          </td>


          <td class="border-b border-r border-slate-100 dark:border-slate-700 p-4 pr-8  text-white">
            Comments
          </td>
          <td class="border-b border-r border-slate-100 dark:border-slate-700 p-4 pr-8  text-white">
            Date
          </td>
          <td class="border-b border-r border-slate-100 dark:border-slate-700 p-4 pr-8  text-white">
            Attachments
          </td>
          <td class="border-b border-r border-slate-100 dark:border-slate-700 p-4 pr-8  text-white">
            Meeting Conclusion
          </td>
          <td class="border-b border-r border-slate-100 dark:border-slate-700 p-4 pr-8  text-white">
            Meeting With
          </td>
          <td class="border-b border-r border-slate-100 dark:border-slate-700 p-4 pr-8  text-white">
            Meeting Date
          </td>
          <td class="border-b border-r border-slate-100 dark:border-slate-700 p-4 pr-8  text-white">
            RID
          </td>

          <td class="border-b border-r border-slate-100 dark:border-slate-700 p-4  text-white">
            Area
          </td>
          <td class="border-b border-r border-slate-100 dark:border-slate-700 p-4  text-white">
            Destination
          </td>
          <td class="border-b border-r border-slate-100 dark:border-slate-700 p-4 pr-8  text-white">
            Flight no
          </td>

          <td class="border-b border-r border-slate-100 dark:border-slate-700 p-4 pr-8  text-white">
            HideFromProfile
          </td>
          <td class="border-b border-r border-slate-100 dark:border-slate-700 p-4 pr-8  text-white">
            Approved Status
          </td>
          <td class="border-b border-r border-slate-100 dark:border-slate-700 p-4 pr-8  text-white">
            Approved Action
          </td>

        </tr>
      </thead>
      <tbody class="bg-a-gray text-white">
        {spmsRecord.map((staff, index) => {
          return (
            <TableRow
              adminLevel={adminLevel}
              staff={staff}
              key={index}
              updateRECORDSPMS={updateRECORDSPMS}
              selectedRecord={selectedRecord} setSelectedRecord={setSelectedRecord}
              ind={index}
              showEditModel={showEditModel} setshowEditModel={setshowEditModel}
              showCommentModel={showCommentModel} setshowCommentModel={setshowCommentModel}
              selectedComment={selectedComment} setSelectedComment={setSelectedComment}
            />
          );
        })}
      </tbody>
    </table>
  );
};

const Badge = ({ percentage }) => {
  let badgeTitle;

  if (percentage <= 20) {
    badgeTitle = 'Level 1 😔';
  }

  else if (percentage <= 40) {

    badgeTitle = 'Level 2 🫤';
  }
  else if (percentage <= 60) {

    badgeTitle = 'Level 3 😊';
  }
  else if (percentage <= 80) {
    badgeTitle = 'Level 4 🤩';
  }

  else if (percentage <= 100) {

    badgeTitle = 'Level 5 🚀';
  }





  return (
    <div className="w-full h-10 flex items-center justify-center mb-2 bg-a-blue">
      <div className="w-[180px] rounded-full h-10 flex items-center justify-center bg-white  text-lg font-bold text-black uppercase">

        {badgeTitle}
      </div>
    </div>
  )
}

// Input Group
const InputGroup = ({
  stafflist,

  setShowCharts,
  showCharts,

  staffid,
  area,
  destination,
  flightno,
  isPositive,

  setisPositive,
  isApproved, setisApproved,
  recordtype,
  reportedby,
  comments,

  expendrecordtypemultiplesel,
  setexpendrecordtypemultiplesel,

  stafffetchlimit,
  setstafffetchlimit,

  setstaffid,
  setarea,
  setdestination,
  setflightno,
  setrecordtype,
  setreportedby,
  setcomments,
  HandleExportAsExcel,

  setApplyFilter,
  setLoadingBar,
  enddate,
  setEndDate,
  startdate,
  setStartDate,
  tempreportedby,
  settempreportedby,
  tempstaffid,
  settempstaffid,
}) => {
  const handleApplyFilter = () => {
    setApplyFilter(true);
    setLoadingBar(true);
  };
  return (
    <div className="bg-a-gray rounded-lg flex flex-wrap  gap-3 items-start py-5 px-5 w-full   h-fit">
      <div>
        <p className="text-xs mb-1 text-white" > Start Date </p>

        <input
          onChange={(e) => {
            setStartDate(e.target.value);
          }}
          type="date"
          value={startdate}
          className="h-10 rounded-lg px-3"
          placeholder="Category"
        />
      </div>

      <div>
        <p className="text-xs mb-1 text-white" > End Date </p>

        <input
          onChange={(e) => {
            setEndDate(e.target.value);
          }}
          type="date"
          value={enddate}
          className="h-10 rounded-lg px-3"
          placeholder="Category"
        />

      </div>
      <div>
        <p className="text-xs mb-1 text-white" > Select Staff  </p>

        <input
          type="search"
          className="h-10 rounded-lg px-3 "
          placeholder={stafflist.length == 0 ? "Loading..." : "Staff"}
          value={tempstaffid}
          onChange={(e) => {
            let extractstaffid = e.target.value.split(" - ")[3];
            let extracttempstaffid = e.target.value.split(" - ")[1];
            if (e.target.value === "") {
              extractstaffid = "";
              extracttempstaffid = "";
            }
            settempstaffid(extracttempstaffid);
            setstaffid(extractstaffid);
          }}
          list="stafflist"
        />

        <datalist id="stafflist">
          {stafflist.map((staff, index) => {
            return (
              <option
                value={
                  staff.staffid +
                  " - " +
                  staff.staffname +
                  " - " +
                  staff.staffcategory +
                  " - " +
                  staff._id
                }
              />
            );
          })}
        </datalist>
      </div>
      <div>
        <p className="text-xs mb-1 text-white" >  Select Area</p>


        <input
          onChange={(e) => {
            setarea(e.target.value);
          }}
          type="text"
          value={area}
          className="h-10 rounded-lg px-3 "
          placeholder="Area"
        />
      </div>

      <div>
        <p className="text-xs mb-1 text-white" > Destination  </p>

        <input
          onChange={(e) => {
            setdestination(e.target.value);
          }}
          type="text"
          value={destination}
          className="h-10 rounded-lg px-3 "
          placeholder="Destination"
        />
      </div>

      <div>
        <p className="text-xs mb-1 text-white" > Flight No </p>


        <input
          onChange={(e) => {
            setflightno(e.target.value);
          }}
          type="text"
          value={flightno}
          className="h-10 rounded-lg px-3 "
          placeholder="Flight No"
        />
      </div>

      <div>
        <p className="text-xs mb-1 text-white" > Record Category </p>
        <div className="flex ">
          <select
            // onChange={(e) => {
            //   setrecordtype(e.target.value);
            // }}
            onChange={(e) => {
              const selectedValues = Array.from(
                e.target.selectedOptions,
                (option) => option.value
              );

              setrecordtype(selectedValues);
            }}
            type="text"
            multiple
            value={recordtype}
            className={
              "rounded-lg px-3 rounded-tr-none rounded-br-none outline-none " +
              (expendrecordtypemultiplesel ? "h-28" : "h-10")
            }
            placeholder="recordtype"
          >
            <option className="appearance-none h-10 flex items-center" value="">
              All
            </option>
            <option
              className="appearance-none h-10 flex items-center"
              value="Communication"
            >
              Communication
            </option>
            <option
              className="appearance-none h-10 flex items-center"
              value="Grooming Feedback"
            >
              Grooming Feedback
            </option>
            <option
              className="appearance-none h-10 flex items-center"
              value="Memo"
            >
              Memo
            </option>
            <option className="appearance-none h-10 flex items-center" value="Inad">Inad</option>

            <option
              className="appearance-none h-10 flex items-center"
              value="Performance"
            >
              Performance
            </option>
            <option
              className="appearance-none h-10 flex items-center"
              value="Customer Service Feedback"
            >
              Customer Service Feedback
            </option>
            <option
              className="appearance-none h-10 flex items-center"
              value="ROD"
            >
              ROD
            </option>
            <option
              className="appearance-none h-10 flex items-center"
              value="ABSENCE + WARNING"
            >
              ABSENCE / WARNING
            </option>
            <option
              className="appearance-none h-10 flex items-center"
              value="Annual Leave + ROD"
            >
              Annual Leave / ROD
            </option>
            <option
              className="appearance-none h-10 flex items-center"
              value="ROA"
            >
              ROA
            </option>
            <option
              className="appearance-none h-10 flex items-center"
              value="Airlines Appreciation"
            >
              Airlines Appreciation
            </option>
            <option
              className="appearance-none h-10 flex items-center"
              value="Airline Complaints"
            >
              Airline Complaints
            </option>
            <option
              className="appearance-none h-10 flex items-center"
              value="Absence"
            >
              Absence
            </option>
            <option
              className="appearance-none h-10 flex items-center"
              value="Interview Note"
            >
              Interview Note
            </option>
            <option
              className="appearance-none h-10 flex items-center"
              value="Punctuality"
            >
              Punctuality
            </option>
            <option
              className="appearance-none h-10 flex items-center"
              value="Sick Leave"
            >
              Sick Leave
            </option>

            <option
              className="appearance-none h-10 flex items-center"
              value="Verbal Warning"
            >
              Verbal Warning
            </option>
            <option
              className="appearance-none h-10 flex items-center"
              value="Written Warning"
            >
              Written Warning
            </option>
            <option
              className="appearance-none h-10 flex items-center"
              value="Final Warning"
            >
              Final Warning
            </option>
            <option
              className="appearance-none h-10 flex items-center"
              value="Extended Final"
            >
              Extended Final
            </option>
            <option
              className="appearance-none h-10 flex items-center"
              value="meeting"
            >
              Meeting
            </option>
            <option className="appearance-none h-10 flex items-center" value="Check in discepenates">Check in performance</option>
            <option className="appearance-none h-10 flex items-center" value="Boarding discepenates">Boarding performance</option>
            <option className="appearance-none h-10 flex items-center" value="Intercepted Cases">Intercepted Case</option>
            <option className="appearance-none h-10 flex items-center" value="Customer Care Complaint">
              Customer Care Complaint
            </option>
            <option className="appearance-none h-10 flex items-center" value="Remarkable Performance">Remarkable Performance</option>
            <option className="appearance-none h-10 flex items-center" value="IQSMS">IQSMS</option>
            <option className="appearance-none h-10 flex items-center" value="Mystery Shopper Record">Mystery Shopper Record</option>
            <option className="appearance-none h-10 flex items-center" value="Sbp no compliance">SBP non-compliance</option>
            <option className="appearance-none h-10 flex items-center" value="Checkin & Gates Management">Checkin & Gates Management</option>

            <option
              className="appearance-none h-10 flex items-center"
              value="Other"
            >
              Other
            </option>
          </select>
        </div>
        <button
          onClick={() =>
            setexpendrecordtypemultiplesel(!expendrecordtypemultiplesel)
          }
          className="  bg-white px-2 rounded-tl-none cursor-pointer rounded-bl-none h-10 rounded-lg text-a-gray uppercase text-xs"
        >
          <BsThreeDotsVertical />
        </button>
      </div>

      <div>
        <p className="text-xs mb-1 text-white" > Is Positive? </p>

        <select
          onChange={(e) => {
            setisPositive(e.target.value);
          }}
          value={isPositive}
          className="h-10 rounded-lg px-3"
          placeholder="limit"
        >
          <option value="both">Both</option>
          <option value="true">Positive</option>
          <option value="false">Negative</option>

        </select>

      </div>

      <div>
        <p className="text-xs mb-1 text-white" > Is Approved? </p>

        <select
          onChange={(e) => {
            setisApproved(e.target.value);
          }}
          value={isPositive}
          className="h-10 rounded-lg px-3"
          placeholder="limit"
        >
          <option value="both">Both</option>
          <option value="false">Not Approved</option>
          <option value="true">Approved</option>

        </select>

      </div>

      <div>
        <p className="text-xs mb-1 text-white" > Reported By  </p>

        <input
          onChange={(e) => {
            //   setreportedby(e.target.value);

            let extractstaffid = e.target.value.split(" - ")[3];
            let extracttempstaffid = e.target.value.split(" - ")[1];
            if (e.target.value === "") {
              extractstaffid = "";
              extracttempstaffid = "";
            }
            // settempstaffid(extracttempstaffid);
            setreportedby(extractstaffid);
            settempreportedby(extracttempstaffid);
          }}
          type="search"
          value={tempreportedby}
          className="h-10 rounded-lg px-3 "
          placeholder={stafflist.length == 0 ? "Loading..." : "Reported By"}
          list="stafflist"
        />
      </div>

      <div>
        <p className="text-xs mb-1 text-white" >  Limit </p>

        <select
          onChange={(e) => {
            setstafffetchlimit(e.target.value);
          }}
          value={stafffetchlimit}
          className="h-10 rounded-lg px-3"
          placeholder="limit"
        >
          <option value="1">1</option>
          <option value="5">5</option>
          <option selected value="10">
            10
          </option>
          <option value="20">20</option>
          <option value="30">30</option>
          <option value="">All</option>

        </select>
      </div>
      <button
        onClick={handleApplyFilter}
        className="  bg-gradient-to-r from-a-pink to-a-blue hover:to-a-pink hover:from-a-blue px-12 h-10 rounded-lg text-white uppercase text-xs"
      >
        Apply
      </button>
      <button
        onClick={HandleExportAsExcel}
        className="flex items-center gap-2  bg-green-600 px-12 h-10 rounded-lg text-white uppercase text-xs"
      >
        <PiMicrosoftExcelLogoFill size="22px" />
        Export Excel
      </button>
      <button
        onClick={() => {
          setstaffid("")
          settempstaffid("")
          setarea("")
          setdestination("")
          setflightno("")
          setrecordtype("")
          setreportedby("")
          settempreportedby("")
          setcomments("")
          handleApplyFilter()
        }}
        className="  bg-red-500 from-a-pink to-a-blue hover:to-a-pink hover:from-a-blue px-12 h-10 rounded-lg text-white uppercase text-xs"
      >
        Clear Filter
      </button>


      <div>
        {/* <p className="text-xs mb-1 text-white" > Start Date </p> */}

        <button
          onClick={() => setShowCharts(true)}
          className="  bg-gradient-to-r from-a-pink to-a-blue hover:to-a-pink hover:from-a-blue px-12 h-10 rounded-lg text-white uppercase text-xs"
        >
          Graph View
        </button>
      </div>

      <div>
        {/* <p className="text-xs mb-1 text-white" > End Date </p> */}

        <button
          onClick={() => setShowCharts(false)}

          className="  bg-gradient-to-r from-a-pink to-a-blue hover:to-a-pink hover:from-a-blue px-12 h-10 rounded-lg text-white uppercase text-xs"
        >
          Static View
        </button>
      </div>

    </div>
  );
};

const InputGroup2 = ({



  dateofincidentstart, setdateofincidentstart,
  dateofincidentend, setdateofincidentend,

  setApplyFilter,
  setLoadingBar,
  enddate,
  setEndDate,
  startdate,
  setStartDate,

}) => {
  const handleApplyFilter = () => {
    setApplyFilter(true);
    setLoadingBar(true);
  };
  return (
    <div className=" bg-gradient-to-r from-a-pink to-a-blue mt-5 rounded-lg flex flex-wrap  gap-3 items-end py-5 px-5 w-full   h-fit">

      <div className="flex gap-3 bg-a-gray p-3 rounded-lg">

        <div className="text-white font-bold flex gap-2 items-center">DOI/R <MdInfoOutline data-tooltip-id="my-tooltip" data-tooltip-content='Date Of Incident / Records' data-tooltip-place="right" size={16} /></div>
        <Tooltip id="my-tooltip" />

        <div>
          {/* <p className="text-xs mb-1 text-white" > Start Date </p> */}

          <input
            onChange={(e) => {
              setdateofincidentstart(e.target.value);
            }}
            type="date"
            value={dateofincidentstart}
            className="h-10 rounded-lg px-3"
            placeholder="Category"
          />
        </div>

        <div>
          {/* <p className="text-xs mb-1 text-white" > End Date </p> */}

          <input
            onChange={(e) => {
              setdateofincidentend(e.target.value);
            }}
            type="date"
            value={dateofincidentend}
            className="h-10 rounded-lg px-3"
            placeholder="Category"
          />

        </div>

      </div>

      {/* {dateofincidentstart}
        {dateofincidentend} */}

      <div className="flex gap-3 bg-a-gray p-3 rounded-lg ">

        <div className="text-white font-bold flex gap-2 items-center">DORA <MdInfoOutline data-tooltip-id="my-tooltip" data-tooltip-content='Date Of Record Added' data-tooltip-place="right" size={16} /></div>
        <Tooltip id="my-tooltip" />

        <div>
          {/* <p className="text-xs mb-1 text-white" > Start Date </p> */}

          <input
            onChange={(e) => {
              setStartDate(e.target.value);
            }}
            type="date"
            value={startdate}
            className="h-10 rounded-lg px-3"
            placeholder="Category"
          />
        </div>

        <div>
          {/* <p className="text-xs mb-1 text-white" > End Date </p> */}

          <input
            onChange={(e) => {
              setEndDate(e.target.value);
            }}
            type="date"
            value={enddate}
            className="h-10 rounded-lg px-3"
            placeholder="Category"
          />

        </div>

      </div>





      <div>
        <p className="text-xs mb-1 text-transparent" > End Date </p>

        <button
          onClick={handleApplyFilter}
          className="  bg-a-gray px-12 h-10 rounded-lg text-white uppercase text-xs"
        >
          Apply
        </button>
      </div>
      {/* <button
        onClick={HandleExportAsExcel}
        className="flex items-center gap-2  bg-green-600 px-12 h-10 rounded-lg text-white uppercase text-xs"
      >
        <PiMicrosoftExcelLogoFill size="22px" />
        Export Excel
      </button> */}
      {/* <button
        onClick={()=>{
          setstaffid("")
          settempstaffid("")
          setarea("")
          setdestination("")
          setflightno("")
          setrecordtype("")
          setreportedby("")
          settempreportedby("")
          setcomments("")
          handleApplyFilter()
        }}
        className="  bg-red-500 from-a-pink to-a-blue hover:to-a-pink hover:from-a-blue px-12 h-10 rounded-lg text-white uppercase text-xs"
      >
        Clear Filter
      </button> */}
    </div>
  );
};

const TableRow = ({ adminLevel, staff, updateRECORDSPMS, key, ind, showEditModel, setshowEditModel, selectedRecord, setSelectedRecord, showCommentModel, setshowCommentModel, selectedComment, setSelectedComment }) => {
  const renderFilePreview = (url, file) => {
    const fileExtension = file.newfilename.split(".").pop().toLowerCase();

    // Check the file extension and render the appropriate preview
    if (fileExtension === "pdf") {
      return <iframe src={url} width="300" height="200" />;
    } else if (["jpg", "jpeg", "png", "gif"].includes(fileExtension)) {
      return <img src={url} alt="Image Preview" />;
    } else if (["mp4", "webm"].includes(fileExtension)) {
      return (
        <video width="300" height="200" controls>
          <source src={url} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      );
    } else {
      return <p>Unsupported file type: {fileExtension}</p>;
    }
  };
  return (
    <tr key={key} className="bg-a-gray hover:bg-a-lgray">
      <td class="border-b border-r border-slate-100 dark:border-slate-700 p-4  text-white">
        <button
          onClick={() => {
            setshowEditModel(true)
            setSelectedRecord({
              "_id": staff._id,
              "staffname": staff.staffid["staffname"],
              "attachments": staff.attachments
            })


            // const [selectedRecord, setSelectedRecord] = useState({
            //   "_id": "",
            //   "staffname": "",
            //   "attachments": ""
            // });

          }}
          // onClick={handleAddSPMSRecord}
          className=" bg-[#555] px-4 py-2 rounded-lg text-white uppercase text-md border-2 border-a-gray
              shadow-md
              "
        >
          Files
        </button>
      </td>
      <td class="border-b border-r border-slate-100 dark:border-slate-700 p-4  text-white">
        {ind}
      </td>
      <td class="border-b border-r border-slate-100 dark:border-slate-700 p-4  text-white">
        {staff.staffid["staffid"]}
      </td>
      <td class="border-b border-r border-slate-100 dark:border-slate-700 p-4 pr-8  text-white">
        {staff.staffid["staffname"]}
      </td>
      <td class="border-b border-r border-slate-100 dark:border-slate-700 p-4 pr-8  text-white">
        {staff.reportedby && staff.reportedby.staffname}
      </td>

      <td class="border-b border-r w-[130px] border-slate-100 dark:border-slate-700 p-4 pr-8  text-white">
        {staff.dateofincident.split("T")[0]}
      </td>
      <td class="border-b border-r border-slate-100 dark:border-slate-700 p-4 pr-8 text-a-gray">
        {/* {["Sick Leave", "Absence"].includes(staff.recordtype) && (
          <select
            // onChange={(e) => {
            //   setStaffDesignation(e.target.value);
            // }}
            // value={designation}
            className="h-10 rounded-lg px-3"
            placeholder="Designation"
            // defaultValue={staff.recordtype}
            onChange={(e) => {
              updateRECORDSPMS("recordtype", e.target.value, staff._id);
            }}
          >
            <option value={staff.recordtype}>{staff.recordtype}</option>
            <option value="Annual Leave + ROD">Annual Leave + ROD</option>
            <option value="ABSENCE + WARNING">ABSENCE + WARNING</option>
          </select>
        )}{" "}
        <span className="text-white">{staff.recordtype}</span> */}
        <p className="text-white">{!['SUPER-ADMIN', 'ADM'].includes(adminLevel) && staff.recordtype}</p>
        <p className="text-white">{staff.isApproved && staff.recordtype}</p>

        {
          ['SUPER-ADMIN', 'ADM'].includes(adminLevel) &&
          !staff.isApproved &&
          <select
            type="text"
            value={staff.recordtype}
            className="h-10 rounded-lg px-3 w-[150px]"
            placeholder="recordtype"
            onChange={(e) => {
              updateRECORDSPMS("recordtype", e.target.value, staff._id)
            }
            }
          >
            <option value="">Select Category</option>
            <option value="Communication">Communication</option>
            <option value="Grooming Feedback">Grooming Feedback</option>
            <option value="Performance">Performance</option>
            <option value="Customer Service Feedback">Customer Service Feedback</option>
            <option value="Memo">Memo</option>
            <option value="ROD">ROD</option>
            <option value="ROA">ROA</option>
            <option value="Airlines Appreciation">Airlines Appreciation</option>
            <option value="Airline Complaints">Airline Complaints</option>
            <option value="Absence">Absence</option>
            <option value="Interview Note">Interview Note</option>
            <option value="Punctuality">Punctuality</option>
            <option value="Sick Leave">Sick Leave</option>
            <option value="ABSENCE + WARNING">ABSENCE + WARNING</option>
            <option value="Annual Leave + ROD">Annual Leave + ROD</option>

            <option value="Verbal Warning">Verbal Warning</option>
            <option value="Written Warning">Written Warning</option>
            <option value="Final Warning">Final Warning</option>
            <option value="Extended Final">Extended Final</option>

            <option value="Check in discepenates">Check in Performance</option>
            <option value="Boarding discepenates">Boarding Performance</option>
            <option value="Intercepted Cases">Intercepted Case</option>
            <option value="Customer Care Complaint">Customer Care Complaint</option>
            <option disabled value="Remarkable Performance">N/A - Remarkable Performance</option>
            <option value="IQSMS">IQSMS</option>
            <option value="Mystery Shopper Record">Mystery Shopper Record</option>
            <option value="Sbp no compliance">SBP non-compliance</option>
            <option value="Checkin & Gates Management">Checkin & Gates Management</option>

            <option value="Other">Other</option>
          </select>}
      </td>
      <td class="border-b border-r border-slate-100 dark:border-slate-700 p-4 pr-8   text-white ">
        {
          ['SUPER-ADMIN', 'ADM'].includes(adminLevel) &&
          !staff.isApproved &&
          <input
            onChange={(e) => {
              // console.log(e.target.v)
              updateRECORDSPMS("isPositive", e.target.checked, staff._id);
            }}
            type="checkbox"
            value={staff.isPositive}
            checked={staff.isPositive}
            className="rounded-lg manageassign-cb"
            placeholder="Destination"
          />}

        {staff.isPositive ? (
          <div className="flex gap-2">
            <p className="text-2xl">😊</p>
          </div>
        ) : (
          <div className="flex gap-2 ">
            {staff.recordtype === "Sick Leave" && (
              <p className="text-sm">Get well soon. Wishing you a speedy recovery.</p>
            )}
            {staff.recordtype !== "Sick Leave" && <p className="text-2xl">😔</p>}
          </div>
        )}
      </td>


      <td onClick={()=>{
        setshowCommentModel(true)
        setSelectedComment(staff.comments.replace(/<[^>]*>/g, ''))
      }} title={staff.comments.replace(/<[^>]*>/g, '')} class="border-b border-r border-slate-100 dark:border-slate-700 p-4 pr-8  text-white">
        {/* <div
          dangerouslySetInnerHTML={{
            __html: staff.comments && staff.comments.substring(0, 1000),
          }}
        />
        <input type="button" value="value" /> */}
        {!['SUPER-ADMIN', 'ADM'].includes(adminLevel) && staff.comments.replace(/<[^>]*>/g, '').substring(0, 200) + "..."}
        {['SUPER-ADMIN', 'ADM'].includes(adminLevel) && staff.isApproved && staff.comments.replace(/<[^>]*>/g, '').substring(0, 200) + "..."}
        {
          ['SUPER-ADMIN', 'ADM'].includes(adminLevel) &&
          !staff.isApproved &&
          <textarea
            value={staff.comments ? staff.comments.replace(/<[^>]*>/g, '') : ''}
            type="text"
            onKeyUp={(e) => {
              if (e.key === "Enter") {
                updateRECORDSPMS("comments", e.target.value, staff._id);
              }
            }}
            className="text-black mt-2 p-2 outline-none rounded-md w-[200px]"
          >
            {staff.comments ? staff.comments.replace(/<[^>]*>/g, '') : ''}
          </textarea>}
      </td>
      <td class="border-b border-r border-slate-100 dark:border-slate-700 p-4 pr-8  text-white">
        {staff.createdDate}
      </td>
      <td class="border-b border-r border-slate-100 dark:border-slate-700 p-4   text-a-gray">
        <div className="flex gap-1 bg-white px-3 py-3 rounded-md ">
          {staff.attachments.length <= 0 && <p className="">No Attachment!</p>}
          {staff.attachments.map((file, index) => {
            return (
              <>
                <a
                  className="p-2  bg-gray-200 rounded"
                  onMouseOver={() => {
                    let element = document.getElementById(
                      `popup-` + ind + index
                    );
                    element.classList.remove("hidden");
                  }}
                  onMouseLeave={() => {
                    let element = document.getElementById(
                      `popup-` + ind + index
                    );
                    element.classList.add("hidden");
                  }}
                  target="_blank"
                  href={`${Utils["domains"]["development"]}/uploads/${file.newfilename}`}
                  download={`${Utils["domains"]["development"]}/uploads/${file.newfilename}`}
                >
                  <GrDocumentDownload
                    size="20px"
                    className="inline-block text-white relative "
                  />
                </a>
                <div
                  id={`popup-` + ind + index}
                  className="hidden bg-gray-200 h-[240px] w-[330px] rounded-md absolute right-[360px] shadow-md overflow-hidden p-3"
                >
                  {renderFilePreview(
                    `${Utils["domains"]["development"]}/uploads/${file.newfilename}`,
                    file
                  )}
                </div>
              </>
            );
          })}
          {/* <ReactTooltip id={`tooltip-${index}`}> */}
        </div>
      </td>
      <td class="border-b border-r border-slate-100 dark:border-slate-700 p-4 pr-8  text-white">
        {staff.meetingconclusion}
      </td>
      <td class="border-b border-r border-slate-100 dark:border-slate-700 p-4 pr-8  text-white">
        {/* {console.log(staff.meetingwith)} */}
        {/* {staff.meetingwith.staffname} */}
        {staff.meetingwith && staff.meetingwith.staffname}
      </td>
      <td class="border-b border-r border-slate-100 dark:border-slate-700 p-4 pr-8  text-white">
        {staff.meetingdate}
      </td>
      <td class="border-b border-r border-slate-100 dark:border-slate-700 p-4 pr-8  text-white">
        {staff._id}
      </td>

      <td class="border-b border-r border-slate-100 dark:border-slate-700 p-4 pr-8  text-white">
        {!['SUPER-ADMIN', 'ADM'].includes(adminLevel) && staff.area}
        {staff.isApproved && staff.area}
        {
          ['SUPER-ADMIN', 'ADM'].includes(adminLevel) &&
          !staff.isApproved &&
          <input type="text" onKeyUp={(e) => {
            if (e.key === "Enter") {
              updateRECORDSPMS("area", e.target.value, staff._id);
            }
          }} value={staff.area} className="text-black mt-2 p-2 outline-none rounded-md" />}
      </td>
      <td class="border-b border-r border-slate-100 dark:border-slate-700 p-4 pr-8  text-white">
        {!['SUPER-ADMIN', 'ADM'].includes(adminLevel) && staff.destination}
        {staff.isApproved && staff.destination}
        {
          ['SUPER-ADMIN', 'ADM'].includes(adminLevel) &&
          !staff.isApproved &&
          <input type="text" onKeyUp={(e) => {
            if (e.key === "Enter") {
              updateRECORDSPMS("destination", e.target.value, staff._id);
            }
          }} value={staff.destination} className="text-black mt-2 p-2 outline-none rounded-md" />}
      </td>
      <td class="border-b border-r border-slate-100 dark:border-slate-700 p-4 pr-8  text-white">
        {!['SUPER-ADMIN', 'ADM'].includes(adminLevel) && staff.flightno}
        {staff.isApproved && staff.flightno}
        {
          ['SUPER-ADMIN', 'ADM'].includes(adminLevel) &&
          !staff.isApproved &&
          <input type="text" onKeyUp={(e) => {
            if (e.key === "Enter") {
              updateRECORDSPMS("flightno", e.target.value, staff._id);
            }
          }} value={staff.flightno} className="text-black mt-2 p-2 outline-none rounded-md" />}
      </td>

      <td class="border-b border-r border-slate-100 dark:border-slate-700 p-4 pr-8  text-white">
        <p className="font-black text-xl">
          {staff.HideFromProfile ? <div>Yes</div> : <div>No</div>}
        </p>
      </td>
      <td class="border-b border-r border-slate-100 dark:border-slate-700 p-4 pr-8  text-white">
        <p className="font-black text-xl">
          {staff.isApproved ? <span className="text-green-600"> <FaCheckCircle /> </span> : <span className="text-red-600"><MdCancel /></span>}
        </p>
      </td>
      <td class="border-b border-r border-slate-100 dark:border-slate-700 p-4 pr-8  text-white">
        <p className="text-xs text-white">Click to</p>
        {['SUPER-ADMIN', 'ADM'].includes(adminLevel) && <button
          className="mt-2 px-2 w-[120px] text-sm h-8 rounded-lg text-white uppercase text-xs bg-[#666]"
          // style={{
          //   background: staff.isApproved ? 'red' : 'green'
          // }}
          onClick={(e) => {
            let statusss = staff.isApproved ? "approved" : "not approved";

            let con = window.confirm(`Do you wish to ${statusss} this record`);
            if (con) {
              updateRECORDSPMS("isApproved", !staff.isApproved, staff._id);
            }
          }}
        >
          {staff.isApproved ? 'Not Approved' : 'Approved'}
        </button>}

      </td>

    </tr>
  );
};

const STAFFBDCHART = ({ convertedobject }) => {
  return (
    <div className="">
      <Chart
        chartType="PieChart"
        data={[["Record Type", "Ratio"], ...convertedobject]}
        width={"400px"}
        height={"400px"}
      />
    </div>
  );
};

export default ViewRecord;