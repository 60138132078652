import React, { useEffect, useState } from "react";
import Navbar from "../Global/Navbar";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import LoadingBar from "../Global/LoadingBar";
import { PiMicrosoftExcelLogoFill } from "react-icons/pi";
import { AiFillDelete } from "react-icons/ai"
import { TiTick } from "react-icons/ti"
import Utils from '../../utils.json';

import { Chart } from "react-google-charts";

const Badge = ({ percentage }) => {
  let badgeTitle;

  // alert(percentage)

  if (percentage <= 20) {
    badgeTitle = 'Level 1 😔';
  }

  else if (percentage <= 40) {

    badgeTitle = 'Level 2 🫤';
  }
  else if (percentage <= 60) {

    badgeTitle = 'Level 3 😊';
  }
  else if (percentage <= 80) {
    badgeTitle = 'Level 4 🤩';
  }

  else if (percentage <= 100) {

    badgeTitle = 'Level 5 🚀';
  }

  else {
    badgeTitle = 'Level 5 🚀';

  }



  return (
  <div className="w-full h-12 bg-white rounded-md text-lg flex items-center justify-center">
        {badgeTitle}
   
    </div>
  )
}

const Logs = () => {
  const navigate = useNavigate();

  const [staffRecords, setStaffRecords] = useState([]);
  const [loadingbar, setLoadingBar] = useState(true);
  const [staffid, setstaffid] = useState(null);
  const [staff, setStaff] = useState(null);
  const [spmsRecord, setSpmsRecord] = useState([]);
  const [posNegRec, setPosNegRec] = useState({
    "positive": 0,
    "negative": 0,
    "total-rec": 0,
  });

  function posNeg(records) {
    let dict = {
      "positive": 0,
      "negative": 0,
      "total-rec": 0,
    }
    records.forEach(record => {
      if (record.isPositive) {
        dict.positive += 1;
      } else {
        dict.negative += 1;
      }
      dict["total-rec"] += 1;
    })
    return dict;
  }

  useEffect(() => {

  }, [posNegRec])

  useEffect(() => {
    let ls = localStorage.getItem('staff')
    if (!ls) {
      navigate('/login')
    }
    console.log()
    setstaffid(JSON.parse(ls)['_id'])
    setStaff(JSON.parse(ls))
    return;
  }, [])

  // Function to count occurrences of each record type and return as list of arrays
  function countRecordTypes(records) {
    const recordCounts = {};

    // Iterate through each record
    records.forEach(record => {
      const recordType = record.recordtype.toUpperCase(); // Convert to lowercase for consistency

      // If the record type already exists in the recordCounts object, increment its count
      if (recordCounts.hasOwnProperty(recordType)) {
        recordCounts[recordType]++;
      } else {
        // If it doesn't exist, initialize it with a count of 1
        recordCounts[recordType] = 1;
      }
    });

    // Convert the recordCounts object into a list of arrays
    const countList = Object.entries(recordCounts).map(([type, count]) => [`${count} - ${type}`, count]);

    return countList;
  }

  useEffect(() => {
    if (staffid != null) {
      fetch(`${Utils["domains"]["development"]}/spms/viewrecordbyfilter?staffid=${staffid}&singleprofile=true`, {
        method: "get",
        headers: {
          "Content-Type": "application/json", // Specify that you're sending JSON data
        },
        // body: JSON.stringify({}), // Set the JSON data as the request body
      })
        .then((e) => {
          return e.json();
        })
        .then((data) => {
          setTimeout(() => {
            setLoadingBar(false);
            setStaffRecords(countRecordTypes(data))
            setSpmsRecord(data)
            setPosNegRec(posNeg(data));
          }, 0);
        });
    }
  }, [staffid]);

  return (
    <div className="bg-a-gray w-full  p-5 rounded-lg">
      {/* <Navbar title={"Staff List"} showarrow={false} /> */}
      {loadingbar && <LoadingBar />}
      <div className="text-2xl text-white">My Records</div>

      {/* {spmsRecord.len <div>

      </div>} */}

      <Badge percentage={(posNegRec['positive'] / posNegRec['total-rec']) * 100}/>
      {/* {console.clear()}
      {console.log(posNegRec)}
      {console.log((posNegRec['positive'] / posNegRec['total-rec']) * 100)} */}

      {/* <div>{staffid} 's profile</div> */}
      <STAFFBDCHART convertedobject={staffRecords} />

      <div className="flex items-center gap-5">
        <div className="w-1/2">
          <STAFFBDCHARTMeter2 convertedobject={[
            ["Label", "Value"],
            ["😇", Math.round((posNegRec['positive'] / posNegRec['total-rec']) * 100)],
            // ["CPU", getRandomNumber()],
            // ["Network", getRandomNumber()],
          ]} />
        </div>
        <div className="w-1/2">
          <STAFFBDCHARTMeter convertedobject={[
            ["Label", "Value"],
            ["😒", Math.round((posNegRec['negative'] / posNegRec['total-rec']) * 100)],

            // ["CPU", getRandomNumber()],
            // ["Network", getRandomNumber()],
          ]} />
        </div>

      </div>
      <div class="relative rounded-xl overflow-auto w-full">
        <div class="shadow-sm overflow-x-scroll w-full  lg:overflow-x-scroll my-8">
          <StaffTable spmsRecord={spmsRecord} />
        </div></div>


    </div>
  );
};

const STAFFBDCHART = ({ convertedobject }) => {
  return (
    <div className="mt-5 overflow-hidden rounded">
      <Chart
        chartType="PieChart"
        data={[["Record Type", "Ratio"], ...convertedobject]}
        width={"100%"}
        height={"600px"}
      />

    </div>
  );
};




const STAFFBDCHARTMeter = ({ convertedobject }) => {
  return (
    <div className="mt-5 overflow-hidden rounded">
      <Chart
        chartType="Gauge"
        width="100%"
        // height="400px"
        data={convertedobject}
        options={{
          // width: 400,
          // height: 400,
          redFrom: 0,
          redTo: 100,
        }}
      />
    </div>
  );
};

const STAFFBDCHARTMeter2 = ({ convertedobject }) => {
  return (
    <div className="mt-5 overflow-hidden rounded">
      <Chart
        chartType="Gauge"
        width="100%"
        // height="400px"
        data={convertedobject}
        options={{
          // width: 400,
          // height: 400,
          greenFrom: 0,
          greenTo: 100,
        }}
      />
    </div>
  );
};


// 
// 
// 
const StaffTable = ({ loadingbar, spmsRecord, updateRECORDSPMS }) => {
  return (
    <table class="border-collapse  w-full  text-xs rounded-lg overflow-x-scroll lg:overflow-x-scroll mt-5">
      <thead className="bg-gradient-to-r from-a-pink to-a-blue text-white">
        <tr>

          <td class="border-b border-r border-slate-100 dark:border-slate-700 p-4  text-white">
            Area
          </td>
          <td class="border-b border-r border-slate-100 dark:border-slate-700 p-4  text-white">
            Destination
          </td>
          <td class="border-b border-r border-slate-100 dark:border-slate-700 p-4 pr-8  text-white">
            Flight no
          </td>
          <td class="border-b border-r border-slate-100 dark:border-slate-700 p-4 pr-8  text-white">
            DOI/R
          </td>
          <td class="border-b border-r border-slate-100 dark:border-slate-700 p-4 pr-8  text-white">
            Report Type
          </td>
          <td class="border-b border-r border-slate-100 dark:border-slate-700 p-4 pr-8  text-white">
            Positivity Status
          </td>

          <td class="border-b border-r border-slate-100 dark:border-slate-700 p-4 pr-8  text-white">
            Comments
          </td>
          <td class="border-b border-r border-slate-100 dark:border-slate-700 p-4 pr-8  text-white">
            Date
          </td>
          <td class="border-b border-r border-slate-100 dark:border-slate-700 p-4 pr-8  text-white">
            Attachments
          </td>

        </tr>
      </thead>
      <tbody class="bg-a-gray text-white">
        {spmsRecord.map((staff, index) => {
          return (
            <TableRow
              staff={staff}
              key={index}
              // updateRECORDSPMS={updateRECORDSPMS}s
              ind={index}
            />
          );
        })}
      </tbody>
    </table>
  );
};

const TableRow = ({ staff, updateRECORDSPMS, key, ind }) => {
  const renderFilePreview = (url, file) => {
    const fileExtension = file.newfilename.split(".").pop().toLowerCase();

    // Check the file extension and render the appropriate preview
    if (fileExtension === "pdf") {
      return <iframe src={url} width="300" height="200" />;
    } else if (["jpg", "jpeg", "png", "gif"].includes(fileExtension)) {
      return <img src={url} alt="Image Preview" />;
    } else if (["mp4", "webm"].includes(fileExtension)) {
      return (
        <video width="300" height="200" controls>
          <source src={url} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      );
    } else {
      return <p>Unsupported file type: {fileExtension}</p>;
    }
  };
  return (
    <tr key={key}>

      <td class="border-b border-r border-slate-100 dark:border-slate-700 p-4 pr-8  text-white">
        {staff.area}
      </td>
      <td class="border-b border-r border-slate-100 dark:border-slate-700 p-4 pr-8  text-white">
        {staff.destination}
      </td>
      <td class="border-b border-r border-slate-100 dark:border-slate-700 p-4 pr-8  text-white">
        {staff.flightno}
      </td>
      <td class="border-b border-r w-[130px] border-slate-100 dark:border-slate-700 p-4 pr-8  text-white">
        {staff.dateofincident.split("T")[0]}
      </td>
      <td class="border-b border-r border-slate-100 dark:border-slate-700 p-4 pr-8 text-a-gray">
        {/* {["Sick Leave", "Absence"].includes(staff.recordtype) && (
          <select
            // onChange={(e) => {
            //   setStaffDesignation(e.target.value);
            // }}
            // value={designation}
            className="h-10 rounded-lg px-3"
            placeholder="Designation"
            // defaultValue={staff.recordtype}
            onChange={(e) => {
              updateRECORDSPMS("recordtype", e.target.value, staff._id);
            }}
          >
            <option value={staff.recordtype}>{staff.recordtype}</option>
            <option value="Annual Leave + ROD">Annual Leave + ROD</option>
            <option value="ABSENCE + WARNING">ABSENCE + WARNING</option>
          </select>
        )}{" "} */}
        <span className="text-white">{staff.recordtype}</span>
        {/* {!(['Sick Leave', 'Absence'].includes(staff.recordtype)) && staff.recordtype } */}
      </td>
      <td class="border-b border-r border-slate-100 dark:border-slate-700 p-4 pr-8 text-2xl  text-white ">
        {staff.isPositive ?( <div className="flex gap-2">
          <p>👍</p>
          <p>😇</p>
        </div>) :( <div className="flex gap-2 ">
          
          {staff.recordtype === "Sick Leave" && (
              <p className="text-sm">Get well soon. Wishing you a speedy recovery.</p>
            )}
            {staff.recordtype !== "Sick Leave" && <p className="text-2xl">Need to improve</p>}
        </div>)}
      </td>

      <td class="border-b border-r border-slate-100 dark:border-slate-700 p-4 pr-8  text-white">
        <div
          dangerouslySetInnerHTML={{
            __html: staff.comments && staff.comments.substring(0, 1000),
          }}
        />
      </td>
      <td class="border-b border-r border-slate-100 dark:border-slate-700 p-4 pr-8  text-white">
        {staff.createdDate}
      </td>
      <td class="border-b border-r border-slate-100 dark:border-slate-700 p-4   text-a-gray">
        <div className="flex gap-1 bg-white px-3 py-3 rounded-md ">
          {staff.attachments.length <= 0 && <p className="">No Attachment!</p>}
          {staff.attachments.map((file, index) => {
            return (
              <>
                <a
                  className="p-2  bg-gray-200 rounded"
                  onMouseOver={() => {
                    let element = document.getElementById(
                      `popup-` + ind + index
                    );
                    element.classList.remove("hidden");
                  }}
                  onMouseLeave={() => {
                    let element = document.getElementById(
                      `popup-` + ind + index
                    );
                    element.classList.add("hidden");
                  }}
                  target="_blank"
                  href={`${Utils["domains"]["development"]}/uploads/${file.newfilename}`}
                  download={`${Utils["domains"]["development"]}/uploads/${file.newfilename}`}
                >
                  {/* <GrDocumentDownload
                    size="20px"
                    className="inline-block text-white relative "
                  /> */}
                </a>
                <div
                  id={`popup-` + ind + index}
                  className="hidden bg-gray-200 h-[240px] w-[330px] rounded-md absolute right-[360px] shadow-md overflow-hidden p-3"
                >
                  {renderFilePreview(
                    `${Utils["domains"]["development"]}/uploads/${file.newfilename}`,
                    file
                  )}
                </div>
              </>
            );
          })}
          {/* <ReactTooltip id={`tooltip-${index}`}> */}
        </div>
      </td>

    </tr>
  );
};


export default Logs;
