import React, {
  useState,
  useEffect,
  useLayoutEffect,
  useCallback,
  useMemo,
} from "react";
import { useRef } from "react";

const quoteList = [
  `A smile is the prettiest thing you can wear.`,
  `Start every day off with a smile and get it over with.`,
  `Use your smile to change the world don't let the world change your smile.`,
  `A smile is the curve that sets everything straight`,
  `There's Always a reason to smile you just have to find it.`,
  `A smile is happiness you'll find right under your nose.`,
  `Always wear smile, because your smile is a reason for many other to smile.`,
  `Your smile will give you a positive countenance that will amke people feel comfortable around you`,
  `Keep smile and brighten someone's day`,
  `life is like a mirror smile at it and it smiles back at you`,
  `use your smile to change this world but don't let this world change your smile`,
  `Smiling doest't necessarily mean you're happy. sometimes it just mean you're strong`,
  `Start a day with a smile and get it over with`,
];

const Quotes = ({ setShowQuoteModel, staffname }) => {
  const [timeOfDay, setTimeOfDay] = useState("");
  const [initialBlockTimer, setInitialBlockTimer] = useState(5);
  const [randomQuote, setRandomQuote] = useState("");
  const hasEffectRun = useRef(false);


  const handleSpeech = (text) => {
    const speechSynthesis = window.speechSynthesis;
    const speechUtterance = new SpeechSynthesisUtterance(text);
    speechUtterance.rate = 0.7;
    // if ()
    speechSynthesis.speak(speechUtterance);
  };

  
  useEffect(()=>{
    if (!hasEffectRun.current) {
      const currentTime = new Date().getHours();

      let timetempvar = '' 
    if (currentTime >= 5 && currentTime < 12) {
      setTimeOfDay("Good Morning");
      timetempvar = 'Good Morning'
    } else if (currentTime >= 12 && currentTime < 18) {
      setTimeOfDay("Good Afternoon");
      timetempvar = 'Good Afternoon'

    } else {
      timetempvar = 'Good Evening'
      setTimeOfDay("Good Evening");
    }
  

      // handleSpeech('Welcome ' + staffname + ' Quote of the day is ' + getQuote)
      handleSpeech(`${timetempvar} ${staffname}`);
      hasEffectRun.current = true;
    }
  }, [])


  const getQuote = useMemo(() => {
    const random = Math.floor(Math.random() * quoteList.length);
 
    return quoteList[random];
  }, [quoteList]);

  useEffect(() => {
    if (initialBlockTimer > 0) {
      const timer = setInterval(() => {
        setInitialBlockTimer((prevTimer) => {
          if (prevTimer <= 1) {
            clearInterval(timer);  // Clear the interval when the timer reaches 0 or goes below
            return 0; // Set the timer to 0 when it reaches or goes below 0
           
          }
          return prevTimer - 1;
        });
      }, 1000);

      return () => clearInterval(timer);
    }
  }, []);

  useLayoutEffect(() => {
    const currentTime = new Date().getHours();

    if (currentTime >= 5 && currentTime < 12) {
      setTimeOfDay("Good Morning");
    } else if (currentTime >= 12 && currentTime < 18) {
      setTimeOfDay("Good Afternoon");
    } else {
      setTimeOfDay("Good Evening");
    }
  }, []);
  return (
    <div className="h-full w-full  absolute -top-16 left-0">
      <div className="text-white flex items-center justify-center flex-col w-[90%] lg:w-[500px] h-[350px] rounded-lg border-2 bg-a-gray absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 p-10 text-center">
        <p className="bg-gradient-to-r from-a-pink to-a-blue px-2 py-1 rounded-lg">
          {timeOfDay}, {staffname}!
        </p>
        <p className="mt-6 underline">Today Quote</p>
        <p className="text-2xl text-center  ">"{getQuote}"</p>
        <button
          disabled={initialBlockTimer !== 0}
          onClick={() => {
            setShowQuoteModel(false);
          }}
          className="bg-gradient-to-r mt-6 bg-white px-12 py-4 rounded-lg text-a-gray uppercase text-xs
              
              "
        >
          {initialBlockTimer > 0 && (
            <span className="font-black rounded-full w-7 h-7 border-2 inline-flex items-center justify-center text-yellow-500 border-yellow-500">
              {" "}
              {initialBlockTimer}{" "}
            </span>
          )}{" "}
          {initialBlockTimer > 0 ? "" : "Continue Briefing"} 
        </button>
      </div>
    </div>
  );
};

export default React.memo(Quotes);
