import React, { useEffect, useState } from "react";
import { Editor } from "@tinymce/tinymce-react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useDropzone } from "react-dropzone";

import { MiniLoadingBar } from "../Global/LoadingBar";
import Utils from '../../utils.json';

import { BiCloudUpload } from "react-icons/bi";
import { AiOutlineFile, AiOutlineDelete } from "react-icons/ai";
import 'react-tooltip/dist/react-tooltip.css'
import Navbar from "../Global/Navbar";

import { Tooltip } from "react-tooltip";

import { IoCloseCircleSharp } from "react-icons/io5"

import { MdFindReplace } from "react-icons/md";

import { MdInfoOutline } from "react-icons/md";

const AddRecord = () => {
  const navigate = useNavigate();
  const [loggedinUser, setLoggedinUser] = useState(null);
  const [showCustomizeOverlay, setshowCustomizeOverlay] = useState(false);

  useEffect(() => {
    let ls = localStorage.getItem("admin");
    if (!ls) {
      navigate("/login");
    }
    setLoggedinUser(JSON.parse(ls))
    setreportedby(JSON.parse(ls)['_id'])
    settempreportedby(JSON.parse(ls)['staffname'])
  }, []);

  //   creating from here
  const [stafflist, setStaffList] = useState([]);
  const [attachments, setAttachments] = useState([]);
  //   const [initialBlockTimer, setInitialBlockTimer] = useState(30);
  const [loadingbar, setLoadingBar] = useState(true);
  const [isPositive, setisPositive] = useState(false);
  const [HideFromProfile, setHideFromProfile] = useState(false);


  const [staffid, setstaffid] = useState("");
  const [tempstaffid, settempstaffid] = useState("");
  const [area, setarea] = useState("");
  const [destination, setdestination] = useState("");
  const [flightno, setflightno] = useState("");
  const [recordtype, setrecordtype] = useState("");
  const [reportedby, setreportedby] = useState("");
  const [tempreportedby, settempreportedby] = useState("");
  const [comments, setcomments] = useState("");
  const [dateofincident, setdateofincident] = useState(null);
  const [selectedStaffList, setSelectedStaffList] = useState([]);

  useEffect(() => {
    fetch(`${Utils['domains']['development']}/spms/getstafflist`, {
      method: "get",
      headers: {
        "Content-Type": "application/json", // Specify that you're sending JSON data
      },
      // body: JSON.stringify({}), // Set the JSON data as the request body
    })
      .then((e) => {
        return e.json();
      })
      .then((data) => {
        setTimeout(() => {
          //   setLoadingBar(false);
          console.clear()
          console.log(data)
          setStaffList(data);
        }, 500);
      });
  }, []);

  const handleAddSPMSRecord = () => {
    // if (title === "") {
    //   toast.error("Title is required!", { autoClose: 2000 });
    //   return false;
    // }

    // if (content === "") {
    //   toast.error("Content is required For the briefing!", { autoClose: 2000 });
    //   return false;
    // }

    // console.log(staffid,
    //     area,
    //     destination,
    //     flightno,
    //     recordtype,
    //     reportedby,
    //     comments,
    //     attachments,)
    // return false;

    console.log(selectedStaffList);
    const selectedStaffList_ids = selectedStaffList.map(staff => staff.staffId);
    
    // return ;
    if (selectedStaffList_ids.length < 1) {
      toast.error("No staff selected!", { autoClose: 2000 })
      return;
    }

    if (dateofincident === "" || dateofincident === null) {
      toast.error("Date of incident is required", { autoClose: 2000 })
      return;
    }

    // if (staffid === "") {
    //   toast.error("Staff id is required!", { autoClose: 2000 });
    //   return false;
    // }

    if (area === "") {
      toast.error("Area is required!", { autoClose: 2000 });
      return false;
    }

    if (recordtype === "") {
      toast.error("Record Category is required!", { autoClose: 2000 });
      return false;
    }

    if (reportedby === "") {
      toast.error("ReportedBy is required!", { autoClose: 2000 });
      return false;
    }

    if (comments === "") {
      toast.error("comments are required!", { autoClose: 2000 });
      return false;
    }

    if (!isPositive) {
      let con = window.confirm("The record will consider as negative! do you want to continue? Incase if it is positive so please select the checkbox");
      if (!con) {
        return;
      }
    }

    if (HideFromProfile) {
      let con = window.confirm("Caution: The record will be hidden from user profile so incase if it needs to display please unchecked the HideFromProfile checkbox");
      if (!con) {
        return;
      }
    }


    const data = {
      staffid,
      area,
      destination,
      flightno,
      recordtype,
      reportedby,
      isPositive,
      HideFromProfile,
      comments,
      attachments,
      dateofincident,
      selectedStaffList_ids
      //   initialBlockTimer,
    };
    fetch(`${Utils['domains']['development']}/spms/addrecord`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json", // Specify that you're sending JSON data
      },
      body: JSON.stringify(data), // Set the JSON data as the request body
    }).then((e) => {
      toast("Record added successfully", { autoClose: 2000 });

      setTimeout(() => {
        navigate("/spms/viewrecord");
      }, 2000);
    });
  };

  return (
    <div>
      {/*  */}
      <Navbar title={"Add New Record"} showarrow={false} />

      <div className="flex mt-5 gap-5 flex-col lg:flex-row">
        <div className="w-full lg:w-1/2 flex flex-col gap-5">
          {/* Input Group */}
          <InputGroup
            staffid={staffid}
            showCustomizeOverlay={showCustomizeOverlay}
            setshowCustomizeOverlay={setshowCustomizeOverlay}
            area={area}
            destination={destination}
            flightno={flightno}
            recordtype={recordtype}
            setSelectedStaffList={setSelectedStaffList}
            selectedStaffList={selectedStaffList}
            reportedby={reportedby}
            comments={comments}
            loggedinUser={loggedinUser}
            dateofincident={dateofincident}
            setdateofincident={setdateofincident}
            setisPositive={setisPositive}
            HideFromProfile={HideFromProfile}
            setHideFromProfile={setHideFromProfile}
            isPositive={isPositive}

            tempreportedby={tempreportedby} settempreportedby={settempreportedby}
            tempstaffid={tempstaffid} settempstaffid={settempstaffid}

            setstaffid={setstaffid}
            setarea={setarea}
            setdestination={setdestination}
            setflightno={setflightno}
            setrecordtype={setrecordtype}
            setreportedby={setreportedby}
            setcomments={setcomments}

            stafflist={stafflist}
          />
          <TextArea comments={comments} setcomments={setcomments} />
        </div>

        {/* textarea */}
        <div className="w-1/2">
          <AddAttachment
            attachments={attachments}
            setAttachments={setAttachments}
          />
          <div className="w-full flex justify-end">
            <button
              onClick={handleAddSPMSRecord}
              className="mt-5  bg-gradient-to-r from-a-pink to-a-blue hover:to-a-pink hover:from-a-blue px-12 py-4 rounded-lg text-white uppercase text-md
              fixed bottom-5 right-5 border-2 border-a-gray
              shadow-md
              "
            >
              Create
            </button>

            {/* react */}
            {/* <button className="bg-gradient-to-r from-a-pink to-a-blue px-6 py-2 border-none rounded-md text-white mt-5 uppercase ">Create</button>  */}

            {/* traditional */}
            {/* <button style="padding: 2px; border: none; border-radius "></button> */}
          </div>
        </div>

        {/* q & a */}
      </div>

      {showCustomizeOverlay && <CustomizeOverlay setshowCustomizeOverlay={setshowCustomizeOverlay} />}
    </div>
  );
};

const AddAttachment = ({ attachments, setAttachments }) => {
  const [files, setFiles] = useState([]); // Use an array for multiple files
  const [uploadFile, setUploadFile] = useState(false); // Use an array for multiple

  const onDrop = (acceptedFiles) => {
    const selectedFile = acceptedFiles[0];
    const uniquekey = `${Date.now()}`;
    if (selectedFile) {
      selectedFile.status = "pending";
      selectedFile.newfilename = "";
      selectedFile.key = uniquekey;

      setFiles([selectedFile, ...files]); // Append new files to the existing list
      setAttachments([
        { key: uniquekey, filename: selectedFile.name, newfilename: "" },
        ...attachments,
      ]);

      setUploadFile(true);
    }
    // alert(); // This is just for debugging purposes, you can remove it
  };

  useEffect(() => {
    if (uploadFile) {
      const formData = new FormData();
      formData.append("file", files[0]);
      formData.append("key", files[0].key);

      const response = fetch(`${Utils['domains']['development']}/files/upload`, {
        method: "POST",
        body: formData,
      })
        .then((e) => {
          return e.json();
        })
        .then((data) => {
          setUploadFile(false);
          const updatedFiles = files.map((file) => {
            if (file.key === data.key) {
              file.status = "uploaded";
              file.newfilename = data.newfilename;
            }

            return file;
            // const updatedFile = {
            //   ...JSON.parse(JSON.stringify(file)),
            //   status: "uploaded",
            // };
            // return updatedFile;
          });

          const modifyattachments = attachments.map((file) => {
            if (file.key === data.key) {
              file.newfilename = data.newfilename;
            }
            return file;
          });

          setAttachments(modifyattachments);

          // console.table(files)
          // console.table(updatedFiles)
          // // setFiles(updatedFiles)
        });
    }
  }, [uploadFile]);

  const handleDeleteFile = (uid) => {
    // Create a new array that excludes the item with the specified index (uid)
    const updatedFiles = files.filter((_, index) => index !== uid);
    const updatedattachment = attachments.filter((_, index) => index !== uid);

    // Update the state with the new array
    setFiles(updatedFiles);
    setAttachments(updatedattachment);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: false,
    // accept: 'image/*', // Define the accepted file types
  });

  return (
    <div className="w-full">
      <div
        {...getRootProps()}
        className="dropzone w-full cursor-pointer  h-80 border bg-white flex-col rounded-lg flex items-center justify-center gap-2"
      >
        <input {...getInputProps()} name="file" />
        <BiCloudUpload size="140px" className="text-a-pink" />
        <p> Drag & drop file or browse! </p>
      </div>
      <div className="bg-white p-5 mt-5 rounded-lg grid grid-cols-2 gap-3">
        {files.map((file, index) => {
          return (
            <FileDisplay
              file={file}
              uid={index}
              handleDeleteFile={handleDeleteFile}
            />
          );
        })}
      </div>
      {/* <button onClick={uploadFiles}>Upload Testing</button> */}

      {/* {file && (
      <div>
        <p>Selected File: {file.name}</p>
        <button>Upload</button>
      </div>
    )} */}
    </div>
  );
};

const FileDisplay = ({ file, uid, handleDeleteFile }) => {
  return (
    <div className="w-full cursor-pointer  h-12 bg-gray-200 border-l-4 border-l-a-pink border border-gray-300  rounded-lg flex items-center gap-2 px-5 justify-between">
      <div
        className="flex items-center gap-2 relative"
        title={file.name + " " + file.newfilename}
      >
        {file.status === "pending" && <MiniLoadingBar />}
        {file.status === "uploaded" && <AiOutlineFile />}
        {/* <MiniLoadingBar/> */}
        <p>
          {file.name.substring(0, 5)} <span className="text-a-pink">···</span>{" "}
          {file.name.substring(file.name.length - 5)}
        </p>
      </div>
      <div
        onClick={() => handleDeleteFile(uid)}
        className="bg-red-500 w-8 h-8 rounded-full grid place-items-center"
      >
        <AiOutlineDelete className="text-white" size="17px" />
      </div>
    </div>
  );
};

const CustomizeOverlay = ({ setshowCustomizeOverlay }) => {
  return (<div className="fixed top-1/2 left-1/2 z-100 rounded-md p-10 -translate-x-1/2 -translate-y-1/2 w-[90%] h-[90vh] bg-a-gray border border-white drop-shadow-lg">

    <div className="flex items-center justify-between">
      <div className="text-white text-2xl">
        Customize Staff Selection
      </div>

      <button onClick={() => setshowCustomizeOverlay(false)} className="bg-white w-[45px] h-[45px] rounded-md flex items-center justify-center">
        <IoCloseCircleSharp size={25} />
      </button>
    </div>

    <div className="grid grid-cols-3 w-full h-full gap-10 py-5 pb-20 rounded-md">
      <div>
        <p className="text-white mb-2">Filter Bar</p>
        <div className="bg-white h-full rounded-lg"></div></div>
      <div>
        <p className="text-white mb-2">Select Staffs</p>

        <div className="bg-white h-full rounded-lg"></div></div>
      <div>
        <p className="text-white mb-2">Selected Staffs</p>

        <div className="bg-white h-full rounded-lg"></div></div>
    </div>

  </div>)
}

// Input Group
const InputGroup = ({
  stafflist,
  setshowCustomizeOverlay,
  showCustomizeOverlay,
  staffid,
  area,
  destination,
  loggedinUser,
  flightno,
  recordtype,
  reportedby,
  comments,

  setdateofincident,
  dateofincident,

  isPositive,
  setisPositive,

  HideFromProfile,
  setHideFromProfile,

  setSelectedStaffList,
  selectedStaffList,

  setstaffid,
  setarea,
  setdestination,
  setflightno,
  setrecordtype,
  setreportedby,
  setcomments,

  tempreportedby, settempreportedby,
  tempstaffid, settempstaffid
}) => {

  const [matchRecords, setmatchRecords] = useState([]);
  const [searching, setsearching] = useState(false);

  // 
  const handleRecheck = () => {

    console.clear()
    console.log(staffid)


    // validation
    if (staffid === "") {
      toast.error("Staff id is required!", { autoClose: 2000 });
      return false;
    }

    if (recordtype === "") {
      toast.error("Record Category is required!", { autoClose: 2000 });
      return false;
    }

    // 
    if (dateofincident === "" || dateofincident === null) {
      toast.error("Date of incident is required", { autoClose: 2000 })
      return;
    }

    setsearching(true)

    fetch(
      `${Utils["domains"]["development"]}/spms/viewrecordbyfilter?staffid=${staffid}`,
      {
        method: "get",
        headers: {
          "Content-Type": "application/json", // Specify that you're sending JSON data
        },
        //   body: JSON.stringify(data), // Set the JSON data as the request body
      }
    )
      .then((e) => {
        return e.json();
      })
      .then((data) => {

        setTimeout(() => {
          // setmatchRecords(data)
          if (data.length >= 1) {

            console.log(dateofincident.toISOString(), staffid, recordtype)
            console.log(data)
            // console.log(typeof dateofincident.toString())

            let available = false;



            // critera 
            data.map((record, index) => {
              console.log(typeof record.dateofincident)
              console.log(record.dateofincident == dateofincident.toISOString().toString())
              if (record.dateofincident === dateofincident.toISOString().toString() && record.recordtype === 'Sick Leave' && recordtype === "Sick Leave") {
                // toast.error("Sick Leave, available!", { autoClose: 2000 });
                available = true;
              }
            })

            if (available) {
              toast.error(`Sick Leave, created already! ${tempstaffid} - ${dateofincident.toISOString()}`, { autoClose: 2000 });
            } else {
              toast.success(`No Sick Leave found! based on ${tempstaffid} - ${dateofincident.toISOString()}`, { autoClose: 2000 });
            }

          } else {
            toast.success(`No Sick Leave found! based on ${tempstaffid} - ${dateofincident.toISOString()}`, { autoClose: 2000 });
          }

          setsearching(false)

        }, 300);
      });



  }

  // 
  // useEffect(()=>{

  //   if (matchRecords.length >= 1) {

  //     console.log(dateofincident.toISOString(), staffid, recordtype)
  //     console.log(matchRecords)
  //     // console.log(typeof dateofincident.toString())



  //     // critera 
  //     matchRecords.map((record, index)=>{
  //       console.log(typeof record.dateofincident)
  //       console.log(record.dateofincident == dateofincident.toISOString().toString())
  //       if (record.dateofincident === dateofincident.toISOString().toString() && record.recordtype === 'Sick Leave' && recordtype === "Sick Leave") {
  //         console.log('Already exists')
  //       }
  //     })

  //   }
  // }, [matchRecords])

  return (
    <div className="bg-gradient-to-r from-a-pink to-a-blue rounded-lg grid grid-cols-2 gap-3 items-start py-5 px-5 w-full bg-gray-500  h-fit">
      <div>
        <p className="text-xs mb-1 text-white" > Select Staff </p>

        <div className="flex gap-2 flex-col">
          <div className="bg-white p-1 rounded">
            <mark>

              If you are using iphone so don't add any record because IOS is unable to autofill, please use android or computer/laptop for records adding purpose until we shortout it.
            </mark>
          </div>
          <input
            type="text" // Change from 'search' to 'text' to prevent free typing
            className="h-10 rounded-lg px-3 w-full"
            placeholder={stafflist.length === 0 ? "Loading..." : "Select Staff"}
            value={tempstaffid}
            onChange={(e) => {
              console.log('Typing:', e.target.value);
              settempstaffid(e.target.value);  // Allow typing, but only selection from the list will be valid
            }}
            onKeyUp={(e) => {
              if (e.key === 'Enter') {
                // This ensures that the user can only select from the list when the input loses focus
                const selectedStaff = stafflist.find(staff =>
                  (staff.staffid + " - " + staff.staffname + " - " + staff.staffcategory + " - " + staff._id) === tempstaffid
                );
                if (selectedStaff) {
                  // Extract staff ID, name, and category when a valid selection is made
                  let staffId = selectedStaff._id;
                  let staffName = selectedStaff.staffname;
                  let staffCategory = selectedStaff.staffcategory;

                  if (!selectedStaffList.some(staff => staff.staffId === staffId)) {
                    setSelectedStaffList([...selectedStaffList, { staffId, staffName, staffCategory }]);
                  }
                } else {
                  settempstaffid('');  // Reset input if the value doesn't match a valid staff
                }

        settempstaffid(''); 

              }



            }


            }
            list="stafflist"
          />

          <datalist id="stafflist">
            {stafflist.map((staff, index) => {
              return (
                <option
                  value={
                    staff.staffid +
                    " - " +
                    staff.staffname +
                    " - " +
                    staff.staffcategory +
                    " - " +
                    staff._id
                  }
                />
              );
            })}
          </datalist>

          <div className="mt-2">
            {selectedStaffList.map((staff, index) => (
              <span key={index} className="text-sm bg-blue-200 p-1 rounded mr-2">
                {staff.staffName} - {staff.staffCategory}
                {/* Option to remove staff from selection */}
                <button
                  className="ml-2 text-red-500"
                  onClick={() => {
                    setSelectedStaffList(selectedStaffList.filter((item) => item.staffId !== staff.staffId));
                  }}
                >
                  &times;
                </button>
              </span>
            ))}
          </div>


          {/* <button
            onClick={() => setshowCustomizeOverlay(true)}
            className="  bg-a-gray px-4 h-10 rounded-lg text-white uppercase text-xs"
          >
            Customize
          </button> */}
        </div>

      </div>

      <div>
        <p className="text-xs mb-1 text-white" > Select Area  </p>


        <input
          onChange={(e) => {
            setarea(e.target.value);
          }}
          type="text"
          value={area}
          className="h-10 rounded-lg px-3 w-full"
          placeholder="Area"
        />
      </div>

      <div>
        <p className="text-xs mb-1 text-white" > Destination </p>

        <input
          onChange={(e) => {
            setdestination(e.target.value);
          }}
          type="text"
          value={destination}
          className="h-10 rounded-lg px-3 w-full"
          placeholder="Destination"
        /></div>

      <div>
        <p className="text-xs mb-1 text-white" >Flight No  </p>


        <input
          onChange={(e) => {
            setflightno(e.target.value);
          }}
          type="text"
          value={flightno}
          className="h-10 rounded-lg px-3 w-full"
          placeholder="Flight No"
        />

      </div>

      <div>
        <p className="text-xs mb-1 text-white" > Record Category </p>


        <select
          onChange={(e) => {
            setrecordtype(e.target.value);
          }}
          type="text"
          value={recordtype}
          className="h-10 rounded-lg px-3 w-full"
          placeholder="recordtype"
        >
          <option value="">Select Category</option>
          <option value="Communication">Communication</option>
          <option value="Grooming Feedback">Grooming Feedback</option>
          <option value="Performance">Performance</option>
          <option value="Customer Service Feedback">Customer Service Feedback</option>
          <option value="Memo">Memo</option>
          <option value="Inad">Inad</option>
          <option value="ROD">ROD</option>
          <option value="ROA">ROA</option>
          <option value="Airlines Appreciation">Airlines Appreciation</option>
          <option value="Airline Complaints">Airline Complaints</option>
          <option value="Absence">Absence</option>
          <option value="Interview Note">Interview Note</option>
          <option value="Punctuality">Punctuality</option>
          <option value="Sick Leave">Sick Leave</option>
          <option value="ABSENCE + WARNING">ABSENCE + WARNING</option>
          <option value="Annual Leave + ROD">Annual Leave + ROD</option>

          <option value="Verbal Warning">Verbal Warning</option>
          <option value="Written Warning">Written Warning</option>
          <option value="Final Warning">Final Warning</option>
          <option value="Extended Final">Extended Final</option>

          <option value="Check in discepenates">Check in Performance</option>
          <option value="Boarding discepenates">Boarding Performance</option>
          <option value="Intercepted Cases">Intercepted Case</option>
          <option value="Customer Care Complaint">Customer Care Complaint</option>
          <option disabled value="Remarkable Performance">N/A - Remarkable Performance</option>
          <option value="IQSMS">IQSMS</option>
          <option value="Mystery Shopper Record">Mystery Shopper Record</option>
          <option value="Sbp no compliance">SBP non-compliance</option>
          <option value="Checkin & Gates Management">Checkin & Gates Management</option>

          <option value="Other">Other</option>
        </select>
      </div>

      <div>
        <p className="text-xs mb-1 text-white" > If record is positive click here!</p>

        <input
          onChange={(e) => {
            console.clear()
            console.log(e.target.checked)
            setisPositive(e.target.checked);
          }}
          type="checkbox"
          value={isPositive}
          className="rounded-lg manageassign-cb"
          placeholder="Destination"
        /></div>




      <div>
        <p className="text-xs mb-1 text-white" > Reported By </p>

        {/* {loggedinUser && loggedinUser._id} <br />
          {loggedinUser && loggedinUser.staffname} <br /> */}
        {/* {reportedby} <br />
          {tempreportedby} <br /> */}

        <input
          disabled
          onChange={(e) => {

            //   setreportedby(e.target.value);

            let extractstaffid = e.target.value.split(' - ')[3];
            setreportedby(extractstaffid);
            settempreportedby(e.target.value.split(' - ')[1])

          }}
          type="search"
          value={tempreportedby}
          className="h-10 rounded-lg px-3 w-full"
          placeholder={stafflist.length == 0 ? "Loading..." : "Reported By"}
          list="stafflist"
        />
      </div>

      <div>
        <p className="text-xs mb-1 text-white" > Hide From Profile</p>

        <input
          onChange={(e) => {
            console.clear()
            console.log(e.target.checked)
            setHideFromProfile(e.target.checked);
          }}
          type="checkbox"
          value={HideFromProfile}
          className="rounded-lg manageassign-cb"
          placeholder="Destination"
        /></div>

      <div className="col-span-2">
        <p className="text-xs mb-1 text-white flex gap-2 items-center mb-3" >Date Of Incident / Records <MdInfoOutline data-tooltip-id="my-tooltip" data-tooltip-content='DOI/R is linked to incident dates, while record addition dates are system-generated. "Linked to incident dates needs maunal entry"' data-tooltip-place="top" size={16} />  </p>
        <Tooltip id="my-tooltip" style={{ width: "340px" }} />

        <input
          onChange={(e) => {
            // console.log(e.target.value)
            let selectedValue = e.target.value;
            if (selectedValue) {
              setdateofincident(new Date(e.target.value));
            }
            // return;
          }}
          type="date"
          value={dateofincident ? dateofincident.toISOString().split('T')[0] : ''}
          className="h-10 rounded-lg px-3 w-full "
        />
      </div>
      <div>


        <button
          onClick={handleRecheck}
          className="mt-5  bg-a-gray px-2 py-1 rounded-lg text-white uppercase text-xs border-2 border-a-gray
              shadow-md flex gap-2 items-center
              "
        >
          <MdFindReplace size="22" />
          {searching ? <MiniLoadingBar /> : 'Recheck'}
        </button>
      </div>


    </div>
  );
};

// Textarea
const TextArea = ({ comments, setcomments }) => {
  const handleEditorChange = (content, editor) => {
    setcomments(content);
  };
  return (
    <Editor
      apiKey="jao93rxzn995ll8jjvrhi92vr7zlkr1xkrwh1k2w28o2yteh" // You need to obtain an API key from TinyMCE (https://www.tiny.cloud/)
      value={comments}
      init={{
        height: 340,

        menubar: true,
        plugins: [
          "advlist  lists link image charmap print preview anchor",
          "searchreplace visualblocks code fullscreen",
          "insertdatetime media table paste code help wordcount",
        ],
        toolbar:
          "fontsize | undo redo | link | formatselect | " +
          "image |" +
          "bold italic backcolor | alignleft aligncenter " +
          "alignright alignjustify | bullist numlist ",
      }}
      onEditorChange={handleEditorChange}
    />
  );
};

export default AddRecord;