import React, { useEffect, useState } from "react";
import Navbar from "../Global/Navbar";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import LoadingBar from "../Global/LoadingBar";
import { PiMicrosoftExcelLogoFill } from "react-icons/pi";
import { AiFillDelete } from "react-icons/ai"
import { TiTick } from "react-icons/ti"
import Utils from '../../utils.json';

const Logs = () => {
  const navigate = useNavigate();

  useEffect(() => {
    let ls = localStorage.getItem('admin');
    if (!ls) {
      navigate('/login')
    }
    return;
  })
  const [staffList, setStaffList] = useState([]);
  const [loadingbar, setLoadingBar] = useState(true);

  // create new staff
  const [staffname, setStaffName] = useState('');
  const [staffid, setStaffId] = useState('');
  const [staffcategory, setStaffCategory] = useState('');
  const [staffbriefing, setStaffbriefing] = useState(0);
  const [staffpassword, setStaffPassword] = useState('');
  const [newCreateNewStaff, setNewCreateNewStaff] = useState(false);
  const [controlpanelaccess, setcontrolpanelaccess] = useState(false);

  // filter
  const [applyFilter, setApplyFilter] = useState(false);
  const [stafffetchlimit, setstafffetchlimit] = useState(20);

  // new state
  const [logslist, setloglist] = useState([])




  useEffect(() => {
    fetch(`${Utils['domains']['development']}/logs/getlogslist?reqtype=spmsrecordsview`, {
      method: "get",
      headers: {
        "Content-Type": "application/json", // Specify that you're sending JSON data
      },
      // body: JSON.stringify({}), // Set the JSON data as the request body
    })
      .then((e) => {
        return e.json();
      })
      .then((data) => {
        setTimeout(() => {
          setLoadingBar(false);
          setloglist(data);
          console.log(data)
        }, 0);
      });
  }, []);



  return (
    <div>
      <Navbar title={"Staff List"} showarrow={false} />
      {loadingbar && <LoadingBar />}

      {/*  <AddNewStaffPanel
        staffid={staffid}
        staffname={staffname}
        staffcategory={staffcategory}
        HandleExportAsExcel={HandleExportAsExcel}

        controlpanelaccess={controlpanelaccess}
        setcontrolpanelaccess={setcontrolpanelaccess}

        setStaffPassword={setStaffPassword}
        staffpassword={staffpassword}

        setStaffId={setStaffId}
        setStaffName={setStaffName}
        setStaffCategory={setStaffCategory}

       
        setApplyFilter={setApplyFilter}

        setLoadingBar={setLoadingBar}
        stafffetchlimit={stafffetchlimit}
        setstafffetchlimit={setstafffetchlimit}
  />*/}

      <div class="relative rounded-xl overflow-auto w-full">
        <div class="shadow-sm overflow-x-scroll w-full  lg:overflow-x-scroll my-8">
          {logslist.length <= 0 && !loadingbar && <div className="">No Log added yet!</div>}

          {!logslist.length <= 0 && <StaffTable loadingbar={loadingbar} logslist={logslist} />}
        </div>
      </div>
    </div>
  );
};



// const AddNewStaffPanel = ({        
//   staffid,
//   staffname,
//   staffcategory,
//   setStaffId,
//   setStaffName,
//   setStaffCategory,
//   handleAddNewStaff,
//   controlpanelaccess,
//   setcontrolpanelaccess,

//   setApplyFilter,
//   setLoadingBar,

//   stafffetchlimit,
//   setstafffetchlimit,

//   setStaffPassword,
//   staffpassword,
//   HandleExportAsExcel

// }) => {

//   const handleApplyFilter = () => {
//     setApplyFilter(true)
//     setLoadingBar(true)
//   }
//   return (
//     <div className="bg-a-gray py-4 mt-5 rounded-lg flex flex-wrap lg:flex-nowrap  gap-3 items-center px-5 w-full   h-auto">
//       <input
//         type="text"
//         className="h-10 rounded-lg px-3 w-20"
//         placeholder="Staff Id"
//         value={staffid}
//         onChange={(e) => {
//           setStaffId(e.target.value);
//         }}
//       />
//            <input
//         type="text"
//         className="h-10 rounded-lg px-3"
//         placeholder="Staff Name"
//         value={staffname}
//         onChange={(e) => {
//           setStaffName(e.target.value);
//         }}
//       />

//         <input
//         type="text"
//         className="h-10 rounded-lg px-3 "
//         placeholder="Password"
//         value={staffpassword}
//         onChange={(e) => {
//           setStaffPassword(e.target.value);
//         }}
//       />
//       <select
//         onChange={(e) => {
//           setStaffCategory(e.target.value);
//         }}
//         value={staffcategory}
//         className="h-10 rounded-lg px-3"
//         placeholder="Designation"
//       >
//         <option value="">All</option>
//         <option value="aa">AA</option>
//         <option value="pa">PA</option>
//         <option value="psa">PSA</option>
//         <option value="tl">TL</option>
//         <option value="css">CSS</option>
//         <option value="csm">CSM</option>
//         <option value="vct">VCT</option>
//         <option value="hala">Hala</option>
//       </select>
//       <p className="text-white">
//       CP Access
//       </p>

//       <input
//         type="checkbox"
//         className="h-10 rounded-lg px-3"
//         placeholder="Staff Name"
//         value={controlpanelaccess}
//         onChange={(e) => {
//           setcontrolpanelaccess(e.target.checked);
//         }}
//       />
//       <select
//         onChange={(e) => {
//           setstafffetchlimit(e.target.value);
//         }}
//         value={stafffetchlimit}
//         className="h-10 rounded-lg px-3"
//         placeholder="limit"
//       >
//         <option value="5">5</option>
//         <option selected value="10">10</option>
//         <option value="20">20</option>
//         <option value="30">30</option>
//       </select>

//       <button onClick={handleApplyFilter} className="  bg-gradient-to-r from-a-pink to-a-blue hover:to-a-pink hover:from-a-blue px-12 h-10 rounded-lg text-white uppercase text-xs">
//         Apply
//       </button>
//       {/* <button
//         onClick={HandleExportAsExcel}
//         className="flex items-center gap-2  bg-green-600 px-12 h-10 rounded-lg text-white uppercase text-xs"
//       >
//         <PiMicrosoftExcelLogoFill size="22px" />
//         Export Excel
//       </button> */}
//     </div>
//   );
// };



const StaffTable = ({ loadingbar, logslist }) => {
  return (
    <table class="border-collapse  w-full  text-xs rounded-lg overflow-x-scroll lg:overflow-x-scroll mt-5  ">
      <thead className="bg-gradient-to-r from-a-pink to-a-blue text-white">

        <tr>
          <td class="border-b border-r border-slate-100 dark:border-slate-700 p-4 pl-8  text-white">
            Actions
          </td>
          <td class="border-b border-r border-slate-100 dark:border-slate-700 p-4 pl-8  text-white">
            Sno
          </td>
          <td class="border-b border-r border-slate-100 dark:border-slate-700 p-4 pl-8  text-white">
            Staff Id
          </td>
          <td class="border-b border-r border-slate-100 dark:border-slate-700 p-4  text-white">
            Staff Name
          </td>
          <td class="border-b border-r border-slate-100 dark:border-slate-700 p-4  text-white">
            Area
          </td>
          <td class="border-b border-r border-slate-100 dark:border-slate-700 p-4  text-white">
            Destination
          </td>
          <td class="border-b border-r border-slate-100 dark:border-slate-700 p-4 pr-8  text-white">
            Flight no
          </td>
          <td class="border-b border-r border-slate-100 dark:border-slate-700 p-4 pr-8  text-white">
            DOI/R
          </td>
          <td class="border-b border-r border-slate-100 dark:border-slate-700 p-4 pr-8  text-white">
            Report Type
          </td>
          <td class="border-b border-r border-slate-100 dark:border-slate-700 p-4 pr-8  text-white">
            Positivity Status
          </td>
          <td class="border-b border-r border-slate-100 dark:border-slate-700 p-4 pr-8  text-white">
            Reported By
          </td>
          <td class="border-b border-r border-slate-100 dark:border-slate-700 p-4 pr-8  text-white">
            Comments
          </td>
          <td class="border-b border-r border-slate-100 dark:border-slate-700 p-4 pr-8  text-white">
            Date
          </td>
          <td class="border-b border-r border-slate-100 dark:border-slate-700 p-4 pr-8  text-white">
            Attachments
          </td>
          <td class="border-b border-r border-slate-100 dark:border-slate-700 p-4 pr-8  text-white">
            Meeting Conclusion
          </td>
          <td class="border-b border-r border-slate-100 dark:border-slate-700 p-4 pr-8  text-white">
            Meeting With
          </td>
          <td class="border-b border-r border-slate-100 dark:border-slate-700 p-4 pr-8  text-white">
            Meeting Date
          </td>
          <td class="border-b border-r border-slate-100 dark:border-slate-700 p-4 pr-8  text-white">
            RID
          </td>


        </tr>

      </thead>
      <tbody class="bg-a-gray text-white">
        {logslist.map((log, index) => {
          return <TableRow staff={log} key={index} ind={index} />;
        })}
      </tbody>
    </table>
  );
};

const TableRow = ({ staff, ind }) => {
  return (
    <tr key={ind} id={ind}>
      <td class="border-b border-r border-slate-100 dark:border-slate-700 p-4  text-white">
        N/A
      </td>
      <td class="border-b border-r border-slate-100 dark:border-slate-700 p-4  text-white">
        {ind}
      </td>
      <td class="border-b border-r border-slate-100 dark:border-slate-700 p-4  text-white">
        {/* {staff.staffid["staffid"]} */}
        {staff['staffid'] ? staff.staffid['staffid'] : "Unable to find"}
      </td>
      <td class="border-b border-r border-slate-100 dark:border-slate-700 p-4 pr-8  text-white">
        {staff['staffid'] ? staff.staffid["staffname"] : "Unable to find"}
      </td>
      <td class="border-b border-r border-slate-100 dark:border-slate-700 p-4 pr-8  text-white">
        {staff.area}
      </td>
      <td class="border-b border-r border-slate-100 dark:border-slate-700 p-4 pr-8  text-white">
        {staff.destination}
      </td>
      <td class="border-b border-r border-slate-100 dark:border-slate-700 p-4 pr-8  text-white">
        {staff.flightno}
      </td>
      <td class="border-b border-r w-[130px] border-slate-100 dark:border-slate-700 p-4 pr-8  text-white">
        {staff.dateofincident.split("T")[0]}
      </td>
      <td class="border-b border-r border-slate-100 dark:border-slate-700 p-4 pr-8 text-a-gray">
        {staff.recordtype}
      </td>
      <td class="border-b border-r border-slate-100 dark:border-slate-700 p-4 pr-8 text-a-gray">
        {staff.isPositive ? "Positive" : "Negative"}
      </td>
      <td class="border-b border-r border-slate-100 dark:border-slate-700 p-4 pr-8  text-white">
        {staff.reportedby && staff.reportedby.staffname}
      </td>
      <td title={staff.comments.replace(/<[^>]*>/g, '')} class="border-b border-r border-slate-100 dark:border-slate-700 p-4 pr-8  text-white">
        {/* <div
          dangerouslySetInnerHTML={{
            __html: staff.comments && staff.comments.substring(0, 1000),
          }}
        />
        <input type="button" value="value" /> */}
        {staff.comments.replace(/<[^>]*>/g, '').substring(0, 200) + "..."}
      </td>
      <td class="border-b border-r border-slate-100 dark:border-slate-700 p-4 pr-8  text-white">
        {staff.createdDate}
      </td>
      <td class="border-b border-r border-slate-100 dark:border-slate-700 p-4 pr-8  text-white">
        N/A
      </td>
      <td class="border-b border-r border-slate-100 dark:border-slate-700 p-4 pr-8  text-white">
        {staff.meetingconclusion}
      </td>
      <td class="border-b border-r border-slate-100 dark:border-slate-700 p-4 pr-8  text-white">
        {/* {console.log(staff.meetingwith)} */}
        {/* {staff.meetingwith.staffname} */}
        {staff.meetingwith && staff.meetingwith.staffname}
      </td>
      <td class="border-b border-r border-slate-100 dark:border-slate-700 p-4 pr-8  text-white">
        {staff.meetingdate}
      </td>
      <td class="border-b border-r border-slate-100 dark:border-slate-700 p-4 pr-8  text-white">
        {staff._id}
      </td>

    </tr>
  );
};

export default Logs;
