// Import all necessary modules and components first
import "./App.css";

// Commented imports (you can keep them if needed)
// import BG1 from "./Assets/1.mp4";
// import BG2 from "./Assets/2.mp4";
import { useMemo } from "react";
import accessdeniedimg from './Assets/accessdenied.jpg';
import io from "socket.io-client";

// // Videos for random Background
// import BG4 from "./Assets/1.mp4";
// import BG5 from "./Assets/2.mp4";
// import BG6 from "./Assets/3.mp4";

import BG1 from "./Assets/bg1.mp4";
import BG2 from "./Assets/bg2.mp4";
import BG3 from "./Assets/bg3.mp4";
import BG4 from "./Assets/bg4.mp4";
import BG5 from "./Assets/bg5.mp4";
// import BG6 from "./Assets/bg1.mp4";

// // import BG7 from "./Assets/4.mp4";

// import { useNavigate } from "react-router-dom";
// import OneSignal from "react-onesignal";

import {
  // BrowserRouter as Router,
  Route,
  Routes,
} from "react-router-dom";

// Components
import Sidebar from "./Components/Global/Sidebar";
import Create from "./Components/Create/Create";
import BriefingDisplay from "./Components/BriefingDisplay/BriefingDisplay";
import ManageAssign from "./Components/BriefingDisplay/ManageAssign";
import { ToastContainer, toast } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
import StaffList from "./Components/StaffList/StaffList";
import { useState, useEffect, useLayoutEffect, useCallback } from "react";
import BriefingStaff from "./Components/Staff/BriefingStaff/BriefingStaff";
import Urls from "./Components/Urls/Urls";
import RTCActiveUsers from "./Components/Urls/RTCActiveUsers";

// Reports
import AddReport from "./Components/Reports/AddReport.jsx";
import ViewReport from "./Components/Reports/ViewReport.jsx";
import HighestFields from "./Components/Reports/HighestFields.jsx";


import BriefingStaffSign from "./Components/Staff/BriefingStaffSign/BriefingStaffSign";
import Summary from "./Components/Summary/Summary";
import BriefingDisplayExpand from "./Components/BriefingDisplay/BriefingDisplayExpand";
import SignNotSIgnStaff from "./Components/Staff/SignNotSIgnStaff/SignNotSIgnStaff";
import Login from "./Components/Login/Login";
import Logout from "./Components/Login/Logoutadmin";
import { FaPowerOff } from "react-icons/fa";

import { RxHamburgerMenu } from "react-icons/rx";
import { RiCloseFill } from "react-icons/ri";
import AddRecord from "./Components/SPMS/AddRecord";
import ViewRecord from "./Components/SPMS/ViewRecord";
import ApprovedRecords from "./Components/SPMS/ApprovedRecords";
import RecordAnalyzer from "./Components/SPMS/RecordAnalyzer";
import Logs from "./Components/Logs/Logs";
import Actions from "./Components/Actions/Actions";
import ActionsStaff from "./Components/Actions/ActionsStaff";
import About from "./Components/Logs/About";
import SBPApps from "./SBPApps";
import StaffProfile from "./Components/Staff/StaffProfile.jsx";

// import { Redirect } from "react-router-dom";

// Now, initialize your socket after all imports
const socket = io("https://sbpbackend.sharjahaviation.com");

// const videoPaths = [BG1, BG2, BG4, BG5, BG6, BG7, BG8, BG9, BG10, BG13, BG14, BG15, BG16, BG17, BG18, BG19, BG20, BG21, BG22, BG23, BG24, BG25, BG26, BG27, BG28, BG29, BG30, BG31, BG32, BG33];
const videoPaths = [BG1, BG2, BG3, BG4, BG5]

function App() {
  // const navigate = useNavigate();
  const [timeLeft, setTimeLeft] = useState(1800); // 30 minutes in seconds
  const [showPopup, setShowPopup] = useState(false);
  const [popupTimeout, setPopupTimeout] = useState(null);

  // ---------------------------------------(RTC USERS)----------------------------------------------------------

  const [activeUsers, setActiveUsers] = useState({});


  useEffect(() => {
    let ls = localStorage.getItem('admin');
    if (!ls) {
      // navigate('/login')
      return;
    }

    const userId = 'user_' + Math.random().toString(36).substr(2, 9);
    const username = JSON.parse(ls)['staffname']

    socket.emit('userActive', { userId, username })

    return;
  }, [])

  useEffect(() => {
    let ls = localStorage.getItem('staff');
    if (!ls) {
      // navigate('/login')
      return;
    }

    const userId = 'user_' + Math.random().toString(36).substr(2, 9);
    const username = JSON.parse(ls)['staffname']

    socket.emit('userActive', { userId, username })

    return;
  }, [])

  useEffect(() => {

    socket.on('activeUsers', (users) => {
      setActiveUsers(users);
      // console.log(users);
    })

    return () => {
      socket.disconnect();
    }


  }, [])

  // ------------------------------------------------------------------------------------------------------------------

  // time
  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft((prevTime) => prevTime - 1);
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    if (timeLeft === 0) {
      handleTimeUp();
    }
  }, [timeLeft]);

  const handleTimeUp = useCallback(() => {
    setShowPopup(true);

    const timeout = setTimeout(() => {
      clearLocalStorage();
    }, 10000); // 1 minute

    setPopupTimeout(timeout);
  }, []);

  const handleRenewSession = () => {
    setShowPopup(false);
    setTimeLeft(1800); // Reset timer to 30 minutes

    if (popupTimeout) {
      clearTimeout(popupTimeout);
    }
  };

  const clearLocalStorage = () => {
    localStorage.removeItem('admin')
    alert('You have been logout due to inactivity.');
    setShowPopup(false)
    window.location.reload()
  };


  // time end

  // useLayoutEffect(() => {
  //   toast.info("We are on the process to develop the app and added new features if you are unable to read or sign your records please try at later stage. Or try to refresh page please", {
  //     autoclose: 20000,
  //     position: "top-center"
  //   })
  // }, [])


  useLayoutEffect(() => {
    let admin = localStorage.getItem("admin");
    let staff = localStorage.getItem("staff");
    // let newLoginadmin = localStorage.getItem("newloginadmin");
    // let newLoginstaff = localStorage.getItem("newloginstaff");

    // if (admin) {
    //   admin = JSON.parse(admin);
    //   if (!newLoginadmin) {
    //     localStorage.clear()
    //     localStorage.setItem("newloginadmin", true)
    //     window.location.reload()
    //   }
    // }

    // if (staff) {
    //   staff = JSON.parse(staff);
    //   if (!newLoginstaff) {
    //     localStorage.clear()
    //     localStorage.setItem("newloginstaff", true)
    //     window.location.reload()

    //   }

    // }


  }, [])

  const secretAccessCode = '001199565';

  const [validReferrer, setValidReferrer] = useState(false);
  // const [secretAccessCode, setsecretAccessCode] = useState('')

  const handleSecretAccess = (e) => {
    if (e.target.value === secretAccessCode) {
      setValidReferrer(true)
    } else {
      setValidReferrer(false)
    }
  }

  useLayoutEffect(() => {
    if (['https://airarabia.sharepoint.com/', 'https://sbp.sharjahaviation.com/view', 'https://airarabia.sharepoint.com/sites/sharjahaviation/SitePages/sas.aspx', 'https://airarabia.sharepoint.com/sites/sharjahaviation/SitePages/sas.aspx/', 'https://airarabia.sharepoint.com'].includes(document.referrer)) {
      // alert("Welcome")
      setValidReferrer(true)
    } else {
      // alert('access denied')
      setValidReferrer(true); // temp disable (true)  else false
    }
  }, [])

  // useEffect(() => {
  //   OneSignal.init({
  //     appId: "1a6d9000-4cbb-497a-bb54-381429776cf6"
  //   })
  // }, [])

  const [showSidebar, setShowSidebar] = useState(false);

  const getRandomVideoPath = useMemo(() => {
    const randomIndex = Math.floor(Math.random() * videoPaths.length);
    return videoPaths[randomIndex];
  }, [videoPaths])

  return (
    <>
      {showPopup && (
        <div className="popup">
          <p>Do you wish to keep you login</p>
          <p>Click on Yes if you wanna extend additional 30 minute </p>
          <button onClick={handleRenewSession}>Yes</button>
        </div>
      )}
      <p className="z-[100000] border border-1 border-white fixed bottom-5 bg-[#666] text-white py-2 p-5 rounded left-1/2 -translate-x-1/2 flex items-center justify-between gap-4 "> <FaPowerOff className="text-white" /> Time left: {Math.floor(timeLeft / 60)}:{('0' + (timeLeft % 60)).slice(-2)}</p>


      {validReferrer && (<div className="App text-agray flex">
        {    <video
          className="fixed top-0 left-0  object-cover h-screen w-full"
          src={getRandomVideoPath}
          autoPlay
          loop
          muted
        ></video>}
        <ToastContainer />


        {/* <div className={`p-5 w-full ${showSidebar ? "lg:ml-52" : "lg:ml-0"} relative h-screen mt-16 main__container`}> */}
        <div className={`transition-all duration-300 relative h-screen mt-16 main__container p-5`}
          style={{
            width: showSidebar ? "calc(100% - 13rem)" : "100%", // Adjust width based on sidebar visibility
            marginLeft: showSidebar ? "13rem" : "0", // Sidebar width = 52 (Tailwind w-52) ≈ 13rem
          }}>

          <Routes>

            <Route path="/apps" element={<SBPApps />} />
            <Route path="/about" element={<About />} />
            <Route path="/login" element={<Login />} />
            <Route path="/" element={<Create />} />
            <Route path="/view" element={<BriefingDisplay />} />
            <Route path="/manageassign" element={<ManageAssign />} />
            <Route path="/viewexpand/:id" element={<BriefingDisplayExpand />} />
            <Route path="/stafflist" element={<StaffList />} />
            <Route path="/urls" element={<Urls />} />
            <Route path="/summary" element={<Summary />} />
            <Route path="/logs" element={<Logs />} />
            <Route path="/rtcactiveusers" element={<RTCActiveUsers activeUsers={activeUsers} />} />
            <Route path="/logout" element={<Logout />} />

            <Route path="/spms/addrecord" element={<AddRecord />} />
            <Route path="/spms/viewrecord" element={<ViewRecord />} />
            <Route path="/spms/approvedrecords" element={<ApprovedRecords />} />
            <Route path="/spms/recordanalyzer" element={<RecordAnalyzer />} />
            <Route path="/spms/actions" element={<Actions />} />


            <Route path="/report/addreports" element={<AddReport />} />
            <Route path="/report/viewreports" element={<ViewReport />} />
            <Route path="/report/highestfields" element={<HighestFields />} />


            <Route
              path="/briefingstaff/:designation"
              element={<BriefingStaff />}
            />
            <Route
              path="/briefingstaff/sign/:id/:issign"
              element={<BriefingStaffSign />}
            />
            <Route
              path="/briefingstaff/signnotsIgnStaff"
              element={<SignNotSIgnStaff />}
            />
            <Route
              path="/staffactions"
              element={<ActionsStaff />}
            />
            <Route
              path="/profile"
              element={<StaffProfile />}
            />
          </Routes>


          <button onClick={() => { setShowSidebar(!showSidebar) }} className="fixed top-5 right-5  bg-a-gray w-12 h-12 rounded-lg flex items-center justify-center ">
            {!showSidebar && <RxHamburgerMenu className="text-white " size={"25px"} />}
            {showSidebar && <RiCloseFill className="text-white " size={"25px"} />}
          </button>
        </div>
        <Sidebar showSidebar={showSidebar} setShowSidebar={setShowSidebar} />
      </div>)}

      {/* {validReferrer ? <div>Processing...</div> : <div>Invalid Redirect URL</div>} */}

      {!validReferrer &&
        (<div className="flex items-center justify-center flex-col absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
          <img width="500px" src={accessdeniedimg} />
          <p className="text-center">
            Access Denied! You can access it from <a className="text-blue-700 underline" target="_blank" href="http://sharjahaviationstaff.com">sharjahaviationstaff.com</a></p>
          <br />   <input type="password" onChange={handleSecretAccess} />
        </div>)}
      {/* <EidEdition /> */}

      {/* 
      <div className="fixed w-full h-screen inset-0 bg-black bg-opacity-90 text-white flex justify-center items-center z-[9999]">
      <div className="max-w-lg p-6 text-center">
        <strong className="text-xl">Notice:</strong>
        <p className="mt-4">
          Dear All, SBP is currently undergoing maintenance. Please try again later or wait for further updates.
        </p>
        <p className="mt-4">
          We will notify you as soon as the system becomes operational.
        </p>
        <p className="mt-4">Thank you for your understanding.</p>
      </div>
    </div> */}

    </>
  );
}

const EidEdition = () => {

  const [keepEidEdition, setkeepEidEdition] = useState(false);

  // setTimeout(() => {
  //   setkeepEidEdition(true)
  // }, 25000);

  return (
    <div className="surface w-fit ediedition-move h-[120px] flex items-center  fixed bottom-0 z-[1000000] right-0 ">
      {!keepEidEdition &&
        <div className="flex  gap-5 items-center text-white w-[500px]">
          <p className="text-[100px]">
            🐄
          </p>
          <div>
            <p className="text-xl font-bold ediedition-move-title">Happy Eid Al Adha </p>
            <p className="ediedition-move-title">EID Edition</p>
          </div>
        </div>}

    </div>)
}

export default App;
