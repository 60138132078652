import React, { useEffect, useState } from "react";
import Navbar from "../Global/Navbar";

import { toast } from "react-toastify";
import LoadingBar from "../Global/LoadingBar";
import { Link } from "react-router-dom";
import Utils from '../../utils.json';
import PDFGenerator from '../../PDFGenerator.js';
import { useNavigate } from "react-router-dom";


import { AiOutlineFile, AiOutlineDelete } from "react-icons/ai";



const BriefingDisplay = () => {
  const navigate = useNavigate();

  const [staffCategory, setStaffCategory] = useState('');

  useEffect(() => {
    let ls = localStorage.getItem('admin');
    if (!ls) {
      navigate('/login')
    }
    // console.clear()
    // console.log(JSON.parse(ls))
    setStaffCategory(JSON.parse(ls)['staffcategory'])
  })

  useEffect(() => {
    if (staffCategory !== "") {
      { !['SUPER-ADMIN', 'csm', 'ADM', 'HOD', 'HALA-MANAGER'].includes(staffCategory) && navigate("/spms/addrecord") }
    }
  }, [staffCategory])

  const [briefingList, setBriefingList] = useState([]);
  const [combinebriefingList, setcombinebriefingList] = useState([]);
  const [loadingbar, setLoadingBar] = useState(true);
  const [showlistview, setshowlistview] = useState(false);

  const [designation, setStaffDesignation] = useState("");
  const [category, setCategory] = useState('');
  const [briefinglimit, setBriefingLimit] = useState(20)

  const [applyFilter, setApplyFilter] = useState(false);
  const [startdate, setStartDate] = useState('');
  const [enddate, setEndDate] = useState('');



  useEffect(() => {
    if (staffCategory !== "") {
      fetch(`${Utils['domains']['development']}/briefing/view?staffcategory=${staffCategory}`, {
        method: "get",
        headers: {
          "Content-Type": "application/json", // Specify that you're sending JSON data
        },
        // body: JSON.stringify(data), // Set the JSON data as the request body
      })
        .then((e) => {
          return e.json();
        })
        .then((data) => {
          setTimeout(() => {
            setLoadingBar(false);
            setBriefingList(data);
          }, 0);
        });
    }
  }, [staffCategory]);

  useEffect(() => {
    if (applyFilter) {
      fetch(
        `${Utils['domains']['development']}/briefing/viewbyfilter?designation=${designation}&briefinglimit=${briefinglimit}&category=${category}&startdate=${startdate}&enddate=${enddate}&staffcategory=${staffCategory}`,
        {
          method: "get",
          headers: {
            "Content-Type": "application/json", // Specify that you're sending JSON data
          },
          // body: JSON.stringify(data), // Set the JSON data as the request body
        }
      )
        .then((e) => {
          return e.json();
        })
        .then((data) => {
          setTimeout(() => {
            setLoadingBar(false);
            setBriefingList(data);
            setApplyFilter(false)
          }, 0);
        });
    }

  }, [applyFilter]);

  useEffect(() => {
    if (briefingList.length > 0) {

      function organizeBriefings(briefings) {
        // Create an object to store briefings by title
        const briefingMap = {};

        // Iterate through each briefing
        briefings.forEach(briefing => {
          // Extract relevant information
          const { _id, title, designation, createdDate, category, attachments } = briefing;

          // Check if the title exists in the map
          if (briefingMap[title]) {
            // If the title exists, push the designation and _id to its list
            briefingMap[title].similarBriefings.push({ _id, designation });
          } else {
            // If the title doesn't exist, create a new entry
            briefingMap[title] = {
              similarBriefings: [{ _id, designation }],
              date: createdDate,
              category: category,
              attachments: attachments
            };
          }
        });

        // Convert the object into an array of objects
        const organizedBriefings = Object.entries(briefingMap).map(([title, data]) => ({
          title,
          similarBriefings: data.similarBriefings,
          date: data.date,
          category: data.category,
          attachments: data.attachments
        }));

        return organizedBriefings;
      }


      const organizedBriefings = organizeBriefings(briefingList);
      console.log(organizedBriefings);
      setcombinebriefingList(organizedBriefings)

    }
  }, [briefingList])



  return (
    <div>
      <Navbar title={"Briefing List"} showarrow={false} />
      {loadingbar && <LoadingBar />}

      <BriefingFilterPanel staffCategory={staffCategory} category={category} setCategory={setCategory} setBriefingLimit={setBriefingLimit} briefinglimit={briefinglimit} setApplyFilter={setApplyFilter} setLoadingBar={setLoadingBar} designation={designation} setStaffDesignation={setStaffDesignation} enddate={enddate} setEndDate={setEndDate}
        startdate={startdate} setStartDate={setStartDate} />

      <section className="text-gray-600 body-font">
        <div className=" w-full  mt-5 grid grid-cols-3 gap-10">
          <div className="grid grid-cols-3 col-span-2 gap-5 ">
            {briefingList.length <= 0 && !loadingbar && <div className="ml-4">No Briefing Created Yet!</div>}
            {briefingList.map((briefing) => {
              return <BriefingDisplayCard data={briefing} />;
            })}
          </div>

          <div className="col-span-1">
            <div className="flex gap-5">

              <button onClick={() => setshowlistview(false)} className="  bg-gradient-to-r from-a-pink to-a-blue hover:to-a-pink hover:from-a-blue w-1/2 h-10 rounded-lg text-white uppercase text-xs">
                Combine View
              </button>
              <button onClick={() => setshowlistview(true)} className="  bg-gradient-to-r from-a-pink to-a-blue hover:to-a-pink hover:from-a-blue w-1/2 h-10 rounded-lg text-white uppercase text-xs">
                List View
              </button>

            </div>
            {showlistview && <div className=" mt-5 col-span-1 flex flex-col gap-5 bg-white p-5 h-screen overflow-scroll">
              {briefingList.map((briefing) => {
                return <BriefingDisplayCardList data={briefing} />;
              })}
            </div>}
            {!showlistview && <div className="mt-5 flex flex-col gap-5 bg-white p-5 h-screen overflow-scroll">
              {combinebriefingList.map((briefing, index) => {
                return <BriefingDisplayCardListCom data={briefing} key={index} />;
              })}
            </div>}
          </div>
        </div>
      </section>
    </div>
  );
};

const BriefingDisplayCard = ({ data }) => {
  return (
    <Link to={'/viewexpand/' + data._id} className="w-full  h-full">
      <div className="border h-full border-t-4 border-t-a-blue bg-stone-100 border-gray-200 p-6 rounded-lg flex flex-col justify-between">
        <p className="leading-relaxed text-xs">
          <div
            dangerouslySetInnerHTML={{ __html: data.content.substring(0, 200) }}
          />
        </p>

        <div>
          <p className="mt-1  text-xs text-gray-400">Created on: {new Date(data.createdDate).toISOString().split('T')[0]}</p>
          <p className="mt-1 rounded-md  text-xs bg-a-blue px-2 text-white py-1 uppercase">{data.category} For {data.designation}</p>
          <p className="mt-1 font-bold text-md">{data.title}</p>
          {/* <p className="mt-1 font-bold text-sm">{data.questions.length} Questions {data.noofsigned} Sign</p> */}
          <p className="mt-1 font-bold text-sm">{data.questions.length} Questions</p>
          <p className="mt-1 font-bold text-sm flex items-center gap-2"><AiOutlineFile /> {data.attachments.length}  Files Attached </p>
          {/* <PDFGenerator text={data.content} title={data.title.substring(0, 10) + ".pdf"} assetUrls={data.attachments} />
          {console.log(data.attachments)} */}
        </div>
      </div>
    </Link>
  );
};

const BriefingDisplayCardList = ({ data }) => {
  return (
    <Link to={'/viewexpand/' + data._id} className="w-full h-[100px]">
      <div className="border h-[100px] border-t-4 border-t-a-blue relative bg-a-gray text-white border-gray-200 p-3 rounded-lg flex flex-col justify-between">
        {/* <div className="leading-relaxed text-xs">
          <div
            dangerouslySetInnerHTML={{ __html: data.content.substring(0, 200) }}
          />
        </div> */}

        <p className="mt-1 rounded-md  text-xs bg-a-blue absolute right-2 bottom-2 px-2 text-white py-1 uppercase">{data.category} For {data.designation}</p>
        <div>
          <p className="mt-1 font-bold text-md">{data.title}</p>
          {/* <p className="mt-1 font-bold text-sm">{data.questions.length} Questions {data.noofsigned} Sign</p> */}
          <div className="flex gap-2 items-center">

            <p className="mt-1  text-xs text-gray-400">{new Date(data.createdDate).toISOString().split('T')[0]}</p>
            <p className="mt-1 font-bold text-sm">{data.questions.length} Questions</p>
            <p className="mt-1 font-bold text-sm flex items-center gap-2"><AiOutlineFile /> {data.attachments.length}  Files Attached </p>
          </div>
        </div>
      </div>
    </Link>
  );
};

const BriefingDisplayCardListCom = ({ data, key }) => {
  return (
    <div className="w-full h-[120px]" id={key}>
      <div className="border h-[120px] border-t-4 border-t-a-blue relative bg-a-gray text-white border-gray-200 p-3 rounded-lg flex flex-col justify-between">
        {/* <div className="leading-relaxed text-xs">
          <div
            dangerouslySetInnerHTML={{ __html: data.content.substring(0, 200) }}
          />
        </div> */}


        <div>
          <p className="mt-1 font-bold text-md">{data.title}</p>
          <div className=" flex gap-2 mt-2">
            {data['similarBriefings'].map((briefing) => {

              return <Link to={'/viewexpand/' + briefing._id} target="_blank" className="mt-1 rounded-md  text-xs bg-a-blue px-2 text-white py-1 uppercase">{briefing.designation}</Link>
            })}
          </div>
          {/* <p className="mt-1 font-bold text-sm">{data.questions.length} Questions {data.noofsigned} Sign</p> */}
          <div className="flex gap-2 items-center mt-2">

            <p className="mt-1  text-xs text-gray-400">{new Date(data.date).toISOString().split('T')[0]}</p>
            <p className="mt-1 font-bold text-sm">{data.category}</p>
            <p className="mt-1  text-sm">{data.attachments.length} Attachment</p>
          </div>
        </div>
      </div>
    </div>
  );
};

const BriefingFilterPanel = ({

  category, setCategory, setBriefingLimit, briefinglimit,
  setLoadingBar,
  setApplyFilter,
  designation,
  enddate, setEndDate,
  startdate, setStartDate,
  setStaffDesignation,
  staffCategory


}) => {

  const handleApplyFilter = () => {
    setApplyFilter(true)
    setLoadingBar(true)
  }
  return (
    <div className="bg-a-gray py-4 mt-5 rounded-lg flex flex-wrap  gap-3 items-center px-5 w-full   h-auto">
      <div>
        <p className="text-xs mb-1 text-white" > Start Date </p>

        <input
          onChange={(e) => {
            setStartDate(e.target.value);
          }}
          type="date"
          value={startdate}
          className="h-10 rounded-lg px-3"
          placeholder="Category"
        />
      </div>

      <div>
        <p className="text-xs mb-1 text-white" > End Date </p>

        <input
          onChange={(e) => {
            setEndDate(e.target.value);
          }}
          type="date"
          value={enddate}
          className="h-10 rounded-lg px-3"
          placeholder="Category"
        />
      </div>

      <div>
        <p className="text-xs mb-1 text-white" > Designation </p>
        {staffCategory}
        <select
          onChange={(e) => {
            setStaffDesignation(e.target.value);
          }}
          value={designation}
          className="h-10 rounded-lg px-3"
          placeholder="Designation"
        >
          <option value="">All</option>
          <option value="aa">AA</option>
          <option value="pa">PA</option>
          <option value="psa">PSA</option>
          <option value="PSA-HALA">PSA-HALA</option>
          <option value="PSA-VCT">PSA-VCT</option>
          <option value="tl">TL</option>
          {!(['tl'].includes(staffCategory)) && (<option value="css">CSS</option>)}
          {!(['tl', 'css'].includes(staffCategory)) && (<option value="csm">CSM</option>)}
          <option value="CSRA">CSRA</option>

        </select>

      </div>

      <div>
        <p className="text-xs mb-1 text-white" > Briefing Category</p>

        <select
          onChange={(e) => {
            setCategory(e.target.value);
          }}
          type="text"
          value={category}
          className="h-10 rounded-lg px-3"
          placeholder="Category"
        >
          <option value="">All</option>
          <option value="SOP">SOP</option>
          <option value="General Briefing">General Briefing</option>
          <option value="Safety Briefing">Safety Briefing</option>
          <option value="Immigration Guideline">Immigration Guideline </option>
          <option value="Product Knowledge">Product Knowledge</option>
          <option value="Work Instruction">Work Instruction</option>
          <option value="Memo">Memo</option>
          <option value="GSA">GSA</option>

          <option value="Ops Requirments">Ops Requirments</option>
          <option value="Other">Other</option>
        </select>
      </div>

      <div>
        <p className="text-xs mb-1 text-white" > Limit</p>


        <select
          onChange={(e) => {
            setBriefingLimit(e.target.value);
          }}
          value={briefinglimit}
          className="h-10 rounded-lg px-3"
          placeholder="limit"
        >
          <option value="1">1</option>
          <option value="5">5</option>
          <option selected value="10">10</option>
          <option value="20">20</option>
          <option value="30">30</option>
          <option value="">All</option>

        </select>

      </div>

      <button onClick={handleApplyFilter} className="  bg-gradient-to-r from-a-pink to-a-blue hover:to-a-pink hover:from-a-blue px-12 h-10 rounded-lg text-white uppercase text-xs">
        Apply
      </button>
    </div>
  );
};


export default BriefingDisplay;
