import React, { useState, useEffect, useSyncExternalStore } from "react";
import { useNavigate } from "react-router-dom";
import Utils from '../../utils.json';

// Removed unused imports
// import Navbar from "../Global/Navbar";
// import { toast } from "react-toastify";
// import LoadingBar from "../Global/LoadingBar";
// import BriefingDisplay from "../BriefingDisplay/BriefingDisplay";
// import { Link } from "react-router-dom";
// import { PiMicrosoftExcelLogoFill } from "react-icons/pi";
import { Bar, Pie, Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ArcElement, PointElement, LineElement } from 'chart.js';
// import 'chartjs-plugin-colorschemes';





// Removed Chart.js registration since it's not used
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ArcElement, PointElement, LineElement);

const Summary = () => {
  const navigate = useNavigate();
  const [loadingStatistics, setLoadingStatistics] = useState(false);
  const [dynamicStatistics, setDynamicStatistics] = useState({});
  const [adminLevel, setadminLevel] = useState('');


  useEffect(() => {
    const ls = localStorage.getItem('admin');
    if (!ls) {
      navigate('/login');
    }
    if (ls) {

      let admin = JSON.parse(ls)
      setadminLevel(admin.staffcategory)
    }
  }, [navigate]);

  useEffect(() => {
    if (adminLevel !== "") {
      { !['SUPER-ADMIN', 'ADM', 'HOD', 'HALA-MANAGER'].includes(adminLevel) && navigate("/view") }
    }
  }, [adminLevel])

  useEffect(() => {
    setLoadingStatistics(true);
    fetch(`${Utils['domains']['development']}/sbpapis/statistics`, {
      method: "get",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setDynamicStatistics(data);
        setLoadingStatistics(false);

      })
      .catch((error) => {
        console.error('Error fetching statistics:', error);
        setLoadingStatistics(false);
      });
  }, []);



  useEffect(() => {
    // Demo data for the charts
    const recordTypeData = dynamicStatistics.SpmsSCH?.['record-type'];
    if (recordTypeData) {
      const demoData = {
        labels: Object.keys(dynamicStatistics.SpmsSCH?.['record-type']),
        datasets: [
          {
            label: 'Records Type',
            data: Object.values(dynamicStatistics.SpmsSCH?.['record-type']),
            backgroundColor: 'rgba(75, 192, 192, 0.6)',
          },
        ],
      };
    }
  }, [dynamicStatistics])



  return (
    <div className="bg-white p-5 rounded-md">
      {loadingStatistics ? (
        <div>Loading...</div> // You might want to replace this with a spinner or loading component
      ) : (
        <div className="grid gap-5">
          <StatesCard data={dynamicStatistics} />
          <div className="bg-gray-200 w-full flex items-center justify-center p-10 gap-2 flex-col rounded-md">

            {dynamicStatistics.SpmsSCH?.['record-type'] && <Bar data={{
              labels: Object.keys(dynamicStatistics.SpmsSCH?.['record-type']),
              datasets: [
                {
                  label: 'Record Type',
                  data: Object.values(dynamicStatistics.SpmsSCH?.['record-type']),
                  backgroundColor: ['rgba(255, 107, 107, 0.8)', 'rgba(56, 103, 214, 0.8)',]

                },
              ],
            }}
            />}
          </div>
          <SPMSCard data={dynamicStatistics} />

          {/*  */}
          <div className="grid grid-cols-3 gap-4"> {/* Added gap for spacing */}
            <div className="bg-gray-200 h-[400px] p-10 rounded-md flex items-center justify-center">
              <Pie data={{
                labels: ['ROD', 'ROA'],
                datasets: [
                  {
                    data: [dynamicStatistics.SpmsSCH?.['record-type']?.['ROD'], dynamicStatistics.SpmsSCH?.['record-type']?.['ROA']],
                    backgroundColor: ['rgba(56, 103, 214, 0.8)', 'rgba(255, 107, 107, 0.8)'],
                    borderColor: 'transparent',  // Remove the border
                    borderWidth: 0,  // Ensure border width is 0
                  },
                ],
              }} />
            </div>
            <div className="bg-gray-200 h-[400px] p-10 rounded-md flex items-center justify-center">
              <Pie data={{
                labels: ['Verbal Warning', 'Written Warning', 'Final Warning'],
                datasets: [
                  {
                    data: [dynamicStatistics.SpmsSCH?.['record-type']?.['Verbal Warning'], dynamicStatistics.SpmsSCH?.['record-type']?.['Written Warning'], dynamicStatistics.SpmsSCH?.['record-type']?.['Final Warning']],
                    backgroundColor: ['rgba(255, 107, 107, 0.8)', 'rgba(56, 103, 214, 0.8)', '#FFCE56'],
                    borderColor: 'transparent',  // Remove the border
                    borderWidth: 0,  // Ensure border width is 0
                  },
                ],
              }} />
            </div>
            <div className="bg-gray-200 h-[400px] p-10 rounded-md flex items-center justify-center">
              <Pie data={{
                labels: ['Airlines Appreciation', 'Airline Complaints'],
                datasets: [
                  {
                    data: [dynamicStatistics.SpmsSCH?.['record-type']?.['Airlines Appreciation'], dynamicStatistics.SpmsSCH?.['record-type']?.['Airline Complaints']],
                    backgroundColor: ['rgba(255, 107, 107, 0.8)', 'rgba(56, 103, 214, 0.8)'],
                    borderColor: 'transparent',  // Remove the border
                    borderWidth: 0,  // Ensure border width is 0
                  },
                ],
              }} />
            </div>
          </div>

          {/* Staff */}
          <STaffCard data={dynamicStatistics}/>

        </div>
      )}
    </div>
  );
};

const StatesCard = ({ data }) => {
  return (
    <div className="grid grid-cols-3 gap-4"> {/* Added gap for spacing */}
      <StatesCardItem key="Total" label="Total" value={data.StaffSignSCH?.total.toLocaleString()} />
      <StatesCardItem key="Signed" label="Signed" value={data.StaffSignSCH?.sign.toLocaleString()} />
      <StatesCardItem key="Not Sign Yet" label="Not Sign Yet" value={data.StaffSignSCH?.['no-sign'].toLocaleString()} />
    </div>
  );
}

const StatesCardItem = ({ label, value }) => {
  return (
    <div className="bg-gray-200 p-4 h-[170px]  rounded-md">
      <div >{label}</div>
      <div className="font-bold text-3xl">{value}</div>
    </div>
  );
}

// ------------------------------------------------------------------------------------------------------------------------------------------
const SPMSCard = ({ data }) => {

  const pieData = {
    labels: ['Positive', 'Negative'],
    datasets: [
      {
        data: [data.SpmsSCH?.['positive'], data.SpmsSCH?.['negative']],
        backgroundColor: ['rgba(255, 107, 107, 0.8)', 'rgba(56, 103, 214, 0.8)'],
        borderColor: 'transparent',  // Remove the border
        borderWidth: 0,  // Ensure border width is 0
      },
    ],
  };

  const total = data.SpmsSCH?.total || 0;
const positive = data.SpmsSCH?.positive || 0;
const negative = data.SpmsSCH?.negative || 0;

// Calculate percentages
const positivePercentage = total > 0 ? (positive / total) * 100 : 0;
const negativePercentage = total > 0 ? (negative / total) * 100 : 0;

// Format the percentages (optional)
const formattedPositivePercentage = positivePercentage.toFixed(2); // Format to 2 decimal places
const formattedNegativePercentage = negativePercentage.toFixed(2); // Format to 2 decimal places

  return (
    <div className="grid grid-cols-3 gap-4"> {/* Added gap for spacing */}
      <SPMSCardItem key="Total" label="Total Records" value={data.SpmsSCH?.total.toLocaleString()} />
      <div className="bg-gray-200 h-[400px] p-10 rounded-md flex items-center justify-center">
        <Pie data={pieData} />
      </div>
      <div className="h-[400px] gap-4 grid grid-cols-2">
        <div className="bg-gray-200  flex items-center justify-center gap-2 flex-col rounded-md">
          <div >Positive</div>
          <p className="font-bold text-3xl">{data.SpmsSCH?.['positive'].toLocaleString()}</p>
        </div>
        <div className="bg-gray-200  flex items-center justify-center gap-2 flex-col rounded-md">
          <div >Percentage</div>
          <p className="font-bold text-3xl">{Math.round(formattedPositivePercentage).toString()}%</p>
        </div>
        <div className="bg-gray-200  flex items-center justify-center gap-2 flex-col rounded-md">
          <div >Negative</div>
          <p className="font-bold text-3xl">{data.SpmsSCH?.['negative'].toLocaleString()}</p>
        </div>
        <div className="bg-gray-200  flex items-center justify-center gap-2 flex-col rounded-md">
          <div >Percentage</div>
          <p className="font-bold text-3xl">{Math.round(formattedNegativePercentage).toString()}%</p>
        </div>
      </div>




    </div>
  );
}

const SPMSCardItem = ({ label, value }) => {
  return (
    <div className="bg-gray-200 h-[400px] flex items-center justify-center gap-2 flex-col rounded-md">
      <div >{label}</div>
      <div className="font-bold text-7xl">{value}</div>
    </div>
  );
}

const STaffCard = ({ data }) => {

  // const pieData = {
  //   labels: ['Positive', 'Negative'],
  //   datasets: [
  //     {
  //       data: [data.SpmsSCH?.['positive'], data.SpmsSCH?.['negative']],
  //       backgroundColor: ['rgba(255, 107, 107, 0.8)', 'rgba(56, 103, 214, 0.8)'],
  //       borderColor: 'transparent',  // Remove the border
  //       borderWidth: 0,  // Ensure border width is 0
  //     },
  //   ],
  // };



  return (
    <div className="grid grid-cols-3 gap-4"> {/* Added gap for spacing */}
      {/* <SPMSCardItem key="Total" label="Total Records" value={data.SpmsSCH?.total.toLocaleString()} />
      <div className="bg-gray-200 h-[400px] p-10 rounded-md flex items-center justify-center">
        <Pie data={pieData} />
      </div> */}
      <div className="h-[400px] gap-4 grid grid-cols-2">
        <div className="bg-gray-200  flex items-center justify-center gap-2 flex-col rounded-md">
          <div >Total Staff</div>
          <p className="font-bold text-3xl">{data.Staff?.['total'].toLocaleString()}</p>
        </div>
        <div className="bg-gray-200  flex items-center justify-center gap-2 flex-col rounded-md">
          <div >Active</div>
          <p className="font-bold text-3xl">{data.Staff?.['active-staff'].toLocaleString()}</p>
        </div>
        <div className="bg-gray-200  flex items-center justify-center gap-2 flex-col rounded-md">
          <div >Resign</div>
          <p className="font-bold text-3xl">{data.Staff?.['resign-staff'].toLocaleString()}</p>
        </div>
        <div className="bg-gray-200  flex items-center justify-center gap-2 flex-col rounded-md">
          <div >Others</div>
          <p className="font-bold text-3xl">N/A</p>
        </div>
      </div>
    </div>
  );
}

export default Summary;
