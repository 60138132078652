import React, { useEffect, useState } from "react";
import Navbar from "../Global/Navbar";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import LoadingBar from "../Global/LoadingBar";
import { PiMicrosoftExcelLogoFill } from "react-icons/pi";
import { AiFillDelete, AiOutlineCheckCircle } from "react-icons/ai";
import { TiTick } from "react-icons/ti";

import Utils from  '../../utils.json';

import { MdHistory } from "react-icons/md";

const Actions = () => {
  const navigate = useNavigate();

  useEffect(() => {
    let ls = localStorage.getItem("admin");
    if (!ls) {
      navigate("/login");
    }
    return;
  });

  const [staffList, setStaffList] = useState([]);
  const [loadingbar, setLoadingBar] = useState(true);

  // create new staff
  const [staffid, setstaffid] = useState("");
  const [tempstaffid, setempstaffid] = useState("");

  // action plan
  const [actionwith, setactionwith] = useState("");
  const [tempactionwith, settempactionwith] = useState("");

  const [actionstatus, setactionstatus] = useState("");

  // filter
  const [applyFilter, setApplyFilter] = useState(false);
  const [stafffetchlimit, setstafffetchlimit] = useState(20);

  // new state
  const [logslist, setloglist] = useState([]);
  // const [stafflist, setstafflist] = useState([]);

  useEffect(() => {
    fetch(`${Utils['domains']['development']}/spms/getstafflist`, {
      method: "get",
      headers: {
        "Content-Type": "application/json", // Specify that you're sending JSON data
      },
      // body: JSON.stringify({}), // Set the JSON data as the request body
    })
      .then((e) => {
        return e.json();
      })
      .then((data) => {
        setTimeout(() => {
          //   setLoadingBar(false);
          setStaffList(data);
        }, 500);
      });
  }, []);

  useEffect(() => {
    fetch(`${Utils['domains']['development']}/actions/view`, {
      method: "get",
      headers: {
        "Content-Type": "application/json", // Specify that you're sending JSON data
      },
      // body: JSON.stringify({}), // Set the JSON data as the request body
    })
      .then((e) => {
        return e.json();
      })
      .then((data) => {
        setTimeout(() => {
          setLoadingBar(false);
          setloglist(data);
          console.log(data);
        }, 0);
      });
  }, []);

  useEffect(() => {
    if (applyFilter) {
      let data = { staff: staffid, actionwith, status: actionstatus };
      fetch(`${Utils['domains']['development']}/actions/viewbyfilter`, {
        method: "post",
        headers: {
          "Content-Type": "application/json", // Specify that you're sending JSON data
        },
        body: JSON.stringify(data), // Set the JSON data as the request body
      })
        .then((e) => {
          return e.json();
        })
        .then((data) => {
          setTimeout(() => {
            setLoadingBar(false);
            setloglist(data);
            setApplyFilter(false);
          }, 0);
        });
    }
  }, [applyFilter]);

  const HandleExportAsExcel = () => {
    const newStaffList = staffList.map((item) => {
      const updatedItem = { ...item };
      delete updatedItem.staffbriefing;
      delete updatedItem._id;
      // delete updatedItem.briefingid;
      delete updatedItem.__v;
      return updatedItem;
    });
    // const newStaffList = chartsBriefingList;

    const separator = ",";
    const keys = Object.keys(newStaffList[0]);
    const csvContent = [
      keys.join(separator),
      ...newStaffList.map((item) =>
        keys.map((key) => item[key]).join(separator)
      ),
    ].join("\n");

    const blob = new Blob([csvContent], { type: "text/csv" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "data.csv";
    a.click();
    URL.revokeObjectURL(url);
    setTimeout(() => {
      a.remove();
    }, 1000);
  };

   const handleUpdateAction = (key, value, id) => {
    const data = {
      key, value, id
    }
    fetch(`${Utils['domains']['development']}/actions/update`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json", // Specify that you're sending JSON data
      },
      body: JSON.stringify(data), // Set the JSON data as the request body
    }).then((e) => {
      toast('Details updated successfully!', {autoClose: 2000})
      setApplyFilter(true);
      setLoadingBar(true)
    });

  }

  return (
    <div>
      <Navbar title={"Staff List"} showarrow={false} />
      {loadingbar && <LoadingBar />}

      <AddNewStaffPanel
      handleUpdateAction={handleUpdateAction}
        setstaffid={setstaffid}
        staffid={staffid}
        tempstaffid={tempstaffid}
        settempstaffid={setempstaffid}
        setactionstatus={setactionstatus}
        actionstatus={actionstatus}
        HandleExportAsExcel={HandleExportAsExcel}
        actionwith={actionwith}
        setactionwith={setactionwith}
        tempactionwith={tempactionwith}
        settempactionwith={settempactionwith}
        staffList={staffList}
        setApplyFilter={setApplyFilter}
        setLoadingBar={setLoadingBar}
      />

      <div class="relative rounded-xl overflow-auto">
        <div class="shadow-sm overflow-x-scroll  lg:overflow-hidden my-8">
          {logslist.length <= 0 && !loadingbar && (
            <div className="">No Log added yet!</div>
          )}

          {!logslist.length <= 0 && (
            <StaffTable loadingbar={loadingbar} logslist={logslist} handleUpdateAction={handleUpdateAction} setApplyFilter={setApplyFilter} setLoadingBar={setLoadingBar}/>
          )}
        </div>
      </div>
    </div>
  );
};

const AddNewStaffPanel = ({
  settempstaffid,
  setstaffid,

  handleUpdateAction,

  setApplyFilter,
  setLoadingBar,
  staffList,
  tempstaffid,
  setactionstatus,
  actionstatus,

  actionwith,
  setactionwith,
  tempactionwith,
  settempactionwith,

  HandleExportAsExcel,
}) => {
  const handleApplyFilter = () => {
    setApplyFilter(true);
    setLoadingBar(true);
  };
  return (
    <div className="bg-a-gray py-4 mt-5 rounded-lg flex flex-wrap lg:flex-nowrap  gap-3 items-center px-5 w-full   h-auto">
      <input
        type="search"
        className="h-10 rounded-lg px-3 "
        placeholder={staffList.length == 0 ? "Loading..." : "Staff"}
        value={tempstaffid}
        onChange={(e) => {
          let extractstaffid = e.target.value.split(" - ")[3];
          settempstaffid(e.target.value.split(" - ")[1]);
          setstaffid(extractstaffid);
        }}
        list="stafflist"
      />
      <datalist id="stafflist">
        {staffList.map((staff, index) => {
          return (
            <option
              value={
                staff.staffid +
                " - " +
                staff.staffname +
                " - " +
                staff.staffcategory +
                " - " +
                staff._id
              }
            />
          );
        })}
      </datalist>

      <input
        type="search"
        className="h-10 rounded-lg px-3 font-normal text-sm bg-[#eee] "
        placeholder={staffList.length == 0 ? "Loading..." : "Assign To"}
        value={tempactionwith}
        onChange={(e) => {
          let extractstaffid = e.target.value.split(" - ")[3];
          let extracttempstaffid = e.target.value.split(" - ")[1];
          if (e.target.value === "") {
            extractstaffid = "";
            extracttempstaffid = "";
          }
          settempactionwith(extracttempstaffid);
          setactionwith(extractstaffid);
        }}
        list="actionwith"
      />
      <datalist id="actionwith">
        {staffList.map((staff, index) => {
          return (
            <option
              value={
                staff.staffid +
                " - " +
                staff.staffname +
                " - " +
                staff.staffcategory +
                " - " +
                staff._id
              }
            />
          );
        })}
      </datalist>

      {/* <input
        type="text"
        className="h-10 rounded-lg px-3"
        placeholder="Staff Name"
        value={staffname}
        onChange={(e) => {
          setStaffName(e.target.value);
        }}
      /> */}
      {/* 
      <input
        type="text"
        className="h-10 rounded-lg px-3 "
        placeholder="Password"
        value={staffpassword}
        onChange={(e) => {
          setStaffPassword(e.target.value);
        }}
      /> */}
      <select
        onChange={(e) => {
          setactionstatus(e.target.value);
        }}
        value={actionstatus}
        className="h-10 rounded-lg px-3"
        placeholder="Designation"
      >
        <option value="">All</option>
        <option value="assigner-release">Awating For Recipient</option>
        <option value="recipient-release">Awating For Approve</option>
        <option value="approved">Approved</option>
      </select>

      {/* 
      <input
        type="checkbox"
        className="h-10 rounded-lg px-3"
        placeholder="Staff Name"
        value={controlpanelaccess}
        onChange={(e) => {
          setcontrolpanelaccess(e.target.checked);
        }}
      /> */}
      {/* <select
        onChange={(e) => {
          setstafffetchlimit(e.target.value);
        }}
        value={stafffetchlimit}
        className="h-10 rounded-lg px-3"
        placeholder="limit"
      >
        <option value="5">5</option>
        <option selected value="10">
          10
        </option>
        <option value="20">20</option>
        <option value="30">30</option>
      </select> */}

      <button
        onClick={handleApplyFilter}
        className="  bg-gradient-to-r from-a-pink to-a-blue hover:to-a-pink hover:from-a-blue px-12 h-10 rounded-lg text-white uppercase text-xs"
      >
        Apply
      </button>
      {/* <button
        onClick={HandleExportAsExcel}
        className="flex items-center gap-2  bg-green-600 px-12 h-10 rounded-lg text-white uppercase text-xs"
      >
        <PiMicrosoftExcelLogoFill size="22px" />
        Export Excel
      </button> */}
    </div>
  );
};

const StaffTable = ({ loadingbar, logslist, handleUpdateAction, setApplyFilter, setLoadingBar }) => {
  return (
    <table class="border-collapse  w-full text-sm rounded-lg overflow-x-scroll    ">
      <thead className="bg-gradient-to-r from-a-pink to-a-blue text-white">
        <tr>
          <td class="border-b border-r border-slate-100 dark:border-slate-700 p-4 pl-8  text-white">
            Staff ID
          </td>
          <td class="border-b border-r border-slate-100 dark:border-slate-700 p-4  text-white">
            Staff Name
          </td>
          <td class="border-b border-r border-slate-100 dark:border-slate-700 p-4  text-white">
            Action With
          </td>
          <td class="border-b border-r border-slate-100 dark:border-slate-700 p-4  text-white">
            Action Note
          </td>
          <td class="border-b border-r border-slate-100 dark:border-slate-700 p-4 pr-8  text-white">
            Action Note By Recipient
          </td>

          <td class="border-b border-r border-slate-100 dark:border-slate-700 p-4 pr-8  text-white">
            Status
          </td>

      
        </tr>
      </thead>
      <tbody class="bg-a-gray text-white">
        {logslist.map((log) => {
          return <TableRow log={log} handleUpdateAction={handleUpdateAction} setApplyFilter={setApplyFilter} setLoadingBar={setLoadingBar} />;
        })}
      </tbody>
    </table>
  );
};

const TableRow = ({ log, handleUpdateAction, setApplyFilter, setLoadingBar }) => {
  return (
    <tr>
      <td class="border-b border-r border-slate-100 dark:border-slate-700 p-4 pl-8  text-white">
        {log.staff.staffid}
      </td>
      <td class="border-b border-r border-slate-100 dark:border-slate-700 p-4  text-white">
        {log.staff.staffname}
      </td>
      <td class="border-b border-r border-slate-100 dark:border-slate-700 p-4  text-white">
        {log.actionwith.staffname}
      </td>

      <td class="border-b border-r border-slate-100 dark:border-slate-700 p-4  text-white">
        {log.actionnote}
      </td>
      <td class="border-b border-r border-slate-100 dark:border-slate-700 p-4  text-white">
        {log.actionnotebyrecipient}
      </td>
      <td class="border-b border-r border-slate-100 dark:border-slate-700 p-4  text-white flex items-center gap-2">
        {log.status === `assigner-release` && (
          <MdHistory className="text-yellow-400" size="25px" />
        )}
        {log.status === `assigner-release` &&
          (`Awaiting For  "${log.actionwith.staffname.substring(0, 10)}"`)}

        {log.status === `recipient-release` && (
          <button onClick={(e)=>{
            let con = window.confirm('Are you sure you want to approved this?')
            if (con) {
              handleUpdateAction('status', 'approved', log._id );
            }

          }} className="flex items-center gap-2  bg-red-600 px-4 h-10 rounded-lg text-white uppercase text-xs">
            <AiOutlineCheckCircle size="22px" />
          Pending Approval
          </button>
        )}
          {log.status === `approved` && (
          // <button disabled className="cursor-not-allowed flex items-center gap-2  bg-green-600 px-4 h-10 rounded-lg text-white uppercase text-xs">
          //   <AiOutlineCheckCircle size="22px" />
          //   Approved
          // </button>
          <span className="flex gap-2">

            <AiOutlineCheckCircle size="22px" className="text-green-600" />
            Approved
          </span>
        )}
      </td>

 
    </tr>
  );
};

export default Actions;
