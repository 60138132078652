import React, { useEffect, useState } from "react";
import { Editor } from "@tinymce/tinymce-react";
import Questions from "./Questions";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useDropzone } from "react-dropzone";
import Utils from '../../utils.json';

import { MiniLoadingBar } from "../Global/LoadingBar";

import { BiCloudUpload } from "react-icons/bi";
import { AiOutlineFile, AiOutlineDelete } from "react-icons/ai";
import { FaStar } from "react-icons/fa6"
import { FaRegStar } from "react-icons/fa"



const Create = () => {
  const navigate = useNavigate();
  const [adminLevel, setadminLevel] = useState('');


  useEffect(() => {
    let ls = localStorage.getItem('admin');
    if (!ls) {
      navigate('/login')
    }

    if (ls) {

      let admin = JSON.parse(ls)
      setadminLevel(admin.staffcategory)
    }

  })

  useEffect(() => {
    if (adminLevel !== "") {
      { !['SUPER-ADMIN', 'csm', 'ADM', 'HOD', 'HALA-MANAGER'].includes(adminLevel) && navigate("/view") }
    }
  }, [adminLevel])

  const [title, setTitle] = useState("");
  const [designation, setDesignation] = useState("all");
  const [customDesignation, setCustomDesignation] = useState([]);
  const [showCustomDesignation, setShowCustomDesignation] = useState(false);
  const [showMannualStaffSelection, setshowMannualStaffSelection] = useState(false);
  const [importantBriefing, setimportantBriefing] = useState(false)

  const [category, setCategory] = useState("SOP");
  const [stafftype, setStafftype] = useState("");
  const [content, setContent] = useState("");
  const [attachments, setAttachments] = useState([]);
  const [initialBlockTimer, setInitialBlockTimer] = useState(30);

  const [selectedStaffList, setSelectedStaffList] = useState([]);
  const [tempstaffid, settempstaffid] = useState("");

  useEffect(()=>{
    const selectedStaffList_ids = selectedStaffList.map(staff => staff.staffId);

console.log(selectedStaffList_ids)
  }, [selectedStaffList])

  // selectedStaffList, setSelectedStaffList, tempstaffid, settempstaffid
  // selectedStaffList={selectedStaffList} setSelectedStaffList={setSelectedStaffList} tempstaffid={tempstaffid} settempstaffid={settempstaffid}


  // Quetions
  const [questionQuantity, setQuestionQuantity] = useState(3);
  const [questions, setQuestions] = useState([
    {
      qid: "1",
      question: "Question 1",
      options: [
        { opt: "Option 1", correct: true },
        { opt: "Option 2", correct: false },
        { opt: "Option 3", correct: false },
        { opt: "Option 4", correct: false },
      ],
    },
  ]);

  const handleBriefingCreate = () => {

    let logby = '';
    if (localStorage.getItem('admin')) {
      logby = JSON.parse(localStorage.getItem('admin'))._id;
    }


    if (title === "") {
      toast.error("Title is required!", { autoClose: 2000 });
      return false;
    }

    if (title !== title.trim()) {
      toast.error("Extra Space found in the title, please remove it.", { autoClose: 2000 });
      return false;
    }

    if (content === "") {
      toast.error("Content is required For the briefing!", { autoClose: 2000 });
      return false;
    }



    // return false;
    const data = {
      title,
      designation,
      customDesignation,
      category,
      stafftype,
      content,
      questions,
      attachments,
      initialBlockTimer,
      importantBriefing,
      logby,

    };
    fetch(`${Utils['domains']['development']}/briefing/create`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json", // Specify that you're sending JSON data
      },
      body: JSON.stringify(data), // Set the JSON data as the request body
    }).then((e) => {
      toast("New Briefing Created Successfully", { autoClose: 2000 });
      setTitle("");
      setDesignation("all");
      setCategory("SOP");
      setTimeout(() => {
        navigate("/view");
      }, 2000);
    });
  };

  const handleAddQuestion = () => {
    const newQuestion = {
      qid: `${questions.length + 1}`,
      question: `Question  ${questions.length + 1}`,
      options: [
        { opt: "", correct: true },
        { opt: "", correct: false },
        { opt: "", correct: false },
        { opt: "", correct: false },
      ],
    };
    setQuestions([...questions, newQuestion]);
    toast.success("New Question Added Successfully!", { autoClose: 2000 });
  };

  const handleRemoveQuestion = () => {

    let updatedquestionslist = questions.slice(0, -1);

    setQuestions(updatedquestionslist);

    toast.error("Question Removed Successfully!", { autoClose: 2000 });
  };

  const handleQuestionChange = (qid, value) => {
    const updatedQuestions = questions.map((question) =>
      question.qid === qid ? { ...question, question: value } : question
    );
    setQuestions(updatedQuestions);

  };

  const handleOptionChange = (qid, index, value) => {
    const updatedQuestions = questions.map((question) => {
      if (question.qid === qid) {
        const updatedOptions = [...question.options];
        updatedOptions[index].opt = value;
        return { ...question, options: updatedOptions };
      }
      return question;
    });
    setQuestions(updatedQuestions);

  };

  const handleCorrectOptionChange = (qid, index) => {
    const updatedQuestions = questions.map((question) => {
      if (question.qid === qid) {
        const updatedOptions = question.options.map((option, i) => ({
          ...option,
          correct: i === index,
        }));
        return { ...question, options: updatedOptions };
      }
      return question;
    });
    setQuestions(updatedQuestions);

  };

  const [activeSectionBri, setactiveSectionBri] = useState("content");

  // const handleSecBri = (active='content') => {
  //   setactiveSectionBri(active)
  // }

  return (
    <div className="w-full">

      {/*  */}
      <p className="mb-5 bg-white p-5 rounded-lg">Create New Briefing</p>

      <div className="flex gap-5 w-full">
        {/* Input Group */}
        <InputGroup
          title={title}
          importantBriefing={importantBriefing}
          setimportantBriefing={setimportantBriefing}
          designation={designation}
          category={category}
          stafftype={stafftype}
          content={content}
          setTitle={setTitle}
          setDesignation={setDesignation}
          setStafftype={setStafftype}
          
 
  selectedStaffList={selectedStaffList} setSelectedStaffList={setSelectedStaffList} tempstaffid={tempstaffid} settempstaffid={settempstaffid}
          setCategory={setCategory}
          customDesignation={customDesignation}
          setCustomDesignation={setCustomDesignation}
          showCustomDesignation={showCustomDesignation}
          setShowCustomDesignation={setShowCustomDesignation}
          showMannualStaffSelection={showMannualStaffSelection} setshowMannualStaffSelection={setshowMannualStaffSelection}
          initialBlockTimer={initialBlockTimer} setInitialBlockTimer={setInitialBlockTimer}
        />

        {/*  */}
        <div className="flex gap-5 flex-col w-full">
          {/*  */}
          <div className="flex gap-5">
            <button
              onClick={() => setactiveSectionBri("content")}
              className={`px-10 py-3 rounded-lg uppercase text-md ${activeSectionBri === 'content' ? "bg-[#444] text-white" : "bg-[#fff] text-[#777]"
                }`}
            >
              Content
            </button>
            <button
              onClick={() => setactiveSectionBri("files")}
              className={`px-10 py-3 rounded-lg uppercase text-md ${activeSectionBri === 'files' ? "bg-[#444] text-white" : "bg-[#fff] text-[#777]"
                }`}
            >
              Files
            </button>
            <button
              onClick={() => setactiveSectionBri("questions")}
              className={`px-10 py-3 rounded-lg uppercase text-md ${activeSectionBri === 'questions' ? "bg-[#444] text-white" : "bg-[#fff] text-[#777]"
                }`}
            >
              Questions
            </button>
          </div>


          {/* textarea */}
          <div className="w-full">
            {/* {activeSectionBri == 'content' && */}
            {true &&

              <TextArea content={content} setContent={setContent} />
            }
            {/* {activeSectionBri == 'files' && */}
            {true &&

              <AddAttachment
                attachments={attachments}
                setAttachments={setAttachments}
              />}

            {/* create btn */}
            <div className="w-full flex justify-end">
              <button
                onClick={handleBriefingCreate}
                className="mt-5  bg-gradient-to-r from-a-pink to-a-blue hover:to-a-pink hover:from-a-blue px-12 py-4 rounded-lg text-white uppercase text-md
              fixed bottom-5 right-5 border-2 border-a-gray
              shadow-md
              "
              >
                Create
              </button>

              {/* react */}
              {/* <button className="bg-gradient-to-r from-a-pink to-a-blue px-6 py-2 border-none rounded-md text-white mt-5 uppercase ">Create</button>  */}

              {/* traditional */}
              {/* <button style="padding: 2px; border: none; border-radius "></button> */}
            </div>
          </div>

          {/* q & a */}
          {/* {activeSectionBri == 'questions' && */}
          {true &&
            <div className="w-full flex flex-col gap-10 bg-white rounded-lg p-5">

              <div className="p-5 bg-[yellow] font-black">

                If there is no question so please removed question template by clicking on the red button below

              </div>

              <div className="flex gap-5 items-center">
                <p>{questions.length} No Of Questions</p>

                <button
                  onClick={handleAddQuestion}
                  className="mt-5  bg-green-500 hover:bg-green-600 lg:px-12 px-3 py-1 lg:py-4 transition-all  rounded-lg text-white uppercase text-xs"
                >
                  Add Question
                </button>

                <button
                  className="mt-5 bg-red-600 hover:bg-red-500 transition-all px-3 py-1  lg:px-12 lg:py-4 rounded-lg text-white uppercase text-xs"
                  onClick={handleRemoveQuestion}
                >
                  Remove Question
                </button>
              </div>

              <div className="flex gap-5 flex-wrap">
                {questions.map((question) => {
                  return (
                    <button className="bg-a-gray text-white p-2 rounded">
                      Question {question.qid}
                    </button>
                  );
                })}
              </div>

              <div className="grid grid-cols-2	gap-10">

                {questions.map((question, index) => {
                  return (
                    <Questions
                      key={index}
                      qid={question.qid}
                      entirequestion={question}
                      handleQuestionChange={handleQuestionChange}
                      handleOptionChange={handleOptionChange}
                      handleCorrectOptionChange={handleCorrectOptionChange}
                    />
                  );
                })}
              </div>
            </div>}
        </div>
      </div>
    </div>
  );
};

const AddAttachment = ({ attachments, setAttachments }) => {
  const [files, setFiles] = useState([]); // Use an array for multiple files
  const [uploadFile, setUploadFile] = useState(false); // Use an array for multiple

  const onDrop = (acceptedFiles) => {
    const selectedFile = acceptedFiles[0];
    const uniquekey = `${Date.now()}`;
    if (selectedFile) {
      selectedFile.status = "pending";
      selectedFile.newfilename = "";
      selectedFile.key = uniquekey;

      setFiles([selectedFile, ...files]); // Append new files to the existing list
      setAttachments([
        { key: uniquekey, filename: selectedFile.name, newfilename: "" },
        ...attachments,
      ]);

      setUploadFile(true);
    }
    // alert(); // This is just for debugging purposes, you can remove it
  };

  useEffect(() => {
    if (uploadFile) {
      const formData = new FormData();
      formData.append("file", files[0]);
      formData.append("key", files[0].key);

      const response = fetch(`${Utils['domains']['development']}/files/upload`, {
        method: "POST",
        body: formData,
      })
        .then((e) => {
          return e.json();
        })
        .then((data) => {

          setUploadFile(false);
          const updatedFiles = files.map((file) => {

            if (file.key === data.key) {
              file.status = "uploaded";
              file.newfilename = data.newfilename;
            }

            return file;
            // const updatedFile = {
            //   ...JSON.parse(JSON.stringify(file)),
            //   status: "uploaded",
            // };
            // return updatedFile;
          });

          const modifyattachments = attachments.map((file) => {
            if (file.key === data.key) {
              file.newfilename = data.newfilename;
            }
            return file;
          });

          setAttachments(modifyattachments);



          // console.table(files)
          // console.table(updatedFiles)
          // // setFiles(updatedFiles)
        });
    }
  }, [uploadFile]);

  const handleDeleteFile = (uid) => {
    // Create a new array that excludes the item with the specified index (uid)
    const updatedFiles = files.filter((_, index) => index !== uid);
    const updatedattachment = attachments.filter((_, index) => index !== uid);


    // Update the state with the new array
    setFiles(updatedFiles);
    setAttachments(updatedattachment);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: false,
    // accept: 'image/*', // Define the accepted file types
  });

  return (
    <div className="grid grid-cols-2	gap-5">
      <div
        {...getRootProps()}
        className="dropzone w-full cursor-pointer  h-[600px] bg-white mt-5 rounded-lg flex flex-col items-center justify-center gap-2"
      >
        <input {...getInputProps()} name="file" />
        <BiCloudUpload size="100px" className="text-a-pink" />
        <p className="text-2xl"> Drag & drop file or browse! </p>
      </div>
      <div className="bg-white p-5 mt-5 rounded-lg grid grid-cols-2 gap-3">
        {files.map((file, index) => {

          return (
            <FileDisplay
              file={file}
              uid={index}
              handleDeleteFile={handleDeleteFile}
            />
          );
        })}
      </div>
      {/* <button onClick={uploadFiles}>Upload Testing</button> */}

      {/* {file && (
      <div>
        <p>Selected File: {file.name}</p>
        <button>Upload</button>
      </div>
    )} */}
    </div>
  );
};

const FileDisplay = ({ file, uid, handleDeleteFile }) => {
  return (
    <div className="w-full cursor-pointer  h-12 bg-gray-200 border-l-4 border-l-a-pink border border-gray-300  rounded-lg flex items-center gap-2 px-5 justify-between">
      <div
        className="flex items-center gap-2 relative"
        title={file.name + " " + file.newfilename}
      >
        {file.status === "pending" && <MiniLoadingBar />}
        {file.status === "uploaded" && <AiOutlineFile />}
        {/* <MiniLoadingBar/> */}
        <p>
          {file.name.substring(0, 5)} <span className="text-a-pink">···</span>{" "}
          {file.name.substring(file.name.length - 5)}
        </p>
      </div>
      <div
        onClick={() => handleDeleteFile(uid)}
        className="bg-red-500 w-8 h-8 rounded-full grid place-items-center"
      >
        <AiOutlineDelete className="text-white" size="17px" />
      </div>
    </div>
  );
};

// Input Group
const InputGroup = ({
  title,
  designation,
  stafftype,
  category,
  setTitle,
  setDesignation,
  customDesignation,
  setCustomDesignation,
  showCustomDesignation,
  setShowCustomDesignation,
  importantBriefing,
  setimportantBriefing,
  initialBlockTimer, setInitialBlockTimer,
  showMannualStaffSelection, setshowMannualStaffSelection,

  setStafftype,
  setCategory,
   selectedStaffList, setSelectedStaffList, tempstaffid, settempstaffid
}) => {
  const [stafflist, setStaffList] = useState([]);
  // const [selectedStaffList, setSelectedStaffList] = useState([]);
  // const [tempstaffid, settempstaffid] = useState("");

  useEffect(() => {
    fetch(`${Utils['domains']['development']}/spms/getstafflist`, {
      method: "get",
      headers: {
        "Content-Type": "application/json", // Specify that you're sending JSON data
      },
      // body: JSON.stringify({}), // Set the JSON data as the request body
    })
      .then((e) => {
        return e.json();
      })
      .then((data) => {
        setTimeout(() => {
          //   setLoadingBar(false);
          console.clear()
          console.log(data)
          setStaffList(data);
        }, 500);
      });
  }, []);

  return (
    <div className="bg-[#fff] rounded-lg flex flex-col gap-3 items-start py-5 px-5 w-[300px] text-[#777]  h-fit">
      <div className="w-full">
        <p className="text-xs mb-1" > Briefing Title </p>

        <input
          type="text"
          className="h-10 rounded-md bg-[#e2e2e2] w-full px-3"
          placeholder="Title"
          value={title}
          onChange={(e) => {
            setTitle(e.target.value);
          }}
        />
      </div>
      <div className="w-full">
        <p className="text-xs mb-1" > Designation </p>

        <select
          onChange={(e) => {
            setDesignation(e.target.value);
            if (e.target.value === "custom") {
              setShowCustomDesignation(true);
            } else {
              setShowCustomDesignation(false);
            }
          }}
          type="text"
          value={designation}
          className="h-10 rounded-md bg-[#e2e2e2] w-full px-3"
          placeholder="Designation"
        >
          <option value="all">All</option>
          <option value="aa">AA</option>
          <option value="pa">PA</option>
          <option value="psa">PSA</option>
          <option key="PSA-HALA" value="PSA-HALA">PSA-HALA</option>
          <option key="PSA-VCT" value="PSA-VCT">PSA-VCT</option>
          <option value="tl">TL</option>
          <option value="css">CSS</option>
          <option value="csm">CSM</option>
          <option value="CSRA">CSRA</option>

          {/* <option value="vct">VCT</option>
          <option value="hala">Hala</option> */}
          <option value="custom">Custom</option>
        </select>
      </div>



      {showCustomDesignation && (
        <div className="w-full">
          <p className="text-xs mb-1" > Designation </p>
          <select
            onChange={(e) => {
              const selectedValues = Array.from(
                e.target.selectedOptions,
                (option) => option.value
              );
              setCustomDesignation(selectedValues);
            }}
            // type="text"
            multiple
            value={customDesignation}
            className="h-[300px] rounded-md bg-[#e2e2e2] w-full px-3"
            placeholder="Designation"
          >
            <option value="aa">AA</option>
            <option value="pa">PA</option>
            <option value="psa">PSA</option>
            <option key="PSA-HALA" value="PSA-HALA">PSA-HALA</option>
            <option key="PSA-VCT" value="PSA-VCT">PSA-VCT</option>
            <option value="tl">TL</option>
            <option value="css">CSS</option>
            <option value="csm">CSM</option>
            <option value="CSRA">CSRA</option>

            {/* <option value="vct">VCT</option>
            <option value="hala">Hala</option> */}
          </select>
        </div>
      )}

      <div className="w-full">
        <p className="text-xs mb-1" > Briefing Category </p>

        <select
          onChange={(e) => {
            setCategory(e.target.value);
            if (e.target.value === "General Briefing NSR") {
              setshowMannualStaffSelection(true);
            } else {
              setshowMannualStaffSelection(false);
            }
          }}
          type="text"
          value={category}
          className="h-10 rounded-md bg-[#e2e2e2] w-full px-3"
          placeholder="Category"
        >
          <option value="SOP">SOP</option>
          <option value="General Briefing">General Briefing</option>
          <option value="General Briefing NSR">General Briefing (NSR)</option>
          <option value="Safety Briefing">Safety Briefing</option>
          <option value="Immigration Guideline">Immigration Guideline </option>
          <option value="Product Knowledge">Product Knowledge</option>
          <option value="Work Instruction">Work Instruction</option>
          <option value="Memo">Memo</option>
          <option value="GSA">GSA</option>

          <option value="Ops Requirments">Ops Requirments</option>
          <option value="Other">Other</option>
        </select>
      </div>
      {showMannualStaffSelection && <div>
        <div className="w-full">
          <p className="text-xs mb-1" > Select Staff </p>

          <input
            type="text" // Change from 'search' to 'text' to prevent free typing
            className="h-10 rounded-md bg-[#e2e2e2] w-full px-3"
            placeholder={stafflist.length === 0 ? "Loading..." : "Select Staff"}
            value={tempstaffid}
            onChange={(e) => {
              console.log('Typing:', e.target.value);
              settempstaffid(e.target.value);  // Allow typing, but only selection from the list will be valid
            }}
            onKeyUp={(e) => {
              if (e.key === 'Enter') {
                // This ensures that the user can only select from the list when the input loses focus
                const selectedStaff = stafflist.find(staff =>
                  (staff.staffid + " - " + staff.staffname + " - " + staff.staffcategory + " - " + staff._id) === tempstaffid
                );
                if (selectedStaff) {
                  // Extract staff ID, name, and category when a valid selection is made
                  let staffId = selectedStaff._id;
                  let staffName = selectedStaff.staffname;
                  let staffCategory = selectedStaff.staffcategory;

                  if (!selectedStaffList.some(staff => staff.staffId === staffId)) {
                    setSelectedStaffList([...selectedStaffList, { staffId, staffName, staffCategory }]);
                  }
                } else {
                  settempstaffid('');  // Reset input if the value doesn't match a valid staff
                }

                settempstaffid('');

              }



            }


            }
            list="stafflist"
          />

          <div className="mt-2">
            {selectedStaffList.map((staff, index) => (
              <span key={index} className="text-sm bg-blue-200 p-1 rounded mr-2">
                {staff.staffName} - {staff.staffCategory}
                {/* Option to remove staff from selection */}
                <button
                  className="ml-2 text-red-500"
                  onClick={() => {
                    setSelectedStaffList(selectedStaffList.filter((item) => item.staffId !== staff.staffId));
                  }}
                >
                  &times;
                </button>
              </span>
            ))}
          </div>

          <datalist id="stafflist">
            {stafflist.map((staff, index) => {
              return (
                <option
                  value={
                    staff.staffid +
                    " - " +
                    staff.staffname +
                    " - " +
                    staff.staffcategory +
                    " - " +
                    staff._id
                  }
                />
              );
            })}
          </datalist>
        </div>
      </div>}
      <div className="w-full mt-2">

        <button name="" id="" className="w-full" onClick={() => {
          setimportantBriefing(!importantBriefing)
        }}>
          <div className="flex text-[#777] h-10 rounded-md bg-[#e2e2e2] items-center gap-2 font-medium text-left b font-xs w-full px-3">
            <p>Is important ?</p>
            {importantBriefing ? <FaStar size="25px" className="text-[orange]" /> : <FaRegStar size="25px" />}
          </div>
          {/* <F */}
        </button>

      </div>
    </div>
  );
};

// Textarea
const TextArea = ({ content, setContent }) => {
  const handleEditorChange = (content, editor) => {
    setContent(content);
  };
  return (
    <Editor
      apiKey="jao93rxzn995ll8jjvrhi92vr7zlkr1xkrwh1k2w28o2yteh" // You need to obtain an API key from TinyMCE (https://www.tiny.cloud/)
      value={content}
      init={{
        height: 600,

        menubar: true,
        plugins: [
          "advlist  lists link image charmap print preview anchor",
          "searchreplace visualblocks code fullscreen",
          "insertdatetime media table paste code help wordcount",
        ],
        toolbar:
          "fontsize | undo redo | link | formatselect | " +
          "image |" +
          "bold italic backcolor | alignleft aligncenter " +
          "alignright alignjustify | bullist numlist ",
      }}
      onEditorChange={handleEditorChange}
    />
  );
};

export default Create;
