import React, { useEffect, useState } from "react";
import Navbar from "../Global/Navbar";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import LoadingBar from "../Global/LoadingBar";
import { PiMicrosoftExcelLogoFill } from "react-icons/pi";
import { AiFillDelete } from "react-icons/ai"
import { TiTick } from "react-icons/ti"
import Utils from '../../utils.json';
import SubCategoriesJson from "../../SubCategory.json"

const StaffList = () => {
  const navigate = useNavigate();

  const [adminLevel, setadminLevel] = useState('');

  useEffect(() => {
    let ls = localStorage.getItem('admin');
    if (!ls) {
      navigate('/login')
    }
    // console.clear()
    let admin = JSON.parse(ls)

    setadminLevel(admin.staffcategory)

    console.log('json', SubCategoriesJson)
    return;
  })
  const [staffList, setStaffList] = useState([]);
  const [loadingbar, setLoadingBar] = useState(true);

  // create new staff
  const [staffname, setStaffName] = useState('');
  const [staffid, setStaffId] = useState('');
  const [staffcategory, setStaffCategory] = useState('');
  const [staffstatus, setstaffstatus] = useState('');
  const [staffsubcategory, setstaffsubcategory] = useState(SubCategoriesJson);
  const [staffbriefing, setStaffbriefing] = useState(0);
  const [staffpassword, setStaffPassword] = useState('');
  const [newCreateNewStaff, setNewCreateNewStaff] = useState(false);
  const [controlpanelaccess, setcontrolpanelaccess] = useState(false);
  const [shift, setshift] = useState('');
  const [shiftlist, setshiftlist] = useState([]);

  // filter
  const [applyFilter, setApplyFilter] = useState(false);
  const [stafffetchlimit, setstafffetchlimit] = useState(20);

  useEffect(() => {
    fetch(`${Utils['domains']['development']}/staff/view`, {
      method: "get",
      headers: {
        "Content-Type": "application/json", // Specify that you're sending JSON data
      },
      // body: JSON.stringify({}), // Set the JSON data as the request body
    })
      .then((e) => {
        return e.json();
      })
      .then((data) => {
        setTimeout(() => {
          setLoadingBar(false);
          setStaffList(data);
        }, 0);
      });
  }, [newCreateNewStaff]);

  useEffect(() => {
    fetch(`${Utils['domains']['development']}/shift/getlist`, {
      method: "get",
      headers: {
        "Content-Type": "application/json", // Specify that you're sending JSON data
      },
      // body: JSON.stringify({}), // Set the JSON data as the request body
    })
      .then((e) => {
        return e.json();
      })
      .then((data) => {
        setTimeout(() => {
          // setLoadingBar(false);
          setshiftlist(data);
        }, 0);
      });
  }, []);


  useEffect(() => {
    if (applyFilter) {
      let data = { staffname, staffid, staffcategory, stafffetchlimit, shift, staffstatus }
      fetch(`${Utils['domains']['development']}/staff/viewbyfilter`, {
        method: "post",
        headers: {
          "Content-Type": "application/json", // Specify that you're sending JSON data
        },
        body: JSON.stringify(data), // Set the JSON data as the request body
      })
        .then((e) => {
          return e.json();
        })
        .then((data) => {
          setTimeout(() => {
            setLoadingBar(false);
            setStaffList(data);
            setApplyFilter(false);
          }, 0);
        });
    }
  }, [applyFilter]);

  // 
  const handleAddNewStaff = () => {
    const data = {
      staffname,
      staffid,
      staffcategory,
      staffbriefing,
      staffpassword,
      controlpanelaccess,
      shift
    }
    setLoadingBar(true)


    if (staffid === "") {
      toast.error('Staff id is required!', { autoClose: 2000 });
      return false;
    }

    if (staffname === "") {
      toast.error('Staff name is required!', { autoClose: 2000 });
      return false;
    }

    if (staffpassword === "") {
      toast.error('Staff password is required!', { autoClose: 2000 });
      return false;
    }

    fetch(`${Utils['domains']['development']}/staff/create`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json", // Specify that you're sending JSON data
      },
      body: JSON.stringify(data), // Set the JSON data as the request body
    }).then((e) => {
      return e.json()

    }).then((data) => {
      console.log(data)
      if (data == 'staff alreay exists') {
        toast.error("Staff already exists with the given Staffid")
      }
      else {
        toast('Staff added successfully', { autoClose: 2000 });
        setNewCreateNewStaff(!newCreateNewStaff)
      }
      setLoadingBar(false)

    })
  }

  const HandleExportAsExcel = () => {

    const newStaffList = staffList.map((item) => {
      const updatedItem = { ...item };
      delete updatedItem.staffbriefing;
      delete updatedItem._id;
      // delete updatedItem.briefingid;
      delete updatedItem.__v;
      return updatedItem;
    });
    // const newStaffList = chartsBriefingList;

    const separator = ",";
    const keys = Object.keys(newStaffList[0]);
    const csvContent = [
      keys.join(separator),
      ...newStaffList.map((item) =>
        keys.map((key) => item[key]).join(separator)
      ),
    ].join("\n");

    const blob = new Blob([csvContent], { type: "text/csv" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "data.csv";
    a.click();
    URL.revokeObjectURL(url);
    setTimeout(() => {
      a.remove()
    }, 1000);
  };

  const updateStaff = (key, value, id) => {
    const data = {
      key, value, id
    }
    fetch(`${Utils['domains']['development']}/staff/updatestaff`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json", // Specify that you're sending JSON data
      },
      body: JSON.stringify(data), // Set the JSON data as the request body
    }).then((e) => {
      toast('Details updated successfully!', { autoClose: 2000 })
    });
  }


  return (
    <div>
      <Navbar title={"Staff List"} showarrow={false} />
      {loadingbar && <LoadingBar />}

      <AddNewStaffPanel
        adminLevel={adminLevel}
        staffstatus={staffstatus} setstaffstatus={setstaffstatus}
        shift={shift}
        staffsubcategory={staffsubcategory}
        setstaffsubcategory={setstaffsubcategory}
        setshift={setshift}
        shiftlist={shiftlist}
        setshiftlist={setshiftlist}
        staffid={staffid}
        staffname={staffname}
        staffcategory={staffcategory}
        HandleExportAsExcel={HandleExportAsExcel}

        controlpanelaccess={controlpanelaccess}
        setcontrolpanelaccess={setcontrolpanelaccess}

        setStaffPassword={setStaffPassword}
        staffpassword={staffpassword}

        setStaffId={setStaffId}
        setStaffName={setStaffName}
        setStaffCategory={setStaffCategory}

        handleAddNewStaff={handleAddNewStaff}

        setApplyFilter={setApplyFilter}

        setLoadingBar={setLoadingBar}
        stafffetchlimit={stafffetchlimit}
        setstafffetchlimit={setstafffetchlimit}
      />

      <div class="relative rounded-xl overflow-auto">
        <div class="shadow-sm overflow-x-scroll  lg:overflow-hidden my-8">
          {staffList.length <= 0 && !loadingbar && <div className="">No Staff Added yet</div>}

          {!staffList.length <= 0 && <StaffTable adminLevel={adminLevel} loadingbar={loadingbar} staffList={staffList} updateStaff={updateStaff} />}
        </div>
      </div>
    </div>
  );
};



const AddNewStaffPanel = ({
  staffid,
  staffname,
  staffcategory,
  setStaffId,
  setStaffName,
  setStaffCategory,
  handleAddNewStaff,
  controlpanelaccess,
  setcontrolpanelaccess,
  setstaffsubcategory,
  staffsubcategory,

  shift, setshift, shiftlist, setshiftlist,
  adminLevel,
  staffstatus, setstaffstatus,
  setApplyFilter,
  setLoadingBar,

  stafffetchlimit,
  setstafffetchlimit,

  setStaffPassword,
  staffpassword,
  HandleExportAsExcel

}) => {

  const handleApplyFilter = () => {
    setApplyFilter(true)
    setLoadingBar(true)
  }
  return (
    <div className="bg-a-gray py-4 mt-5 rounded-lg flex flex-wrap  gap-3 items-center px-5 w-full   h-auto">
      <div>
        <p className="text-xs mb-1 text-white" > Select Staff </p>

        <input
          type="text"
          className="h-10 rounded-lg px-3 w-20"
          placeholder="Staff Id"
          value={staffid}
          onChange={(e) => {
            setStaffId(e.target.value);
          }}
        />
      </div>

      <div>
        <p className="text-xs mb-1 text-white" > Staff Name</p>

        <input
          type="text"
          className="h-10 rounded-lg px-3"
          placeholder="Staff Name"
          value={staffname}
          onChange={(e) => {
            setStaffName(e.target.value);
          }}
        />
      </div>

      <div>
        <p className="text-xs mb-1 text-white" > Password </p>


        <input
          type="text"
          className="h-10 rounded-lg px-3 "
          placeholder="Password"
          value={staffpassword}
          onChange={(e) => {
            setStaffPassword(e.target.value);
          }}
        />

      </div>

      <div>
        <p className="text-xs mb-1 text-white" > Status </p>

        <select
          onChange={(e) => {
            setstaffstatus(e.target.value);
          }}
          value={staffstatus}
          className="h-10 rounded-lg px-3"
          placeholder="Designation"
        >
          <option key="" value="">All</option>
          <option key="Pause Login" value="Pause Login">Pause Login</option>
          <option key="Active" value="Active">Active</option>
          <option key="Suspended" value="Suspended">Suspended</option>
          <option key="Resign" value="Resign">Resign</option>
          <option key="Terminated" value="Terminated">Terminated</option>
          <option key="On Anuual Leave" value="On Anuual Leave">On Anuual Leave</option>
        </select>

      </div>

      <div>
        <p className="text-xs mb-1 text-white" > Designation </p>

        <select
          onChange={(e) => {
            setStaffCategory(e.target.value);
          }}
          value={staffcategory}
          className="h-10 rounded-lg px-3"
          placeholder="Designation"
        >
          <option value="">All</option>
          <option value="aa">AA</option>
          <option value="pa">PA</option>
          <option value="psa">PSA</option>
          <option key="PSA-HALA" value="PSA-HALA">PSA-HALA</option>
          <option key="PSA-VCT" value="PSA-VCT">PSA-VCT</option>
          <option value="tl">TL</option>
          <option value="css">CSS</option>
          <option value="csm">CSM</option>
          <option value="ADM">ADM</option>
          <option value="CSRA">CSRA</option>
          {/* <option value="vct">VCT</option>
        <option value="hala">Hala</option> */}
          {/* <option value="hala">Super A</option> */}
        </select>

      </div>

      <div>
        <p className="text-xs mb-1 text-white" > Sub Designation </p>

        <select
          // onChange={(e) => {
          //   setStaffCategory(e.target.value);
          // }}
          // value={staffcategory}
          className="h-10 rounded-lg px-3"
          placeholder="Designation"
        >
          {staffsubcategory.map((element) => {
            return <optgroup label={element["category"].toUpperCase()}>
              {element["sub-categories"].map((sub) => {
                return <option value={element + "---" + sub}> {sub} </option>
              })}

            </optgroup>
          })}
          {/* <option value="">All</option>
        <option value="aa">AA</option>
        <option value="pa">PA</option>
        <option value="psa">PSA</option>
        <option value="tl">TL</option>
        <option value="css">CSS</option>
        <option value="csm">CSM</option> */}
          {/* <option value="vct">VCT</option>
        <option value="hala">Hala</option> */}
          {/* <option value="hala">Super A</option> */}
        </select>

      </div>

      <div>
        <p className="text-xs mb-1 text-white" > Select Shift by ADM </p>


        <select
          // onChange={(e) => {
          //   setStaffDesignation(e.target.value);
          // }}
          onChange={(e) => {
            setshift(e.target.value);
          }}
          className="h-10 rounded-lg px-3"
          placeholder="Designation"
        // defaultValue={staff.shift}
        // onChange={(e)=>{
        //   updateStaff('shift', e.target.value, staff._id )
        // }}
        >

          {shiftlist.map((shift, index) => {
            return <option key={shift._id} value={shift._id}>{shift.shiftadm}</option>
          })}


        </select>

      </div>
      <br />      <p className="text-white">
        CP Access
      </p>



      <input
        type="checkbox"
        className="h-10 rounded-lg px-3"
        placeholder="Staff Name"
        value={controlpanelaccess}
        onChange={(e) => {
          setcontrolpanelaccess(e.target.checked);
        }}
      />

      <div>
        <p className="text-xs mb-1 text-white" > Limit </p>

        <select
          onChange={(e) => {
            setstafffetchlimit(e.target.value);
          }}
          value={stafffetchlimit}
          className="h-10 rounded-lg px-3"
          placeholder="limit"
        >
          <option value="5">5</option>
          <option selected value="10">10</option>
          <option value="20">20</option>
          <option value="30">30</option>
          <option value="">All</option>

        </select>
      </div>

      {['SUPER-ADMIN'].includes(adminLevel) &&
        <button onClick={handleAddNewStaff} className="  bg-gradient-to-r from-a-pink to-a-blue hover:to-a-pink hover:from-a-blue px-12 h-10 rounded-lg text-white uppercase text-xs">
          Create
        </button>}

      <button onClick={handleApplyFilter} className="  bg-gradient-to-r from-a-pink to-a-blue hover:to-a-pink hover:from-a-blue px-12 h-10 rounded-lg text-white uppercase text-xs">
        Apply
      </button>
      {['SUPER-ADMIN'].includes(adminLevel) &&
        <button
          onClick={HandleExportAsExcel}
          className="flex items-center gap-2  bg-green-600 px-12 h-10 rounded-lg text-white uppercase text-xs"
        >
          <PiMicrosoftExcelLogoFill size="22px" />
          Export Excel
        </button>}
    </div>
  );
};



const StaffTable = ({ loadingbar, staffList, updateStaff, adminLevel }) => {
  return (
    <table class="border-collapse  w-full text-sm rounded-lg overflow-x-scroll    ">
      <thead className="bg-gradient-to-r from-a-pink to-a-blue text-white">

        <tr>
          <td class="border-b border-r border-slate-100 dark:border-slate-700 p-4 pl-8  text-white">
            Sno
          </td>
          <td class="border-b border-r border-slate-100 dark:border-slate-700 p-4  text-white">
            Staff Id
          </td>
          <td class="border-b border-r border-slate-100 dark:border-slate-700 p-4  text-white">
            Staff Name
          </td>
          <td class="border-b border-r border-slate-100 dark:border-slate-700 p-4  text-white">
            Category
          </td>
          <td class="border-b border-r border-slate-100 dark:border-slate-700 p-4  text-white">
            Status
          </td>
          <td class="border-b border-r border-slate-100 dark:border-slate-700 p-4  text-white">
            Shift
          </td>
          <td class="border-b border-r border-slate-100 dark:border-slate-700 p-4 pr-8  text-white">
            Briefing
          </td>
          <td class="border-b border-r border-slate-100 dark:border-slate-700 p-4 pr-8  text-white">
            Password
          </td>
          <td class="border-b border-r border-slate-100 dark:border-slate-700 p-4 pr-8  text-white">
            Email
          </td>
          {['SUPER-ADMIN'].includes(adminLevel) &&

            <td class="border-b border-r border-slate-100 dark:border-slate-700 p-4 pr-8  text-white">
              CP Access
            </td>
          }
          <td class="border-b border-r border-slate-100 dark:border-slate-700 p-4 pr-8  text-white">
            Actions
          </td>

        </tr>

      </thead>
      <tbody class="bg-a-gray text-white">
        {staffList.map((staff) => {
          return <TableRow staff={staff} adminLevel={adminLevel} updateStaff={updateStaff} />;
        })}
      </tbody>
    </table>
  );
};

const TableRow = ({ staff, updateStaff, adminLevel }) => {
  return (
    <tr key={staff.staffid} className={staff.staffname.includes("-T") && "bg-[brown]"}>
      <td class="border-b border-r border-slate-100 dark:border-slate-700 p-4 pl-8  text-white">
        {/* {staff.staffname.includes("-T") && <h1>this </h1>} */}
        -
      </td>
      <td class="border-b border-r border-slate-100 dark:border-slate-700 p-4  text-white">
        {staff.staffid}
      </td>
      <td class="border-b border-r border-slate-100 dark:border-slate-700 p-4 pr-0 text-a-gray">

        <span className="text-white">{staff.staffname}</span>
        {/* <input
        type="text"
        className="h-10 rounded-lg px-3 w-30"
        placeholder="Staff Name"
        // defaultValue={staff.staffname}
        // onBlur={(e)=>{
        //   updateStaff('staffname', e.target.value, staff._id, staff._id )
        // }}
        onKeyUp={(e)=>{
          if (e.key === "Enter") {
          updateStaff('staffname', e.target.value, staff._id, staff._id )
          }
        }}
        onChange={(e)=>{
          e.target.value = e.target.value
        }}
        // onChange={(e) => {
        //   setStaffId(e.target.value);
        // }}
      /> */}
      </td>
      <td class="border-b border-r border-slate-100 dark:border-slate-700 p-4 pr-8  text-a-gray">
        {/* {staff.staffcategory} */}
        {['SUPER-ADMIN'].includes(adminLevel) && <select
          // onChange={(e) => {
          //   setStaffDesignation(e.target.value);
          // }}
          // value={designation}
          className="h-10 rounded-lg px-3"
          placeholder="Designation"
          defaultValue={staff.staffcategory}
          onChange={(e) => {
            updateStaff('staffcategory', e.target.value, staff._id)
          }}
        >
          <option key="aa" value="aa">AA</option>
          <option key="pa" value="pa">PA</option>
          <option key="psa" value="psa">PSA</option>
          <option key="PSA-HALA" value="PSA-HALA">PSA-HALA</option>
          <option key="PSA-VCT" value="PSA-VCT">PSA-VCT</option>
          <option key="tl" value="tl">TL</option>
          <option key="css" value="css">CSS</option>
          <option key="csm" value="csm">CSM</option>
          <option value="ADM">ADM</option>
          <option value="HOD">HOD</option>
          <option value="CSRA">CSRA</option>
        </select>}
        {/* {!['SUPER-ADMIN'].includes(adminLevel) &&<p className="text-white">{ staff.staffcategory}</p>} */}
      </td>
      <td class="border-b border-r border-slate-100 dark:border-slate-700 p-4 pr-8  text-a-gray">
        {/* {staff.staffcategory} */}
        <select
          // onChange={(e) => {
          //   setStaffDesignation(e.target.value);
          // }}
          // value={designation}
          className="h-10 rounded-lg px-3"
          placeholder="Designation"
          defaultValue={staff.staffstatus}
          disabled={!['SUPER-ADMIN'].includes(adminLevel)}
          onChange={(e) => {
            updateStaff('staffstatus', e.target.value, staff._id)
          }}
        >
          <option key="Pause Login" value="Pause Login">Pause Login</option>
          <option key="Active" value="Active">Active</option>
          <option key="Suspended" value="Suspended">Suspended</option>
          <option key="Resign" value="Resign">Resign</option>
          <option key="Terminated" value="Terminated">Terminated</option>
          <option key="On Anuual Leave" value="On Anuual Leave">On Anuual Leave</option>
        </select>
      </td>
      <td class="border-b border-r border-slate-100 dark:border-slate-700 p-4 pr-8  text-a-gray">
        {/* {staff.staffcategory} */}
        <select
          // onChange={(e) => {
          //   setStaffDesignation(e.target.value);
          // }}
          // value={designation}
          className="h-10 rounded-lg px-3"
          placeholder="Designation"
          defaultValue={staff.shift}
          disabled={!['SUPER-ADMIN'].includes(adminLevel)}
          onChange={(e) => {
            updateStaff('shift', e.target.value, staff._id)
          }}
        >
          <option key="65281d391fd0aaff48cf8e2f" value="65281d391fd0aaff48cf8e2f">Mr Mourad rezqi</option>
          <option key="65281dcd1fd0aaff48cf8e31" value="65281dcd1fd0aaff48cf8e31">Humaid Mohamed</option>
          <option key="65281de81fd0aaff48cf8e33" value="65281de81fd0aaff48cf8e33">Rachita Srivastava</option>
          <option key="65281e101fd0aaff48cf8e35" value="65281e101fd0aaff48cf8e35">Mohamed Othman</option>
          <option key="652820cc820913b341dbdf9e" value="652820cc820913b341dbdf9e">Not Assign</option>
        </select>
      </td>
      <td class="border-b border-r border-slate-100 dark:border-slate-700 p-4 pr-8  text-white">
        {/* niche wali logic actuall main jugar hia 😂 */}
        {staff.briefingquantity} /  {parseInt(staff.totalbriefingsign) > parseInt(staff.briefingquantity) ? staff.briefingquantity : staff.totalbriefingsign}
        {/* upper wali logic actuall main jugar hai 😂 */}
      </td>

      <td class="border-b border-r border-slate-100 dark:border-slate-700 p-4 pr-0  text-a-gray">
        {/* {staff.staffpassword} */}
        {['SUPER-ADMIN'].includes(adminLevel) &&
          <input
            type="text"
            className="h-10 rounded-lg px-3 w-30"
            placeholder="Staff Password"
            defaultValue={staff.staffpassword}
            // onBlur={(e)=>{
            //   updateStaff('staffpassword', e.target.value, staff._id )
            // }}
            onKeyUp={(e) => {
              if (e.key === "Enter") {
                updateStaff('staffpassword', e.target.value, staff._id)

              }
            }}
          // onChange={(e) => {
          //   setStaffId(e.target.value);
          // }}
          />}
        {!['SUPER-ADMIN'].includes(adminLevel) && <p className="text-white">********</p>}
      </td>

      {/* email */}
      <td class="border-b border-r border-slate-100 dark:border-slate-700 p-4 pr-0  text-a-gray">
        {/* {staff.staffpassword} */}
        {['SUPER-ADMIN'].includes(adminLevel) &&
          <input
            type="text"
            className="h-10 rounded-lg px-3 w-30"
            placeholder="Email"
            defaultValue={staff.email}
            // onBlur={(e)=>{
            //   updateStaff('staffpassword', e.target.value, staff._id )
            // }}
            onKeyUp={(e) => {
              if (e.key === "Enter") {
                updateStaff('email', e.target.value, staff._id)

              }
            }}
          // onChange={(e) => {
          //   setStaffId(e.target.value);
          // }}
          />}
        {!['SUPER-ADMIN'].includes(adminLevel) && <p className="text-white">********</p>}
      </td>
      {['SUPER-ADMIN'].includes(adminLevel) &&
        <td class="border-b border-r border-slate-100 dark:border-slate-700 p-4 pr-8  text-white">
          <input
            type="checkbox"
            className="h-10 rounded-lg px-3"
            placeholder="Staff Name"
            defaultValue={staff.controlpanelaccess}
            defaultChecked={staff.controlpanelaccess}
            onChange={(e) => {
              updateStaff('controlpanelaccess', e.target.checked, staff._id)
            }}
          />
        </td>}
      <td class="border-b border-r border-slate-100 dark:border-slate-700 p-4 pr-8  text-white flex gap-3">
        <button className="bg-green-500 p-2 rounded-full"> <TiTick size="22px" className="" /> </button>
        <button className="bg-red-500 p-2 rounded-full"> <AiFillDelete size="21px" className="" /> </button>
      </td>
    </tr>
  );
};

export default StaffList;
