import React, { useEffect, useState } from "react";
import Navbar from "../../Global/Navbar";

import {useNavigate} from 'react-router-dom'

import { useParams } from "react-router-dom";

import { toast } from "react-toastify";
import LoadingBar from "../../Global/LoadingBar";
import Questions from "../../Create/Questions";
import { AiOutlineFile, AiOutlineEye } from "react-icons/ai";
import Utils from  '../../../utils.json';

const BriefingStaffSign = () => {
  const navigate = useNavigate()
  const [staffname, setStaffName] = useState("");
  const [staffid, setStaffId] = useState("");
  const [files, setFiles] = useState([]); // Use an array for multiple files

  const [briefingid, setBriefingId] = useState("");
  const [bissign, setbissign] = useState("");
  const [loadingbar, setLoadingBar] = useState(true);
  const [showcorrectquestions, setShowCorrectQuestions] = useState(false)
  const [questions, setQuestions] = useState({
    qid: "1",
    question: "Question 1",
    options: [
      { opt: "Option 1", correct: false },
      { opt: "Option 2", correct: false },
      { opt: "Option 3", correct: false },
      { opt: "Option 4", correct: false },
    ],
  });

  const [initialBlockTimer, setInitialBlockTimer] = useState(5);

  const [correctquestions, setCorrectQuestions] = useState({
    qid: "1",
    question: "Question 1",
    options: [
      { opt: "Option 1", correct: false },
      { opt: "Option 2", correct: false },
      { opt: "Option 3", correct: false },
      { opt: "Option 4", correct: false },
    ],
  });

  useEffect(()=>{
    let ls = localStorage.getItem("staff");
    if (ls) {
      let json = JSON.parse(ls);
      setStaffName(json.staffname)
    }
  }, [])

  useEffect(()=>{
    alert("Dear All,\n\nWe have removed the initial timer for signing the briefing. You now have the flexibility to read at your own pace, with no time restrictions. Please take your time to read carefully, understand the updates/briefings, and sign.\n\nRegards,\nSyed");
  }, [])

  

  // const initialTimer = setInterval(()=>{
  //   setInitialBlockTimer(initialBlockTimer-1);
  // }, 3000*3)

  // setTimeout(() => {
  //   clearInterval(initialTimer)
  // }, 3000*3);

  useEffect(() => {
    if (initialBlockTimer > 0) {
      const timer = setInterval(() => {
        setInitialBlockTimer(initialBlockTimer => initialBlockTimer - 1);
      }, 1000);

      return () => clearInterval(timer);
    }
  }, [initialBlockTimer]);

  const [data, setData] = useState({});

  let { id, issign } = useParams();

  useEffect(() => {
    setBriefingId(id);
    setbissign(issign)
  }, []);

  useEffect(() => {
    if (briefingid != "")
      fetch(
        `${Utils['domains']['development']}/briefing/viewbyfiltersign?id=${briefingid}`,
        {
          method: "get",
          headers: {
            "Content-Type": "application/json", // Specify that you're sending JSON data
          },
          // body: JSON.stringify(data), // Set the JSON data as the request body
        }
      )
        .then((e) => {
          return e.json();
        })
        .then((data) => {
          setTimeout(() => {
            setLoadingBar(false);
            // alert(data.initialBlockTimer)
            // setInitialBlockTimer(data.initialBlockTimer)
            setFiles(data.attachments)


            setData(data);
            setQuestions(
              data.questions.map((question) => {
                let updatedOptions = question.options.map((option, i) => ({
                  ...option,
                  correct: i === false,
                }));
                return { ...question, options: updatedOptions };
              }))
              setCorrectQuestions(data.questions)
            
          }, 0);
        });
  }, [briefingid]);

  const handleAddStaffSign = () => {

    // console.clear()
    // console.log(questions)
    // console.log(correctquestions)
    var totalcorrectquestions = 0;
    var incorrectquestionslist = []

    questions.map((question, index) => {
      const options = question['options'];
      const correctques = correctquestions[index];
      options.map((option, index) => {
        if (option['correct'] === true && correctques['options'][index]['correct'] === true) {
          totalcorrectquestions+=1;
          // console.log(totalcorrectquestions)
        }
      })
    })

    let updateeeedques = questions.map((question, index) => {
      const options = question['options'];
      const correctques = correctquestions[index];
      let preopt = false;
      options.map((option, index) => {
        if (option['correct'] === true && correctques['options'][index]['correct'] === true) {
          // totalcorrectquestions+=1;
          preopt = true;
        }
      })
     if (!preopt) {
          let modifiedquestion = question;
        modifiedquestion.wrong = true;
        incorrectquestionslist.push(index);
        // let selectdomquestion = document.getElementById(`question-${index}`).scrollIntoView({ behavior: 'smooth' });
        return modifiedquestion;
      } else {
        let modifiedquestion = question;
        modifiedquestion.wrong = false;
        return modifiedquestion;
      }
    })
    setQuestions(updateeeedques)

    
    if (incorrectquestionslist.length >= 1){
        let selectdomquestion = document.getElementById(`question-${incorrectquestionslist[0]}`).scrollIntoView({ behavior: 'smooth' });
    }

    if (totalcorrectquestions === questions.length) {
      // console.log(totalcorrectquestions, questions.length)
    } else {
      toast.warn('Please select correct options for each question', {autoClose: 2000})
      return false;
    }



    

    if (staffid === "") {
      toast.error('Staff Id is required!', {autoClose: 2000});
      return false;
    }

    if (staffname === "") {
      toast.error('Staff Name is required!', {autoClose: 2000});
      return false;
    }

    const payload_data = {
      staffid,
      staffname,
      designation: data.designation,
      category: data.category,
      questions,
      id,
      briefingid
      
    };

    fetch(`${Utils['domains']['development']}/staff/addstaffsign`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json", // Specify that you're sending JSON data
      },
      body: JSON.stringify(payload_data), // Set the JSON data as the request body
    }).then((e) => {
      toast(`Thank you ${staffname} For the sign`, {autoClose: 2000});
      setShowCorrectQuestions(true);
       setTimeout(() => {
        navigate(`/briefingstaff/${payload_data.designation}`)
      }, 3000);

      // setTitle("")
      // setDesignation("all")
      // setCategory("SOP")
      // setTimeout(() => {
      //   navigate(`/briefingstaff/${data.designation}`)
      // }, 2000);

    });


  }

  const handleCorrectOptionChange = (qid, index) => {
    const updatedQuestions = questions.map((question) => {
      if (question.qid === qid) {
      
        const updatedOptions = question.options.map((option, i) => ({
          ...option,
          correct: i === index,
        }));
        return { ...question, options: updatedOptions };
      }
      return question;
    });
    setQuestions(updatedQuestions);
    
  };

  return (
    <div>
      {loadingbar && <LoadingBar />}
      {!loadingbar && (
        <div className="flex w-full gap-5 flex-col lg:flex-row">
          
          <BriefingDisplayCard
            data={{
              title: data.title,
              designation: data.designation,
              category: data.category,
              // stafftype: ",
              content: data.content,
              questions: "questions",
            }}
            questions= {questions}
          />
          <div className=" flex flex-col gap-5">
          <div className=" flex flex-col gap-5">
            <div className="bg-white p-5 mt-0 rounded-lg grid grid-cols-1 gap-3">
              {!loadingbar && !files.length > 0 && (
                <div>No Attachment Related to this briefing!</div>
              )}
              {!loadingbar && files.length > 0 && (
                <div>
                  <span>{files.length}</span> Attachments{" "}
                </div>
              )}
              {!loadingbar &&
                files.length > 0 &&
                files.map((file, index) => {
                  return (
                    <FileDisplay
                      file={file}
                      // uid={index}
                      // handleDeleteFile={handleDeleteFile}
                    />
                  );
                })}
            </div>
            {/* <StaffDetailsForm
              staffid={staffid}
              setStaffId={setStaffId}
              staffname={staffname}
              setStaffName={setStaffName}
              handleAddStaffSign={handleAddStaffSign}
            /> */}
            {/* <div className=" grid gap-5 grid-cols-2">
              {questions.map((question, index) => {
                return (
                  <Question
                    qid={`${questions.length + 1}`}
                    entirequestion={question}
                    handleCorrectOptionChange={handleCorrectOptionChange}
                  />
                );
              })}
            </div> */}
            {/* {showcorrectquestions && <span className="bg-white text-a-gray px-5 py-1 rounded-lg">Correct Answer</span>}
            <div className=" grid gap-5 grid-cols-2">
              {showcorrectquestions && correctquestions.map((question, index) => {
                return (
                  <CorrectQuestion
                    qid={`${questions.length + 1}`}
                    entirequestion={question}
                    handleCorrectOptionChange={handleCorrectOptionChange}
                  />
                );
              })}
            </div> */}
          </div>
       
            <div className=" grid gap-5 lg:grid-cols-2">
              {questions.map((question, index) => {
                return (
                  <Question
                    qid={`${index}`}
                    entirequestion={question}
                    handleCorrectOptionChange={handleCorrectOptionChange}
                  />
                );
              })}
            </div>
            {issign != 'sign' && <StaffDetailsForm
              staffid={staffid}
              issign={issign}
              setStaffId={setStaffId}
              staffname={staffname}
              setStaffName={setStaffName}
              handleAddStaffSign={handleAddStaffSign}
              initialBlockTimer={initialBlockTimer} setInitialBlockTimer={setInitialBlockTimer}
            />}
            {
              issign == 'sign' && <div className="bg-white p-5 rounded">
                You signed this briefing already!

              </div>
            }
            {showcorrectquestions && <span className="bg-white text-a-gray px-5 py-1 rounded-lg">Correct Answer</span>}
            <div className=" grid gap-5 grid-cols-2">
              {showcorrectquestions && correctquestions.map((question, index) => {
                return (
                  <CorrectQuestion
                    qid={`${questions.length + 1}`}
                    entirequestion={question}
                    handleCorrectOptionChange={handleCorrectOptionChange}
                  />
                );
              })}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const BriefingDisplayCard = ({ data, questions }) => {
  return (
    <div className="lg:w-1/2 h-auto">
      <div className="border h-full bg-white overflow-x-hidden border-gray-200 p-6 rounded-lg flex flex-col justify-between">
        <p className="leading-relaxed text-xs">
          {/* <p className="mt-1  text-xs text-gray-400">Created on: {new Date(data.createdDate).toISOString().split('T')[0]}</p> */}
          <p className="mt-1 rounded-md  text-xs bg-a-blue px-2 text-white py-1">
            {data.category} For {data.designation}
          </p>
          <p className="mt-1 font-bold text-xl">{data.title}</p>
          <p className="mt-1 font-bold text-sm">
            {questions.length} Questions
          </p>
          <div
            className="mt-1 break-words"
            dangerouslySetInnerHTML={{ __html: data.content }}
          />
        </p>

        <div></div>
      </div>
    </div>
  );
};

const StaffDetailsForm = ({issign, staffid, staffname, setStaffId, setStaffName, handleAddStaffSign, initialBlockTimer, setInitialBlockTimer }) => {
  const handleApplyFilter = () => {
      // setApplyFilter(true)
      // setLoadingBar(true)
  };
  return (
    <div className="bg-gradient-to-r from-a-pink to-a-blue py-4  rounded-lg flex  gap-3 items-center px-5 w-full h-fit flex-wrap lg:flex-nowrap">
      <input
        type="text"
        className="h-10 rounded-lg px-3"
        placeholder="Staff Id"
        value={staffid}
        onChange={(e) => {
          setStaffId(e.target.value);
        }}
      />
      <input
        type="text"
        className="h-10 rounded-lg px-3"
        placeholder="Staff Name"
        value={staffname}
        onChange={(e) => {
          setStaffName(e.target.value);
        }}
      />


      <button disabled={initialBlockTimer !== 0} onClick={handleAddStaffSign} className="  bg-a-gray hover:from-a-blue px-12 h-10 rounded-lg text-white uppercase text-xs flex gap-3 items-center ">
      {initialBlockTimer > 0 &&  <span className="font-black rounded-full w-7 h-7 border-2 inline-flex items-center justify-center text-yellow-500 border-yellow-500"> {initialBlockTimer} </span>} {initialBlockTimer > 0 ? '' : 'Sign'}
      </button>
    </div>
  );
};

const FileDisplay = ({ file, }) => {
  return (
    <a href={`${Utils['domains']['development']}/uploads/${file.newfilename}`} download={`${Utils['domains']['development']}/uploads/${file.newfilename}`} className="w-full cursor-pointer  h-12 bg-gray-200 border-l-4 border-l-a-pink border border-gray-300  rounded-lg flex items-center gap-2 px-5 justify-between">
      <div  className="flex items-center gap-2 relative" title={file.name +" " + file.newfilename}>
        {/* {file.status === "pending" && <MiniLoadingBar />} */}
        {file.status === "uploaded" && <AiOutlineFile />}
        {/* <MiniLoadingBar/> */}
        <p>
          {file.filename.substring(0, 5)} <span className="text-a-pink">···</span>{" "}
          {file.filename.substring(file.filename.length - 5)}
        </p>
      </div>
      <div
        
        className="bg-a-blue w-8 h-8 rounded-full grid place-items-center"
      >
        <AiOutlineEye className="text-white" size="17px" />
      </div>
    </a>
  );
};

const Question = ({ qid, entirequestion, handleCorrectOptionChange }) => {
  return (
    <div className={"w-full " } id={"question-" + qid}>
      <p className="text-white mb-5">Question {console.log('djlfkjsdlfjkl ',entirequestion.wrong == true)} </p>
      <textarea
        type="text"
        className={"h-24 rounded-lg p-3 w-full  text-white " + (entirequestion.wrong == true ? 'bg-red-800' : 'bg-a-gray') }
        // placeholder={"Question "+qid}
        // disabled
        placeholder={entirequestion.question}
        // value={entirequestion.question}
      ></textarea>
      <div className="mt-5 grid grid-cols-2 gap-5 ">
        
        {entirequestion["options"].map((option, index) => {
          return (
            <label
              className="inline-flex gap-3 h-24 items-start rounded-lg p-3 w-full bg-a-gray text-white"
              htmlFor={"option-cb-" + index + "-" + qid}
            >
              <input
                type="checkbox"
                name=""
                id={"option-cb-" + index + "-" + qid}
                checked={option.correct}
                onChange={() =>
                  handleCorrectOptionChange(entirequestion.qid, index)
                }
              />
              {/* <p className="bg-white h-6 w-6 text-xs text-black flex items-center justify-center rounded-full ">
                {index+1}
              </p> */}
              <textarea
                value={option.opt}
                disabled
                className="bg-transparent border-none outline-none text-xs h-full  w-full "
                placeholder={"Option " + index}
              > </textarea>
            </label>
          );
        })}
      </div>
    </div>
  );
};

const CorrectQuestion = ({ qid, entirequestion }) => {
  return (
    <div className="w-full" id={"question-" + qid}>
      <textarea
        type="text"
        className="h-24 rounded-lg p-3 w-full bg-a-gray text-white"
        // placeholder={"Question "+qid}
        disabled
        placeholder={entirequestion.question}
        // value={entirequestion.question}
      ></textarea>
      <div className="mt-5 grid grid-cols-2 gap-5 ">
        
        {entirequestion["options"].map((option, index) => {
          return (
            <label
              className={"inline-flex gap-2 h-14 items-center rounded-lg p-3 w-full text-white "  + (option.correct ? 'bg-green-500' : 'bg-a-gray')}
              htmlFor={"option-cb-" + index + "-" + qid}
            >
              <input
                type="checkbox"
                name=""
                id={"option-cb-" + index + "-" + qid}
                checked={option.correct}
                // onChange={() =>
                //   handleCorrectOptionChange(entirequestion.qid, index)
                // }
              />
              {/* <p className="bg-white h-6 w-6 text-xs text-black flex items-center justify-center rounded-full ">
                {index+1}
              </p> */}
              <input
                value={option.opt}
                disabled
                className="bg-transparent border-none outline-none  w-full "
                placeholder={"Option " + index}
              />
            </label>
          );
        })}
      </div>
    </div>
  );
};



export default BriefingStaffSign;
