import React, { useEffect, useState, useRef } from "react";
import Navbar from "../Global/Navbar";

import { toast } from "react-toastify";
import LoadingBar from "../Global/LoadingBar";
import { Link } from "react-router-dom";
import Utils from '../../utils.json';
import { useNavigate } from "react-router-dom";

import { FaStar } from "react-icons/fa6"
import { FaRegStar } from "react-icons/fa"


import { AiOutlineFile, AiOutlineDelete } from "react-icons/ai";
import { RxCross1 } from "react-icons/rx";



const BriefingDisplay = () => {
  const navigate = useNavigate();

  useEffect(() => {
    let ls = localStorage.getItem('admin');
    if (!ls) {
      navigate('/login')
    }
  })
  const [briefingList, setBriefingList] = useState([]);
  const [loadingbar, setLoadingBar] = useState(true);

  const inputRef = useRef(null)

  const [designation, setStaffDesignation] = useState("psa");
  const [category, setCategory] = useState('');
  const [briefinglimit, setBriefingLimit] = useState(20)

  const [applyFilter, setApplyFilter] = useState(false);
  const [startdate, setStartDate] = useState('');
  const [enddate, setEndDate] = useState('');

  const [stafflist, setStaffList] = useState([]);
  const [tempstaffid, settempstaffid] = useState("");
  const [staffid, setstaffid] = useState("");
  const [selectedStaffList, setSelectedStaffList] = useState([]);
  const [selectedBriefingList, setSelectedBriefingList] = useState([]);
  // const [assignType, setAssignType] = useState('sign') // no-sign


  // useEffect(() => {
  //   fetch(`${Utils['domains']['development']}/spms/getstafflist`, {
  //     method: "get",
  //     headers: {
  //       "Content-Type": "application/json", // Specify that you're sending JSON data
  //     },
  //     // body: JSON.stringify({}), // Set the JSON data as the request body
  //   })
  //     .then((e) => {
  //       return e.json();
  //     })
  //     .then((data) => {
  //       setTimeout(() => {
  //         //   setLoadingBar(false);
  //         console.clear()
  //         console.log(data)
  //         setStaffList(data);
  //       }, 500);
  //     });
  // }, []);

  useEffect(() => {

    let data = { staffcategory: designation, stafffetchlimit: "" }
    fetch(`${Utils['domains']['development']}/staff/viewbyfilter`, {
      method: "post",
      headers: {
        "Content-Type": "application/json", // Specify that you're sending JSON data
      },
      body: JSON.stringify(data), // Set the JSON data as the request body
    })
      .then((e) => {
        return e.json();
      })
      .then((data) => {
        setTimeout(() => {
          // setLoadingBar(false);
          setStaffList(data);
          // setApplyFilter(false);
        }, 0);
      });

  }, [designation]);



  useEffect(() => {
    fetch(`${Utils['domains']['development']}/briefing/viewbyfilter?designation=${designation}&briefinglimit=${briefinglimit}&category=${category}&startdate=${startdate}&enddate=${enddate}`, {
      method: "get",
      headers: {
        "Content-Type": "application/json", // Specify that you're sending JSON data
      },
      // body: JSON.stringify(data), // Set the JSON data as the request body
    })
      .then((e) => {
        return e.json();
      })
      .then((data) => {
        setTimeout(() => {
          setLoadingBar(false);
          setBriefingList(data);
        }, 0);
      });
  }, []);

  useEffect(() => {
    if (applyFilter) {
      fetch(
        `${Utils['domains']['development']}/briefing/viewbyfilter?designation=${designation}&briefinglimit=${briefinglimit}&category=${category}&startdate=${startdate}&enddate=${enddate}`,
        {
          method: "get",
          headers: {
            "Content-Type": "application/json", // Specify that you're sending JSON data
          },
          // body: JSON.stringify(data), // Set the JSON data as the request body
        }
      )
        .then((e) => {
          return e.json();
        })
        .then((data) => {
          setTimeout(() => {
            setLoadingBar(false);
            setBriefingList(data);
            setApplyFilter(false)
            setSelectedBriefingList([])
            setSelectedStaffList([])
          }, 0);
        });
    }

  }, [applyFilter]);

  const handleManageAssign = (assignType) => {



    if (selectedBriefingList.length < 1) {
      toast.error('Please Select Briefing to continue')
      return false;
    }


    if (selectedStaffList.length < 1) {
      toast.error("Please Select any Staff to continue")
      return false;
    }


    let data = { selectedStaffList: selectedStaffList, selectedBriefingList: selectedBriefingList, assignType: assignType };
    fetch(
      `${Utils['domains']['development']}/staff/managesign`,
      {
        method: "post",
        headers: {
          "Content-Type": "application/json", // Specify that you're sending JSON data
        },
        body: JSON.stringify(data), // Set the JSON data as the request body
      }
    )
      .then((e) => {
        return e.json();
      })
      .then((data) => {
        setTimeout(() => {
          console.clear()
          console.log(data)
          toast.success(`Selected Briefing mark as "${assignType}" for the following staff list ${selectedStaffList.toString().replace(/,/g, " , ")}`)
          // setLoadingBar(false);
          // setStaffList(data);
          // console.clear()
          // console.log("-----------------------------------------")
          // console.log(data)
          // // console.clear()
          // // console.log(data)
          // setApplyFilter(false);
          // setmodifyrecords1sttime(true)

        }, 0);
      });
  }

  const updateStaff = (key, value, id) => {

    const data = {
      key, value, id, actiontypebriefing: "update"
    }
    fetch(`${Utils['domains']['development']}/briefing/create`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json", // Specify that you're sending JSON data
      },
      body: JSON.stringify(data), // Set the JSON data as the request body
    }).then((e) => {
      let imp = ""
      if (value) {
        imp = "important" 
      } else {
        imp = "not important" 
      }
      toast(`selected briefing is now mark as ${imp} for the ${designation}`, { autoClose: 2000 })
    });
  }

  // updateStaff()


  return (
    <div>
      <Navbar title={"Manage Assign"} showarrow={false} />
      {loadingbar && <LoadingBar />}

      <BriefingFilterPanel category={category} setCategory={setCategory} setBriefingLimit={setBriefingLimit} briefinglimit={briefinglimit} setApplyFilter={setApplyFilter} setLoadingBar={setLoadingBar} designation={designation} setStaffDesignation={setStaffDesignation} enddate={enddate} setEndDate={setEndDate}
        startdate={startdate} setStartDate={setStartDate} />

      <section className="text-gray-600 body-font bg-a-gray p-5 mt-5 rounded-lg">
        <div className="container  mt-5 ml-5 mx-auto flex ">
          <div className="  w-1/3">
            {briefingList.length <= 0 && !loadingbar && <div className="ml-4">No Briefing Created Yet!</div>}
            {briefingList.map((briefing) => {
              return <div key={briefing._id} className="flex w-full items-center gap-5">
                <input checked={selectedBriefingList.includes(briefing._id)} onClick={() => {

                  setSelectedBriefingList((previousRecords) => {

                    if (previousRecords.includes(briefing._id)) {
                      return previousRecords.filter(brief => brief !== briefing._id)
                      return []

                    } else {
                      return [...previousRecords, briefing._id]
                    }
                  })


                }} type="checkbox" className="manageassign-cb" name="" id="" />
                <BriefingDisplayCard data={briefing} updateStaff={updateStaff} setApplyFilter={setApplyFilter} /> </div>;
            })}
          </div>
          <div className="w-1/2 ml-5">

            <h1 className="text-white text-lg mb-5">Select Staff for briefing Allocation</h1>

            <input
              ref={inputRef}
              type="search"
              className="h-10 rounded-lg px-3 w-[600px]"
              placeholder={stafflist.length == 0 ? "Loading..." : "Staff"}
              value={tempstaffid}
              onChange={(e) => {
                let extractstaffid = e.target.value.split(' - ')[0];
                // settempstaffid(e.target.value.split(' - ')[1]);
                // settempstaffid('');
                // setstaffid(extractstaffid)

                // check if extractstaffid exist in the whole stafflist
                const staffexists = stafflist.some((staff) => {
                  return staff.staffid === extractstaffid;
                })


                if (staffexists) {

                  // verify the length of staff id if it is under criteria so it will allow
                  if (extractstaffid.length > 5 && extractstaffid.length <= 6) {

                    // append staffid in the selectedstafflist
                    setSelectedStaffList((previousRecords) => {



                      // check if staffid not already exists in the list
                      if (!previousRecords.includes(extractstaffid)) {
                        return [extractstaffid, ...previousRecords]
                      }
                      else {
                        return [...previousRecords]
                      }

                    })
                  }
                }

              }}
              list="stafflist"
            />
            {tempstaffid}
            {staffid}
            <datalist id="stafflist">
              {stafflist.map((staff, index) => {
                return (
                  <option
                    value={
                      staff.staffid +
                      " - " +
                      staff.staffname +
                      " - " +
                      staff.staffcategory +
                      " - " +
                      staff._id
                    }
                  />
                );
              })}
            </datalist>

            <button onClick={() => {
              setSelectedStaffList([])
            }} className=" mt-5 w-[600px]  bg-gradient-to-r from-a-pink to-a-blue hover:to-a-pink hover:from-a-blue px-12 h-10 rounded-lg text-white uppercase text-xs">
              Empty Staff Bucket
            </button>

            <div className="bg-white w-[600px] h-auto mt-5 rounded grid gap-5 p-3 grid-cols-5">
              {selectedStaffList.map((staff, index) => {
                return <div onClick={() => {
                  // alert(selectedStaffList[index])
                  setSelectedStaffList((previousRecords) => {
                    const newList = [...previousRecords];
                    newList.splice(index, 1)
                    return newList;
                  })
                }} className="bg-gray-200 p-2 rounded flex items-center justify-between"><p className="font-bold">{staff}</p> <RxCross1 className="text-red-600" /> </div>

              })}
              {/* <div className="bg-gray-200 p-2 rounded flex items-center justify-between"><p className="font-bold">802711</p> <RxCross1 className="text-red-600" /> </div>
              <div className="bg-gray-200 p-2 rounded flex items-center justify-between"><p className="font-bold">802711</p> <RxCross1 className="text-red-600" /> </div>
              <div className="bg-gray-200 p-2 rounded flex items-center justify-between"><p className="font-bold">802711</p> <RxCross1 className="text-red-600" /> </div>
              <div className="bg-gray-200 p-2 rounded flex items-center justify-between"><p className="font-bold">802711</p> <RxCross1 className="text-red-600" /> </div>
              <div className="bg-gray-200 p-2 rounded flex items-center justify-between"><p className="font-bold">802711</p> <RxCross1 className="text-red-600" /> </div>
              <div className="bg-gray-200 p-2 rounded flex items-center justify-between"><p className="font-bold">802711</p> <RxCross1 className="text-red-600" /> </div>
              <div className="bg-gray-200 p-2 rounded flex items-center justify-between"><p className="font-bold">802711</p> <RxCross1 className="text-red-600" /> </div>
              <div className="bg-gray-200 p-2 rounded flex items-center justify-between"><p className="font-bold">802711</p> <RxCross1 className="text-red-600" /> </div>
              <div className="bg-gray-200 p-2 rounded flex items-center justify-between"><p className="font-bold">802711</p> <RxCross1 className="text-red-600" /> </div>
              <div className="bg-gray-200 p-2 rounded flex items-center justify-between"><p className="font-bold">802711</p> <RxCross1 className="text-red-600" /> </div> */}
            </div>



          </div>
        </div>
      </section>
      <div className="fixed bottom-5 right-5 flex gap-3 bg-white p-3 rounded">
        <button
          onClick={() => { handleManageAssign("assign") }}
          className=" bg-green-500 w-[120px] inline-block py-2 rounded-lg text-white uppercase text-sm
                border-a-gray
              shadow-md
              "
        >
          Assign
        </button>
        <button
          onClick={() => { handleManageAssign("not-assign") }}

          // onClick={handleBriefingCreate}
          className=" bg-red-500 w-[120px] py-2 rounded-lg text-white uppercase text-sm
                border-a-gray
              shadow-md
              "
        >
          Not Assign
        </button>

      </div>
    </div>
  );
};

const BriefingDisplayCard = ({ data, updateStaff, setApplyFilter }) => {
  return (
    <Link className="w-full p-2">

      <div className=" bg-stone-100 rounded manageassign-card   border-gray-200 p-2 px-4 flex flex-col justify-between">
        <div className="flex items-center justify-between">

          <div className="flex items-center gap-4">     <div>
            {data.importantBriefing}
            {console.log(data._id, data.importantBriefing)}
            <button onClick={() => {
              // updateStaff("syed")
              updateStaff("importantBriefing", !data.importantBriefing, data._id,)
              setApplyFilter(true)
            }} name="" id="" >
              {data.importantBriefing ? <FaStar size="30px" className="text-[orange]" /> : <FaRegStar size="30px" />}
            </button>
          </div>

            <div>

              <p className=" font-bold text-sm">{data.title.substring(0, 20) + "..."}</p>
              <p className="  text-xs text-gray-400 mt-1"> {new Date(data.createdDate).toISOString().split('T')[0]}</p>
            </div>
          </div>
          {/* <p className=" rounded-md  text-xs bg-a-blue px-2 text-white py-1 uppercase">{data.category} For {data.designation}</p> */}



          <div>
            <p className="text-xs">{data.category.substring(0, 14) + "..."}</p>
            <p className=" text-xs">{data.questions.length} Questions</p>
            <p className=" text-xs flex items-center gap-2"><AiOutlineFile /> {data.attachments.length}  Files Attached </p>
          </div>



        </div>
      </div>
    </Link>
  );
};

const BriefingFilterPanel = ({

  category, setCategory, setBriefingLimit, briefinglimit,
  setLoadingBar,
  setApplyFilter,
  designation,
  enddate, setEndDate,
  startdate, setStartDate,
  setStaffDesignation


}) => {

  const handleApplyFilter = () => {
    setApplyFilter(true)
    setLoadingBar(true)
  }
  return (
    <div className="bg-a-gray py-4 mt-5 rounded-lg flex flex-wrap  gap-3 items-center px-5 w-full   h-auto">
      <div>
        <p className="text-xs mb-1 text-white" > Start Date </p>

        <input
          onChange={(e) => {
            setStartDate(e.target.value);
          }}
          type="date"
          value={startdate}
          className="h-10 rounded-lg px-3"
          placeholder="Category"
        />
      </div>

      <div>
        <p className="text-xs mb-1 text-white" > End Date </p>

        <input
          onChange={(e) => {
            setEndDate(e.target.value);
          }}
          type="date"
          value={enddate}
          className="h-10 rounded-lg px-3"
          placeholder="Category"
        />
      </div>

      <div>
        <p className="text-xs mb-1 text-white" > Designation </p>

        <select
          onChange={(e) => {
            setStaffDesignation(e.target.value);
          }}
          value={designation}
          className="h-10 rounded-lg px-3"
          placeholder="Designation"
        >
          <option disabled value="">All</option>
          <option value="aa">AA</option>
          <option value="pa">PA</option>
          <option value="psa">PSA</option>
          <option value="tl">TL</option>
          <option value="css">CSS</option>
          <option value="csm">CSM</option>
          <option value="vct">VCT</option>
          <option value="hala">Hala</option>
        </select>

      </div>

      <div>
        <p className="text-xs mb-1 text-white" > Briefing Category</p>

        <select
          onChange={(e) => {
            setCategory(e.target.value);
          }}
          type="text"
          value={category}
          className="h-10 rounded-lg px-3"
          placeholder="Category"
        >
          <option value="">All</option>
          <option value="SOP">SOP</option>
          <option value="General Briefing">General Briefing</option>
          <option value="Safety Briefing">Safety Briefing</option>
          <option value="Immigration Guideline">Immigration Guideline </option>
          <option value="Product Knowledge">Product Knowledge</option>
          <option value="Work Instruction">Work Instruction</option>

          <option value="Ops Requirments">Ops Requirments</option>
          <option value="Other">Other</option>
        </select>
      </div>

      <div>
        <p className="text-xs mb-1 text-white" > Limit</p>


        <select
          onChange={(e) => {
            setBriefingLimit(e.target.value);
          }}
          value={briefinglimit}
          className="h-10 rounded-lg px-3"
          placeholder="limit"
        >
          <option value="1">1</option>
          <option value="5">5</option>
          <option selected value="10">10</option>
          <option value="20">20</option>
          <option value="30">30</option>
          <option value="">All</option>

        </select>

      </div>

      <button onClick={handleApplyFilter} className="  bg-gradient-to-r from-a-pink to-a-blue hover:to-a-pink hover:from-a-blue px-12 h-10 rounded-lg text-white uppercase text-xs">
        Apply
      </button>
    </div>
  );
};


export default BriefingDisplay;
