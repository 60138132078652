import React from 'react'

import { Chart } from "react-google-charts";


const COMShiftReport = () => {
  return (
    <div className=''>

      <div className='grid gap-10 grid-cols-3'>


        {/* General Fields */}
        <GeneralFields />

        {/* ImportExportTotalChart */}
        <ImportExportTotalChart />
      </div>

    </div>
  )
}

// 
const GeneralFields = () => {
  return (
    <div className='col-span-2'>
      <div className=' grid gap-5'>

        {/* Pax Flights Related Fields */}
        <p className="font-bold">Pax Flights </p>
        <div className="grid grid-cols-2 gap-5 mt-1">

          {/*  Imports  */}
          <div className="">
            <p className="text-sm mb-1" > Imports		 </p>
            <input
              type="search"
              className="h-10 rounded-lg px-3 w-full bg-gray-200 border border-a-blue "
              placeholder="Imports	"
              // value={tempselectedboc}
              onChange={(e) => {


              }}
            />
          </div>

          {/* Exports	  */}
          <div className="">
            <p className="text-sm mb-1" > Exports			 </p>
            <input
              type="search"
              className="h-10 rounded-lg px-3 w-full bg-gray-200 border border-a-blue "
              placeholder="Exports	"
              // value={tempselectedboc}
              onChange={(e) => {


              }}
            />
          </div>

        </div>

        <hr />

        {/* Freighters Related Fields */}
        <p className="font-bold">Freighters </p>
        <div className="grid grid-cols-2 gap-5 mt-1">

          {/*  Imports  */}
          <div className="">
            <p className="text-sm mb-1" > Imports		 </p>
            <input
              type="search"
              className="h-10 rounded-lg px-3 w-full bg-gray-200 border border-a-blue "
              placeholder="Imports	"
              // value={tempselectedboc}
              onChange={(e) => {


              }}
            />
          </div>

          {/* Exports	  */}
          <div className="">
            <p className="text-sm mb-1" > Exports			 </p>
            <input
              type="search"
              className="h-10 rounded-lg px-3 w-full bg-gray-200 border border-a-blue "
              placeholder="Exports	"
              // value={tempselectedboc}
              onChange={(e) => {


              }}
            />
          </div>

        </div>

        {/* Trucks Related Fields */}
        <p className="font-bold">Trucks </p>
        <div className="grid grid-cols-2 gap-5 mt-1">

          {/*  Imports  */}
          <div className="">
            <p className="text-sm mb-1" > Imports		 </p>
            <input
              type="search"
              className="h-10 rounded-lg px-3 w-full bg-gray-200 border border-a-blue "
              placeholder="Imports	"
              // value={tempselectedboc}
              onChange={(e) => {


              }}
            />
          </div>

          {/* Exports	  */}
          <div className="">
            <p className="text-sm mb-1" > Exports			 </p>
            <input
              type="search"
              className="h-10 rounded-lg px-3 w-full bg-gray-200 border border-a-blue "
              placeholder="Exports	"
              // value={tempselectedboc}
              onChange={(e) => {


              }}
            />
          </div>

        </div>



      </div>
    </div>
  )
}

// 
const ImportExportTotalChart = () => {
  return (
    <div className='col-span-1 '>
      <Chart
        chartType="PieChart"
        width="100%"
        height="400px"
        data={[
          ["Field", "Total"],
          ["Import", 11],
          ["Export", 2],
        ]}
        options={{
          title: "Total",
          pieHole: 0.4,
          is3D: false,
        }}
      />
    </div>
  )
}

export default COMShiftReport