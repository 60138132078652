import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";

import { Editor } from "@tinymce/tinymce-react";
import { toast } from "react-toastify";
import { useDropzone } from "react-dropzone";
import { Tree, TreeNode } from 'react-organizational-chart';
import { Chart } from "react-google-charts";

import { MdOutlineMenu } from "react-icons/md";
import { MdClose } from "react-icons/md";

import { FaList } from "react-icons/fa";

import { FaCodeCompare, FaArrowTrendUp, FaArrowTrendDown, FaArrowUpWideShort } from "react-icons/fa6";

import { FaArrowUp, FaArrowDown } from 'react-icons/fa';

// import {  } from "react-icons/fa6";

import { FaChartPie } from "react-icons/fa";
import { MdNightsStay } from "react-icons/md";
import { IoIosPartlySunny } from "react-icons/io";

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import {
  BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer
} from 'recharts';
import { FaCaretDown, FaCaretUp } from "react-icons/fa";
import { IoMdInformationCircle } from "react-icons/io";




import { MiniLoadingBar } from "../Global/LoadingBar";

import Utils from '../../utils.json';


import Select from "react-select";

import ViewCSMReport from "./ViewCSMReport";

const ViewReport = () => {
  const navigate = useNavigate();
  const [adminLevel, setadminLevel] = useState('');

  // reports related
  const ReportsList = ['CSM Shift Report', 'AOM Shift Report', 'BOC Shift Report', 'COM Shift Report'];
  const [allreports, setallreports] = useState([]);
  const [load_allreports, setload_allreports] = useState(false);
  const [applyFilter, setApplyFilter] = useState(false);

  // const monthlyreports 
  const [monthlyReports, setmonthlyReports] = useState([]);

  // 
  const [reportViewMode, setViewReportMode] = useState('ComparisonMode'); // default mode
  // ['DataMode', 'ComparisonMode', 'ChartMode', 'MonthlyMode]
  const [toggleFilterBar, setToggleFilterBar] = useState(true);


  const formatDate = (date) => date.toISOString().split("T")[0];

  // Today's date
  const today = new Date();

  // Date 4 days ago
  const fourDaysAgo = new Date();
  fourDaysAgo.setDate(today.getDate() - 4);


  // // 
  // const [filterDateFrom, setfilterDateFrom] = useState(formatDate(fourDaysAgo));
  // const [filterDateTo, setfilterDateTo] = useState(formatDate(today));

  const [filterDateFrom, setfilterDateFrom] = useState('');
  const [filterDateTo, setfilterDateTo] = useState('');
  const [filterReport, setFilterReport] = useState('...');
  const [filterTime, setFilterTime] = useState("...");
  const [filterShift, setFilterShift] = useState("");
  const [filtercsmgeneral, setFilterCsmGeneral] = useState("");

  const [currentPage, setCurrentPage] = useState(1); // Track the current page
  const [reportsPerPage, setReportsPerPage] = useState(8); // Number of reports per page
  const [totalReports, settotalReports] = useState(0);
  const [totalPages, settotalPages] = useState(0);

  const [isLoading, setIsLoading] = useState(false);


  // schema copying from add_report
  const CSMSHIFTREPORT_Schema = {
    "otpw0m": { value: 0, demoValue: 0, defaultValue: 0, title: "OTP within 0 Min", user: "" },
    "otpw15m": { value: 0, demoValue: 0, defaultValue: 0, title: "OTP within 15 Min", user: "" },
    "otpgs": { value: 0, demoValue: 0, defaultValue: 0, title: "OTP Ground Service", user: "" },
    "totalg9f": { value: 0, demoValue: 0, defaultValue: 0, title: "Total G9 Flights", user: "" },
    "totalot": { value: 0, demoValue: 0, defaultValue: 0, title: "Total on Time", user: "" },
    "delyo15m": { value: 0, demoValue: 0, defaultValue: 0, title: "Delays Over 15 Min", user: "" },
    "totald": { value: 0, demoValue: 0, defaultValue: 0, title: "Total Delayed", user: "" },
    "totalaccd": { value: 0, demoValue: 0, defaultValue: 0, title: "Total accountable delays", user: "" },
    "totalabyp": { value: 0, demoValue: 0, defaultValue: 0, title: "Total ABY Pax", user: "" },
    "totalabypinf": { value: 0, demoValue: 0, defaultValue: 0, title: "Total ABY Pax INF", user: "" },
    "totalabyjp": { value: 0, demoValue: 0, defaultValue: 0, title: "Total ABY joining Pax", user: "" },
    "totalabyjpinf": { value: 0, demoValue: 0, defaultValue: 0, title: "Total ABY joining Pax INF", user: "" },
    "totalabyip": { value: 0, demoValue: 0, defaultValue: 0, title: "Total ABY inbound Pax", user: "" },
    "totalabyipinf": { value: 0, demoValue: 0, defaultValue: 0, title: "Total ABY inbound Pax INF", user: "" },


    "onlineci": { value: 0, demoValue: 0, defaultValue: 0, title: "Online check in", user: "" },
    // "totaloafli": { value: 0, demoValue: 0, defaultValue: 0, title: "Total other airlines flights", user: "" },
    // "totaloapax": { value: 0, demoValue: 0, defaultValue: 0, title: "Total other airlines Pax", user: "" },
    "ebt": { value: 0, demoValue: 0, defaultValue: 0, title: "EBT", user: "" },
    "lcdfc": { value: 0, demoValue: 0, defaultValue: 0, title: "LCD Fees collection", user: "" },
    "halasc": { value: 0, demoValue: 0, defaultValue: 0, title: "Hala Service Collection", user: "" },

    "otpw0m_oal": { value: 0, demoValue: 0, defaultValue: 0, title: "OTP within 0 Min OAL", user: "" },
    "otpw15m_oal": { value: 0, demoValue: 0, defaultValue: 0, title: "OTP within 15 Min OAL", user: "" },
    "otpgs_oal": { value: 0, demoValue: 0, defaultValue: 0, title: "OTP Ground Service OAL", user: "" },
    "totaloalf__oal": { value: 0, demoValue: 0, defaultValue: 0, title: "Total OAL Flights", user: "" },
    "totalot_oal": { value: 0, demoValue: 0, defaultValue: 0, title: "Total on Time OAL", user: "" },
    "delyo15m_oal": { value: 0, demoValue: 0, defaultValue: 0, title: "Delays Over 15 Min OAL", user: "" },
    "totald_oal": { value: 0, demoValue: 0, defaultValue: 0, title: "Total Delayed OAL", user: "" },
    "totalaccd_oal": { value: 0, demoValue: 0, defaultValue: 0, title: "Total accountable delays OAL", user: "" },
    "totaloalp__oal": { value: 0, demoValue: 0, defaultValue: 0, title: "Total OAL Pax", user: "" },
    "totaloalp__oal__INF": { value: 0, demoValue: 0, defaultValue: 0, title: "Total OAL Pax INF", user: "" },

    "shiftadm": { value: "", demoValue: "", defaultValue: "", title: "ADM", user: "" },
    "shiftaom": { value: "", demoValue: "", defaultValue: "", title: "AOM", user: "" },
    "shiftbocjoin": { value: "", demoValue: "", defaultValue: "", title: "BOC Joining", user: "" },
    "shiftboctran": { value: "", demoValue: "", defaultValue: "", title: "BOC Transfer", user: "" },
    "shiftcom": { value: "", demoValue: "", defaultValue: "", title: "COM", user: "" },
    "shiftairsideacsm": { value: "", demoValue: "", defaultValue: "", title: "Airside CSM", user: "" },
    "shiftairsidelcsm": { value: "", demoValue: "", defaultValue: "", title: "Landside CSM", user: "" },
    "shiftairsidefcsm": { value: "", demoValue: "", defaultValue: "", title: "Floater CSM", user: "" },
  }

  // const fieldGroups = {
  //   OTP_G9: ['otpw0m', 'otpw15m', 'otpgs'],
  //   G9_FLIGHTS: ['totalg9f', 'totalot'],
  //   G9_PAX: ['totalabyp', 'totalabyjp', 'totalabyip', 'totalabypinf', 'totalabyjpinf', 'totalabyipinf'],
  //   G9_DELAY: ['delyo15m', 'totald', 'totalaccd'],
  //   HALA: ['lcdfc', 'halasc'],
  //   OTHERS: ['ebt', 'onlineci'],

  //   OTP_OAL: ['otpw0m_oal', 'otpw15m_oal', 'otpgs_oal'],
  //   ROLE: ['shiftadm', 'shiftaom', 'shiftbocjoin', 'shiftboctran', 'shiftcom', 'shiftairsideacsm', 'shiftairsidelcsm', 'shiftairsidefcsm'],

  //   // Add more groups if needed
  // };

  const fieldGroups = {
    'G9 Ops': {
      OTP: ['otpw0m', 'otpw15m', 'otpgs'],
      FLIGHTS: ['totalg9f', 'totalot'],
      PAX: ['totalabyp', 'totalabyjp', 'totalabyip', 'totalabypinf', 'totalabyjpinf', 'totalabyipinf'],
      DELAY: ['delyo15m', 'totald', 'totalaccd'],
      OTHERS: ['onlineci']
    },
    'Other Airlines': {
      OTP: ['otpw0m_oal', 'otpw15m_oal', 'otpgs_oal'],
      FLIGHTS: ['totaloalf__oal', 'totalot_oal'],
      PAX: ['totaloalp__oal', 'totaloalp__oal__INF'],
      DELAY: ['delyo15m_oal', 'totald_oal', 'totalaccd_oal'],
    },
    Hala: {
      HALA: ['halasc'],
      OTHERS: ['ebt', 'lcdfc'],
    },
    // Others: {

    // },
    Role: {
      ADM: ['shiftadm'],
      Airside: ['shiftairsideacsm', 'shiftairsidelcsm', 'shiftairsidefcsm'],
      Landside: ['shiftaom', 'shiftbocjoin', 'shiftboctran'],
      Cargo: ['shiftcom'],
    },
  };


  // parsing admin from ls
  useEffect(() => {
    let ls = localStorage.getItem('admin');
    if (!ls) {
      navigate('/login')
    }

    if (ls) {

      let admin = JSON.parse(ls)
      setadminLevel(admin.staffcategory)
    }

  })

  // checking if user have access
  useEffect(() => {
    if (adminLevel !== "") {
      { !['SUPER-ADMIN', 'csm', 'ADM', 'HOD', 'HALA-MANAGER'].includes(adminLevel) && navigate("/view") }
    }
  }, [adminLevel])

  // fetch all reports
  useEffect(() => {
    setload_allreports(true);
    fetch(`${Utils["domains"]["development"]}/sbpapis/get?filterDateFrom=${filterDateFrom}&filterDateTo=${filterDateTo}&filterReport=${filterReport}&filterTime=${filterTime}&page=${currentPage}&limit=${reportsPerPage}&filtershift=${filterShift}&filtercsmgeneral=${filtercsmgeneral}`, {
      method: "get",
      headers: {
        "Content-Type": "application/json", // Specify that you're sending JSON data
      },
      // body: JSON.stringify({}), // Set the JSON data as the request body
    })
      .then((e) => {
        return e.json();
      })
      .then((data) => {
        setallreports(data.reports);
        settotalReports(data.totalReports)
        settotalPages(data.totalPages)

      }).finally((fin) => {
        setTimeout(() => {
          setload_allreports(false);
          setApplyFilter(false);
        }, 0);
      })
  }, [applyFilter, currentPage, reportsPerPage]);

  const [selectedFields, setSelectedFields] = useState([]);

  const handleCheckboxChange = (fieldOrGroup) => {
    const isGroup = fieldGroups[fieldOrGroup] || Object.values(fieldGroups).some(childGroups => childGroups[fieldOrGroup]);

    setSelectedFields((prevSelectedFields) => {
      if (isGroup) {
        // Check if all fields in the group are selected
        const fieldsInGroup = Object.values(fieldGroups)
          .flatMap(childGroups => childGroups[fieldOrGroup] || [])
          .flat();
        const allSelected = fieldsInGroup.every(field => prevSelectedFields.includes(field));

        if (allSelected) {
          // Deselect all fields in the group
          return prevSelectedFields.filter(f => !fieldsInGroup.includes(f));
        } else {
          // Select all fields in the group
          return [...new Set([...prevSelectedFields, ...fieldsInGroup])];
        }
      } else {
        // Handle individual field selection
        return prevSelectedFields.includes(fieldOrGroup)
          ? prevSelectedFields.filter(f => f !== fieldOrGroup)
          : [...prevSelectedFields, fieldOrGroup];
      }
    });
  };


  // useEffect(()=>{
  //   console.log(selectedFields)
  // }, [selectedFields])

  // Extract data function
  const extractDataForMetric = (metric) => {
    return allreports.map(report => ({
      date: new Date(report.reportDate).toLocaleDateString(), // format as needed
      time: report.reportTime,
      value: report.reportFields[metric]?.value || 0
    }));
  };

  const formatDataForChart = (data, metricTitle) => {
    // Add chart headers
    const formattedData = [['Date', 'Value']];
    data.forEach(item => {
      formattedData.push([`${item.date} ${item.time}`, item.value]);
    });
    return formattedData;
  };


  // useEffect(()=> {
  //   if (allreports.length >= 1) {
  //     console.log('lcd fees collection')
  //     console.log( formatDataForChart(extractDataForMetric('lcdfc'), 'LCD Fees Collection'))
  //   }
  // }, [allreports])

  return (
    <div className="">

      <p className="mb-5 bg-white p-5 rounded-lg">View Reports</p>

      {/* Container around the entire content */}
      <div className="relative">

        {/* container of filterbar and center content */}
        <div className="flex gap-5 w-full overflow-hidden">

          {/* Left Filter bar */}
          <div >


            <div className="sticky top-5 self-start" >
              <button onClick={() => setToggleFilterBar(!toggleFilterBar)} className="mb-5 bg-[#fff] text-[#444] w-[40px] h-[40px] rounded-md text-lg flex items-center justify-center">

                {!toggleFilterBar ? <MdOutlineMenu size={26} /> :
                  <MdClose size={26} />}

              </button>


              {!toggleFilterBar && <button
                onClick={() => {
                  setViewReportMode("DataMode")
                  setApplyFilter(true)

                }}
                className={`mb-5 w-[40px] h-[40px] rounded-md text-lg flex items-center justify-center ${reportViewMode === 'DataMode' ? "bg-[#444] text-white" : "bg-[#fff] text-[#777]"
                  }`}
              >
                <FaList size={26} />

              </button>}
              {!toggleFilterBar && <button
                onClick={() => {
                  setViewReportMode("ComparisonMode")
                  setApplyFilter(true)

                }}
                className={`mb-5 w-[40px] h-[40px] rounded-md text-lg flex items-center justify-center ${reportViewMode === 'ComparisonMode' ? "bg-[#444] text-white" : "bg-[#fff] text-[#777]"
                  }`}
              >
                <FaCodeCompare size={26} />

              </button>}
              {!toggleFilterBar && <button
                onClick={() => {
                  setViewReportMode("ChartMode")
                  setApplyFilter(true)

                }}
                className={`mb-5 w-[40px] h-[40px] rounded-md text-lg flex items-center justify-center ${reportViewMode === 'ChartMode' ? "bg-[#444] text-white" : "bg-[#fff] text-[#777]"
                  }`}
              >

                <FaChartPie size={26} />
              </button>}

              {toggleFilterBar && <FilterBar FilterValues={{
                filterDateFrom,
                filterDateTo,
                filterReport,
                filterTime,
                filterShift,
                filtercsmgeneral,

                setFilterCsmGeneral,
                setFilterShift,
                setfilterDateFrom,
                setfilterDateTo,
                setFilterReport,
                setFilterTime
              }} ReportsList={ReportsList} applyFilter={applyFilter} setApplyFilter={setApplyFilter} CSMSHIFTREPORT_Schema={CSMSHIFTREPORT_Schema} selectedFields={selectedFields} setSelectedFields={setSelectedFields} handleCheckboxChange={handleCheckboxChange} fieldGroups={fieldGroups} monthlyReports={monthlyReports} setmonthlyReports={setmonthlyReports} setIsLoading={setIsLoading} isLoading={isLoading} />}


            </div>
          </div>

          {/* data, Comparison, charts view */}
          <div className="rounded-md bg-white p-5 w-full flex-1 overflow-hidden ">

            {/* View Container */}
            {toggleFilterBar && <ModeToggleBtnBar reportViewMode={reportViewMode} setViewReportMode={setViewReportMode} setApplyFilter={setApplyFilter} />}

            {load_allreports == false && <div className="mt-10  w-full ">
              {reportViewMode == 'DataMode' && <DataViewMode allreports={allreports} selectedFields={selectedFields} />}
              {reportViewMode == 'ComparisonMode' && <ComparisonViewMode allreports={allreports} selectedFields={selectedFields} pagination={{ currentPage: currentPage, setCurrentPage: setCurrentPage, totalPages: totalPages, totalReports: totalReports, reportsPerPage: reportsPerPage, setReportsPerPage: setReportsPerPage }} />}
              {/* {reportViewMode == 'ChartMode' && <ChartViewMode allreports={allreports} formatDataForChart={formatDataForChart} extractDataForMetric={extractDataForMetric} selectedFields={selectedFields} />} */}
              {reportViewMode == 'MonthlyMode' && <MonthlyMode monthlyReports={monthlyReports} setIsLoading={setIsLoading} isLoading={isLoading} />}
            </div>}

            {/* alt */}
            {load_allreports === true && <div className="flex items-center mt-5 gap-5">
              <MiniLoadingBar />
              Fetching Reports From the server...
            </div>}

            {load_allreports === true && <div className="flex items-center mt-5 gap-5">
              <MiniLoadingBar />
              Generating Reports...
            </div>}

          </div>
        </div>
      </div>
      <div className="w-full h-[300px]">

      </div>
    </div>

  );
};

// Generate year options dynamically
const currentYear = new Date().getFullYear();
const yearOptions = Array.from(new Array(5), (v, i) => {
  const year = currentYear - i;
  return { value: year, label: year };
});

// Options for months
const monthOptions = [
  { value: 1, label: "January" },
  { value: 2, label: "February" },
  { value: 3, label: "March" },
  { value: 4, label: "April" },
  { value: 5, label: "May" },
  { value: 6, label: "June" },
  { value: 7, label: "July" },
  { value: 8, label: "August" },
  { value: 9, label: "September" },
  { value: 10, label: "October" },
  { value: 11, label: "November" },
  { value: 12, label: "December" }
];


// 
const FilterBar = ({ ReportsList, applyFilter, setApplyFilter, CSMSHIFTREPORT_Schema, selectedFields, setSelectedFields, handleCheckboxChange, FilterValues, fieldGroups, setmonthlyReports, monthlyReports, setIsLoading, isLoading }) => {

  const [hidenshowFieldsSelection, sethidenshowFieldsSelection] = useState(false);


  const {
    filterDateFrom,
    filterDateTo,
    filterReport,
    filterTime,
    filterShift, filtercsmgeneral,

    setFilterCsmGeneral,
    setFilterShift,
    setfilterDateFrom,
    setfilterDateTo,
    setFilterReport,
    setFilterTime,


  } = FilterValues;

  const handleIncludeAll = () => {
    setSelectedFields([]);
    setApplyFilter(true);
  };

  const handleExcludeAll = () => {
    const allFields = Object.keys(CSMSHIFTREPORT_Schema);
    setSelectedFields(allFields);
    setApplyFilter(true);
  };


  // State to manage which accordion is open
  const [openAccordion, setOpenAccordion] = useState(null);

  // Function to toggle accordion open/close
  const toggleAccordion = (groupName) => {
    setOpenAccordion(openAccordion === groupName ? null : groupName);
  };


  // monthly, yearly, weekly reports  -------------------------------------------- complicated -----------------------------------------------------------


  // console.table(yearOptions)
  // console.table(monthOptions)

  const [selectedMonthsYears, setSelectedMonthsYears] = useState([]);

  const handleMonthYearChange = (selectedOption) => {
    // console.clear()
    // console.log(selectedOption)
    setSelectedMonthsYears(selectedOption);
  };

  // Build combined month-year options
  const combinedOptions = [];
  yearOptions.forEach((year) => {
    monthOptions.forEach((month) => {
      combinedOptions.push({
        value: `${year.value}-${month.value}`, // example: "2024-1" for January 2024
        label: `${month.label} ${year.value}`
      });
    });
  });

  const fetchReportsByMonths = async () => {
    setIsLoading(true)
    try {
      fetch(`${Utils["domains"]["development"]}/sbpapis/get-by-months`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ selectedMonths: selectedMonthsYears })
      }).then((e) => {
        return e.json();
      })
        .then((data) => {
          // console.log(data);
          // Your logic here
          setmonthlyReports(data['reportsByMonth'])
        })
        .catch((error) => {
          console.error('An error occurred:', error);
          setmonthlyReports([])
          // Handle the error, for example, show an error message or update state
        })
        .finally(() => {
          // Uncomment if you want to re-enable these actions
          // setTimeout(() => {
          //   setload_allreports(false);
          //   setApplyFilter(false);
          // }, 300);
        });

    } catch (error) {
      console.error('Error fetching reports:', error);
      throw error;
    }
  };

  // monthly, yearly, weekly reports  -------------------------------------------- complicated end -----------------------------------------------------------


  return (
    <div className="bg-[#fff] rounded-lg w-[300px] flex flex-col gap-3 items-start py-5 px-5  text-[#777]  h-[600px] overflow-scroll">

      {/* date from */}
      <div className="w-full">
        <p className="text-xs mb-1 font-bold" > Date From  </p>
        <input
          value={filterDateFrom}
          onChange={(e) => { setfilterDateFrom(e.target.value) }}
          type="date"
          className="h-10 rounded-md bg-gray-200 border border-gray-300 w-full px-3"

        />
      </div>

      {/* date to */}
      <div className="w-full">
        <p className="text-xs mb-1 font-bold" > Date To </p>
        <input
          value={filterDateTo}
          onChange={(e) => { setfilterDateTo(e.target.value) }}
          type="date"
          className="h-10 rounded-md bg-gray-200 border border-gray-300 w-full px-3"

        />
      </div>

      {/* Choose Report */}
      <div className="w-full">
        <p className="text-xs mb-1 font-bold" > Select Report </p>
        <select
          value={filterReport}
          // defaultValue={'not-selected'}
          onChange={(e) => { setFilterReport(e.target.value) }}
          className="h-10 w-full rounded-lg px-3 bg-gray-200 border border-gray-300 mt-1"
        // onChange={(e) => {
        //   // if (e.target.value != "not-selected") {
        //   setSelectedReport(e.target.value)
        //   // }
        // }}
        // value={selectedReport}
        >

          <option value="..." selected>...</option>
          <option value="not-selected">No Report Selected!</option>
          {ReportsList.map((report) => {
            return <option value={report}>{report}</option>
          })}

        </select>
      </div>

      {/* Choose Time */}
      <div className="w-full">
        <p className="text-sm mb-1 font-bold" > Choose Your Time </p>
        <select
          value={filterTime}
          onChange={(e) => { setFilterTime(e.target.value) }}
          className="h-10 rounded-lg px-3 bg-gray-200 border border-gray-300 mt-1 w-full"
        >
          <option value="...">...</option>
          <option value="">24 Hours Operation</option>
          <option value="Morning">Morning</option>
          <option value="Night">Night</option>
        </select>
      </div>

      {/* Select shift */}
      <div className="w-full">
        <p className="text-sm mb-1 font-bold" > Filter By Shift </p>
        <select
          value={filterShift}
          onChange={(e) => { setFilterShift(e.target.value) }}
          className="h-10 rounded-lg px-3 bg-gray-200 border border-gray-300 mt-1 w-full"
        >
          <option value="">All</option>
          <option value="Mr. Mourad">Mr. Mourad</option>
          <option value="Mr. Hummaid">Mr. Humaid</option>
          <option value="Mr. Othman">Mr. Othman</option>
          <option value="Ms. Rachita">Ms. Rachita</option>
        </select>
      </div>

      {/* Select Shift */}
      <div className="w-full">
        <p className="text-sm mb-1 font-bold" > Filter By CSM </p>
        <select
          value={filtercsmgeneral}
          onChange={(e) => { setFilterCsmGeneral(e.target.value) }}
          className="h-10 rounded-lg px-3 bg-gray-200 border border-gray-300 mt-1 w-full"
        >
          <option value="">All</option>
          <option value="Dridi Karim">Dridi Karim</option>
          <option value="Issam Shamlan">Issam Shamlan</option>
          <option value="Fares Mustapha">Fares Mustapha</option>
          <option value="Abdessamad Aoussar">Abdessamad Aoussar</option>
          <option value="Lucie Zborovska">Lucie Zborovska</option>
          <option value="Adnan Ahmed">Adnan Ahmed</option>
          <option value="Yudha Singh Subedi">Yudha Singh Subedi</option>
          <option value="Yamn Al Swidan">Yamn Al Swidan</option>
          <option value="Sumesh Surendran">Sumesh Surendran</option>
          <option value="Nour El Houda Mamouni">Nour El Houda Mamouni</option>
          <option value="Souad Abbasi">Souad Abbasi</option>
        </select>
      </div>

      {/* Comparison Type */}
      <div className="w-full">
        <p className="text-sm mb-1 font-bold" > Comparison Type </p>
        <select
          // value={filterTime}
          // onChange={(e) => { setFilterTime(e.target.value) }}
          className="h-10 rounded-lg px-3 bg-gray-200 border border-gray-300 mt-1 w-full"
        >
          <option value="daily">Daily</option>
          <option value="weekly">Weekly</option>
          <option value="bi-weekly">Bi-Weekly</option>
          <option selected value="monthly" >Monthly</option>
          <option value="quarterly">Quarterly</option>
          <option value="semi-annually">Semi-Annually</option>
          <option value="yearly">Yearly</option>
          <option value="biennially">Biennially</option>
          <option value="custom">Custom</option>
        </select>
      </div>



      <div className="w-full flex gap-3">

        <button
          onClick={() => { handleIncludeAll() }}

          className="  bg-[dodgerblue] mt-5 w-1/2 flex items-center justify-center h-10 rounded-lg text-white uppercase text-xs"
        >
          Include All
        </button>
        <button
          onClick={() => { handleExcludeAll() }}
          className="  bg-[dodgerblue] mt-5 w-1/2 flex items-center justify-center h-10 rounded-lg text-white uppercase text-xs"
        >
          Exclude All
        </button>
      </div>

      <hr />

      {/* apply filter */}
      <div className="w-full">

        <button
          onClick={() => { sethidenshowFieldsSelection(pre => !pre) }}
          className="  bg-gradient-to-r from-a-pink to-a-blue hover:to-a-pink hover:from-a-blue mt-5 w-full flex items-center justify-center h-10 rounded-lg text-white uppercase text-xs"
        >
          {hidenshowFieldsSelection ? 'Hide Fields Selection' : 'Show Fields Selection'}
        </button>
      </div>

      {hidenshowFieldsSelection &&
        (<div className="w-full">

          {Object.entries(fieldGroups).map(([parentGroupName, childGroups]) => (
            <div key={parentGroupName} className="parent-group mb-6 p-4 border border-gray-400 rounded-lg shadow-lg">
              {/* Styled Parent Group Header */}
              <h2 className="text-lg font-semibold mb-4 bg-[#777] text-[#fff] px-4 py-2 rounded-md shadow-md">
                {parentGroupName.replace('_', ' ').toUpperCase()}
              </h2>

              {/* Child Groups */}
              {Object.entries(childGroups).map(([groupName, fields]) => (
                <div
                  key={groupName}
                  className="child-group mb-4 p-2 border border-gray-300 rounded-lg shadow-md"
                >
                  {/* Child Group Header with Accordion Toggle */}
                  <div className="flex justify-between items-center cursor-pointer">
                    <label className="flex gap-3 items-center text-md">
                      <input
                        type="checkbox"
                        checked={fields.every((field) => selectedFields.includes(field))}
                        onChange={() => handleCheckboxChange(groupName)}
                      />
                      {groupName.replace('_', ' ').toUpperCase()}
                      <span className="bg-blue-500 text-white text-xs font-semibold w-[20px] h-[20px] flex items-center justify-center rounded-full">
                        {fields.length}
                      </span>
                    </label>
                    <span
                      onClick={() => toggleAccordion(groupName)}
                      className={`transition-transform duration-300 text-md ${openAccordion === groupName ? 'rotate-180' : ''}`}
                    >
                      {openAccordion === groupName ? <FaCaretUp /> : <FaCaretDown />}
                    </span>
                  </div>

                  {/* Child Group Content */}
                  {openAccordion === groupName && (
                    <div className="accordion-content mt-2 pl-6 transition-all duration-300">
                      {fields.map((field) => (
                        <div
                          key={field}
                          className="flex gap-3 items-center mb-2 p-2 bg-gray-50 rounded"
                        >
                          <input
                            type="checkbox"
                            checked={selectedFields.includes(field)}
                            onChange={() => handleCheckboxChange(field)}
                          />
                          <span className="text-gray-800">
                            {CSMSHIFTREPORT_Schema[field]?.title}
                          </span>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              ))}
            </div>
          ))}




          {/* Render fields that don't belong to any group */}
          {/* <hr className="my-4" />
        <p className="text-sm my-3 font-bold text-lg">Target Fields (Not in Groups)</p>

        {Object.entries(CSMSHIFTREPORT_Schema).map(([key, { title }]) => {
          const isInGroup = Object.values(fieldGroups)
            .flat()
            .includes(key);
          if (!isInGroup) {
            return (
              <div
                key={key}
                className="flex gap-3 items-center mb-2 p-2 bg-gray-100 rounded"
              >
                <input
                  type="checkbox"
                  checked={selectedFields.includes(key)}
                  onChange={() => handleCheckboxChange(key)}
                />
                <span className="text-gray-800">{title}</span>
              </div>
            );
          }
          return null;
        })} */}





        </div>)}

      {/* apply filter */}
      <div className="w-full">

        <button
          onClick={() => { setApplyFilter(true) }}
          className="  bg-gradient-to-r from-a-pink to-a-blue hover:to-a-pink hover:from-a-blue mt-5 w-full flex items-center justify-center h-10 rounded-lg text-white uppercase text-xs"
        >
          {applyFilter ? 'Fetching' : 'Apply'}
        </button>
      </div>

      <hr />
      <hr />

      <p className="text-sm mb-1 font-bold" > Monthly Reports </p>

      <div className="w-full">
        <p className="text-sm mb-1 font-bold">Select Months and Years</p>
        <Select
          isMulti
          options={combinedOptions}
          value={selectedMonthsYears}
          onChange={handleMonthYearChange}
          className="basic-multi-select w-full"
          classNamePrefix="select"
        />
      </div>

      {/* apply filter */}
      <div className="w-full">

        <button
          disabled={isLoading}
          onClick={() => { fetchReportsByMonths() }}
          className="  bg-gradient-to-r from-a-pink to-a-blue hover:to-a-pink hover:from-a-blue mt-5 w-full flex items-center justify-center h-10 rounded-lg text-white uppercase text-xs"
        >
          {!isLoading ? 'Fetch Months Reports' : (<div className="flex justify-end items-center mt-0">
            <div className="w-6 h-6 border-4 border-t-transparent  border-blue-400 rounded-full animate-spin"></div>
          </div>)}


        </button>
      </div>

    </div>
  )
}

// Toggle View Mode buttons bar
const ModeToggleBtnBar = ({ reportViewMode, setViewReportMode, setApplyFilter }) => {
  return (
    <div className="flex gap-5">
      <button
        onClick={() => {
          setViewReportMode("DataMode")
          setApplyFilter(true)

        }}
        className={`px-10 py-3 rounded-lg uppercase text-md ${reportViewMode === 'DataMode' ? "bg-[#444] text-white" : "bg-[#fff] text-[#777]"
          }`}
      >
        Data View
      </button>
      <button
        onClick={() => {
          setViewReportMode("ComparisonMode")
          setApplyFilter(true)

        }}
        className={`px-10 py-3 rounded-lg uppercase text-md ${reportViewMode === 'ComparisonMode' ? "bg-[#444] text-white" : "bg-[#fff] text-[#777]"
          }`}
      >
        Comparison View
      </button>
      {/* <button
        onClick={() => {
          setViewReportMode("ChartMode")
          setApplyFilter(true)

        }}
        className={`px-10 py-3 rounded-lg uppercase text-md ${reportViewMode === 'ChartMode' ? "bg-[#444] text-white" : "bg-[#fff] text-[#777]"
          }`}
      >
        Chart View
      </button> */}
      <button
        onClick={() => {
          setViewReportMode("MonthlyMode")
          // setApplyFilter(true)

        }}
        className={`px-10 py-3 rounded-lg uppercase text-md ${reportViewMode === 'MonthlyMode' ? "bg-[#444] text-white" : "bg-[#fff] text-[#777]"
          }`}
      >
        Monthly View
      </button>
    </div>
  )
}

// Data View Mode 1
const DataViewMode = ({ allreports, selectedFields }) => {

  return (
    <div className="w-full">

      <p>Report Context</p>
      <div className="w-full max-w-3xl mt-8">
        {/* Iterate report for tabs */}
        {allreports.map((report, index) => (
          <div
            key={index}
            className="bg-[#2d2d2d] text-white rounded-lg px-4 h-[40px] mt-2 flex items-center justify-between shadow-md hover:bg-[#3a3a3a] transition-all cursor-pointer"
          >
            {/* Left Section - Report Info */}
            <div className="flex items-center gap-3">
              <div className="bg-[#555] rounded-full w-[22px] h-[22px] flex items-center justify-center text-sm font-medium">
                {index + 1}
              </div>

              <div className="font-semibold text-sm">{report.reportTitle}</div>

              <div className="flex items-center gap-2 text-xs text-gray-400">
                <p>⋅</p>
                <div className="bg-[#444] px-2 py-0.5 rounded-md">
                  {report.reportDate.toString().split("T")[0]}
                </div>
                <p>⋅</p>
                <div>{report.reportTime} Shift</div>
                <p>⋅</p>
                <div>{report?.reportShift} Shift</div>
              </div>

              {/* Status Tag */}
              <div
                className={`px-2 py-0.5 rounded-md text-xs uppercase font-semibold ${report.isPublishedInit ? "bg-green-500" : "bg-red-500"
                  }`}
              >
                {report.isPublishedInit ? "Published" : "Draft"}
              </div>
            </div>

            {/* Right Section - View Button */}
            <a href={'#report' + report._id} className="flex items-center justify-center px-4 py-1 rounded-md bg-[#666] hover:bg-[#555] text-sm transition-all">
              View
            </a>
          </div>
        ))}
      </div>



      <hr className="mt-10" />

      {allreports.map((report, index) => (
        <div className="grid gap-5" id={'report' + report._id}>
          <div className="bg-[#444] text-white rounded-md px-5 h-[70px] mt-10 flex items-center justify-between" key={index}>
            <div className="flex items-center justify-between gap-2">
              <div className="bg-[#555] rounded-full w-[30px] h-[30px] flex items-center justify-center">
                {index + 1}
              </div>
              <div className="font-bold">{report.reportTitle}</div>
              <p>⋅</p>
              <div className="bg-[#555] p-2 rounded-md">{report.reportDate.toString().split("T")[0]}</div>
              <p>⋅</p>
              <div>{report.reportTime} Shift</div>
              <p>⋅</p>
              <div>{report?.reportShift} Shift</div>
              <p>⋅</p>
              <div><p
                className={` px-8  text-white uppercase text-md h-10 flex items-center justify-center rounded-lg 
             ${!report.isPublishedInit === true ? ' bg-red-500 ' : ' bg-green-500 '} `}
              >
                {report.isPublishedInit === true ? 'Published' : 'Draft'}
              </p></div>
            </div>
            {/* <div>
              <button
                // onClick={() => setViewReportMode("DataMode")}
                className={`px-5 py-2 rounded-lg uppercase text-md bg-[#666]`}
              >
                View Report
              </button>
            </div> */}
          </div>
          <ViewCSMReport rptDtaFields={report.reportFields} />
          {/* <div className="grid grid-cols-5 gap-5 pt-5">
            {Object.keys(report.reportFields).map((key, idx) => (
              !selectedFields.includes(key) &&
              <div key={idx} className="flex rounded-md overflow-hidden">
                <div className="h-[60px] px-2 bg-gray-300 text-xs font-semibold  w-[60%] flex items-center  justify-left">{report.reportFields[key].title}</div>
                <div className="h-[60px] w-[40%] text-lg bg-gray-200 flex items-center justify-center">{report.reportFields[key].value}</div>
              </div>
            ))}
          </div> */}
          <hr />
        </div>
      ))}

    </div>
  )
}
const shifts = ["Mr. Mourad", "Mr. Othman", "Mr. Humaid", "Ms. Rachita"];

const shiftColors = {
  "Mr. Mourad": "bg-indigo-50 border-indigo-300 text-indigo-800",
  "Mr. Othman": "bg-teal-50 border-teal-300 text-teal-800",
  "Ms. Rachita": "bg-pink-50 border-pink-300 text-pink-800",
  "Mr. Humaid": "bg-yellow-50 border-yellow-300 text-yellow-800"
};

const ComparisonViewMode = ({ allreports, selectedFields, pagination }) => {


  const [activeShift, setActiveShift] = useState('All');
  const [viewMode, setViewMode] = useState('All'); // 'All' or 'Breakdown'
  const [colWidth, setColwidth] = useState(150);


  // Create refs for each shift section
  const shiftRefs = useRef(shifts.reduce((acc, shift) => {
    acc[shift] = React.createRef();
    return acc;
  }, {}));

  const handleTabClick = (shift) => {
    if (shift === 'All') {
      setViewMode('All');
    } else {
      // Scroll to the section instead of activating it
      setViewMode('Breakdown');
      shiftRefs.current[shift].current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div className="space-y-12 w-full overview-hidden ">
      {/* Tab Navigation for Shifts */}
      <div className="flex gap-4 mb-6">
        <button
          onClick={() => setViewMode(viewMode === 'All' ? 'Breakdown' : 'All')}
          className={`px-4 py-2 rounded-md border ${viewMode === 'All' ? 'bg-gray-800 text-white' : 'bg-gray-300 text-gray-700'} transition duration-300`}
        >
          {viewMode === 'All' ? 'Shift View' : 'All'}
        </button>

        {/* Shift Tabs */}
        {viewMode === 'Breakdown' && (
          <div className="flex gap-4">
            {[...shifts].map(shift => (
              <button
                key={shift}
                onClick={() => handleTabClick(shift)}
                className={`px-4 py-2 rounded-md ${activeShift === shift ? 'bg-blue-600 text-white' : 'bg-gray-200'} transition duration-300`}
              >
                {shift}
              </button>
            ))}
          </div>
        )}
      </div>

      {/* Report Display Section */}
      {viewMode === 'All' ? (
        <div className="p-8 rounded-lg shadow-lg hover:shadow-xl transition-all duration-200 ease-in-out">
          <h3 className="text-2xl font-semibold mb-4 border-b-4 pb-2 text-gray-700">All Shifts</h3>
          <ComparisonViewModeSingle
            allreports={allreports}
            selectedFields={selectedFields}
            pagination={pagination}
            colWidth={colWidth} setColwidth={setColwidth}
          />
        </div>
      ) : (
        <div>
          <div className="space-y-12">
            {shifts.map(shift => {
              // Filter reports for the current shift
              const reportsForShift = allreports.filter(report => report.reportShift === shift);
              return (
                <div
                  key={shift}
                  ref={shiftRefs.current[shift]} // Set ref for scrolling
                  className={`p-8 rounded-lg shadow-lg hover:shadow-xl transform transition-all duration-200 ease-in-out hover:-translate-y-1 ${shiftColors[shift]}`}
                >
                  <h3 className={`text-2xl font-semibold mb-4 border-b-4 pb-2 ${shiftColors[shift]}`}>
                    {shift}
                  </h3>
                  <ComparisonViewModeSingle
                    allreports={reportsForShift}
                    selectedFields={selectedFields}
                    pagination={pagination}
                    colWidth={colWidth} setColwidth={setColwidth}
                  />
                </div>
              );
            })}
          </div>
          <div className="space-y-12">
            <div className="w-full overflow-x-scroll scrollbar-hidden">
              <table className="table-fixed border-collapse w-full overflow-x-scroll ">
                <thead>
                  <tr className="bg-gray-200">

                    <th style={{ width: colWidth + "px" }} className={`  border border-gray-300 px-4 py-2 text-left text-xs font-semibold`}>
                      Shift
                    </th>

                    {/* <th style={{ width: colWidth + "px" }} className={`  border border-gray-300 pl-1 py-2 text-left text-xs font-semibold`}>Reports</th> */}
                    {allreports.length > 0 && Object.keys(allreports[0].reportFields).map((key, idx) => (
                      !selectedFields.includes(key) &&
                      <th title={allreports[0].reportFields[key].title} key={idx} style={{ width: colWidth + "px" }} className={` border border-gray-300 px-[4px] py-2 text-left text-[9px] font-semibold`}>
                        {/* {} */}
                        {/* {(parseInt(colWidth) < 90) && createShortForm(allreports[0].reportFields[key].title)} */}
                        {/* {!(parseInt(colWidth) < 90) && allreports[0].reportFields[key].title} */}
                        {allreports[0].reportFields[key].title}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>


                  {shifts.map(shift => {
                    // Filter reports for the current shift
                    const reportsForShift = allreports.filter(report => report.reportShift === shift);

                    return (
                      <ComparisonViewModeSingleOver
                        allreports={reportsForShift}
                        selectedFields={selectedFields}
                        pagination={pagination}
                        colWidth={colWidth} setColwidth={setColwidth}
                        shift={shift}
                      />

                    );
                  })}   </tbody> </table></div>
          </div>
        </div>
      )}
    </div>
  );
};





const ComparisonViewModeSingle = ({ allreports, selectedFields, pagination, colWidth, setColwidth }) => {
  function createShortForm(text) {
    return text
      .split(" ")
      .map(word => word[0].toLowerCase()) // Take the first character of each word and make it lowercase
      .join(""); // Combine them into a single string
  }

  const { currentPage: currentPage, setCurrentPage: setCurrentPage, totalPages, totalReports, reportsPerPage, setReportsPerPage } = pagination;

  console.log('all report will be here \n', allreports)

  // configration 
  // const [colWidth, setColwidth] = useState(150);

  const formatToMillion = (num) => {
    if (num >= 1000000) {
      return `${(num / 1000000).toFixed(2)}M`;
    }
    return num.toString(); // Return the original number if it's less than 1,000,000
  };

  // calc
  // Function to calculate total for a specific column
  const calculateColumnTotal = (key) => {
    let total = 0;
    allreports.forEach((report) => {
      // Check if reportFields[key] is defined and has a value property
      // console.log(report.reportFields[key])
      if (report.reportFields && report.reportFields[key] && report.reportFields[key].value) {
        total += parseInt(report.reportFields[key].value) || 0;
      }
      if (report.reportFields && ['otpw0m', 'otpw15m', 'otpgs'].includes(key)) {
        total = "%";
      }
    });
    return total;
  };



  // Calculate maximum and minimum values for each field across all reports
  const maxValues = {};
  const minValues = {};

  // Initialize minValues with Infinity to correctly handle positive and zero values
  Object.keys(allreports[0]?.reportFields || {}).forEach((key) => {
    minValues[key] = Infinity;
  });

  allreports.forEach(report => {
    Object.entries(report.reportFields).forEach(([key, field]) => {
      // Update maximum values
      if ((!maxValues[key] || field.value > maxValues[key]) && field.value > 0) {
        maxValues[key] = field.value;
      }
      // Update minimum values; zero is valid but shouldn't automatically be considered the minimum
      if (field.value !== 0 && (field.value < minValues[key] || !minValues[key])) {
        minValues[key] = field.value;
      }
    });
  });


  function analyzePercentages(percentages) {
    // Calculate the sum
    const sum = percentages.reduce((acc, curr) => acc + curr, 0);

    // Calculate the average
    const average = sum / percentages.length;

    // Find the highest and lowest percentages
    const highest = Math.max(...percentages);
    const lowest = Math.min(...percentages);

    // Create the distribution categories
    const distribution = {
      '0-25%': percentages.filter(p => p >= 0 && p <= 25).length,
      '26-50%': percentages.filter(p => p > 25 && p <= 50).length,
      '51-75%': percentages.filter(p => p > 50 && p <= 75).length,
      '76-100%': percentages.filter(p => p > 75 && p <= 100).length
    };

    return {
      average: Math.round(average),
      sum: sum,
      highest: highest,
      lowest: lowest,
      distribution: distribution
    };
  }

  const collectPercantages = (key) => {
    let collection = [];
    allreports.forEach((report) => {
      if (report.reportFields && report.reportFields[key] !== undefined) {
        let value = parseInt(report.reportFields[key].value) || 0;
        collection.push(value);
      }
    });
    return collection;
  }

  console.log('otp')

  console.log(analyzePercentages(collectPercantages('otpw0m')))
  console.log(analyzePercentages(collectPercantages('otpw15m')))
  console.log(analyzePercentages(collectPercantages('otpgs')))

  // scrollbar 
  // Refs to access the top and main scrollable divs
  const topScrollRef = useRef(null);
  const mainScrollRef = useRef(null);
  const [tableWidth, setTableWidth] = useState('100%');

  // Sync scroll position between top and main scrollable areas
  const syncScroll = (source, target) => {
    if (target.current) {
      target.current.scrollLeft = source.current.scrollLeft;
    }
  };

  // Update the width of the top scrollbar dummy div based on table width
  useEffect(() => {
    if (mainScrollRef.current) {
      setTableWidth(`${mainScrollRef.current.scrollWidth}px`);
    }
  }, []);


  return (
    <div className=" w-full   ">

      {/* configrations */}
      <div className="mb-5 flex gap-5 items-center justify-between">
        <div className="">
          <p className="text-xs mb-1" > Adjust the Table width - {colWidth} </p>
          <input
            value={colWidth}
            min={50}
            max={500}
            step={1}
            onChange={(e) => {
              setColwidth(Number(e.target.value))
            }}
            type="range"
            className=" rounded-md bg-gray-200 border border-gray-300 px-3"

          />
        </div>

        <div>
          <span className="text-sm">Total Reports: <select name="" id="" value={reportsPerPage} onChange={(e) => {
            setReportsPerPage(Number(e.target.value))
          }}>
            {[8, 25, 50, 75, 100].map((limit) => (
              <option key={limit} value={limit}>
                {limit}
              </option>
            ))}
          </select> / {totalReports}</span>

        </div>

      </div>

      <div className="relative w-full">
        {/* Top scrollbar */}
        <div
          ref={topScrollRef}
          className="w-full overflow-x-auto scrollbar-hidden"
          onScroll={() => syncScroll(topScrollRef, mainScrollRef)}
        >
          {/* This div acts as a dummy scrollbar */}
          <div style={{ minWidth: tableWidth }} className="h-1 bg-gray-300" />
        </div>

        {/* Main scrollable container */}
        <div
          ref={mainScrollRef}
          className="w-full overflow-x-scroll scrollbar-hidden"
          onScroll={() => syncScroll(mainScrollRef, topScrollRef)}
        >
          <table className="table-fixed border-collapse w-full">
            <thead>
              <tr className="bg-gray-200">
                <th style={{ width: colWidth + "px" }} className={`z-10	bg-gray-300 sticky left-0 border border-gray-300 px-4 py-2 text-left text-xs font-semibold`}>
                  -
                </th>
                <th style={{ width: colWidth + "px", left: (colWidth) + "px" }} className={`z-10	bg-gray-300 sticky   border border-gray-300 px-4 py-2 text-left text-xs font-semibold`}>
                  Sno
                </th>
                <th style={{ width: colWidth + "px", left: (colWidth * 2) + "px" }} className={`z-10	bg-gray-300 sticky  border border-gray-300 pl-1 py-2 text-left text-xs font-semibold`}>Reports</th>
                {allreports.length > 0 && Object.keys(allreports[0].reportFields).map((key, idx) => (
                  !selectedFields.includes(key) &&
                  // <th title={allreports[0].reportFields[key].title} key={idx} style={{ width: colWidth + "px" }} className={` border border-gray-300 px-[4px] py-2 text-left text-[9px] font-semibold`}>
                  <th
                    title={allreports[0].reportFields[key].title}
                    key={idx}
                    style={{ width: (['totalabyp', 'totalabyjp', 'totalabyip', 'ebt', 'halasc', 'totaloalp__oal', 'shiftadm', 'shiftaom', 'shiftbocjoin', 'shiftboctran', 'shiftairsideacsm', 'shiftairsidelcsm', 'shiftairsidefcsm', 'shiftcom'].includes(key) ? colWidth * 2 : colWidth) + "px" }}
                    className={`border border-gray-300 px-[4px] py-2 text-left text-[9px] font-semibold`}
                  >


                    {/* {} */}
                    {/* {['totalabyp', 'totalabyjp', 'totalabyip', 'ebt', 'halasc', 'totaloalp__oal', 'shiftadm', 'shiftaom', 'shiftbocjoin', 'shiftboctran', 'shiftairsideacsm', 'shiftairsidelcsm', 'shiftairsidefcsm','shiftcom'].includes(report.reportFields[key])} */}
                    {/* {(parseInt(colWidth) < 90) && createShortForm(allreports[0].reportFields[key].title)} */}
                    {/* {!(parseInt(colWidth) < 90) && allreports[0].reportFields[key].title} */}
                    {allreports[0].reportFields[key].title}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>

              {/* total */}
              <tr className="bg-gray-800 text-white">
                <td className="z-10	border bg-gray-900 border-gray-300 px-4 py-2 text-xs font-semibold sticky left-0"  >-</td>
                <td className="z-10	border bg-gray-900 border-gray-300 px-4 py-2 text-xs font-semibold sticky" style={{ left: (colWidth) + "px" }}>-</td>
                <td className="z-10	border  bg-gray-900 sborder-gray-300 px-1 py-2 text-xs font-semibold sticky" style={{ left: (colWidth * 2) + "px" }}>Total</td>
                {allreports.length > 0 &&
                  Object.keys(allreports[0].reportFields).map((key, idx) => (
                    !selectedFields.includes(key) &&
                    <td
                      key={idx}
                      className={`relative border border-gray-300 ${parseInt(colWidth) < 90 ? 'text-xs' : 'text-lg font-semibold'} px-1 py-2   text-center`}
                    >
                      {['otpw0m', 'otpw15m', 'otpgs', 'otpw0m_oal', 'otpw15m_oal', 'otpgs_oal'].includes(key) && `${analyzePercentages(collectPercantages(key))['average']} %`}
                      {console.log(key)}
                      {!['otpw0m', 'otpw15m', 'otpgs', 'otpw0m_oal', 'otpw15m_oal', 'otpgs_oal'].includes(key) && formatToMillion(calculateColumnTotal(key))}

                      {['otpw0m', 'otpw15m', 'otpgs', 'otpw0m_oal', 'otpw15m_oal', 'otpgs_oal'].includes(key) && (
                        <div className="tooltip relative">
                          <span className="tooltipbtn"> <IoMdInformationCircle /> </span>
                          <span className={`tooltiptext hidden bg-gray-800 text-white rounded-lg p-6 absolute z-10 top-0 left-0 min-w-[300px] opacity-0 transition-opacity duration-300 shadow-xl border border-gray-600`}>
                            <div className="flex flex-col">
                              <h4 className="font-bold text-xl mb-4">Statistics</h4>

                              <div className="grid grid-cols-2 gap-4 mb-4">
                                <div className="flex items-center">
                                  <span className="font-medium"><strong>Average:</strong></span>
                                </div>
                                <div className="text-right font-semibold">{analyzePercentages(collectPercantages(key))['average']}</div>

                                {/* <div className="flex items-center">
                                  <span className="font-medium"><strong>Sum:</strong></span>
                                </div>
                                <div className="text-right font-semibold">{analyzePercentages(collectPercantages(key))['sum']}</div> */}

                                <div className="flex items-center">
                                  <span className="font-medium"><strong>Highest:</strong></span>
                                </div>
                                <div className="text-right font-semibold">{analyzePercentages(collectPercantages(key))['highest']}</div>

                                <div className="flex items-center">
                                  <span className="font-medium"><strong>Lowest:</strong></span>
                                </div>
                                <div className="text-right font-semibold">{analyzePercentages(collectPercantages(key))['lowest']}</div>
                              </div>

                              <div className="mt-4">
                                <strong className="block mb-2">Distribution:</strong>
                                <div className="space-y-2">
                                  {Object.entries(analyzePercentages(collectPercantages(key))['distribution']).map(([range, count]) => (
                                    <div key={range} className="flex justify-between text-sm">
                                      <span className="flex items-center">
                                        <svg className="w-4 h-4 mr-1 text-gray-400" fill="currentColor" viewBox="0 0 20 20">
                                          <circle cx="10" cy="10" r="4" />
                                        </svg>
                                        {range}:
                                      </span>
                                      <span className="font-semibold">{count}</span>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            </div>
                          </span>

                        </div>
                      )}





                    </td>
                  ))}
              </tr>



              {allreports.map((report, index) => (
                <>
                  {/* <tr key={index} className={report.reportTime === 'Morning' ? 'bg-yellow-50' : 'bg-blue-50'}> */}
                  <tr key={"trr" + index} >
                    <td key={'indt' + index} className={`z-10	bg-gray-200 sticky left-0 text-xs  border border-gray-300 ${parseInt(colWidth) < 90 ? 'px-1' : 'px-4'} py-2 text-lg text-left `}>
                      {report.reportTime === 'Morning' ? <IoIosPartlySunny className="text-[#FFD700]" size={30} /> : <MdNightsStay className="text-[#191970]" size={30} />}
                    </td>
                    <td key={'indf' + index} style={{ left: (colWidth) + "px" }} className="z-10	bg-gray-200 sticky text-xs  border border-gray-300 px-4 py-2 text-lg text-left ">
                      {index + 1}
                    </td>
                    <td key={'h' + index} style={{ left: (colWidth * 2) + "px" }} className="z-10	bg-gray-200 sticky text-xs  border border-gray-300 px-1 py-2 text-lg text-left">
                      {!(parseInt(colWidth) < 90) && <div className="font-bold">{report.reportTitle}</div>}
                      <div className="">{report.reportDate.toString().split("T")[0]}</div>
                      {!(parseInt(colWidth) < 90) && <div>{report.reportTime} Shift</div>}

                      {!(parseInt(colWidth) < 90) && (<><div className="">{report.reportTime}</div>
                        <div className="">{report.reportShift}</div>
                        <div><p
                          className={` p-1 text-white uppercase text-xs rounded-lg 
             ${!report.isPublishedInit === true ? ' bg-red-500 ' : ' bg-green-500 '} `}
                        >
                          {report.isPublishedInit === true ? 'Published' : 'Draft'}
                        </p></div> </>)}

                    </td>
                    {Object.keys(report.reportFields).map((key, idx) => {
                      const fieldValue = report.reportFields[key].value;
                      const isMaxValue = fieldValue === maxValues[key];
                      const isMinValue = fieldValue === minValues[key];
                      return (
                        !selectedFields.includes(key) &&

                        // <td
                        //   key={idx}
                        //   className={`border border-gray-300 px-1 py-2 ${parseInt(colWidth) < 90 ? 'text-xs' : 'text-lg'}  text-center ${isMaxValue ? "bg-green-200" : isMinValue ? "bg-red-200" : ""
                        //     }`}
                        //   title={report.reportFields[key].title}
                        // >
                        //   {fieldValue}
                        // </td>
                        <td
                          key={idx}
                          className={`border border-gray-300 px-1 py-2 ${parseInt(colWidth) < 90 ? 'text-xs' : 'text-lg'} text-center`}
                          title={report.reportFields[key].title}
                        >

                          {/* game yahn per hai bro */}
                          <div className="flex items-center justify-center">
                            {isMaxValue && isMinValue ? (
                              <FaArrowUp className="text-green-500 mr-1 transform transition-transform duration-200 hover:scale-125" />
                            ) : (
                              <>
                                {isMaxValue && (
                                  <FaArrowUp className="text-green-500 mr-1 transform transition-transform duration-200 hover:scale-125" />
                                )}
                                {isMinValue && (
                                  <FaArrowDown className="text-red-500 mr-1 transform transition-transform duration-200 hover:scale-125" />
                                )}
                              </>
                            )}

                            {/* <span>{key}{fieldValue}</span> */}
                            <span>{fieldValue}</span>
                          </div>
                        </td>

                      );
                    })}
                  </tr>
                </>
              ))}

              {/* total */}
              {/* total */}
              <tr className="bg-gray-800 text-white">
                <td className="border border-gray-300 px-4 py-2 text-xs font-semibold sticky left-0 z-10 bg-gray-900">-</td>
                <td className="border border-gray-300 px-4 py-2 text-xs font-semibold sticky z-10  bg-gray-900" style={{ left: (colWidth) + "px" }}>-</td>
                <td className="border border-gray-300 px-1 py-2 text-xs font-semibold sticky z-10  bg-gray-900" style={{ left: (colWidth * 2) + "px" }}>Total</td>
                {allreports.length > 0 &&
                  Object.keys(allreports[0].reportFields).map((key, idx) => (
                    !selectedFields.includes(key) &&
                    <td
                      key={idx}
                      className={`relative border border-gray-300 ${parseInt(colWidth) < 90 ? 'text-xs' : 'text-lg font-semibold'} px-1 py-2   text-center`}
                    >
                      {['otpw0m', 'otpw15m', 'otpgs', 'otpw0m_oal', 'otpw15m_oal', 'otpgs_oal'].includes(key) && `${analyzePercentages(collectPercantages(key))['average']} %`}
                      {console.log(key)}
                      {!['otpw0m', 'otpw15m', 'otpgs', 'otpw0m_oal', 'otpw15m_oal', 'otpgs_oal'].includes(key) && formatToMillion(calculateColumnTotal(key))}

                      {/* {['otpw0m', 'otpw15m', 'otpgs', 'otpw0m_oal', 'otpw15m_oal', 'otpgs_oal'].includes(key) && (
                        <div className="tooltip relative">
                          <span className="tooltipbtn">...</span>
                          <span className={`tooltiptext hidden bg-gray-800 text-white rounded-lg p-6 absolute z-10 top-0 left-0 min-w-[300px] opacity-0 transition-opacity duration-300 shadow-xl border border-gray-600`}>
                            <div className="flex flex-col">
                              <h4 className="font-bold text-xl mb-4">Statistics</h4>

                              <div className="grid grid-cols-2 gap-4 mb-4">
                                <div className="flex items-center">
                                  <span className="font-medium"><strong>Average:</strong></span>
                                </div>
                                <div className="text-right font-semibold">{analyzePercentages(collectPercantages(key))['average']}</div>
                               

                                <div className="flex items-center">
                                  <span className="font-medium"><strong>Highest:</strong></span>
                                </div>
                                <div className="text-right font-semibold">{analyzePercentages(collectPercantages(key))['highest']}</div>

                                <div className="flex items-center">
                                  <span className="font-medium"><strong>Lowest:</strong></span>
                                </div>
                                <div className="text-right font-semibold">{analyzePercentages(collectPercantages(key))['lowest']}</div>
                              </div>

                              <div className="mt-4">
                                <strong className="block mb-2">Distribution:</strong>
                                <div className="space-y-2">
                                  {Object.entries(analyzePercentages(collectPercantages(key))['distribution']).map(([range, count]) => (
                                    <div key={range} className="flex justify-between text-sm">
                                      <span className="flex items-center">
                                        <svg className="w-4 h-4 mr-1 text-gray-400" fill="currentColor" viewBox="0 0 20 20">
                                          <circle cx="10" cy="10" r="4" />
                                        </svg>
                                        {range}:
                                      </span>
                                      <span className="font-semibold">{count}</span>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            </div>
                          </span>

                        </div>
                      )} */}





                    </td>
                  ))}
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      {/* Pagination Controls */}
      <div className="pagination-controls flex items-center justify-center gap-4 mt-4">
        <span className="text-lg font-semibold">Page {currentPage} of {totalPages}</span>
        <button
          onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
          disabled={currentPage === 1}
          className={`px-4 py-2 rounded-md text-white ${currentPage === 1 ? 'bg-gray-400 cursor-not-allowed' : 'bg-blue-500 hover:bg-blue-600'} transition duration-300 ease-in-out`}>
          Previous
        </button>
        {/* Page Number Buttons */}
        <div className="flex gap-2">
          {Array.from({ length: totalPages }, (_, index) => index + 1).map((pageNum) => (
            <button
              key={pageNum}
              onClick={() => setCurrentPage(pageNum)}
              className={`w-[40px] h-[40px] flex items-center justify-center rounded-md ${currentPage === pageNum ? 'bg-blue-600 text-white' : 'bg-gray-200 hover:bg-blue-500 hover:text-white'} transition duration-300 ease-in-out`}>
              {pageNum}
            </button>
          ))}
        </div>
        <button
          onClick={() => setCurrentPage((prev) => prev + 1)}
          className="px-4 py-2 rounded-md text-white bg-blue-500 hover:bg-blue-600 transition duration-300 ease-in-out">
          Next
        </button>
      </div>

      <div className="w-full h-[100px]">

      </div>

    </div>
  )
}


const ComparisonViewModeSingleOver = ({ allreports, selectedFields, pagination, colWidth, setColwidth, shift }) => {
  function createShortForm(text) {
    return text
      .split(" ")
      .map(word => word[0].toLowerCase()) // Take the first character of each word and make it lowercase
      .join(""); // Combine them into a single string
  }

  const { currentPage: currentPage, setCurrentPage: setCurrentPage, totalPages, totalReports, reportsPerPage, setReportsPerPage } = pagination;

  console.log('all report will be here \n', allreports)

  // configration 
  // const [colWidth, setColwidth] = useState(150);

  const formatToMillion = (num) => {
    if (num >= 1000000) {
      return `${(num / 1000000).toFixed(2)}M`;
    }
    return num.toString(); // Return the original number if it's less than 1,000,000
  };

  // calc
  // Function to calculate total for a specific column
  const calculateColumnTotal = (key) => {
    let total = 0;
    allreports.forEach((report) => {
      // Check if reportFields[key] is defined and has a value property
      // console.log(report.reportFields[key])
      if (report.reportFields && report.reportFields[key] && report.reportFields[key].value) {
        total += parseInt(report.reportFields[key].value) || 0;
      }
      if (report.reportFields && ['otpw0m', 'otpw15m', 'otpgs'].includes(key)) {
        total = "%";
      }
    });
    return total;
  };



  // Calculate maximum and minimum values for each field across all reports
  const maxValues = {};
  const minValues = {};

  // Initialize minValues with Infinity to correctly handle positive and zero values
  Object.keys(allreports[0]?.reportFields || {}).forEach((key) => {
    minValues[key] = Infinity;
  });

  allreports.forEach(report => {
    Object.entries(report.reportFields).forEach(([key, field]) => {
      // Update maximum values
      if ((!maxValues[key] || field.value > maxValues[key]) && field.value > 0) {
        maxValues[key] = field.value;
      }
      // Update minimum values; zero is valid but shouldn't automatically be considered the minimum
      if (field.value !== 0 && (field.value < minValues[key] || !minValues[key])) {
        minValues[key] = field.value;
      }
    });
  });


  function analyzePercentages(percentages) {
    // Calculate the sum
    const sum = percentages.reduce((acc, curr) => acc + curr, 0);

    // Calculate the average
    const average = sum / percentages.length;

    // Find the highest and lowest percentages
    const highest = Math.max(...percentages);
    const lowest = Math.min(...percentages);

    // Create the distribution categories
    const distribution = {
      '0-25%': percentages.filter(p => p >= 0 && p <= 25).length,
      '26-50%': percentages.filter(p => p > 25 && p <= 50).length,
      '51-75%': percentages.filter(p => p > 50 && p <= 75).length,
      '76-100%': percentages.filter(p => p > 75 && p <= 100).length
    };

    return {
      average: Math.round(average),
      sum: sum,
      highest: highest,
      lowest: lowest,
      distribution: distribution
    };
  }

  const collectPercantages = (key) => {
    let collection = [];
    allreports.forEach((report) => {
      if (report.reportFields && report.reportFields[key] !== undefined) {
        let value = parseInt(report.reportFields[key].value) || 0;
        collection.push(value);
      }
    });
    return collection;
  }

  console.log('otp')

  console.log(analyzePercentages(collectPercantages('otpw0m')))
  console.log(analyzePercentages(collectPercantages('otpw15m')))
  console.log(analyzePercentages(collectPercantages('otpgs')))

  // scrollbar 
  // Refs to access the top and main scrollable divs
  const topScrollRef = useRef(null);
  const mainScrollRef = useRef(null);
  const [tableWidth, setTableWidth] = useState('100%');

  // Sync scroll position between top and main scrollable areas
  const syncScroll = (source, target) => {
    if (target.current) {
      target.current.scrollLeft = source.current.scrollLeft;
    }
  };

  // Update the width of the top scrollbar dummy div based on table width
  useEffect(() => {
    if (mainScrollRef.current) {
      setTableWidth(`${mainScrollRef.current.scrollWidth}px`);
    }
  }, []);


  return (


    <tr className="bg-gray-800 text-white">

      <td className="border border-gray-300 px-1 py-2 text-xs font-semibold">{shift}</td>
      {allreports.length > 0 &&
        Object.keys(allreports[0].reportFields).map((key, idx) => (
          !selectedFields.includes(key) &&
          <td
            key={idx}
            className={`relative border border-gray-300 ${parseInt(colWidth) < 90 ? 'text-xs' : 'text-lg font-semibold'} px-1 py-2   text-center`}
          >
            {['otpw0m', 'otpw15m', 'otpgs'].includes(key) && `${analyzePercentages(collectPercantages(key))['average']} %`}
            {console.log(key)}
            {!['otpw0m', 'otpw15m', 'otpgs'].includes(key) && formatToMillion(calculateColumnTotal(key))}

            {['otpw0m', 'otpw15m', 'otpgs'].includes(key) && (
              <div className="tooltip relative">
                <span className="tooltipbtn">...</span>
                <span className={`tooltiptext hidden bg-gray-800 text-white rounded-lg p-6 absolute z-10 top-0 left-0 min-w-[300px] opacity-0 transition-opacity duration-300 shadow-xl border border-gray-600`}>
                  <div className="flex flex-col">
                    <h4 className="font-bold text-xl mb-4">Statistics</h4>

                    <div className="grid grid-cols-2 gap-4 mb-4">
                      <div className="flex items-center">
                        <span className="font-medium"><strong>Average:</strong></span>
                      </div>
                      <div className="text-right font-semibold">{analyzePercentages(collectPercantages(key))['average']}</div>

                      {/* <div className="flex items-center">
                        <span className="font-medium"><strong>Sum:</strong></span>
                      </div>
                      <div className="text-right font-semibold">{analyzePercentages(collectPercantages(key))['sum']}</div> */}

                      <div className="flex items-center">
                        <span className="font-medium"><strong>Highest:</strong></span>
                      </div>
                      <div className="text-right font-semibold">{analyzePercentages(collectPercantages(key))['highest']}</div>

                      <div className="flex items-center">
                        <span className="font-medium"><strong>Lowest:</strong></span>
                      </div>
                      <div className="text-right font-semibold">{analyzePercentages(collectPercantages(key))['lowest']}</div>
                    </div>

                    <div className="mt-4">
                      <strong className="block mb-2">Distribution:</strong>
                      <div className="space-y-2">
                        {Object.entries(analyzePercentages(collectPercantages(key))['distribution']).map(([range, count]) => (
                          <div key={range} className="flex justify-between text-sm">
                            <span className="flex items-center">
                              <svg className="w-4 h-4 mr-1 text-gray-400" fill="currentColor" viewBox="0 0 20 20">
                                <circle cx="10" cy="10" r="4" />
                              </svg>
                              {range}:
                            </span>
                            <span className="font-semibold">{count}</span>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </span>

              </div>
            )}





          </td>
        ))}
    </tr>

  )
}


// Chart View Mode 3
const ChartViewMode = ({ allreports, formatDataForChart, extractDataForMetric, selectedFields }) => {
  return (
    <div className="grid grid-cols-2 gap-4"> {/* Add gap-4 for spacing */}

      {allreports.length > 0 && Object.keys(allreports[0].reportFields).map((key, idx) => (
        !selectedFields.includes(key) && (
          <div key={key} className="col-span-1 mb-4"> {/* Add key and spacing */}
            <Chart
              chartType="PieChart"
              width="100%"
              height="400px"
              data={formatDataForChart(extractDataForMetric(key), allreports[0].reportFields[key].title)}
              options={{
                title: allreports[0].reportFields[key].title,
                pieHole: 0.4,
                is3D: false,
              }}
            />
          </div>
        )
      ))}

    </div>
  );
};

const MonthlyMode = ({ monthlyReports, setIsLoading, isLoading }) => {
  return (
    <div className="p-6 bg-gray-50 min-h-screen">
      <CustomLoadingBar isLoading={isLoading} setIsLoading={setIsLoading} />
      {/* {!isLoading && ( */}
      {!isLoading && (

        <>
          <MonthlyFieldComparison data={monthlyReports} />
          <MonthlyReportCon data={monthlyReports} />
          <MonthlyReportComparasion data={monthlyReports} />
        </>
      )}
    </div>
  );
};

// Monthly Reports Container
const MonthlyReportCon = ({ data }) => {
  return (
    <div className="">
      {data.map((report) => (
        <MonthlyReportConOver key={report.month} report={report} />
      ))}
    </div>
  );
};

const MonthlyReportConOver = ({ report }) => {
  const combinedFields = report.combinedFields;
  const [hoveredField, setHoveredField] = useState(null);
  const [hoveredFieldData, setHoveredFieldData] = useState([]);
  const tooltipRef = useRef(null); // Ref for the tooltip
  // const [loading, setLoading] = useState(false)

  // console.log(report)
  // console.log(combinedFields)

  const handleMouseEnter = (field) => {
    // setLoading(true)
    // setTimeout(() => {
    //   setLoading(false)
    // }, 1000);
    setHoveredField(field);
    setHoveredFieldData(collectFieldData(field));
  };

  const handleMouseLeave = () => {
    // Check if the mouse is still over the tooltip
    if (tooltipRef.current && !tooltipRef.current.contains(event.relatedTarget)) {
      setHoveredField(null);
      setHoveredFieldData([]);
    }
  };

  const handleTooltipMouseEnter = () => {
    // Keep the tooltip active
    if (hoveredField) {
      setHoveredFieldData(collectFieldData(hoveredField));
    }
  };

  const handleTooltipMouseLeave = () => {
    setHoveredField(null);
    setHoveredFieldData([]);
  };



  // Function to collect values for a specific field
  const collectFieldData = (fieldName) => {
    const collectedData = [];

    report.reports.forEach(report => {
      const date = new Date(report.reportDate).toISOString().split('T')[0]; // Format date as YYYY-MM-DD
      const value = report.reportFields[fieldName]?.value; // Use optional chaining to safely access the value

      if (value !== undefined) { // Only push if the value exists
        const time = report.reportTime;
        collectedData.push({ date, 'value': value, time: time });
      }
    });

    return collectedData;
  };

  function analyzePercentages(percentages) {
    // Calculate the sum
    const sum = percentages.reduce((acc, curr) => acc + curr, 0);

    // Calculate the average
    const average = sum / percentages.length;

    // Find the highest and lowest percentages
    const highest = Math.max(...percentages);
    const lowest = Math.min(...percentages);

    // Create the distribution categories
    const distribution = {
      '0-25%': percentages.filter(p => p >= 0 && p <= 25).length,
      '26-50%': percentages.filter(p => p > 25 && p <= 50).length,
      '51-75%': percentages.filter(p => p > 50 && p <= 75).length,
      '76-100%': percentages.filter(p => p > 75 && p <= 100).length
    };

    return {
      average: Math.round(average),
      sum: sum,
      highest: highest,
      lowest: lowest,
      distribution: distribution
    };
  }

  const collectPercantages = (key) => {
    let collection = [];
    report.reports.forEach((report) => {
      if (report.reportFields && report.reportFields[key] !== undefined) {
        let value = parseInt(report.reportFields[key].value) || 0;
        collection.push(value);
      }
    });
    return collection;
  }

  console.log(report.month)
  console.log('otpw0m ', collectPercantages('otpw0m'))
  console.log('otpw15m ', collectPercantages('otpw15m'))
  console.log('otpgs ', collectPercantages('otpgs'))

  console.log('otpw0m ', analyzePercentages(collectPercantages('otpw0m')))
  console.log('otpw15m ', analyzePercentages(collectPercantages('otpw15m')))
  console.log('otpgs ', analyzePercentages(collectPercantages('otpgs')))





  return (
    <div className="bg-white rounded-lg shadow-md p-4 mb-12 relative grid grid-cols-5">
      <div className="col-span-4">
        <h2 className="text-2xl font-bold text-gray-800">{report.month}</h2>
        {Object.keys(combinedFields).length > 0 ? (
          <table className="w-full mt-2 text-left">
            <thead className="bg-gray-100">
              <tr>
                <th className="px-4 py-2 border-b border-gray-300">Field Title</th>
                <th className="px-4 py-2 border-b border-gray-300">Value</th>
              </tr>
            </thead>
            <tbody>
              {Object.entries(combinedFields).map(([key, field]) => (
                (!['shiftadm', 'shiftaom', 'shiftbocjoin', 'shiftboctran', 'shiftcom', 'shiftairsideacsm', 'shiftairsidelcsm', 'shiftairsidefcsm'].includes(key) &&
                  <tr key={key} className="even:bg-gray-50  hover:bg-gray-200"
                    onMouseEnter={() => handleMouseEnter(key)}
                    onMouseLeave={handleMouseLeave}>
                    <td className="px-4 py-2 border-b border-gray-300">{field.title}</td>
                    {/* {console.log(key, field)} */}
                    <td className="px-4 py-2 border-b border-gray-300">


                      {!['otpw0m', 'otpw15m', 'otpgs', 'otpw0m_oal', 'otpw15m_oal', 'otpgs_oal'].includes(key) && field.value.toLocaleString()}
                      {['otpw0m', 'otpw15m', 'otpgs', 'otpw0m_oal', 'otpw15m_oal', 'otpgs_oal'].includes(key) && `${analyzePercentages(collectPercantages(key))['average']} %`}


                      {['otpw0m', 'otpw15m', 'otpgs', 'otpw0m_oal', 'otpw15m_oal', 'otpgs_oal'].includes(key) && (
                        <div className="tooltip relative">
                          <span className="tooltipbtn"> <IoMdInformationCircle /></span>
                          <span className={`tooltiptext hidden bg-gray-800 text-white rounded-lg p-6 absolute z-10 top-0 right-0 min-w-[300px] opacity-0 transition-opacity duration-300 shadow-xl border border-gray-600`}>
                            <div className="flex flex-col">
                              <h4 className="font-bold text-xl mb-4">Statistics</h4>

                              <div className="grid grid-cols-2 gap-4 mb-4">
                                <div className="flex items-center">
                                  <span className="font-medium"><strong>Average:</strong></span>
                                </div>
                                <div className="text-right font-semibold">{analyzePercentages(collectPercantages(key))['average']}</div>
                                {/* 
                                <div className="flex items-center">
                                  <span className="font-medium"><strong>Sum:</strong></span>
                                </div>
                                <div className="text-right font-semibold">{analyzePercentages(collectPercantages(key))['sum']}</div> */}

                                <div className="flex items-center">
                                  <span className="font-medium"><strong>Highest:</strong></span>
                                </div>
                                <div className="text-right font-semibold">{analyzePercentages(collectPercantages(key))['highest']}</div>

                                <div className="flex items-center">
                                  <span className="font-medium"><strong>Lowest:</strong></span>
                                </div>
                                <div className="text-right font-semibold">{analyzePercentages(collectPercantages(key))['lowest']}</div>
                              </div>

                              <div className="mt-4">
                                <strong className="block mb-2">Distribution:</strong>
                                <div className="space-y-2">
                                  {Object.entries(analyzePercentages(collectPercantages(key))['distribution']).map(([range, count]) => (
                                    <div key={range} className="flex justify-between text-sm">
                                      <span className="flex items-center">
                                        <svg className="w-4 h-4 mr-1 text-gray-400" fill="currentColor" viewBox="0 0 20 20">
                                          <circle cx="10" cy="10" r="4" />
                                        </svg>
                                        {range}:
                                      </span>
                                      <span className="font-semibold">{count}</span>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            </div>
                          </span>

                        </div>
                      )}


                    </td>
                  </tr>)
              ))}
            </tbody>
          </table>
        ) : (
          <p className="mt-2 text-gray-500">No data available for this month.</p>
        )}
      </div>

      {/* Tooltip for Hovered Field */}
      {hoveredField && hoveredFieldData.length > 0 && (
        <div
          ref={tooltipRef}
          className="col-span-1 bg-white shadow-lg border border-gray-300 p-4 z-10 mt-2 sticky self-start top-4" // Added sticky class and top positioning
          onMouseEnter={handleTooltipMouseEnter}
          onMouseLeave={handleTooltipMouseLeave}
        >
          <h3 className="text-lg font-semibold w-full">Details for {combinedFields[hoveredField].title}</h3>
          <ul className="w-full">
            {hoveredFieldData.map((data, index) => (
              <li key={index} className="flex justify-between items-center px-2 py-3 even:bg-gray-100 hover:bg-gray-200  border-b border-gray-200">
                <span className="text-xs">{data.date}</span>
                <span className="font-bold">{data.value !== null ? data.value.toLocaleString() : 'N/A'}</span>
                <span>

                  {data.time === 'Morning' ? <IoIosPartlySunny className="text-[#FFD700]" size={20} /> : <MdNightsStay className="text-[#191970]" size={20} />}
                </span>
              </li>
            ))}
          </ul>
        </div>
      )}

      {/* {loading && (
        <div className="col-span-1 bg-white shadow-lg border border-gray-300 p-4 z-10 mt-2 sticky self-start top-4">
          <div className="flex justify-end items-center mt-0">
            <div className="w-6 h-6 border-4 border-t-transparent  border-blue-400 rounded-full animate-spin"></div>
          </div>
        </div>
      )} */}

      {!hoveredField && Object.keys(combinedFields).length > 0 && (

        <div className="col-span-1 bg-white shadow-lg border border-gray-300 p-4 z-10 mt-2 sticky self-start top-4">
          Hover over any field to view the daily overview for <span className="font-bold">{report.month}</span> based on the selected field.

        </div>
      )}
    </div>
  );
};

const MonthlyReportComparasion = ({ data }) => {
  const allMetrics = data[0]?.combinedFields ? Object.keys(data[0].combinedFields) : [];
  console.log("data data data");
  console.log(data[0]?.reports)

  // Helper function to find the max and min values for each metric
  const getMinMax = (metricKey) => {
    const values = data.map((monthData) => monthData.combinedFields[metricKey]?.value || 0);
    const maxValue = Math.max(...values);
    const minValue = Math.min(...values);
    return { maxValue, minValue };
  };



  function analyzePercentages(percentages) {
    // Calculate the sum
    const sum = percentages.reduce((acc, curr) => acc + curr, 0);

    // Calculate the average
    const average = sum / percentages.length;

    // Find the highest and lowest percentages
    const highest = Math.max(...percentages);
    const lowest = Math.min(...percentages);

    // Create the distribution categories
    const distribution = {
      '0-25%': percentages.filter(p => p >= 0 && p <= 25).length,
      '26-50%': percentages.filter(p => p > 25 && p <= 50).length,
      '51-75%': percentages.filter(p => p > 50 && p <= 75).length,
      '76-100%': percentages.filter(p => p > 75 && p <= 100).length
    };

    return {
      average: Math.round(average),
      sum: sum,
      highest: highest,
      lowest: lowest,
      distribution: distribution
    };
  }

  const collectPercantages = (key) => {
    let collection = [];
    data[0]?.reports.forEach((report) => {
      if (report.reportFields && report.reportFields[key] !== undefined) {
        let value = parseInt(report.reportFields[key].value) || 0;
        collection.push(value);
      }
    });
    return collection;
  }

  console.log(analyzePercentages(collectPercantages('otpw0m')))
  console.log(analyzePercentages(collectPercantages('otpw15m')))
  console.log(analyzePercentages(collectPercantages('otpgs')))

  return (
    <div className="bg-white rounded-lg shadow-md p-6 mt-6">
      <h2 className="text-2xl font-semibold text-gray-700 mb-4">Monthly Report Comparison</h2>
      <div className="overflow-x-auto">
        <table className="min-w-full table-auto border-collapse ">
          <thead className="bg-gray-100">
            <tr>
              <th className="px-4 py-2 text-left font-medium text-gray-600">Title</th>
              {data.map((monthData, index) => (
                <th key={index} className="px-4 py-2 border border-gray-300 text-left font-medium text-gray-600">
                  {monthData.month}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {allMetrics.map((metricKey) => {

              const { maxValue, minValue } = getMinMax(metricKey);
              const metricTitle = data[0].combinedFields[metricKey].title;

              return (
                (!['shiftadm', 'shiftaom', 'shiftbocjoin', 'shiftboctran', 'shiftcom', 'shiftairsideacsm', 'shiftairsidelcsm', 'shiftairsidefcsm'].includes(metricKey) &&
                  <tr key={metricKey} className="bg-white even:bg-gray-50 ">
                    <td className="px-4 py-2 font-medium text-gray-700">{metricTitle}</td>
                    {data.map((monthData) => {
                      const value = monthData.combinedFields[metricKey]?.value || 0;
                      let cellClass = "px-4 py-2 border border-gray-300 text-gray-600";

                      // Apply green for highest and red for lowest
                      if (value === maxValue) {
                        cellClass += " bg-green-50 text-green-600 font-bold";
                      } else if (value === minValue) {
                        cellClass += " bg-red-50 text-red-600 font-bold";
                      }

                      return (
                        <td key={monthData.month} className={` ${cellClass}`}>
                          <div className="flex items-center justify-between w-full">
                            {/* {value.toLocaleString()} */}

                            {!['otpw0m', 'otpw15m', 'otpgs', 'otpw0m_oal', 'otpw15m_oal', 'otpgs_oal'].includes(metricKey) && value.toLocaleString()}

                            {['otpw0m', 'otpw15m', 'otpgs', 'otpw0m_oal', 'otpw15m_oal', 'otpgs_oal'].includes(metricKey) && `${analyzePercentages(collectPercantages(metricKey))['average']} %`}

                            {/* {value === maxValue && (
                              <FaArrowUpWideShort className="text-green-500 mr-1 transform transition-transform scale-125 duration-200 hover:scale-125" />
                            )}
                            {value === minValue && (
                              <FaArrowUpWideShort className=" rotate-180 text-red-500 mr-1 transform scale-125 transition-transform duration-200 hover:scale-125" />
                            )} */}

                            {minValue && maxValue ? (
                              <FaArrowUpWideShort className="text-green-500 mr-1 transform transition-transform scale-125 duration-200 hover:scale-125" />
                            ) : (
                              <>
                                {maxValue && (
                                  <FaArrowUpWideShort className="text-green-500 mr-1 transform transition-transform scale-125 duration-200 hover:scale-125" />
                                )
                                }
                                {
                                  minValue && (
                                    <FaArrowUpWideShort className=" rotate-180 text-red-500 mr-1 transform scale-125 transition-transform duration-200 hover:scale-125" />

                                  )
                                }
                              </>
                            )}

                          </div>
                        </td>
                      );
                    })}
                  </tr>)
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};


const MonthlyFieldComparison = ({ data }) => {
  const allMetrics = data[0]?.combinedFields ? Object.keys(data[0].combinedFields) : [];

  function analyzePercentages(percentages) {
    // Calculate the sum
    const sum = percentages.reduce((acc, curr) => acc + curr, 0);

    // Calculate the average
    const average = sum / percentages.length;

    // Find the highest and lowest percentages
    const highest = Math.max(...percentages);
    const lowest = Math.min(...percentages);

    // Create the distribution categories
    const distribution = {
      '0-25%': percentages.filter(p => p >= 0 && p <= 25).length,
      '26-50%': percentages.filter(p => p > 25 && p <= 50).length,
      '51-75%': percentages.filter(p => p > 50 && p <= 75).length,
      '76-100%': percentages.filter(p => p > 75 && p <= 100).length
    };

    return {
      average: Math.round(average),
      sum: sum,
      highest: highest,
      lowest: lowest,
      distribution: distribution
    };
  }

  const collectPercantages = (key) => {
    let collection = [];
    data[0]?.reports.forEach((report) => {
      if (report.reportFields && report.reportFields[key] !== undefined) {
        let value = parseInt(report.reportFields[key].value) || 0;
        collection.push(value);
      }
    });
    return collection;
  }

  console.log(analyzePercentages(collectPercantages('otpw0m')))
  console.log(analyzePercentages(collectPercantages('otpw15m')))
  console.log(analyzePercentages(collectPercantages('otpgs')))

  return (
    <div className="mt-6">
      <h2 className="text-xl font-semibold text-gray-800 mb-4">Field Comparisons by Month</h2>
      <Tabs>
        <TabList className="flex overflow-x-auto space-x-4 border-b border-gray-300 mb-12 bg-gray-100 p-2 rounded-lg shadow-inner">
          {allMetrics.map((metricKey, index) => (
            <Tab
              key={index}
              className="px-4 py-2 text-sm md:text-base whitespace-nowrap cursor-pointer transition duration-200 ease-in-out transform hover:bg-blue-500 hover:text-white rounded-md focus:outline-none"
              selectedClassName="bg-blue-500 text-white font-semibold shadow-md"
            >
              {data[0].combinedFields[metricKey].title}
            </Tab>
          ))}
        </TabList>

        {allMetrics.map((metricKey, index) => {
          const metricTitle = data[0].combinedFields[metricKey].title;
          const chartData = data.map((monthData) => ({
            month: `${monthData.month} - ${monthData.combinedFields[metricKey]?.value || 0}`,
            value: ['otpw0m', 'otpw15m', 'otpgs', 'otpw0m_oal', 'otpw15m_oal', 'otpgs_oal'].includes(metricKey) ? analyzePercentages(collectPercantages(metricKey))['average'] : monthData.combinedFields[metricKey]?.value || 0
          }));

          // {value: ['otpw0m', 'otpw15m', 'otpgs', 'otpw0m_oal', 'otpw15m_oal', 'otpgs_oal'].includes(metricKey) ? `${analyzePercentages(collectPercantages(key))['average']} %` : monthData.combinedFields[metricKey]?.value || 0 }

          return (
            <TabPanel key={index} className="p-0 mb-12">
              <h3 className="text-lg font-semibold mb-2">{metricTitle}</h3>
              <ResponsiveContainer width="35%" height={300}>
                <BarChart data={chartData} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="month" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Bar dataKey="value" fill="#4F46E5" />
                </BarChart>
              </ResponsiveContainer>
            </TabPanel>
          );
        })}
      </Tabs>
    </div>
  );
};


const CustomLoadingBar = ({ isLoading, setIsLoading }) => {
  const [progress, setProgress] = useState(0);
  const [message, setMessage] = useState('');

  const getRandomTime = (min, max) => Math.floor(Math.random() * (max - min + 1)) + min;

  // const steps = [
  //   { text: 'Fetching reports from the server...', time: getRandomTime(1500, 2500) }, // Random between 2500 and 3500
  //   { text: 'Combining monthly reports...', time: getRandomTime(1500, 2500) }, // Random between 2500 and 3500
  //   { text: 'Generating charts...', time: getRandomTime(700, 1300) }, // Random between 1500 and 2500
  //   { text: 'Making comparisons...', time: getRandomTime(1500, 2500) }, // Random between 2500 and 3500
  //   { text: 'Wrapping up...', time: getRandomTime(700, 1300) }, // Random between 700 and 1300
  //   { text: 'Completed!', time: getRandomTime(700, 1300) }, // Random between 700 and 1300
  // ];

  const steps = [
    { text: 'Fetching reports from the server...', time: getRandomTime(0, 10) }, // Random between 2500 and 3500
    { text: 'Combining monthly reports...', time: getRandomTime(0, 10) }, // Random between 2500 and 3500
    { text: 'Generating charts...', time: getRandomTime(0, 10) }, // Random between 1500 and 2500
    { text: 'Making comparisons...', time: getRandomTime(0, 10) }, // Random between 2500 and 3500
    { text: 'Wrapping up...', time: getRandomTime(0, 10) }, // Random between 700 and 1300
    { text: 'Completed!', time: getRandomTime(0, 10) }, // Random between 700 and 1300
  ];

  useEffect(() => {
    if (isLoading) {
      let currentProgress = 0;
      const updateProgress = () => {
        if (currentProgress < steps.length) {
          const step = steps[currentProgress];
          setMessage(step.text);
          setProgress((currentProgress / steps.length) * 100);
          setTimeout(() => {
            currentProgress += 1;
            updateProgress();
          }, step.time);
        } else {
          setProgress(100);
          setTimeout(() => {
            setIsLoading(false);
          }, 500);
        }
      };
      updateProgress();
    }
  }, [isLoading, setIsLoading]);

  if (!isLoading) return null;

  return (
    <div className="w-full max-w-lg mx-auto p-6 bg-white rounded-lg shadow-lg text-center space-y-4">
      <div className="relative w-full h-4 bg-gray-200 rounded-full overflow-hidden">
        <div
          className={`absolute left-0 top-0 h-full bg-gradient-to-r from-blue-500 to-green-500 transition-all duration-700 ease-out`}
          style={{ width: `${progress}%` }}
        ></div>
      </div>
      <p className="mt-4 text-gray-800 font-semibold animate-pulse">{message}</p>
      <div className="flex justify-end items-center mt-0">
        <div className="w-6 h-6 border-4 border-t-transparent  border-blue-400 rounded-full animate-spin"></div>
      </div>
    </div>
  );
};




export default ViewReport;
