
// CSM Report
const CSMReportTemp = {
    config: {
        "title": "CSM Shift Report"
    },
    schemas: {
        "otpw0m": { value: 0, title: "OTP within 0 Min" },
        "otpw15m": { value: 0, title: "OTP within 15 Min" },
        "otpgs": { value: 0, title: "OTP Ground Service" },
        "totalg9f": { value: 0, title: "Total G9 Flights" },
        "totalot": { value: 0, title: "Total on Time" },
        "delyo15m": { value: 0, title: "Delays Over 15 Min" },
        "totald": { value: 0, title: "Total Delayed" },
        "totalaccd": { value: 0, title: "Total accountable delays" },
        "totalabyp": { value: 0, title: "Total ABY Pax" },
        "totalabyjp": { value: 0, title: "Total ABY joining Pax" },
        "totalabyip": { value: 0, title: "Total ABY inbound Pax" },
        "onlineci": { value: 0, title: "Online check in" },
        "totaloafli": { value: 0, title: "Total other airlines flights" },
        "totaloapax": { value: 0, title: "Total other airlines Pax" },
        "ebt": { value: 0, title: "EBT" },
        "lcdfc": { value: 0, title: "LCD Fees collection" },
        "halasc": { value: 0, title: "Hala Service Collection" },
        // "adm": { value: [], title: "ADM" },
        // "csm": { value: [], title: "CSM" },
        // "aom": { value: [], title: "AOM" },
        // "boc": { value: [], title: "BOC" },
        // "com": { value: [], title: "COM" }
    }
};


// AOM Report
const AOMReport = {};

// AOM Report
const COMReport = {};

// BOC Report
const BOCReport = {};

export { CSMReportTemp, AOMReport, COMReport, BOCReport } 