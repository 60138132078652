import React, {useState, useEffect} from 'react'
import Navbar from '../Global/Navbar'

import { useNavigate } from 'react-router-dom';

const Urls = () => {
  const navigate = useNavigate();

  useEffect(()=>{
    let ls = localStorage.getItem('admin');
    if (!ls) {
      navigate('/login')
    }
    return;
  })


  return (
    <div>
    <Navbar title="Links" showarrow={false} />

    {/*  */}
    <div className='mt-5 flex flex-col gap-5'>

    <UrlsList title='AA' link="http://localhost:3000/briefingstaff/aa"/>
    <UrlsList title='PA' link="http://localhost:3000/briefingstaff/pa"/>
    <UrlsList title='PSA' link="http://localhost:3000/briefingstaff/psa"/>
    <UrlsList title='TL' link="http://localhost:3000/briefingstaff/tl"/>
    <UrlsList title='CSS' link="http://localhost:3000/briefingstaff/css"/>
    <UrlsList title='CSM' link="http://localhost:3000/briefingstaff/csm"/>
    <UrlsList title='All' link="http://localhost:3000/briefingstaff/all"/>
    </div>

    </div>
  )
}

const UrlsList = ({title, link}) => {
  const [isCopied, setIsCopied] = useState(false);

  const copyURL = (e, link)=>{
    const tempInput = document.createElement('input');
    tempInput.value = link;

    // Append the input element to the DOM (not visible)
    document.body.appendChild(tempInput);

    // Select the text inside the input element
    tempInput.select();
    tempInput.setSelectionRange(0, 99999); // For mobile devices

    // Copy the selected text to the clipboard
    document.execCommand('copy');

    // Remove the temporary input element
    document.body.removeChild(tempInput);
    setIsCopied(true)

    setTimeout(() => {
      setIsCopied(false);
    }, 2000);
  }

  return (
    <div className='flex items-center gap-5'>
      <input className='px-2 w-[50px] lg:w-[400px] border-none outline-none bg-slate-100 h-12 rounded-lg text-a-gray  text-xs"' type="text" value={link} />
      <p className='flex items-center justify-center px-2  w-[100px] bg-slate-100 h-12 rounded-lg text-a-gray  text-lg font-bold'>{title}</p>
      <button
            onClick={(e)=>{copyURL(e, link=link)}}
              // onClick={handleBriefingCreate}
              className="text-white  bg-gradient-to-r from-a-pink to-a-blue hover:to-a-pink hover:from-a-blue px-12 h-12 rounded-lg   uppercase text-xs"
              disabled={isCopied}
            >
              {isCopied ? 'Copied' : 'Copy'}
            </button>
    </div>
  )
}

export default Urls