import React, { useEffect, useState } from "react";
import Navbar from "../Global/Navbar";

import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import LoadingBar from "../Global/LoadingBar";
import { PiMicrosoftExcelLogoFill } from "react-icons/pi";
import { GrDocumentDownload } from "react-icons/gr";
import Utils from  '../../utils.json';

const RecordAnalyzer = () => {
  const navigate = useNavigate();

  useEffect(() => {
    let ls = localStorage.getItem("admin");
    if (!ls) {
      navigate("/login");
    }
    return;
  });
  const [spmsRecord, setSpmsRecord] = useState([]);
  const [loadingbar, setLoadingBar] = useState(true);
  const [stafflist, setStaffList] = useState([]);

  const [staffid, setstaffid] = useState("");
  const [tempstaffid, settempstaffid] = useState("");
  const [meetingwith, setmeetingwith] = useState("");

  // action plan
  const [actionwith, setactionwith] = useState("");
  const [tempactionwith, settempactionwith] = useState("");
  const [actionnote, setactionnote] = useState("");
  

  const [meetingconclusion, setmeetingconclusion] = useState("");
  const [area, setarea] = useState("");
  const [destination, setdestination] = useState("");
  const [flightno, setflightno] = useState("");
  const [recordtype, setrecordtype] = useState("");
  const [reportedby, setreportedby] = useState("");
  const [tempreportedby, settempreportedby] = useState("");
  const [comments, setcomments] = useState("");

  const [startdate, setStartDate] = useState("");
  const [enddate, setEndDate] = useState("");

  // filter
  const [applyFilter, setApplyFilter] = useState(false);
  const [stafffetchlimit, setstafffetchlimit] = useState(20);

  useEffect(() => {
    fetch(`${Utils['domains']['development']}/spms/getstafflist`, {
      method: "get",
      headers: {
        "Content-Type": "application/json", // Specify that you're sending JSON data
      },
      // body: JSON.stringify({}), // Set the JSON data as the request body
    })
      .then((e) => {
        return e.json();
      })
      .then((data) => {
        setTimeout(() => {
          //   setLoadingBar(false);
          setStaffList(data);
        }, 100);
      });
  }, []);

  useEffect(() => {
    fetch(`${Utils['domains']['development']}/spms/viewrecordsaggregate`, {
      method: "get",
      headers: {
        "Content-Type": "application/json", // Specify that you're sending JSON data
      },
      // body: JSON.stringify({}), // Set the JSON data as the request body
    })
      .then((e) => {
        return e.json();
      })
      .then((data) => {
        setTimeout(() => {
          setLoadingBar(false);
          console.clear()
          console.log('---------------------------------------------------------------------------------------------------DATA---------------------------------------------------------------------------------------------------')
          console.log(data)
          setSpmsRecord(data);
        }, 0);
      });
  }, []);

  // useEffect(() => {
  //   if (applyFilter) {
  //     // let data = { staffid, staffcategory, stafffetchlimit, briefingcategory };
  //     fetch(
  //       `${Utils['domains']['development']}/spms/viewrecordbyfilter?staffid=${staffid}&area=${area}&destination=${destination}&flightno=${flightno}&recordtype=${recordtype}&reportedby=${reportedby}&limit=${stafffetchlimit}&startdate=${startdate}&enddate=${enddate}`,
  //       {
  //         method: "get",
  //         headers: {
  //           "Content-Type": "application/json", // Specify that you're sending JSON data
  //         },
  //         //   body: JSON.stringify(data), // Set the JSON data as the request body
  //       }
  //     )
  //       .then((e) => {
  //         return e.json();
  //       })
  //       .then((data) => {
  //         setTimeout(() => {
  //           setLoadingBar(false);

  //           setSpmsRecord(data);
  //           setApplyFilter(false);
  //         }, 0);
  //       });
  //   }
  // }, [applyFilter]);



  const handleMeetingClosed = (staffid) => {
    // console.log(spmsRecord)

    if (meetingwith === "") {
      toast.error("Meeting Host is required", { autoClose: 2000 });
      return false;
    }

    if (meetingconclusion === "") {
      toast.error("Meeting Conclusion is required", { autoClose: 2000 });
      return false;
    }

    let recordsids = [];
    spmsRecord.map((staff) => {
      if (staff._id === staffid) {
        const record = staff["records"].map((record) => {
          console.log(record);
          if (record.meetingdone === "not-yet") {
            recordsids.push(record["_id"]);
          }
        });
      }
    });

    const data = {
      recordsids,
      meetingwith,
      meetingconclusion,
      staffid,
      actionwith,
      actionnote
    };

    fetch(`${Utils['domains']['development']}/spms/updaterecord`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json", // Specify that you're sending JSON data
      },
      body: JSON.stringify(data), // Set the JSON data as the request body
    }).then((e) => {
      toast("Meeting CLosed successfully", { autoClose: 2000 });
      // setTitle("");
      // setDesignation("all");
      // setCategory("SOP");
      setTimeout(() => {
        navigate("/spms/viewrecord");
      }, 1000);
    });
  };

  return (
    <div>
      <Navbar title={"Records Analyzer"} showarrow={false} />
      {loadingbar && <LoadingBar />}
    
      <div class="relative rounded-xl overflow-auto">
        <div class="shadow-sm overflow-x-scroll  lg:overflow-hidden my-8">
          {!spmsRecord.length <= 0 && (
            <StaffTable
              loadingbar={loadingbar}
              spmsRecord={spmsRecord}
              stafflist={stafflist}
              tempstaffid={tempstaffid}
              settempstaffid={settempstaffid}
              actionwith={actionwith} setactionwith={setactionwith}
              tempactionwith={tempactionwith} settempactionwith={settempactionwith} 
              actionnote={actionnote} setactionnote={setactionnote}
              setstaffid={setstaffid}
              handleMeetingClosed={handleMeetingClosed}
              meetingwith={meetingwith}
              setmeetingwith={setmeetingwith}
              meetingconclusion={meetingconclusion}
              setmeetingconclusion={setmeetingconclusion}
            />
          )}{" "}
        </div>{" "}
      </div>
    </div>
  );
};

const StaffTable = ({
  loadingbar,
  spmsRecord,
  stafflist,
  tempstaffid,
  settempstaffid,
  actionwith, setactionwith, tempactionwith, settempactionwith, actionnote, setactionnote,
  setstaffid,
  handleMeetingClosed,
  meetingwith,
  setmeetingwith,
  meetingconclusion,
  setmeetingconclusion,
}) => {
  return (
    <table class="border-collapse  w-full  text-sm rounded-lg overflow-x-scroll lg:overflow-hidden mt-5">
      <thead className="bg-gradient-to-r from-a-pink to-a-blue text-white">
        <tr>
          <td class="border-b border-r border-slate-100 dark:border-slate-700 p-4 pl-8  text-white">
            Staff Id
          </td>
          <td class="border-b border-r border-slate-100 dark:border-slate-700 p-4  text-white">
            Staff Name
          </td>
         
          <td class="border-b border-r border-slate-100 dark:border-slate-700 p-4  text-white">
            Area
          </td>
          <td class="border-b border-r border-slate-100 dark:border-slate-700 p-4  text-white">
            Destination
          </td>
          <td class="border-b border-r border-slate-100 dark:border-slate-700 p-4 pr-8  text-white">
            Flight no
          </td>
          <td class="border-b border-r border-slate-100 dark:border-slate-700 p-4 pr-8  text-white">
            Report Type
          </td>
          <td class="border-b border-r border-slate-100 dark:border-slate-700 p-4 pr-8  text-white">
            Reported By
          </td>
          <td class="border-b border-r border-slate-100 dark:border-slate-700 p-4 pr-8  text-white">
            Comments
          </td>
          <td class="border-b border-r border-slate-100 dark:border-slate-700 p-4 pr-8  text-white">
            Date
          </td>
          <td class="border-b border-r border-slate-100 dark:border-slate-700 p-4 pr-8  text-white">
            Attachments
          </td>
        </tr>
      </thead>
      <tbody class="bg-a-gray text-white">
        {/* {console.log(spmsRecord)} */}
        {spmsRecord.map((staff) => {
          // console.log(staff)
          return (
            <>
              <tr className="bg-white text-a-gray">
                <td
                  colSpan="10"
                  class="border-b border-r border-slate-100 dark:border-slate-700 p-4  text-a-gray font-bold text-xl"
                >
                  <div className="w-full flex justify-between ">
                    <div>
                      {staff.staffDetails["staffid"]} -{" "}
                      {staff.staffDetails["staffname"]} -{" "} <br/>
                      <span className="text-a-pink">
                      {staff.staffDetails["shift"][0]['shiftadm']}

                      </span>
                    </div>
                    <div className="flex gap-3 ">
                      <div className="grid gap-3  grid-cols-2 ">

                      <p>Meeting Host</p>
                      <p>Action Plan</p>

                        <input
                          type="search"
                          className="h-10 rounded-lg px-3 font-normal text-sm bg-[#eee] "
                          placeholder={
                            stafflist.length == 0
                              ? "Loading..."
                              : "Meeting with"
                          }
                          value={tempstaffid}
                          onChange={(e) => {
                            let extractstaffid = e.target.value.split(" - ")[3];
                            let extracttempstaffid =
                              e.target.value.split(" - ")[1];
                            if (e.target.value === "") {
                              extractstaffid = "";
                              extracttempstaffid = "";
                            }
                            settempstaffid(extracttempstaffid);
                            setmeetingwith(extractstaffid);
                          }}
                          list="stafflist"
                        />
                        <datalist id="stafflist">
                          {stafflist.map((staff, index) => {
                            return (
                              <option
                                value={
                                  staff.staffid +
                                  " - " +
                                  staff.staffname +
                                  " - " +
                                  staff.staffcategory +
                                  " - " +
                                  staff._id
                                }
                              />
                            );
                          })}
                        </datalist>

                        {/*  */}
                        <input
                          type="search"
                          className="h-10 rounded-lg px-3 font-normal text-sm bg-[#eee] "
                          placeholder={
                            stafflist.length == 0
                              ? "Loading..."
                              : "Assign To"
                          }
                          value={tempactionwith}
                          onChange={(e) => {
                            let extractstaffid = e.target.value.split(" - ")[3];
                            let extracttempstaffid =
                              e.target.value.split(" - ")[1];
                            if (e.target.value === "") {
                              extractstaffid = "";
                              extracttempstaffid = "";
                            }
                            settempactionwith(extracttempstaffid);
                            setactionwith(extractstaffid);
                          }}
                          list="actionwith"
                        />
                        <datalist id="actionwith">
                          {stafflist.map((staff, index) => {
                            return (
                              <option
                                value={
                                  staff.staffid +
                                  " - " +
                                  staff.staffname +
                                  " - " +
                                  staff.staffcategory +
                                  " - " +
                                  staff._id
                                }
                              />
                            );
                          })}
                        </datalist>

                        <textarea
                          onChange={(e) => {
                            setmeetingconclusion(e.target.value);
                          }}
                          type="text"
                          value={meetingconclusion}
                          className="h-16 rounded-lg p-3 w-[300px] font-normal text-sm bg-[#eee] "
                          placeholder="Conclusion"
                        />

                        <textarea
                          onChange={(e) => {
                            setactionnote(e.target.value);
                          }}
                          type="text"
                          value={actionnote}
                          className="h-16 rounded-lg p-3 w-[300px] font-normal text-sm bg-[#eee] "
                          placeholder="Action Note"
                        />
                      </div>

                      <button
                        onClick={() => handleMeetingClosed(staff._id)}
                        className="  bg-gradient-to-r from-a-pink to-a-blue hover:to-a-pink hover:from-a-blue px-12 h-10 rounded-lg text-white uppercase text-xs"
                      >
                        Close Meeting
                      </button>
                    </div>
                  </div>
                </td>
                {/* <td colSpan="5" class="border-b border-r border-slate-100 dark:border-slate-700 p-4  text-white">
                {staff.staffDetails['staffname']}
              </td> */}
              </tr>
              {staff.records.map((record, index) => {
                // {console.log('record-----------------------', record['attachments'])}

                return (
                  record.meetingdone == "not-yet" && (
                    <TableRow staff={record} key={index} />
                  )
                );
              })}
            </>
          );
        })}
      </tbody>
    </table>
  );
};



const TableRow = ({ staff }) => {
  return (
    <tr>
      <td class="border-b border-r border-slate-100 dark:border-slate-700 p-4  text-white">
        {/* {staff.staffid["staffid"]} */}
        {/* {staff["staffid"]} */}
      </td>
      <td class="border-b border-r border-slate-100 dark:border-slate-700 p-4 pr-8  text-white">
        {/* {staff["staffname"]} */}
      </td>
      <td class="border-b border-r border-slate-100 dark:border-slate-700 p-4 pr-8  text-white">
        {staff.area}
      </td>
      <td class="border-b border-r border-slate-100 dark:border-slate-700 p-4 pr-8  text-white">
        {staff.destination}
      </td>
      <td class="border-b border-r border-slate-100 dark:border-slate-700 p-4 pr-8  text-white">
        {staff.flightno}
      </td>
      <td class="border-b border-r border-slate-100 dark:border-slate-700 p-4 pr-8  text-white">
        {staff.recordtype}
      </td>
      <td class="border-b border-r border-slate-100 dark:border-slate-700 p-4 pr-8  text-white">
        {/* {staff.reportedby.staffname} */}
        {staff.reportedby}
      </td>
      <td class="border-b border-r border-slate-100 dark:border-slate-700 p-4 pr-8  text-white">
        <div
          dangerouslySetInnerHTML={{
            __html: staff["comments"].substring(0, 1000),
          }}
        />
      </td>
      <td class="border-b border-r border-slate-100 dark:border-slate-700 p-4 pr-8  text-white">
        {staff.createdDate}
      </td>
      <td class="border-b border-r border-slate-100 dark:border-slate-700 p-4   text-a-gray">
        <div className="flex gap-1 bg-white px-2 py-1 rounded-md ">
          {staff["attachments"].length <= 0 && (
            <p className="">No Attachment!</p>
          )}
          {staff.attachments.map((file, index) => {
            return (
              <a
                target="_blank"
                href={`${Utils['domains']['development']}/uploads/${file.newfilename}`}
                download={`${Utils['domains']['development']}/uploads/${file.newfilename}`}
              >
                <GrDocumentDownload className="inline-block text-white" />
              </a>
            );
          })}
        </div>
      </td>
    </tr>
  );
};

export default RecordAnalyzer;
