import React, { useEffect, useState } from "react";
import Navbar from "../Global/Navbar";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import LoadingBar from "../Global/LoadingBar";
import { PiMicrosoftExcelLogoFill } from "react-icons/pi";
import {AiFillDelete} from "react-icons/ai"
import {TiTick} from "react-icons/ti"
import Utils from  '../../utils.json';

const Logs = () => {
  const navigate = useNavigate();

  useEffect(()=>{
    let ls = localStorage.getItem('admin');
    if (!ls) {
      navigate('/login')
    }
    return;
  })
  const [staffList, setStaffList] = useState([]);
  const [loadingbar, setLoadingBar] = useState(true);

  // create new staff
  const [staffname, setStaffName] = useState('');
  const [staffid, setStaffId] = useState('');
  const [staffcategory, setStaffCategory] = useState('');
  const [staffbriefing, setStaffbriefing] = useState(0);
  const [staffpassword, setStaffPassword] = useState('');
  const [newCreateNewStaff, setNewCreateNewStaff] = useState(false);
  const [controlpanelaccess, setcontrolpanelaccess] = useState(false);

  // filter
  const [applyFilter, setApplyFilter] = useState(false);
  const [stafffetchlimit, setstafffetchlimit] = useState(20);

  // new state
  const [logslist, setloglist] = useState([])



  
  useEffect(() => {
    fetch(`${Utils['domains']['development']}/logs/getlogslist`, {
      method: "get",
      headers: {
        "Content-Type": "application/json", // Specify that you're sending JSON data
      },
      // body: JSON.stringify({}), // Set the JSON data as the request body
    })
      .then((e) => {
        return e.json();
      })
      .then((data) => {
        setTimeout(() => {
          setLoadingBar(false);
          setloglist(data);
          console.log(data)
        }, 0);
      });
  }, []);

  // useEffect(() => {
  //   fetch(`${Utils['domains']['development']}/staff/view`, {
  //     method: "get",
  //     headers: {
  //       "Content-Type": "application/json", // Specify that you're sending JSON data
  //     },
  //     // body: JSON.stringify({}), // Set the JSON data as the request body
  //   })
  //     .then((e) => {
  //       return e.json();
  //     })
  //     .then((data) => {
  //       setTimeout(() => {
  //         setLoadingBar(false);
  //         setStaffList(data);
  //       }, 0);
  //     });
  // }, [newCreateNewStaff]);

  useEffect(() => {
    if (applyFilter) {
    let data = { staffname, staffid, staffcategory, stafffetchlimit }
    fetch(`${Utils['domains']['development']}/staff/viewbyfilter`, {
      method: "post",
      headers: {
        "Content-Type": "application/json", // Specify that you're sending JSON data
      },
      body: JSON.stringify(data), // Set the JSON data as the request body
    })
      .then((e) => {
        return e.json();
      })
      .then((data) => {
        setTimeout(() => {
          setLoadingBar(false);
          setStaffList(data);
          setApplyFilter(false);
        }, 0);
      });
    }
  }, [applyFilter]);

 

  const HandleExportAsExcel = () => {

    const newStaffList = staffList.map((item) => {
      const updatedItem = { ...item };
      delete updatedItem.staffbriefing;
      delete updatedItem._id;
      // delete updatedItem.briefingid;
      delete updatedItem.__v;
      return updatedItem;
    });
    // const newStaffList = chartsBriefingList;

    const separator = ",";
    const keys = Object.keys(newStaffList[0]);
    const csvContent = [
      keys.join(separator),
      ...newStaffList.map((item) =>
        keys.map((key) => item[key]).join(separator)
      ),
    ].join("\n");

    const blob = new Blob([csvContent], { type: "text/csv" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "data.csv";
    a.click();
    URL.revokeObjectURL(url);
    setTimeout(() => {
      a.remove()
    }, 1000);
  };

  

  return (
    <div>
      <Navbar title={"Staff List"} showarrow={false} />
      {loadingbar && <LoadingBar />}

      <AddNewStaffPanel
        staffid={staffid}
        staffname={staffname}
        staffcategory={staffcategory}
        HandleExportAsExcel={HandleExportAsExcel}

        controlpanelaccess={controlpanelaccess}
        setcontrolpanelaccess={setcontrolpanelaccess}

        setStaffPassword={setStaffPassword}
        staffpassword={staffpassword}

        setStaffId={setStaffId}
        setStaffName={setStaffName}
        setStaffCategory={setStaffCategory}

       
        setApplyFilter={setApplyFilter}

        setLoadingBar={setLoadingBar}
        stafffetchlimit={stafffetchlimit}
        setstafffetchlimit={setstafffetchlimit}
      />

      <div class="relative rounded-xl overflow-auto">
        <div class="shadow-sm overflow-x-scroll  lg:overflow-hidden my-8">
      {logslist.length <= 0 && !loadingbar && <div className="">No Log added yet!</div>}

          {!logslist.length <= 0 && <StaffTable loadingbar={loadingbar} logslist={logslist} />}
        </div>
      </div>
    </div>
  );
};



const AddNewStaffPanel = ({        
  staffid,
  staffname,
  staffcategory,
  setStaffId,
  setStaffName,
  setStaffCategory,
  handleAddNewStaff,
  controlpanelaccess,
  setcontrolpanelaccess,

  setApplyFilter,
  setLoadingBar,

  stafffetchlimit,
  setstafffetchlimit,

  setStaffPassword,
  staffpassword,
  HandleExportAsExcel

}) => {

  const handleApplyFilter = () => {
    setApplyFilter(true)
    setLoadingBar(true)
  }
  return (
    <div className="bg-a-gray py-4 mt-5 rounded-lg flex flex-wrap lg:flex-nowrap  gap-3 items-center px-5 w-full   h-auto">
      <input
        type="text"
        className="h-10 rounded-lg px-3 w-20"
        placeholder="Staff Id"
        value={staffid}
        onChange={(e) => {
          setStaffId(e.target.value);
        }}
      />
           <input
        type="text"
        className="h-10 rounded-lg px-3"
        placeholder="Staff Name"
        value={staffname}
        onChange={(e) => {
          setStaffName(e.target.value);
        }}
      />
        
        <input
        type="text"
        className="h-10 rounded-lg px-3 "
        placeholder="Password"
        value={staffpassword}
        onChange={(e) => {
          setStaffPassword(e.target.value);
        }}
      />
      <select
        onChange={(e) => {
          setStaffCategory(e.target.value);
        }}
        value={staffcategory}
        className="h-10 rounded-lg px-3"
        placeholder="Designation"
      >
        <option value="">All</option>
        <option value="aa">AA</option>
        <option value="pa">PA</option>
        <option value="psa">PSA</option>
        <option value="tl">TL</option>
        <option value="css">CSS</option>
        <option value="csm">CSM</option>
        <option value="vct">VCT</option>
        <option value="hala">Hala</option>
      </select>
      <p className="text-white">
      CP Access
      </p>
      
      <input
        type="checkbox"
        className="h-10 rounded-lg px-3"
        placeholder="Staff Name"
        value={controlpanelaccess}
        onChange={(e) => {
          setcontrolpanelaccess(e.target.checked);
        }}
      />
      <select
        onChange={(e) => {
          setstafffetchlimit(e.target.value);
        }}
        value={stafffetchlimit}
        className="h-10 rounded-lg px-3"
        placeholder="limit"
      >
        <option value="5">5</option>
        <option selected value="10">10</option>
        <option value="20">20</option>
        <option value="30">30</option>
      </select>
    
      <button onClick={handleApplyFilter} className="  bg-gradient-to-r from-a-pink to-a-blue hover:to-a-pink hover:from-a-blue px-12 h-10 rounded-lg text-white uppercase text-xs">
        Apply
      </button>
      {/* <button
        onClick={HandleExportAsExcel}
        className="flex items-center gap-2  bg-green-600 px-12 h-10 rounded-lg text-white uppercase text-xs"
      >
        <PiMicrosoftExcelLogoFill size="22px" />
        Export Excel
      </button> */}
    </div>
  );
};



const StaffTable = ({ loadingbar, logslist }) => {
  return (
    <table class="border-collapse  w-full text-sm rounded-lg overflow-x-scroll    ">
      <thead className="bg-gradient-to-r from-a-pink to-a-blue text-white">
         
          <tr>
            <td class="border-b border-r border-slate-100 dark:border-slate-700 p-4 pl-8  text-white">
              Log Title
            </td>
            <td class="border-b border-r border-slate-100 dark:border-slate-700 p-4  text-white">
              Log Category
            </td>
            <td class="border-b border-r border-slate-100 dark:border-slate-700 p-4  text-white">
              Log by
            </td>
            <td class="border-b border-r border-slate-100 dark:border-slate-700 p-4  text-white">
              Created Date
            </td>
            <td class="border-b border-r border-slate-100 dark:border-slate-700 p-4 pr-8  text-white">
              Created Time
            </td>
            
            
          </tr>
        
      </thead>
      <tbody class="bg-a-gray text-white">
        {logslist.map((log) => {
          return <TableRow log={log}  />;
        })}
      </tbody>
    </table>
  );
};

const TableRow = ({ log }) => {
  return (
    <tr>
      <td class="border-b border-r border-slate-100 dark:border-slate-700 p-4 pl-8  text-white">
        {log.logtitle}
      </td>
      <td class="border-b border-r border-slate-100 dark:border-slate-700 p-4  text-white">
      {log.logcategory}
      </td>
      <td class="border-b border-r border-slate-100 dark:border-slate-700 p-4  text-white">
      {log.logby.staffname}
      </td>

      <td class="border-b border-r border-slate-100 dark:border-slate-700 p-4  text-white">
      {log.createdDate}
      </td>
      <td class="border-b border-r border-slate-100 dark:border-slate-700 p-4  text-white">
      {log.createdTime}
      </td>

     
    </tr>
  );
};

export default Logs;
