import React from 'react'
import { Link } from "react-router-dom"

import { LuCloudCog } from "react-icons/lu";
import { FaFileSignature, FaChartArea, FaChalkboardTeacher, FaUserFriends } from "react-icons/fa"

const SBPApps = ({ }) => {
  return (
    <div className='bg-white h-screen p-5 rounded-lg'>
      <div className='grid grid-cols-3 gap-5'>
        <AppTile title="BMS" color="#23CE6B" Icon={FaFileSignature} link="/" />
        <AppTile title="SPMS" color="#A846A0" Icon={FaChartArea} link="/spms/addrecord" />
        <AppTile title="STMS" color="#FFA62B" Icon={FaChalkboardTeacher} />
        <AppTile title="REAZ" color="#D04848" Icon={LuCloudCog} link="/spms/recordanalyzer" />
        <AppTile title="Users" color="#058ED9" Icon={FaUserFriends} link="/stafflist" />


      </div>
    </div>
  )
}

const AppTile = ({ title, color, Icon, link }) => {
  return (
    <Link to={link}>
      <div className='bg-white h-40 p-3 px-10 rounded-md flex items-center justify-between' style={{ backgroundColor: color }}>
        <Icon size={70} className="text-white" />
        <div className='flex flex-col gap-2 items-end'>
          <p className='text-4xl text-white'>{title}</p>
          <button className='bg-[#fff5] font-bold text-white uppercase rounded w-[60px] h-[25px] text-xs mt-5'>Open</button>
        </div>
      </div>
    </Link>
  )
}

export default SBPApps